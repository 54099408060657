import React, { Component } from "react";
// import { isAuthenticated } from "../auth";
import Footer from '../core/Footer';
import ReactGA from 'react-ga';
import { getSchedule } from './apiEvents';
import moment from "moment";
import Paper from '@material-ui/core/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    Resources,
    MonthView,
    Toolbar,
    DateNavigator,
    Appointments,
    AppointmentTooltip,
    TodayButton
} from '@devexpress/dx-react-scheduler-material-ui';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Room from '@material-ui/icons/Room';


const style = ({ palette }) => ({
    icon: {
        color: palette.action.active,
    },
    textCenter: {
        textAlign: 'center',
    }
});

// const styles = theme => ({
//     container: {
//         display: 'flex',
//         marginBottom: theme.spacing(2),
//         justifyContent: 'flex-end',
//     },
//     text: {
//         ...theme.typography.h6,
//         marginRight: theme.spacing(2),
//     },
//     header: {
//         height: '260px',
//         backgroundSize: 'cover',
//     },
//     formControl: {
//         margin: theme.spacing(1),
//         minWidth: 120,
//     },
//     selectEmpty: {
//         marginTop: theme.spacing(2),
//     },
//     physical: {
//         backgroundColor: '#FFFDE7',
//     }
// });


const Content = withStyles(style, { name: 'Content' })(({
    children, appointmentData, classes, ...restProps
}) => (
    <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
        <Grid container alignItems="center">
            <Grid item xs={2} className={classes.textCenter}>
                <Room className={classes.icon} />
            </Grid>
            <Grid item xs={10}>
                <span>
                    <hr />
                    {/* <p><a href={appointmentData.notes} target='_blank'>Join Zoom Here</a></p>  */}
                    <p>{appointmentData.notes && appointmentData.notes.split(",")[0]}</p>
                    {appointmentData.notes && <p><a href={appointmentData.notes.split(",")[1]} target='_blank' rel="noreferrer">Join Zoom Here</a></p>}
                </span>
            </Grid>

        </Grid>
    </AppointmentTooltip.Content>
));



class AllEvents extends Component {

    constructor(props) {
        super(props)
        this.state = {
            allEvents: []
        }
    }

    componentDidMount() {

        
        getSchedule('Physical', this.state.token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                // this.setState({ allEvents: data}) 
                getSchedule('Electrical', this.state.token).then(edata => {
                    if (edata.error) {
                        console.log(edata.error);
                    } else {
                        getSchedule('Innovation', this.state.token).then(gdata => {
                            if (data.error) {
                                console.log(gdata.error);
                            } else {

                                getSchedule('Civil', this.state.token).then(cdata => {
                                    if (data.error) {
                                        console.log(cdata.error);
                                    } else {

                                        getSchedule('Business', this.state.token).then(bdata => {
                                            if (data.error) {
                                                console.log(bdata.error);
                                            } else {
                                                getSchedule('Technology', this.state.token).then(ldata => {
                                                    if (data.error) {
                                                        console.log(ldata.error);
                                                    } else {

                                                        var newData = [...data, ...edata, ...gdata, ...cdata, ...bdata, ...ldata]
                                                        this.setState({ allEvents: newData });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

        ReactGA.initialize('UA-331790-15');
        ReactGA.pageview('/allevents');
    };

    resourceList = [
        {
            fieldName: 'general',
            title: 'General Sessions',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Opening Session', color: '#4caf50' },
                { id: 2, text: 'Closing Session', color: '#4caf50' },
                { id: 3, text: 'Committee Update', color: '#4caf50' },
                { id: 4, text: 'Vendor Company Update', color: '#4caf50' },
                { id: 5, text: 'Vendor Presentation', color: '#4caf50' },
                { id: 6, text: 'Leadership Meeting', color: '#4caf50' }
            ],
        },
        {
            fieldName: 'bizstrategy',
            title: 'Business Strategy Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#000099' },
                { id: 2, text: 'Committee Round Table', color: '#000099' },
                { id: 3, text: 'Committee Presentation', color: '#000099' },
                { id: 4, text: 'Vendor Presentation', color: '#000099' },
                { id: 5, text: 'User Presentation', color: '#000099' },
                { id: 6, text: 'Best Practice Discussion', color: '#000099' },
                { id: 7, text: 'Committee Closing Session', color: '#000099' },
                { id: 8, text: 'IACET Presentation', color: '#000099' }
            ],
        },
        {
            fieldName: 'civil',
            title: 'Civil Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#795548' },
                { id: 2, text: 'Committee Round Table', color: '#795548' },
                { id: 3, text: 'Committee Presentation', color: '#795548' },
                { id: 4, text: 'Vendor Presentation', color: '#795548' },
                { id: 5, text: 'User Presentation', color: '#795548' },
                { id: 6, text: 'Best Practice Discussion', color: '#795548' },
                { id: 7, text: 'Committee Closing Session', color: '#795548' },
                { id: 8, text: 'IACET Presentation', color: '#795548' }
            ],
        },
        {
            fieldName: 'electrical',
            title: 'Electrical Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#f44336' },
                { id: 2, text: 'Committee Round Table', color: '#f44336' },
                { id: 3, text: 'Committee Presentation', color: '#f44336' },
                { id: 4, text: 'Vendor Presentation', color: '#f44336' },
                { id: 5, text: 'User Presentation', color: '#f44336' },
                { id: 6, text: 'Best Practice Discussion', color: '#f44336' },
                { id: 7, text: 'Committee Closing Session', color: '#f44336' },
                { id: 8, text: 'IACET Presentation', color: '#f44336' }
            ],
        },
        {
            fieldName: 'physical',
            title: 'Physical Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#993399' },
                { id: 2, text: 'Committee Round Table', color: '#993399' },
                { id: 3, text: 'Committee Presentation', color: '#993399' },
                { id: 4, text: 'Vendor Presentation', color: '#993399' },
                { id: 5, text: 'User Presentation', color: '#993399' },
                { id: 6, text: 'Best Practice Discussion', color: '#993399' },
                { id: 7, text: 'Committee Closing Session', color: '#993399' },
                { id: 8, text: 'IACET Presentation', color: '#993399' }
            ],
        },
        {
            fieldName: 'technology',
            title: 'Technology Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#339966' },
                { id: 2, text: 'Committee Round Table', color: '#339966' },
                { id: 3, text: 'Committee Presentation', color: '#339966' },
                { id: 4, text: 'Vendor Presentation', color: '#339966' },
                { id: 5, text: 'User Presentation', color: '#339966' },
                { id: 6, text: 'Best Practice Discussion', color: '#339966' },
                { id: 7, text: 'Committee Closing Session', color: '#339966' },
                { id: 8, text: 'IACET Presentation', color: '#339966' }
            ],
        }
    ]

    render() {

        return (
            <div className="container-fluid">
                <h4>All SDSIC Events</h4>
                <div className="row">
                    <Paper elevation={12} >
                        <Scheduler
                            data={this.state.allEvents}
                        >
                            <ViewState
                                defaultCurrentDate={moment().format("YYYY-MM-DD")}
                            />
                            <MonthView />
                            <Toolbar />
                            <DateNavigator />
                            <TodayButton />
                            <Appointments />
                            <AppointmentTooltip
                                contentComponent={Content}
                            />
                            <Resources
                                data={this.resources}
                            />

                        </Scheduler>
                    </Paper>

                </div>
                <Footer />
            </div>
        )
    }
};

export default AllEvents;