
// with pagination
export const list = page => {
    return fetch(`${process.env.REACT_APP_API_URL}/group/?page=${page}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const loadSurveyData = (token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/surveyResults`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const sendMessage = (message) => {
    return fetch(`${process.env.REACT_APP_API_URL}/mail/sendinBlueEmail`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(message)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const chatbotapi = question => {

    return fetch(`${process.env.REACT_APP_API_URL}/qna/searchAnswers`, {
        method: "POST",
        'headers': {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "question": question })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}

//Get all groups
export const listGroups = (token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/groups`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// // Check user access
// export const groupAccessforUser = (token, userId) => {
//     return fetch(`${process.env.REACT_APP_API_URL}/group/member/${userId}`, {
//         method: "GET",
//         headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`
//         }
//     })
//         .then(response => {
//             return response.json();
//         })
//         .catch(err => console.log(err));
// };

export const singleGroup = groupId => {
    return fetch(`${process.env.REACT_APP_API_URL}/group/${groupId}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const groupDetails = (groupId) => {
    return fetch(`${process.env.REACT_APP_API_URL}/groupdetails`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ groupId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}


export const removeUserFromGroup = (token, groupId, userId) => {
    //console.log(groupId, userId)
    return fetch(`${process.env.REACT_APP_API_URL}/group/removeMember`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ groupId, userId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};



export const addUsertoGroup = (token, groupId, userId) => {
    //console.log(groupId, userId)
    return fetch(`${process.env.REACT_APP_API_URL}/group/addMember`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ groupId, userId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const listPresentationsByGroup = (groupId, token, year) => {
    return fetch(`${process.env.REACT_APP_API_URL}/presentationsbyGroup/${groupId}/${year}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

