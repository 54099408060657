import React, { Component } from 'react';
import { isAuthenticated } from '../auth';
import { createNewFile } from './apiFiles';
import { groupDetails } from "../group/apigroup";
import Footer from '../core/Footer';
import LoadingOverlay from 'react-loading-overlay';

class CreateFile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            title: '',
            description: '',
            fileCategory: '',
            file: '',
            groupId: this.props.match.params.groupId,
            groupName: '',
            formData: '',
            error: '',
            success: false,
            fileInputKey: '',
            loading: false
        }
        //any bindinds here
        this.fileForm = this.fileForm.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.getCommitteeName = this.getCommitteeName.bind(this);
    }

    token = isAuthenticated() && isAuthenticated().token;
    userId = isAuthenticated().userId

    componentDidMount() {
        this.setState({formData : new FormData() });        
        this.getCommitteeName(this.state.groupId)
    };


    handleChange = name => event => {
        let value = "";
        if (name === 'file') {
            value = event.target.files[0]
        } else {
            value = event.target.value
        }
        this.setState({ error: "" });
        this.setState({ [name]: value });
        this.state.formData.append(name, value);
    };

    getCommitteeName() {
        groupDetails(this.state.groupId).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({ groupName: data.name })
            }
        });
    };

    clickSubmit = event => {
        event.preventDefault();
        this.state.formData.append("groupId", this.state.groupId);
        this.setState({loading: true })
        createNewFile(this.token, this.userId, this.state.formData).then(data => {
            if (data.error) this.setState({ error: data.error, success: false });
            else
                this.setState({
                    error: "",
                    title: "",
                    description: "",
                    fileCategory: "",
                    file: "",
                    success: true,
                    formData: new FormData(),
                    groupId: this.props.match.params.groupId,
                    fileInputKey: this.state.file.value ? this.state.file.value.name : +new Date(),
                    loading: false
                });
        });
    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };
    showSuccess() {
        return (
            <div className="alert alert-success" style={{ display: this.state.success ? "" : "none" }}>
                File Created Successfully!
            </div>
        )
    };

    fileForm = () => (
        <form >
            <div>
                <p><h4>Your are adding a file to the {this.state.groupName}</h4></p>
            </div>
            <div className="form-group">
                <label className="text-muted">SDSIC File Name (replaces file name uploaded)</label>
                <input
                    onChange={this.handleChange("title")}
                    type="text"
                    className="form-control"
                    value={this.state.title}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Description</label>
                <textarea
                    onChange={this.handleChange("description")}
                    type="text"
                    className="form-control"
                    value={this.state.description}
                />
            </div>
            <div className="form-group">
                <label className="text-muted">File Category</label>
                <select onChange={this.handleChange('fileCategory')} value={this.state.fileCategory} className="form-control">
                    <option value="" selected></option>
                    <option value="SDSIC Leadership">SDSIC Leadership</option>
                    <option value="SDSIC Position Statement">SDSIC Position Statement</option>
                    <option value="SDSIC Proposals">SDSIC Proposals</option>
                    <option value="SDSIC Newsletter">SDSIC Newsletter</option>
                    <option value="SDSIC Event Links">SDSIC Event Links</option>
                    <option value="SDSIC Event Presentations">SDSIC Event Presentations</option>
                    <option value="SDSIC Event Handouts">SDSIC Event Handouts</option>
                    <option value="Lisp File">Lisp Files</option>
                    <option value="Script File">Script Files</option>
                    <option value="iLogic File">iLogic Files</option>
                    <option value="Training">Training</option>
                    <option value="Best Practices">Best Practices</option>
                    <option value="Presentation">Presentation</option>
                    <option value="White Paper">White Paper</option>
                    <option value="Meeting Agenda">Meeting Agenda</option>
                    <option value="Meeting Invite">Meeting Invite</option>
                    <option value="Meeting Minutes">Meeting Minutes</option>
                </select>
            </div>
            <div className="form-group">
                <label className="text-muted">file</label>
                <input
                    key={this.state.fileInputKey}
                    onChange={this.handleChange("file")}
                    type="file"
                    accept="*"
                    className="form-control"
                />
            </div>

            <button onClick={this.clickSubmit}
                className="btn btn-raised btn-primary">
                Create New File</button>

        </form>
    );

    render() {
        return (
            <LoadingOverlay
            active={this.state.loading}
            spinner
            text='Uploading your file ...'
        >
            <div>
                <div>
                    {this.showError()}
                    {this.showSuccess()}
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={`/singleGroup/${this.props.match.params.groupId}`}>Back to {this.state.groupName}</a></li>
                            <li className="breadcrumb-item active" aria-current="page" >Add File</li>
                        </ol>
                    </nav>
                    {this.fileForm()}
                <br/>
                </div>
                <Footer />
            </div>
            </LoadingOverlay>
        )
    }

};

export default CreateFile;