import React, { Component } from 'react'
import { listFilesBycategoryForGroup } from './apiFiles';
import { isAuthenticated } from '../auth';
import SecureLink from './secureLink';
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import 'bootstrap/dist/css/bootstrap.min.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


class FileList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            groupId: this.props.groupId
        };
    }

    token = isAuthenticated() && isAuthenticated().token;


    listFiles = () => {
        listFilesBycategoryForGroup(this.state.groupId, this.token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({
                    categories: data
                });
            }
        });
    };

    componentDidMount() {
        this.listFiles();
    }


    renderFiles = (categories, groupId) => {
        return (
            <div>
                <Accordion defaultActiveKey="0">
                    {categories.map((category, i) => {
                        return (
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey={i}>
                                        {category._id} <Badge variant="info">{category.count}</Badge> <ArrowDropDownIcon/>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={i}>
                                    <Card.Body>
                       
                                        {category.data.map((file, i) => {
                                            return (
                                                <ul>
                                                    <SecureLink
                                                        file={file}
                                                    />                                                       
                                                </ul>
                                            )
                                        })}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )
                    })}
                </Accordion>
            </div>
        )
    }

    render() {
        const { categories, groupId } = this.state;
        return (
            <>
                {this.renderFiles(categories, groupId)}
            </>
        )
    };
};

export default FileList;