import React, { Component } from "react";
import { isAuthenticated } from "../auth";
import ForumPosts from "../forum/ForumPosts";
import EventLists from "../event/EventList";
import EventPhotos from "../event/EventPhotos";
import Groups from "../group/Groups";
import FileList from "../file/FileList";
import { singleGroup, groupDetails } from "../group/apigroup";
import { getChairsLatestMessage, getGroupLatestPost } from "../forum/apiForum";
import Footer from './Footer';
import ReactGA from 'react-ga';
import { EditorState, convertFromRaw, convertToRaw, createFromBlockArray, ContentState, contentBlocks } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Container from 'react-bootstrap/Container';

class Home extends Component {

  state = {
    groupId: '5e5591a97ff73f6d34835a05', //home page takes data from Leadership Group Only
    group: '',
    redirectToHome: false,
    redirectToSignin: false,
    chair: '',
    cochair: '',
    chairsMessage: '',
    shortenBody: '',
    physGroupPost: '',
    electricalGroupPost: '',
    technicalGroupPost: '',
    civilGroupPost: '',
    bizzGroupPost: '',
    innovationGroupPost: ''
  };

  componentDidMount = () => {
    ReactGA.initialize('UA-331790-15')
    ReactGA.pageview('/home/' + isAuthenticated().email);
    ReactGA.set({ userId: isAuthenticated().userId });
    localStorage.removeItem('jwt');
    singleGroup(this.state.groupId).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({
          group: data,

        });

        groupDetails(this.state.groupId).then(data => {
          if (data.error) {
            console.log(data.error);
          } else {
            this.setState({
              chair: data.chair,
              cochair: data.cochair
            })
          }


          getChairsLatestMessage(data.chair._id).then(data => {
            if (data.error) {
              console.log(data.error);
            } else {
              this.setState({
                chairsMessage: data,
                // shortenBody: 'To provide our members with valuable information on product updates, workflows and best practices, the SDSIC has partnered with Autodesk to host a Virtual Event on June 24th and 25th so come and join us!'
                shortenBody: EditorState.createWithContent(convertFromRaw(JSON.parse(data.body)))
              });
            }
          });
        });
      }
    });

    this.latestPhysicalGroupPost()
    this.latestElectricalGroupPost()
    this.latestTechnicalGroupPost()
    this.latestCivilGroupPost()
    this.latestBizzGroupPost()
    this.latestInnovationGroupPost()
  };

  latestPhysicalGroupPost() {
    getGroupLatestPost('5e2a5e764175b97d84ec6cae').then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({
          physGroupPost: data
        });
      }
    });

  };

  latestElectricalGroupPost() {
    getGroupLatestPost('5e44050f4e6ed37ab467d0f2').then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({
          electricalGroupPost: data
        });
      }
    });
  };

  latestTechnicalGroupPost() {
    getGroupLatestPost('5e44052e4e6ed37ab467d0f3').then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({
          technicalGroupPost: data
        });
      }
    });
  };

  latestCivilGroupPost() {
    getGroupLatestPost('5e4405864e6ed37ab467d0f4').then(data => {
      if (data.error) {
        this.setState({
          civilGroupPost: ''
        });
      } else {
        this.setState({
          civilGroupPost: data
        });
      }
    });
  };


  latestBizzGroupPost() {
    getGroupLatestPost('5e4406454e6ed37ab467d0f5').then(data => {
      if (data.error) {
        this.setState({
          bizzGroupPost: ''
        });
      } else {
        this.setState({
          bizzGroupPost: data
        });
      }
    });
  };


  latestInnovationGroupPost() {
    getGroupLatestPost('639242d4971a30726884fd85').then(data => {
      if (data.error) {
        this.setState({
          innovationGroupPost: ''
        });
      } else {
        this.setState({
          innovationGroupPost: data
        });
      }
    });
  };



  render() {
    const { group, chair, cochair, chairsMessage, shortenBody,
      electricalGroupPost, physGroupPost, technicalGroupPost, civilGroupPost,
      bizzGroupPost, innovationGroupPost } = this.state;
    const userName = isAuthenticated() && isAuthenticated().email;

    return (
      <Container fluid>
        <div role="main" className="row" >
          <div className="jumbotron p-3 p-md-5 text-white" style={{ width: "100%", height: "80%", background: "#245aa6" }}>
            <div className="col-md-12 px-0">
              <h3 className="font-weight-normal">The Latest Update from your SDSIC chair, <b>{chair.name} {chair.lastName}</b></h3>
              <br />
              <p className="lead my-1 font-weight-normal">{chairsMessage.title}</p>
              <p className="lead my-1 font-weight-normal" style={{ whiteSpace: 'pre-wrap' }} >
                <Editor
                  toolbarHidden="true"
                  editorState={shortenBody}
                  wrapperClassName="demo-wrapper"
                  editorClassName="editer-content"
                  onEditorStateChange={this.onChange}
                />
              </p>
              <p className="lead mb-0"><a href={`/forumpost/${chairsMessage._id}`} className="text-white font-weight-bold">Read More</a></p>
            </div>
          </div>

          <div className="row" style={{ width: "100%" }}>
            <div className="col-md-8 blog-main">
              <h3 className="pb-3 mb-4 font-italic border-bottom">Latest Committee Updates</h3>
              <div className="blog-post">
                <h4>Posted in Business Strategy</h4>
                <h5 className="alert-success">Title: {bizzGroupPost.title}</h5>
                <div className="row">
                  <div className="col-md-8">
                    <blockquote className="blockquote">
                      <p className="mb-0">Posted on: {bizzGroupPost.created}</p>
                    </blockquote>
                  </div>
                  <div className="col-md-4">
                    <button onClick={() => window.location.href = `/forumpost/${bizzGroupPost._id}`} className="btn btn-outline-primary">Read the post</button>
                  </div>
                </div>
                <hr />
                <h4>Posted in Civil</h4>
                <h5 className="alert-success">Title: {civilGroupPost.title}</h5>
                <div className="row">
                  <div className="col-md-8">
                    <blockquote className="blockquote">
                      <p className="mb-0">Posted on: {civilGroupPost.created}</p>
                    </blockquote>
                  </div>
                  <div className="col-md-4">
                    <button onClick={() => window.location.href = `/forumpost/${civilGroupPost._id}`} className="btn btn-outline-primary">Read the post</button>
                  </div>
                </div>
                <hr />
                <h4>Posted in Electrical</h4>
                <h5 className="alert-success">Title: {electricalGroupPost.title}</h5>
                <div className="row">
                  <div className="col-md-8">
                    <blockquote className="blockquote">
                      <p className="mb-0">Posted on: {electricalGroupPost.created}</p>
                    </blockquote>
                  </div>
                  <div className="col-md-4">
                    <button onClick={() => window.location.href = `/forumpost/${electricalGroupPost._id}`} className="btn btn-outline-primary">Read the post</button>
                  </div>
                </div>
                <hr />

                <h4>Posted in Innovations</h4>
                <h5 className="alert-success">Title: {innovationGroupPost.title}</h5>
                <div className="row">
                  <div className="col-md-8">
                    <blockquote className="blockquote">
                      <p className="mb-0">Posted on: {innovationGroupPost.created}</p>
                    </blockquote>
                  </div>
                  <div className="col-md-4">
                    <button onClick={() => window.location.href = `/forumpost/${innovationGroupPost._id}`} className="btn btn-outline-primary">Read the post</button>
                  </div>
                </div>
                <hr />
                <h4>Posted in Physical</h4>
                <h5 className="alert-success">Title: {physGroupPost.title}</h5>
                <div className="row">
                  <div className="col-md-8">
                    <blockquote className="blockquote">
                      <p className="mb-0">Posted on: {physGroupPost.created}</p>
                    </blockquote>
                  </div>
                  <div className="col-md-4">
                    <button onClick={() => window.location.href = `/forumpost/${physGroupPost._id}`} className="btn btn-outline-primary">Read the post</button>
                  </div>
                </div>

                <hr />
                <h4>Posted in Technical</h4>
                <h5 className="alert-success">Title: {technicalGroupPost.title}</h5>
                <div className="row">
                  <div className="col-md-8">
                    <blockquote className="blockquote">
                      <p className="mb-0">Posted on: {technicalGroupPost.created}</p>
                    </blockquote>
                  </div>
                  <div className="col-md-4">
                    <button onClick={() => window.location.href = `/forumpost/${technicalGroupPost._id}`} className="btn btn-outline-primary">Read the post</button>
                  </div>
                </div>





              </div>
            </div>
            <aside className="col-md-4 blog-sidebar">
              {/* <div className="p-3 mb-3 bg-light rounded">
                <h4 className="font-italic">Event Photos</h4>
                <div className="mb-0">
                  <EventPhotos />
                </div>
              </div> */}
              <div className="p-3 mb-3 bg-light rounded">
                <h4 className="font-italic">SDSIC Events</h4>
                <div className="mb-0">
                  <EventLists />
                </div>
              </div>

              <div className="p-3">
                <h4 className="font-italic">Committees</h4>
                <Groups />
              </div>

            </aside>
          </div>

        </div>
        <br />
        <br />
        <br />
        <Footer />
      </Container>
    )
  }
};

export default Home;