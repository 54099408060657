import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Accordion, Card, Button } from 'react-bootstrap';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// import Posts from "../post/Posts";
import Footer from '../core/Footer';

const Awards = () => (
    <>
        <Container fluid>
            <br />
            <br />
            <Row>
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                The SDSIC Paragon Award <ArrowDropDownIcon />
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Row>
                                    <h4>
                                        The SDSIC Paragon Award
                                    </h4>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <p>
                                                The SDSIC Paragon award was designed to honor the unparalleled dedication and exceptional work ethic of an
                                                outstanding individual within our organization. This award celebrates not just the contribution but the
                                                spirit of collaboration, inclusivity, and unwavering support that fuels the growth and momentum of SDSIC.
                                            </p>
                                            <p>
                                                Each year, we seek to spotlight a remarkable member of our team who goes above and beyond in assisting our
                                                non-profit organization, demonstrating an exceptional commitment to helping members, fostering a collaborative
                                                culture, and significantly contributing to our collective mission. The recipient of the SDSIC Paragon
                                                Award embodies the very essence of leadership, teamwork, and community engagement, making a tangible impact
                                                on our organization's progress and success.
                                            </p>
                                            The award serves as a beacon of excellence, encouraging all members of SDSIC to aspire towards greatness, promote unity, and work tirelessly towards our common goals. Through this recognition, we not only celebrate individual achievement but also reinforce our commitment to building a supportive, dynamic, and inclusive environment where everyone thrives together.

                                            {/* Join us in honoring those who light the way for our organization, pushing boundaries, and setting new standards 
                                            of excellence. The SDSIC Momentum Builder Award is not just an accolade; it's a testament to the power of dedication, 
                                            unity, and collective growth. */}
                                        </div>
                                        <div className="col-md-4">
                                            <img alt="Paragon Award" src="/images/Paragon.jpg" height="375" />
                                        </div>
                                    </div>

                                </Row>
                                <Row>
                                    <h4>Past winners of this award:</h4>
                                    <Col md={4}>

                                        <div className="col-md-10">
                                            <h5><b>2023</b></h5>
                                            <h6>Audrey Marich </h6>
                                            <p>PPL Electric Utilities</p>
                                            <p><a href="https://www.linkedin.com/in/audrey-marich-273431194/" target="_blank" rel="noreferrer">Audrey's LinkedIn profile</a></p>
                                        </div>
                                        <div className="col-md-10">
                                            <img alt="award" img="Award" src="/images/AudreyAward.png" height="300" />
                                        </div>

                                    </Col>
                                    <Col md={4}>
                                        <div className="col-md-10">
                                            <h5><b>2022</b></h5>
                                            <h6>Trevor Scullion </h6>
                                            <p>SDSIC Advisor</p>
                                            <p><a href="https://www.linkedin.com/in/tscullion/" target="_blank" rel="noreferrer">Trevor's LinkedIn profile</a></p>
                                        </div>
                                        <div className="col-md-10">
                                            <img alt="award" img="Award" src="/images/ParagonTscullion.jpg" height="300" />
                                        </div>
                                    </Col>

                                    <Col md={4}>

                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6">

                                        </div>

                                    </Col>

                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                The David H. Milks SDSIC Memorial Award <ArrowDropDownIcon />
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <Row>
                                    <h4>
                                        The David H. Milks SDSIC Memorial Award
                                    </h4>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <p>This award honors the memory of David Milks, a founding member of the SDS Industry Consortium (SDSIC). David came up
                                                with the initial consortium concept, which established a forum for substation design professionals to provide guidance
                                                and influence the electric utility industry’s transition and adoption of intelligent, 3D substation software design tools.
                                                While serving in his industry leadership role at Autodesk, David cowrote the initial SDSIC bylaws, established financial
                                                sponsorships to fund SDSIC events, and he worked tirelessly for many years to grow the SDSIC community.
                                            </p>
                                            <p>
                                                After his passing on August 14, 2019, the David H. Milks SDSIC Memorial Award was established to serve as a lasting tribute
                                                to David and recognize those in our community that have made extraordinary contributions towards advancing the SDS Industry
                                                Consortium throughout the year as voted on by the SDSIC members.
                                            </p>
                                        </div>
                                        <div className="col-md-4">
                                            <img alt="David Milks" src="/images/davidPic.jpg" height="275" />
                                        </div>
                                    </div>

                                </Row>
                                <Row>
                                    <h4>Past winners of this award:</h4>

                                    <Col md={3}>
                                        <div className="col-md-12">
                                            <h5><b>2023</b></h5>
                                            <h6>Jeff Spence</h6>
                                            <p>VP of Strategic Innovations, Ampirical Solutions,LLC</p>
                                            <p><a href="https://www.linkedin.com/in/jeffrey-spence-1720a73b/" target="_blank" rel="noreferrer">Jeff's LinkedIn profile</a></p>
                                        </div>
                                        <div className="col-md-12">
                                            <img alt="award" img="Award" src="/images/JeffsAward.png" height="300" />
                                        </div>
                                    </Col>

                                    <Col md={3}>
                                        <div className="col-md-12">
                                            <h5><b>2022</b></h5>
                                            <h6>Mark Nelson</h6>
                                            <p>Idaho Power and our SDSIC co-Chair  </p>
                                            <p><a href="https://www.linkedin.com/in/mark-nelson-89a78320/" target="_blank" rel="noreferrer">Mark's LinkedIn profile</a></p>
                                        </div>
                                        <div className="col-md-12">
                                            <img alt="award" img="Award" src="/images/nelson2022.jpg" height="300" />
                                        </div>
                                    </Col>
                                    <Col md={3}>

                                        <div className="col-md-12">
                                            <h5><b>2021</b></h5>
                                            <h6>Arnold Fry </h6>
                                            <p>PE, MBA, POWER Engineers  </p>
                                            <p><a href="https://www.linkedin.com/in/arnold-fry-pe-mba-193a3b15b?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BCDI5lhEVTremj8kIjpzhaA%3D%3D" target="_blank" rel="noreferrer">Arnold's LinkedIn profile</a></p>
                                        </div>
                                        <div className="col-md-12">
                                            <img alt="Arnold Fry" src="/images/ArnoldAWard2.JPG" height="300" />
                                        </div>

                                    </Col>
                                    <Col md={3}>

                                        <div className="col-md-12">
                                            <h5><b>2020</b></h5>
                                            <h6>Audrey Marich</h6>
                                            <p>PPL Corporation and SDSIC Chair  </p>
                                            <p><a href="https://www.linkedin.com/in/audrey-marich-13a86b1b?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B2%2FTQQVCLTziDV5FFB5nqug%3D%3D" target="_blank" rel="noreferrer">Audrey's LinkedIn profile</a></p>
                                        </div>
                                        <div className="col-md-12">
                                            <img alt="award" img="Award" src="/images/DHM-award-2020.png" height="300" />
                                        </div>

                                    </Col>

                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                The Dan Gerlach SDSIC Innovation Award <ArrowDropDownIcon />
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <Row>
                                    <h4>
                                        The Dan Gerlach SDSIC Innovation Award
                                    </h4>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <p>This award honors Dan Gerlach, a founding member of the SDS Industry Consortium (SDSIC). Dan architected and
                                                developed the original 3D Substation Design Tools which continue to be adopted by our industry designers today.
                                                At Automationforce, Dan helped change the substation design industry with his innovative ideas year after year
                                                for 12+ years until he retired in 2019.
                                            </p>
                                            <p>
                                                Dan passes away on January 9th, 2022, after a long battle with cancer and the SDSIC established the Dan Gerlach
                                                Innovation Award to serve as a lasting tribute to Dan and recognize those in our community that have made
                                                the most innovative contributions to our industry  throughout the year as voted on by the SDSIC members.
                                            </p>
                                        </div>
                                        <div className="col-md-4">
                                            <img alt="David Milks" src="/images/Daniel-Gerlach.jpg" height="275" />
                                        </div>
                                    </div>

                                </Row>
                                <Row>
                                    <h4>Past winners of this award:</h4>

                                    <Col md={4}>
                                        <div className="col-md-12">
                                            <h5><b>2023</b></h5>
                                            <h6>Michael Bussinger </h6>
                                            <p>Assistant Department Manager at Burns & McDonnell </p>
                                            <p><a href="https://www.linkedin.com/in/michael-bussinger-58179480/" target="_blank" rel="noreferrer">Michael's LinkedIn profile</a></p>
                                        </div>
                                        <div className="col-md-12">
                                            <img alt="award" img="Award" src="/images/MichaelsAWard.png" height="300" />
                                        </div>
                                    </Col>
                                    <Col md={4}>

                                    <div className="col-md-12">
                                            <h5><b>2022</b></h5>
                                            <h6>Steve Kaufman </h6>
                                            <p>SBS  </p>
                                            <p><a href="https://www.linkedin.com/in/stephen-kaufman-96246245/" target="_blank" rel="noreferrer">Steve's LinkedIn profile</a></p>
                                        </div>
                                        <div className="col-md-12">
                                            <img alt="award" img="Award" src="/images/steveaward.jpg" height="300" />
                                        </div>

                                    </Col>
                                    <Col md={4}>

                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6">

                                        </div>

                                    </Col>

                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

            </Row>

        </Container >
        <br />
        <br />
        <br />
        <Footer />

    </>
);

export default Awards;