import React, { Component } from "react";
// import Posts from "../post/Posts";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Accordion, Card, Button } from 'react-bootstrap';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AWS from 'aws-sdk';


class PublicEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: []
        }
    }

    componentDidMount() {
        this.fetchImages();
    }

    fetchImages = async () => {
        // Configure AWS SDK
        AWS.config.update({
            region: 'us-east-1',
            accessKeyId: "AKIAJTACW7QVN7PPWLYQ",
            secretAccessKey: "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",
        });
        const bucketName = "nashville-event"
        // Create a new S3 instance
        const s3 = new AWS.S3();

        // Fetch objects from the specified bucket
        try {
            const response = await s3.listObjects({ Bucket: bucketName }).promise();
            const imageUrls = response.Contents.map(
                (item) => `https://${bucketName}.s3.amazonaws.com/${item.Key}`
            );

            this.setState({ images: imageUrls })
        } catch (error) {
            console.error('Error fetching images from S3:', error);
        }
    };

    render() {
        return (
            <>

                <div className="container-fluid">
                    <div className="row justify-content-md-center">

                        <div className="col-md-12">
                            <br />

                            <h3>
                                Some Past SDSIC Events
                            </h3>
                            <p> Burns & McDonnell just hosted our Fall 2022 event in Kansas City and we had 238 members join us for this first
                                in person event in over two years. Find more information below on the 'Kansas City Fall Event tab'.
                            </p>
                            <p>
                                During the pandemic we held virtual only events via the zoom platform. Although we missed out on all the benefits of
                                in person networking and getting to meet new members, we were able to easily record all sessions.
                            </p>
                            <p>
                                To view our 2021, 2022 spring and fall virtual event recordings, login to this site.
                            </p>
                        </div>
                        <hr />

                    </div>
                    <div>
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        Nashville 10th Anniversary Event <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>

                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <h4>Nashville was Hosted by POWER Engineers</h4>
                                        <div className="col-md-12">
                                            <p>
                                                POWER Engineers went above and beyond to pull in a record breaking crowd to help celebrate
                                                10 years of the SDSIC success! With a sold-out conference, the 4 days in Nashville were a
                                                Smashing success!
                                            </p>
                                            <p>
                                                Here are some pictures so you can get a feel for the event.
                                            </p>
                                        </div>
                                        <Carousel dynamicHeight>
                                            {this.state.images.map((url, index) => (
                                                <img key={index} src={url} alt="" />
                                            ))}
                                        </Carousel>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        2022 Kansas City Fall Event <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>

                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <h4>Kansas City was Hosted by Burns & McDonnell</h4>
                                        <div className="col-md-12">

                                            <p>
                                                The team at Burns & McDonnell pulled out all the stops and held an incredible event over the span of
                                                4 days. It was our first live event in over two years due to pandemic times and everyone sure was
                                                excited to get out in person and have some face to face conversations.
                                            </p>
                                            <p>
                                                Here are some pictures so you can get a feel for the event.
                                            </p>
                                        </div>
                                        <Carousel dynamicHeight>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/FallEvent2022/eventPics/20221031_151014.jpg" />
                                                <p className="legend">2022 Kansas City Fall Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/FallEvent2022/eventPics/20221031_151505.jpg" />
                                                <p className="legend">2022 Kansas City Fall Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/FallEvent2022/eventPics/20221031_154157.jpg" />
                                                <p className="legend">2022 Kansas City Fall Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/FallEvent2022/eventPics/20221101_101625.jpg" />
                                                <p className="legend">2022 Kansas City Fall Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/FallEvent2022/eventPics/20221102_090559.jpg" />
                                                <p className="legend">2022 Kansas City Fall Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/FallEvent2022/eventPics/20221102_093322.jpg" />
                                                <p className="legend">2022 Kansas City Fall Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/FallEvent2022/eventPics/20221102_185343.jpg" />
                                                <p className="legend">2022 Kansas City Fall Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/FallEvent2022/eventPics/20221103_103635.jpg" />
                                                <p className="legend">2022 Kansas City Fall Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/FallEvent2022/eventPics/20221103_130703.jpg" />
                                                <p className="legend">2022 Kansas City Fall Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/FallEvent2022/eventPics/20221103_131230.jpg" />
                                                <p className="legend">2022 Kansas City Fall Event</p>
                                            </div>

                                        </Carousel>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                        2022 Virtual Spring Event  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <h4>Virtual spring event 2022</h4>
                                        <p>The pandemic was not fun for anyone but our volunteers kept things going during this time. We held
                                            spring and fall events thanks to our generous sponsor POWER Engineers who provided us with sponsorship
                                            dollars to pay for our zoom platform
                                        </p>
                                        <p>Members can login to our website to view all the recordings from these sessions</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                        2021 Virtual Events  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <h4>Virtual events</h4>
                                        <p>The pandemic was not fun for anyone but our volunteers kept things going during this time. We held
                                            spring and fall events thanks to our generous sponsor POWER Engineers who provided us with sponsorship
                                            dollars to pay for our zoom platform
                                        </p>
                                        <p>Members can login to our website to view all the recordings from these sessions</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                        2020 Virtual Events  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <h4>Virtual events</h4>
                                        <p>The pandemic was not fun for anyone but our volunteers kept things going during this time. We held
                                            spring and fall events thanks to our generous sponsor POWER Engineers who provided us with sponsorship
                                            dollars to pay for our zoom platform
                                        </p>
                                        <p>Members can login to our website to view all the recordings from these sessions</p>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                        2019 New York City Spring Event  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body>
                                        <h4>New York City hosted by POWER Engineers and ConEd</h4>
                                        <p>Pictures and presentations from this event will be loaded up shortly. Please check back.</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="6">
                                        2018 Alexandria Fall Event  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="6">
                                    <Card.Body>
                                        <h4>Alexandria Hosted by Distran </h4>
                                        <div className="col-md-12">

                                            <p>
                                                The team at Distran rolled our the 'Martigra' for the SDSIC and threw a spectacular event for us
                                                in 2018. They had all the trimmings of the best party in town.
                                            </p>
                                            <p>
                                                Here are some pictures so you can get a feel for the event!
                                            </p>
                                        </div>
                                        <Carousel dynamicHeight>

                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_4818-1024x768.jpg" />
                                                <p className="legend">Alexandria Hosted by Distran</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_4836-1024x768.jpg" />
                                                <p className="legend">Alexandria Hosted by Distran</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_4875-1024x768.jpg" />
                                                <p className="legend">Alexandria Hosted by Distran</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_4961-1024x768.jpg" />
                                                <p className="legend">Alexandria Hosted by Distran</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_4968-1024x768.jpg" />
                                                <p className="legend">Alexandria Hosted by Distran</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_4994-1024x768.jpg" />
                                                <p className="legend">Alexandria Hosted by Distran</p>
                                            </div>

                                        </Carousel>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="17">
                                        2018 Allentown Spring Event  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="17">
                                    <Card.Body>
                                        <h4>Allentown was Hosted by PPL</h4>
                                        <div className="col-md-12">

                                            <p>
                                                The folks at PPL put on an amazing event and handled the crowd exceptionally well during our three
                                                day event in Allentown. They even had us over to their PPL Center for an after hours event.
                                            </p>
                                            <p>
                                                Here are some pictures so you can get a feel for the event.
                                            </p>
                                        </div>
                                        <Carousel dynamicHeight>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_3442-1-1024x768.jpg" />
                                                <p className="legend">2018 Allentown Spring Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_3448-1024x768.jpg" />
                                                <p className="legend">2018 Allentown Spring Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_3459-1024x768.jpg" />
                                                <p className="legend">2018 Allentown Spring Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_3467-1024x768.jpg" />
                                                <p className="legend">2018 Allentown Spring Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_3481-1024x768.jpg" />
                                                <p className="legend">2018 Allentown Spring Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_3484-1024x768.jpg" />
                                                <p className="legend">2018 Allentown Spring Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_3494-1024x768.jpg" />
                                                <p className="legend">2018 Allentown Spring Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_3500-1024x768.jpg" />
                                                <p className="legend">2018 Allentown Spring Event</p>
                                            </div>
                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_3505-1024x768.jpg" />
                                                <p className="legend">2018 Allentown Spring Event</p>
                                            </div>

                                            <div>
                                                <img src="https://sdsic-images.s3.amazonaws.com/IMG_5029-1024x768.jpg" />
                                                <p className="legend">2018 Allentown Spring Event</p>
                                            </div>
                                        </Carousel>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="7">
                                        2017 San Diego Fall Event  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="7">
                                    <Card.Body>
                                        <h4>San Diego hosted by Southern California Edison and Autodesk</h4>
                                        <p>Pictures and presentations from this event will be loaded up shortly. Please check back.</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="8">
                                        2017 St. Louis Spring Event  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="8">
                                    <Card.Body>
                                        <h4>St. Louis Hosted by Ameren</h4>
                                        <p>Pictures and presentations from this event will be loaded up shortly. Please check back.</p>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="9">
                                        2016 Kansas City Fall Event  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="9">
                                    <Card.Body>
                                        <h4> Kansas City was hosted by Burns & McDonnell</h4>
                                        <p>Pictures and presentations from this event will be loaded up shortly. Please check back.</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="19">
                                        2016 Birmingham Spring Event  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="19">
                                    <Card.Body>

                                        <h4>Birmingham, AL Hosted by Southern Company and Autodesk</h4>
                                        <p>Pictures and presentations from this event will be loaded up shortly. Please check back.</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="10">
                                        2015 San Francisco Fall Event  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="10">
                                    <Card.Body>
                                        <h4>San Fransisco, CA hosted by Autodesk</h4>
                                        <p>Pictures and presentations from this event will be loaded up shortly. Please check back.</p>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="11">
                                        2015 New Orleans Spring Event  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="11">
                                    <Card.Body>
                                        <h4>New Orleans was hosted by Entergy and Autodesk</h4>
                                        <p>Pictures and presentations from this event will be loaded up shortly. Please check back.</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="12">
                                        2014 Nashville Inaugural Event  <ArrowDropDownIcon />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="12">
                                    <Card.Body>Our very first event was held in Nashville and hosted by Nashville Electric Services and Autodesk</Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        </Accordion>
                    </div>
                    <div className="row">
                        <div className="col-md-12">

                        </div>
                    </div>
                </div>
                <footer className="page-footer font-small blue">
                    <div className="footer-copyright text-center py-3">© 2022 Copyright SDSIC. Proudly supported by <a href="https://automationforce.com/"> Automationforce</a>
                    </div>
                </footer>

            </>
        )
    }
};

export default PublicEvents;