import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getMerchArticle } from './apiUser'
import { Table } from 'react-bootstrap'
import MerchCarousel from './merchCarousel';
// import { ReactComponent as IconStarFill } from "bootstrap-icons/icons/star-fill.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCartPlus,
    faHeart,
    faShoppingCart,
    faMinus,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
// import { ReactComponent as IconCheckCircleFill } from "bootstrap-icons/icons/check-circle-fill.svg";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Footer from '../core/Footer';
import Carousel from 'react-bootstrap/Carousel';
import Form from 'react-bootstrap/Form';

import CheckoutForm from './checkOutForm';

class MerchCheckOut extends Component {

    constructor(props) {
        super(props);
        this.state = {
            merch: [],
            error: '',
            images: [],
            imageUrl: '',
            variants: [],
            colors: [],
            sizes: [],
            prices: [],
            size: '',
            color: '',
            price: '',
            sku: '',
            description: '',
        }
        this.clickOrder = this.clickOrder.bind(this);
        this.cleanupVariants = this.cleanupVariants.bind(this);
        this.handleSizeSelections = this.handleSizeSelections.bind(this);
        this.handleColorSelections = this.handleColorSelections.bind(this);
    }

    clickOrder() {
        console.log(this.state.colors)
        console.log(this.state.sizes)
        console.log(this.state.prices)
        
    }

    componentDidMount() {

        localStorage.getItem('sdsicProductChoice') && this.setState({
            size: JSON.parse(localStorage.getItem('sdsicProductChoice')).size,
            color: JSON.parse(localStorage.getItem('sdsicProductChoice')).color,
            price: JSON.parse(localStorage.getItem('sdsicProductChoice')).price,
            description: JSON.parse(localStorage.getItem('sdsicProductChoice')).description,
            sku: JSON.parse(localStorage.getItem('sdsicProductChoice')).sku,
        })
        console.log(localStorage.getItem('sdsicProductChoice'))
        


        getMerchArticle(this.props.match.params.articleId).then(data => {
            if (data.error) {
                this.setState({
                    error: data.error
                });
            } else {

                this.setState({
                    merch: data,
                    images: data.images,
                    variants: data.variants,
                    imageUrl: data.images[0].imageUrl,
                    imageUrl2: data.images[1].imageUrl
                })

                this.cleanupVariants(data.variants)
            }
        })
    }

    cleanupVariants(data) {
        var colors = []
        var sizes = []
        var prices = []

        data.forEach(variant => {

            if (!colors.includes(variant.appearanceName)) {
                colors.push(variant.appearanceName)
            }

            if (!sizes.includes(variant.sizeName)) {
                sizes.push(variant.sizeName)
            }

            if (!prices.includes(variant.d2cPrice)) {
                prices.push(variant.d2cPrice)
            }
        });

        this.setState({
            colors: colors,
            sizes: sizes,
            prices: prices
        })
    };

    handleSizeSelections(size, color) {
        this.setState({
            sizeSelected: size
        })
    }

    handleColorSelections(color) {
        this.setState({
            colorSelected: color
        })
    }

    render() {
       
        const { merch, color, price, size, description, sku } = this.state;

        return (
            <>
                <Container fluid>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href={`/merchDetail/${this.props.match.params.articleId}`}>
                            {merch.title}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Check Out</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="container-fluid mt-3">
                        <div className="row">
                            <div className="col-md-12">
                                <CheckoutForm 
                                    email={''}
                                    firstName={''}
                                    lastName={''}
                                    productTitle={merch.title}
                                    productId={this.props.match.params.articleId}
                                    productSize={size}
                                    productColor={color}
                                    productPrice={price}
                                    productDescription={description}
                                    productSku={sku}
                                />


                            </div>

                        </div>
                    </div>

                </Container>

                <Footer />

            </>
        );
    }
}

export default MerchCheckOut;