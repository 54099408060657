import React, { Component } from "react";
import { Container, Row, Breadcrumb } from 'react-bootstrap';
import { isAuthenticated } from "../auth";
import Footer from '../core/Footer';
import EventCalendar from './EventCalendar';

class EventDemo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAdmin: true,     // if admin, open and edit are available            
            token: isAuthenticated() && isAuthenticated().token
        }
    }

    resourceList = [
        {
            fieldName: 'general',
            title: 'General Sessions',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Opening Session', color: '#4caf50' },
                { id: 2, text: 'Closing Session', color: '#4caf50' },
                { id: 3, text: 'Committee Update', color: '#4caf50' },
                { id: 4, text: 'Vendor Company Update', color: '#4caf50' },
                { id: 5, text: 'Vendor Presentation', color: '#4caf50' },
                { id: 6, text: 'Leadership Meeting', color: '#4caf50' }
            ],
        },
        {
            fieldName: 'bizstrategy',
            title: 'Business Strategy Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#000099' },
                { id: 2, text: 'Committee Round Table', color: '#000099' },
                { id: 3, text: 'Committee Presentation', color: '#000099' },
                { id: 4, text: 'Vendor Presentation', color: '#000099' },
                { id: 5, text: 'User Presentation', color: '#000099' },
                { id: 6, text: 'Best Practice Discussion', color: '#000099' },
                { id: 7, text: 'Committee Closing Session', color: '#000099' },
                { id: 8, text: 'IACET Presentation', color: '#000099' }
            ],
        },
        {
            fieldName: 'civil',
            title: 'Civil Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#795548' },
                { id: 2, text: 'Committee Round Table', color: '#795548' },
                { id: 3, text: 'Committee Presentation', color: '#795548' },
                { id: 4, text: 'Vendor Presentation', color: '#795548' },
                { id: 5, text: 'User Presentation', color: '#795548' },
                { id: 6, text: 'Best Practice Discussion', color: '#795548' },
                { id: 7, text: 'Committee Closing Session', color: '#795548' },
                { id: 8, text: 'IACET Presentation', color: '#795548' }
            ],
        },
        {
            fieldName: 'electrical',
            title: 'Electrical Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#f44336' },
                { id: 2, text: 'Committee Round Table', color: '#f44336' },
                { id: 3, text: 'Committee Presentation', color: '#f44336' },
                { id: 4, text: 'Vendor Presentation', color: '#f44336' },
                { id: 5, text: 'User Presentation', color: '#f44336' },
                { id: 6, text: 'Best Practice Discussion', color: '#f44336' },
                { id: 7, text: 'Committee Closing Session', color: '#f44336' },
                { id: 8, text: 'IACET Presentation', color: '#f44336' }
            ],
        },
        {
            fieldName: 'physical',
            title: 'Physical Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#993399' },
                { id: 2, text: 'Committee Round Table', color: '#993399' },
                { id: 3, text: 'Committee Presentation', color: '#993399' },
                { id: 4, text: 'Vendor Presentation', color: '#993399' },
                { id: 5, text: 'User Presentation', color: '#993399' },
                { id: 6, text: 'Best Practice Discussion', color: '#993399' },
                { id: 7, text: 'Committee Closing Session', color: '#993399' },
                { id: 8, text: 'IACET Presentation', color: '#993399' }
            ],
        },
        {
            fieldName: 'technology',
            title: 'Technology Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#eb851e' },
                { id: 2, text: 'Committee Round Table', color: '#eb851e' },
                { id: 3, text: 'Committee Presentation', color: '#eb851e' },
                { id: 4, text: 'Vendor Presentation', color: '#eb851e' },
                { id: 5, text: 'User Presentation', color: '#eb851e' },
                { id: 6, text: 'Best Practice Discussion', color: '#eb851e' },
                { id: 7, text: 'Committee Closing Session', color: '#eb851e' },
                { id: 8, text: 'IACET Presentation', color: '#eb851e' }
            ],
        }
    ]

    render() {
        return (

            <Container fluid>
                <div>
                    <h2>SDSIC Spring Virtual Event Schedule</h2>
                    <p>
                        This schedule is updated often so be sure to check back the day of the meetings to verify times and any changes.
                        Clicking on the panels below will show meeting descriptions and links to the zoom web meetings. You can also follow
                        a specific track by selecting it in the dropdown list below.
                    </p>
                </div>
                <hr />
                    <Breadcrumb>
                        <Breadcrumb.Item href="/usershome">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href={`/grouplist`}>
                            back to committee list
                    </Breadcrumb.Item>
                      <Breadcrumb.Item active>Virtual Event</Breadcrumb.Item>
                    </Breadcrumb>

                <Row> 
                    <EventCalendar
                        scheduleName={'Spring 2021'}
                        isAdmin={isAuthenticated().userId === '5e2611483f99fa3bb4dd922a'}
                        chooseTracks={true}
                        chooseDays={true}
                        currentDate={'2021-05-04'}
                        excludedDays={[0, 1, 5, 6, 7]}
                        weekView={true}
                        resourceList={this.resourceList}
                        mainResourceName={'general'}
                        calendarHeight={1000}
                        startTime={9}
                        endTime={21}
                    />
                </Row>

                <Footer />
            </Container >

        )
    }
};

export default EventDemo;