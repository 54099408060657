import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    Resources,
    WeekView,
    MonthView,
    Toolbar,
    DateNavigator,
    Appointments,
    AppointmentTooltip,
    AppointmentForm,
    DragDropProvider,
} from '@devexpress/dx-react-scheduler-material-ui';
import { eventscheduleD1, eventscheduleD2, eventscheduleD3, eventscheduleD4, eventscheduleD5 } from './2022schedule';
import { committees } from './2022schedule';
import { isAuthenticated } from "../auth";
import Burns_and_McDonnell_Logo from '../images/BMBanner.png';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
// import MoreIcon from '@material-ui/icons/MoreVert';
import classNames from 'clsx';
import { getSchedule, updateSchedule } from './apiEvents';
import LoadingOverlay from 'react-loading-overlay';
import Grid from '@material-ui/core/Grid';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';



const styles = theme => ({
    container: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-end',
    },
    text: {
        ...theme.typography.h7,
        marginRight: theme.spacing(2),
    },
    textCenter: {
        textAlign: 'center',
    },
    formControl: {
        // margin: theme.spacing(2),
        minWidth: 400,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    general: {
        background: 'url(images/GeneralSession.png)',
        backgroundColor: '#42a5f5',
        borderRadius: '8px',
    },
    physical: {
        background: 'url(images/physcommittee.png)',
        backgroundColor: '#F46036',
        borderRadius: '8px',
    },
    electrical: {
        background: 'url(images/ElectricalCommittee.png)',
        backgroundColor: '#1B998B',
        borderRadius: '8px',
    },
    civil: {
        background: 'url(images/civilCommittee.png)',
        backgroundColor: '#4A4238',
        borderRadius: '8px',
    },
    meals: {
        background: 'url(images/meals.png)',
        backgroundColor: '#C6C5B9',
        borderRadius: '8px',
    },
    technology: {
        background: 'url(images/techcommittee.png)',
        backgroundColor: '#79C99E',
        borderRadius: '8px',
    },
    business: {
        background: 'url(images/bizStrategy.png)',
        backgroundColor: '508484',
        borderRadius: '8px',
    },
    header: {
        height: '140px',
        backgroundSize: 'cover',
    },
    commandButton: {
        backgroundColor: 'rgba(255,255,255,0.65)',
    },
    events: {
        background: 'url(images/eventbackground.png)',
        backgroundColor: '#EEC643',
        borderRadius: '8px',
    }
});

const getClassByLocation = (classes, location) => {
    if (location === 'general') return classes.general;
    if (location === 'events') return classes.events;
    if (location === 'meals') return classes.meals;
    if (location === 'physical') return classes.physical;
    if (location === 'electrical') return classes.electrical;
    if (location === 'civil') return classes.civil;
    if (location === 'technology') return classes.technology;
    if (location === 'business') return classes.business;
    return classes.general;
};

const resources = [{
    fieldName: 'ownerId',
    title: 'Committees',
    instances: committees,
}];

const Header = withStyles(styles, { name: 'Header' })(({
    children, appointmentData, classes, ...restProps
}) => (
    <AppointmentTooltip.Header
        {...restProps}
        className={classNames(getClassByLocation(classes, appointmentData.committeeType), classes.header)}
        appointmentData={appointmentData}
    >
        <IconButton
            /* eslint-disable-next-line no-alert */
            onClick={() => alert(JSON.stringify(appointmentData))}
            className={classes.commandButton}
        >
        </IconButton>
    </AppointmentTooltip.Header>
));

const Content = withStyles(styles, { name: 'Content' })(({
    children, appointmentData, classes, ...restProps
}) => (
    <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
        <Grid container alignItems="center">
            {/* <Grid item xs={2} className={classes.textCenter}>
                <Room className={classes.icon} />
            </Grid> */}
            <Grid item xs={10}>

                <p>{appointmentData.description}</p>
                <hr />
                <h5>Detailed Location

                    <ul>
                        <li><a href={appointmentData.geomap}
                            target='_blank' rel="noopener noreferrer">open map
                        </a></li>
                    </ul>
                </h5>

            </Grid>

        </Grid>
    </AppointmentTooltip.Content>
));

const ResourceSwitcher = withStyles(styles, { name: 'ResourceSwitcher' })(
    ({
        onChange, classes, resources
    }) => (

        <div className={classes.container}>

            <div className={classes.text}>
                Choose a committee to follow:
            </div>
            <Select
                native

                onChange={e => onChange(e.target.value)}
                inputProps={{
                    name: 'day',
                    id: 'filled-age-native-simple',
                }}
            >
                {resources.map(resource => (
                    <option key={resource.fieldName} value={resource.fieldName}>
                        {resource.title}
                        {resource.desc}
                    </option>
                ))}
            </Select>
        </div>
    ),
);


const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
    const onzoomIdChange = (nextValue) => {
        onFieldChange({ zoomId: nextValue });
    };
    const onPassCodeChange = (nextValue) => {
        onFieldChange({ zoompass: nextValue });
    };
    const onZoomLinkChange = (nextValue) => {
        onFieldChange({ zoomLink: nextValue });
    };

    const onRoomChange = (nextValue) => {
        onFieldChange({ zoomRoom: nextValue });
    };

    const onComTypeChange = (nextValue) => {
        onFieldChange({ committeeType: nextValue });
    };


    return (
        <AppointmentForm.BasicLayout
            appointmentData={appointmentData}
            onFieldChange={onFieldChange}
            {...restProps}
        >
            <AppointmentForm.Label
                text="Committe Name"
                type="title"
            />
            <AppointmentForm.TextEditor
                value={appointmentData.committeeType}

                onValueChange={onComTypeChange}
                placeholder="Committee name for header"
            />

            <AppointmentForm.Label
                text="Zoom Meeting ID"
                type="title"
            />
            <AppointmentForm.TextEditor
                value={appointmentData.zoomId}
                onValueChange={onzoomIdChange}
                placeholder="Zoom Meeting ID"
            />

            <AppointmentForm.Label
                text="Zoom Passcode"
                type="title"
            />
            <AppointmentForm.TextEditor
                value={appointmentData.zoompass}
                onValueChange={onPassCodeChange}
                placeholder="Zoom Passcode"
            />

            <AppointmentForm.Label
                text="Breakout Room"
                type="title"
            />

            <AppointmentForm.TextEditor
                value={appointmentData.zoomRoom}
                onValueChange={onRoomChange}
                placeholder="Zoom Break Out Room"
            />


            <AppointmentForm.Label
                text="Zoom Web Link"
                type="title"
            />
            <AppointmentForm.TextEditor
                value={appointmentData.zoomLink}
                onValueChange={onZoomLinkChange}
                placeholder="Zoom WebLink"
            />


        </AppointmentForm.BasicLayout>
    );
};

const TextEditor = (props) => {
    // eslint-disable-next-line react/destructuring-assignment
    // if (props.type === 'multilineTextEditor') {
    //     return null;
    return <AppointmentForm.TextEditor {...props} />;
};




export default class Event2022Schedule extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: eventscheduleD1,
            eventDate: new Date(2022, 9, 31),
            mainResourceName: 'General',
            day: '',
            dayList: [0, 5, 6, 7],
            eventDatePicked: eventscheduleD1,
            startTime: 7,
            endTime: 22
        };

        this.currentDateChange = (currentDate) => { this.setState({ currentDate }); };
        this.changeMainResource = this.changeMainResource.bind(this)
        this.handleDayChange = this.handleDayChange.bind(this);
    }


    handleDayChange = (event) => {
        this.setState({eventDatePicked: [] })
        if (event.target.value == 'Show all') { this.setState({ dayList: [0, 5, 6, 7], eventDatePicked: eventscheduleD1, startTime: 7, endTime: 22 }) }
        if (event.target.value == 'Oct 31st') { this.setState({ dayList: [0, 2, 3, 4, 5, 6, 7], eventDatePicked: eventscheduleD2, startTime: 14, endTime: 22 }) }
        if (event.target.value == 'November 1st') { this.setState({ dayList: [0, 1, 3, 4, 5, 6, 7], eventDatePicked: eventscheduleD3,startTime: 7, endTime: 22  }) }
        if (event.target.value == 'November 2nd') { this.setState({ dayList: [0, 1, 2, 4, 5, 6, 7], eventDatePicked: eventscheduleD4, startTime: 7, endTime: 22  }) }
        if (event.target.value == 'November 3rd') { this.setState({ dayList: [0, 1, 2, 3, 5, 6, 7], eventDatePicked: eventscheduleD5, startTime: 7, endTime: 15  }) }

        this.setState({
            day: event.target.value
        });
    };


    token = isAuthenticated() && isAuthenticated().token;

    changeMainResource(mainResourceName) {
        console.log(mainResourceName)
        this.setState({ mainResourceName });
    }

    render() {
        const { data, eventDate, eventDatePicked, startTime, endTime } = this.state;

        return (
            <>
                <div className="container">
                    <div className="py-5 text-center">
                        <h2>SDSIC Kansas City Conference 2022 Schedule</h2>
                        <h4>Hosted By: </h4><img className="d-block mx-auto mb-4" src={Burns_and_McDonnell_Logo} alt="" height="72" />
                    </div>
                </div>
                <div className="container">
                    <h4>Committee Color Legend</h4>
                    <Box sx={{ maxWidth: 250 }}>
                        <Stack direction="row" spacing={1}>
                            <Chip label="Business Strategy Committee" style={{ backgroundColor: '#508484' }} />
                            <Chip label="Civil Committee" style={{ backgroundColor: '#4A4238', color: 'white' }} />
                            <Chip label="Electrical Committee" style={{ backgroundColor: '#1B998B' }} />
                            <Chip label="Evening Events for All" style={{ backgroundColor: '#EEC643' }} />
                            <Chip label="General Session for All" style={{ backgroundColor: '#42a5f5' }} />
                            <Chip label="Physical Committee" style={{ backgroundColor: '#F46036' }} />
                            <Chip label="Technology Committee" style={{ backgroundColor: '#79C99E' }} />
                        </Stack>
                    </Box>
                </div>
                <br/>
                <div className="container">
                    <Box sx={{ maxWidth: 300 }}>
                        <h4>Choose a view:</h4>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Conference Days</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.day}
                                label="Day"
                                onChange={this.handleDayChange}
                            >
                                <MenuItem value={'Show all'}>Show all</MenuItem>
                                <MenuItem value={'Oct 31st'}>Oct 31st</MenuItem>
                                <MenuItem value={'November 1st'}>November 1st</MenuItem>
                                <MenuItem value={'November 2nd'}>November 2nd</MenuItem>
                                <MenuItem value={'November 3rd'}>November 3rd</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                <br />
                
                <div>

                    {/* <ResourceSwitcher
                        resources={committees}
                        mainResourceName={this.state.mainResourceName}
                        onChange={this.changeMainResource}
                    /> */}

                </div>

                <Paper>

                    <Scheduler
                        data={eventDatePicked}
                        crossScrollingEnabled={true}
                    >

                        <ViewState
                            currentDate={eventDate}
                        />
                        <WeekView
                            excludedDays={this.state.dayList}
                            startDayHour={startTime}
                            endDayHour={endTime}
                        />
                        <Toolbar />
                        <Appointments />
                        <AppointmentTooltip
                            onClick={this.handleClick}
                            headerComponent={Header}
                            contentComponent={Content}
                            showCloseButton
                            showOpenButton={false} // only admins can open
                        />
                        <AppointmentForm
                            basicLayoutComponent={BasicLayout}
                            textEditorComponent={TextEditor}
                            readOnly={true} // only admins can edit
                        />
                        <Resources
                            data={resources}
                        />

                    </Scheduler>
                </Paper>
            </>
        );
    };
}

