/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
// import Posts from "../post/Posts";
import Footer from './Footer';
import { sendMessage } from "../group/apigroup";
import moment from "moment";
import ReactPlayer from "react-player"

class publicNashvilleClosed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            success: ''
        }
    }

    handleChange = name => event => {
        if (name === "email") {
            this.setState({ error: "" });
            this.setState({ responseTo: name, [name]: event.target.value });
        }
    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Your on the list!</h3>
                <p>Don't forget to check your inbox and your spam folder for updates from the SDSIC.</p>
                <p>We will use the email you've submitted to send you updates as we finalize the upcoming event.</p>
            </div>
        )
    };

    submitEmail = e => {
        const { email } = this.state;
        e.preventDefault();
        if (email === "") { this.setState({ error: "email required!" }) }

        if (email !== "") {
            const mailMessage = {
                to: 'trevor.scullion@automationforce.com',
                from: email,
                subject: "SDSIC 2022 FALL event list",
                Message: 'Please add ' + email + ' to the SDSIC 2022 FALL event list.',
                html: "<p>Please add " + email + " to the SDSIC 2022 FALL event list.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }
            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                this.setState({
                    email: '',
                    success: true,
                });
            });

            const userMessage = {
                to: email,
                from: 'donotreply@sdsconsortium.com',
                subject: "SDSIC 2022 FALL event",
                Message: 'Your name is on the SDSIC 2022 FALL event list.',
                html: "<p>Your name is on the SDSIC 2022 FALL event list.</p><p>You will be kept up to date " +
                    "as we finalize the event details.</p><p>Please do not reply to this email.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }

            // make request to api to send message
            sendMessage(userMessage).then(data => { });
        };
    };


    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">
                            <div className="jumbotron p-3 p-md-5 text-white rounded bg-dark">
                                <div className="row mb-2">
                                    <div className="col-md-12">
                                        <h4 className="display-5 font-italic">The SDSIC 10th Anniversary 2023 Event</h4>
                                        <h4>April 24th to the 27th 2023</h4>

                                        <p className="lead my-3">We are ecstatic to announce that next year will be 10 years since the SDSIC was founded and POWER Engineers
                                            will be hosting our event in the very place where we held our first meeting. Nashville, TN.  </p>
                                        <p className="lead mb-0">As we finalize the agenda of events, we will send out notifications but for now, register below and save your spot.</p>
                                    </div>

                                </div>
                            </div>

                            <main role="main" className="container">
                                <div className="row">
                                    <div className="col-md-5">
                                        <h2 className="blog-post-title">We are completely Sold out!</h2>
                                        <p>
                                            We had expected a record turn out to help us celebrate 10 years of growing success and our members have not 
                                            dissapointed! We have exceeded expectations and overloaded the venue and hotel.
                                        </p>
                                        <p>
                                            
                                            <p className="card-text mb-auto">
                                                For anyone that missed this event, sign up for your free SDSIC membership and look for future events.
                                            </p>
                                            <br/>
                                            <p className="card-text mb-auto">
                                               For every member that registered, we thank you, and look forward to our time together in Nashville!
                                            </p>
                                            <br/>
                                            <p className="card-text mb-auto">
                                                Get the SDSIC Conference app<br/>
                                                <a href="https://play.google.com/store/apps/details?id=com.cvent.mobile.eventapp&hl=en_CA&gl=US" target="_blank">Apple app store</a><br/>
                                                <a href="https://play.google.com/store/search?q=Cvent%20events&c=apps&hl=en_CA&gl=US" target="_blank">Google App Store</a><br/>
                                               For all registered conference attendees, you can download the cvent app and follow <a href="https://nam10.safelinks.protection.outlook.com/?url=https%3A%2F%2F7c1lv.r.bh.d.sendibt3.com%2Fmk%2Fcl%2Ff%2FhWdJZeBatsKHP1fr6w5d_oK3hBR_Ou0E6LhTkmWTvuoTAdjEyUwcr90ycKXUBLU6UXw_1voW0MJez3Fl_-JKKPZ5wGqkkvovV9esyFqXBxK7sSfsDD3n21nMDNH4HeKIgiM8Lm94PMEKfH_l2o7KK-XVz-xjWVLDsddGCDEUAg6tbJ959AnxXShPNGwvaWBYRhm_zxHWESztntg31cxE4h3zhW4rcGn7oD9GIS5Oa73rkMPAFa9dgImRbpoAmwZDWuHjTpjQRQSrCh6jE0xX28_fglnFVGLa6BEEuGiBcgbWYY_3MZOy1U9-Jp-f-vxWLK0E9LH5Kf1ftNWwadHD8XJPXX5YWMQRAlgkFHX9kF5PLan2ULhX9k6gEurdwrEeB4M4AaaE5cT0vpeZkVomwmO9hvCX9pjNizUrjVTVqt-25A2F2HDW71fvxu14PA0KKIKjfZNATXO83da6b6ha15dr44pEqqZS1A&data=05%7C01%7Ctrevor.scullion%40spatialbiz.com%7Cd9e22166d1db4f14c8cd08db41a0632d%7Cc958c5d08add4bc59cb8a424552db898%7C0%7C0%7C638175929360475121%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=9AWyBVaobPYKRmTXym3c3rAfGd38W3QTC57uQbuttWo%3D&reserved=0" target="_blank">these instructions.</a>
                                            </p>
                                        </p>
                                       
                                    </div>
                                    <div className="col-md-7">
                                        <ReactPlayer
                                            url="https://vimeo.com/764707383" 
                                            playing="false"
                                            volume="0.5"
                                        />
                                    </div>
                                </div>
                                <br />
                                <hr />

                               
                            </main>



                        </div>

                    </div >

                </div >
                <br/>
                <br/>
                <br/>
                <br/>
                
                <Footer />
            </>
        )
    }
}

export default publicNashvilleClosed