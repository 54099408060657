import React, { Component } from 'react';
import { isAuthenticated } from '../auth';
import { createNewEvent } from './apiEvents';
import { singleGroup } from "../group/apigroup";

class CreateEvent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            startDate: '',
            endDate: '',
            location: '',
            groupId: this.props.match.params.groupId,
            groupName: '',
            formData: '',
            file: ''
        }
        //any bindinds here
        this.eventForm = this.eventForm.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.getCommitteeName = this.getCommitteeName.bind(this);
    }

    token = isAuthenticated() && isAuthenticated().token;
    userId = isAuthenticated().userId

    componentDidMount() {
        this.setState({formData: new FormData()})        
        this.getCommitteeName(this.state.groupId)
    };

    handleChange = name => event => {
        let value = "";
        if (name === 'file') {
            value = event.target.files[0]
        } else {
            value = event.target.value
        }
        this.setState({ error: "" });
        this.setState({ [name]: value });
        this.state.formData.append(name, value);
    };

    getCommitteeName() {
        singleGroup(this.state.groupId).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({ groupName: data.name })
            }
        });
    };

    clickSubmit = event => {
        event.preventDefault();
        this.state.formData.append("groupId", this.state.groupId);
        createNewEvent(this.token, this.userId, this.state.formData).then(data => {
            if (data.error) this.setState({ error: data.error, success: false });
            else
                this.setState({
                    error: "",
                    title: "",
                    startDate: "",
                    endDate: "",
                    location: "",
                    file: "",
                    success: true,
                    fileInputKey: this.state.file.value ? this.state.file.value.name : +new Date()
                });
        });
    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };
    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                File Created Successfully!
            </div>
        )
    };

    eventForm = () => (
        <form>
            <div className="form-group">
                <label className="text-muted">Event Title</label>
                <input
                    onChange={this.handleChange("title")}
                    type="text"
                    className="form-control"
                    value={this.state.title}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Start Date</label>
                <textarea
                    onChange={this.handleChange("startDate")}
                    type="text"
                    className="form-control"
                    value={this.state.startDate}
                />
            </div>
            <div className="form-group">
                <label className="text-muted">End Date</label>
                <textarea
                    onChange={this.handleChange("endDate")}
                    type="text"
                    className="form-control"
                    value={this.state.endDate}
                />
            </div>
            <div className="form-group">
                <label className="text-muted">Location</label>
                <textarea
                    onChange={this.handleChange("location")}
                    type="text"
                    className="form-control"
                    value={this.state.location}
                />
            </div>
            
            <div className="form-group">
                <label className="text-muted">file</label>
                <input
                    key={this.state.fileInputKey}
                    onChange={this.handleChange("file")}
                    type="file"
                    accept="*/.ics"
                    className="form-control"
                />
            </div>
            <div>
                <p><h5>File will be added to the {this.state.groupName}</h5></p>
            </div>
            <button onClick={this.clickSubmit}
                className="btn btn-raised btn-primary">
                Create New Event</button>

        </form>
    );

    render() {
        return (
            <div>
                {this.showError()}
                {this.showSuccess()}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/singleGroup/${this.props.match.params.groupId}`}>Back to {this.state.groupName}</a></li>
                        <li className="breadcrumb-item active" aria-current="page" >Add Event</li>
                    </ol>
                </nav>
                {this.eventForm()}
            </div>
        )
    }
}

export default CreateEvent;

