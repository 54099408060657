import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getMerchArticle } from './apiUser'
import { Table } from 'react-bootstrap'
// import { ReactComponent as IconStarFill } from "bootstrap-icons/icons/star-fill.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCartPlus,
    faHeart,
    faShoppingCart,
    faMinus,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
// import { ReactComponent as IconCheckCircleFill } from "bootstrap-icons/icons/check-circle-fill.svg";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Footer from '../core/Footer';
import Carousel from 'react-bootstrap/Carousel';
import Form from 'react-bootstrap/Form';

class MerchDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            merch: [],
            error: '',
            images: [],
            imageUrl: '',
            variants: [],
            colors: [],
            sizes: [],
            prices: [],
            sizeSelected: '',
            colorSelected:'',
            selectedSku: '',
            warning: false
        }

        this.clickOrder = this.clickOrder.bind(this);
        this.cleanupVariants = this.cleanupVariants.bind(this);
        this.handleSizeSelections = this.handleSizeSelections.bind(this);   
        this.handleColorSelections = this.handleColorSelections.bind(this);
        this.lookupItem = this.lookupItem.bind(this);

    }

    clickOrder() {       
        const localizedChoices = {
            size: this.state.sizeSelected,
            color: this.state.colorSelected,
            price: this.state.prices[0],
            description: this.state.merch.description,
            sku: this.state.selectedSku,
        }
      
        if (this.state.sizeSelected === '' || this.state.colorSelected === '') {
            this.setState({ warning: true})
        } else {
            localStorage.setItem('sdsicProductChoice', JSON.stringify(localizedChoices))            
            window.location.href=`/merchCheckOut/${this.props.match.params.articleId}`
        }       
    }

    
    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.warning ? "" : "none" }}>
                Please select size and color option before proceeding to checkout
            </div>
        )
    };

    componentDidMount() {

        getMerchArticle(this.props.match.params.articleId).then(data => {
            if (data.error) {
                this.setState({
                    error: data.error
                });
            } else {

                this.setState({
                    merch: data,
                    images: data.images,
                    variants: data.variants,
                    imageUrl: data.images[0].imageUrl,
                    imageUrl2: data.images[1].imageUrl
                })
                // console.log(data)
                this.cleanupVariants(data.variants)
            }
        })
      
    }

    cleanupVariants(data) {
        var colors = []
        var sizes = []
        var prices = []

        data.forEach(variant => {

            if (!colors.includes(variant.appearanceName)) {
                colors.push(variant.appearanceName)
            }

            if (!sizes.includes(variant.sizeName)) {
                sizes.push(variant.sizeName)
            }

            if (!prices.includes(variant.d2cPrice)) {
                prices.push(variant.d2cPrice)
            }
        });

        this.setState({
            colors: colors, 
            sizes: sizes,
            prices: prices
        })
    };

    handleSizeSelections(size) {
        var sku = this.lookupItem('sizeName', 'appearanceName', size, this.state.colorSelected, 'sku')
        this.setState({
            sizeSelected: size,
            warning: false,
            selectedSku: sku
        })
            
    }

    handleColorSelections(color) {
        var sku = this.lookupItem('sizeName', 'appearanceName', this.state.sizeSelected, color, 'sku')
       
        this.setState({
            colorSelected: color,
            warning: false,
            selectedSku: sku
        })
    }

    // function to lookup an item within an array of objects
    // return a specific item from the lookup
    lookupItem = (key1, key2, value1, value2, returnItem) => {
        const varientArray = this.state.merch.variants

        for (var i = 0; i < varientArray.length; i++) {
            if (varientArray[i][key1] === value1 && varientArray[i][key2] === value2) {
                return varientArray[i][returnItem];
            }
        }
        return null;
    };


    render() {
        const { merch, colors, sizes, prices, images, sizeSelected, colorSelected } = this.state;

        return (
            <>
                <Container fluid>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/sdsicmerch">
                            Merchandise
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{merch.title}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="container-fluid mt-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row mb-3">
                                    <div className="col-md-7 text-center">
                                        <Carousel variant="dark">
                                            {images.map(image =>
                                                <Carousel.Item>
                                                    <img
                                                        className="d-block w-100"
                                                        src={image.imageUrl}
                                                        alt="First slide"
                                                    />
                                                    <Carousel.Caption>
                                                        <h3>{image.perspective}</h3>
                                                        <p>{image.appearance}</p>
                                                    </Carousel.Caption>
                                                </Carousel.Item>
                                            )}
                                        </Carousel>
                                    </div>
                                    <div className="col-md-5">
                                        <h1 className="h5 d-inline me-2">
                                            {merch.title}
                                        </h1>
                                                    
                                        <div className="mb-3">
                                        <br/>
                                            <span className="fw-bold h5 me-2">${prices[0]} each</span>

                                        </div>
                                        <div className="mb-3">
                                            <div>
                                                {sizes.map(size =>
                                                    <button                                                    
                                                        type="button"                                                        
                                                        title="Choose your size"
                                                        className={size === sizeSelected ? "btn btn-sm btn-primary me-2" : "btn btn-sm btn-outline-primary me-2"}
                                                        onClick={() => this.handleSizeSelections(size)}
                                                    >
                                                        {size}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="mb-3">
                                                {colors.map(color =>
                                                    <button 
                                                        type="button"                                                           
                                                        className={color === colorSelected ? "btn btn-sm btn-primary me-2" : "btn btn-sm btn-outline-primary me-2"}
                                                        title="Choose your color"  
                                                        onClick={() => this.handleColorSelections(color)} 
                                                    >   
                                                        {color} 
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mb-3">

                                            <button
                                                type="button"
                                                onClick={this.clickOrder}
                                                className="btn btn-sm btn-warning me-2"
                                                title="Buy now"
                                            >
                                                <FontAwesomeIcon icon={faShoppingCart} /> Buy now
                                            </button>

                                        </div>
                                        {this.showSuccess()}
                                        <div>
                                            <p className="fw-bold mb-2 small">
                                                Product Highlights
                                            </p>
                                            <ul className="small">
                                                <div contentEditable='false' dangerouslySetInnerHTML={{ __html: merch.description }} style={{ whiteSpace: 'pre-wrap' }} ></div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>

                        </div>
                    </div>

                </Container>

                <Footer />

            </>
        );
    }
}

export default MerchDetail;