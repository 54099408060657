import React, { Component } from "react";
import { forgotPassword } from "../auth";
import ReactGA from 'react-ga';

class ForgotPassword extends Component {
    state = {
        email: "",
        message: "",
        error: ""
    };

    componentDidMount() {
        ReactGA.initialize('UA-331790-15');
        ReactGA.pageview('/passwordreset'); 
    };
    
    forgotPassword = e => { 
        e.preventDefault();
        this.setState({ message: "", error: "" });
        
        const bodyData = {
            from: 'dontreply@sdsconsortium.com',
            email: this.state.email,
            website: 'Substation Design Solutions Industry Consortium',
            webUrl: 'https://sdsconsortium.com'
        }
       

        forgotPassword(bodyData).then(data => {
            if (data.error) {
                console.log(data.error);
                this.setState({ error: data.error });
            } else {
                console.log(data.message);
                this.setState({ 
                    message: data.message,
                    email: ''
                });
            }
        });
    };




    render() {
        return (
            <div className="container">
                <h2 className="mt-5 mb-5">Ask for Password Reset</h2>

                {this.state.message && (
                    <h4 className="alert alert-success">{this.state.message}</h4>
                )}
                {this.state.error && (
                    <h4 className="alert alert-warning">{this.state.error}</h4>
                )}

                <form>
                    <div className="form-group mt-5">
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Your email address"
                            value={this.state.email}
                            name="email"
                            onChange={e =>
                                this.setState({
                                    email: e.target.value,
                                    message: "",
                                    error: ""
                                })
                            }
                            autoFocus
                        />
                    </div>
                    <button
                        onClick={this.forgotPassword}
                        className="btn btn-raised btn-primary"
                    >
                        Send Password Reset Link
                    </button>
                </form>
            </div>
        );
    }
}

export default ForgotPassword;