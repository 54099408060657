import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getSDSICMerch } from './apiUser'
import { Table } from 'react-bootstrap'
import MerchCarousel from './merchCarousel';

// import Posts from "../post/Posts";
import Footer from '../core/Footer';



class SdsicMerch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            merch: [],
            error: ''
        }
    }

    clickOrder() {
        alert('ordering is not yet setup')
    }

    componentWillMount() {
        const script = document.createElement("script");
        script.src = 'https://sdsic.myspreadshop.com/shopfiles/shopclient/shopclient.nocache.js'; // new line
        script.onload = function () {
           
        };
        document.body.appendChild(script); // new line
    }



    render() {
        const { merch } = this.state;

        return (
            <>
                <Container fluid>
                    <br />
                    <br />

                    <Row>


                        <div id="myShop">
                            <a href="https://sdsic.myspreadshop.com">sdsic</a>
                        </div>


                    </Row>

                </Container>

                <Footer />

            </>
        );
    }
}

export default SdsicMerch;