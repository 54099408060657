/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { getPresentationById } from "./apiEvents";
import { isAuthenticated } from "../auth";
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import ReactPlayer from 'react-player'
import Footer from '../core/Footer';
import Ampirical from '../images/Ampirical_web.png';
// import chattxt from 'https://s3.console.aws.amazon.com/s3/object/sdsic-images?region=us-east-1&prefix=MonthlyMeetingVideos/Technology/TechCommitteeMonthly_feb_09_22.json';


class Presentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            presentation: {},
            presentationId: this.props.match.params.presentationId,
            eventId: null
        };
    }

    token = isAuthenticated() && isAuthenticated().token;


    componentDidMount() {

        // this.setState({ userId: isAuthenticated().userId})

        getPresentationById(this.props.match.params.presentationId, this.token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                console.log(data)

                this.setState({
                    presentation: data[0],
                    eventId: data[0].eventId._id
                });

            }
        });

    }


    render() {
        const { presentation, eventId } = this.state;

        return (

            <Container fluid>
                <div className="jumbotron p-3 p-md-3 text-white" style={{ width: "100%", height: "40%", background: "#245aa6" }}>
                    <div className="col-md-12 px-0">
                        <h3 className="display-5 font-weight-normal">{presentation.title}</h3>
                        <p className="lead my-3 font-weight-normal">{presentation.description}</p>
                    </div>
                </div>
                <Row>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href={`/showcaseEvent/${eventId}`}>
                            back to event
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{presentation.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <br />
                <Row>
                    <Col xs={4}>

                        <h5><i>Presented by:</i></h5>
                        <br />
                        {presentation.presenter &&
                            <div>
                                <h3><a href={`/user/${presentation.presenter._id}`}>{presentation.presenter.name} {presentation.presenter.lastName}</a> </h3>
                                <p><b> {presentation.presenter.about} </b></p>
                                <li>{presentation.presenter.email}</li>
                                <li>{presentation.presenter.companyName}</li>
                            </div>
                        }
                        <br />
                        <br />

                        {presentation.copresenter &&
                            <div>
                                <h5><i>Co-Presented by:</i></h5>
                                <h3><a href={`/user/${presentation.copresenter._id}`}>{presentation.copresenter.name} {presentation.copresenter.lastName}</a></h3>
                                <p><b> {presentation.copresenter.about} </b></p>
                                <li>{presentation.copresenter.email}</li>
                                <li>{presentation.copresenter.companyName}</li>
                            </div>
                        }
                        <br />
                        <br />
                        {presentation.guest &&
                            <div>
                                <h3>{presentation.guest.name} {presentation.guest.lastName}</h3>
                                <p><b> {presentation.guest.about} </b></p>
                                <li>{presentation.guest.email}</li>
                                <li>{presentation.guest.companyName}</li>
                            </div>
                        }
                        <p>Presentation was done on <b>{presentation.datePresented}</b></p>

                        <p><a href={presentation.download} target="_blank" rel="noreferrer" >Download the meeting chat </a></p>
                    </Col>
                    <Col xs={8}>
                        <div className='player-wrapper'>
                            <ReactPlayer
                                config={{
                                    file: {
                                        attributes: {
                                            onContextMenu: e => e.preventDefault(),
                                            controlsList: 'nodownload'
                                        }
                                    }
                                }}
                                // className='react-player'
                                url={presentation.video}
                                height='500px'
                                width='100%'
                                controls={true}
                            // light={'images/GeneralSession.png'}
                            />
                        </div>
                    </Col>
                    {/* <Col xs={8}>
                        <div>{ chattxt.data }</div>
                    </Col> */}
                </Row>
                <br />
                <br />
                <br />
                <Row>
                    <div className="jumbotron p-3 p-md-3 text-white" style={{ width: "100%", height: "40%", background: "#245aa6" }}>                        
                        <div className="col-md-12 px-0">
                           <h5 className="display-7 font-weight-normal">{`The use of our Zoom platform is made possible by the generous sponsorship by :    `} <a href="https://ampirical.com" target="_blank"><img src={Ampirical} height="50px" alt="Ampirical" /></a></h5>
                        </div>
                    </div>
                </Row>
                <br />
                
                <Footer />
            </Container >


        );
    }
}

export default Presentation;
