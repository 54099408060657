import React, { Component } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { singleForumPost, remove, like, unlike } from './apiForum';
import { Link, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../auth';
import Comment from './Comment';
import { createDownloadLink } from '../file/apiFiles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactGA from 'react-ga';
import Footer from '../core/Footer';

class SingleForumPost extends Component {
    state = {
        forumpost: '',
        redirectToHome: false,
        redirectToSignin: false,
        like: false,
        likes: 0,
        comments: [],
        fileLink: '',
        fileName: '',
        editorState: EditorState.createEmpty()
    };

    checkLike = likes => {
        const userId = isAuthenticated() && isAuthenticated().userId;
        let match = likes.indexOf(userId) !== -1;
        return match;
    };

    componentDidMount = () => {
        const forumpostId = this.props.match.params.forumpostId;
        singleForumPost(forumpostId).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({
                    forumpost: data,
                    likes: data.likes.length,
                    like: this.checkLike(data.likes),
                    comments: data.comments,
                    editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(data.body)))
                });
                if (data.filetoLink !== undefined) {
                    this.setState({ ...this.state, fileName: data.filetoLink.fileName });
                    const fileInfo = {
                        fileName: data.filetoLink.fileName,
                        awsVersion: data.filetoLink.awsVersion
                    }
                    createDownloadLink(isAuthenticated().token, fileInfo).then(data => {
                        if (data.error) {
                            console.log(data.error);
                        } else {
                            //console.log(data);
                            //return data
                            this.setState({ ...this.state, fileLink: data });
                        }
                    });
                }
            }
        });
        ReactGA.initialize('UA-331790-15')
        ReactGA.pageview('/forumpost/' + forumpostId);
    };

    updateComments = comments => {
        this.setState({ comments });
    };

    likeToggle = () => {
        if (!isAuthenticated()) {
            this.setState({ redirectToSignin: true });
            return false;
        }
        let callApi = this.state.like ? unlike : like;
        const userId = isAuthenticated().userId;
        const forumpostId = this.state.forumpost._id;
        const token = isAuthenticated().token;

        callApi(userId, token, forumpostId).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({
                    like: !this.state.like,
                    likes: data.likes.length
                });
            }
        });
    };

    deleteForumPost = () => {
        const forumpostId = this.props.match.params.forumpostId;
        const token = isAuthenticated().token;
        remove(forumpostId, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({ redirectToHome: true });
            }
        });
    };

    deleteConfirmed = () => {
        let answer = window.confirm('Are you sure you want to delete your forum post?');
        if (answer) {
            this.deleteForumPost();
        }
    };

    renderPost = forumpost => {
        const posterId = forumpost.forumpostedBy ? `/user/${forumpost.forumpostedBy._id}` : '';
        const posterName = forumpost.forumpostedBy ? forumpost.forumpostedBy.name : ' Unknown';

        const { like, likes } = this.state;

        return (
            <div className="card-body">
                {like ? (
                    <h3 onClick={this.likeToggle}>
                        <FontAwesomeIcon icon={faThumbsUp} style={{ color: 'green' }} />
                        {' '}
                        {likes} Likes
                    </h3>
                ) : (
                    <h3 onClick={this.likeToggle}>
                        <FontAwesomeIcon icon={faThumbsUp} style={{ color: 'gray' }} />
                        {' '}
                        {likes} Likes
                    </h3>
                )}

                {/* <p className="card-text">{forumpost.body}</p> */}
                <Editor
                    toolbarHidden="true"
                    editorState={this.state.editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="editer-content"
                    onEditorStateChange={this.onChange}
                />

                Document attached: <a href={`${this.state.fileLink}`} title="Click to Download." >{this.state.fileName}</a>
                <br />
                <br />
                <p className="font-italic mark">
                    Posted by <Link to={`${posterId}`}>{posterName} </Link>
                    on {new Date(forumpost.created).toDateString()}
                </p>
                <div className="d-inline-block">
                    <Link to={`/usershome`} className="btn btn-raised btn-primary btn-sm mr-5">
                        Back to posts
                    </Link>
                    {console.log(forumpost.forumpostedBy._id)}
                    {isAuthenticated().user && isAuthenticated().userId === forumpost.forumpostedBy._id && (
                        <>
                            <Link to={`/forumpost/edit/${forumpost._id}`} className="btn btn-raised btn-warning btn-sm mr-5">
                                Update Forum Post
                            </Link>
                            <button onClick={this.deleteConfirmed} className="btn btn-raised btn-danger">
                                Delete Forum Post
                            </button>
                        </>
                    )}

                    <div>
                        {isAuthenticated().user && isAuthenticated().userId === '5e2611483f99fa3bb4dd922a' && (
                            <div className="card mt-5">
                                <div className="card-body">
                                    <h5 className="card-title">Admin</h5>
                                    <p className="mb-2 text-danger">Edit/Delete as an Admin</p>
                                    <Link
                                        to={`/forumpost/edit/${forumpost._id}`}
                                        className="btn btn-raised btn-warning btn-sm mr-5"
                                    >
                                        Update Forum Post
                                    </Link>
                                    <button onClick={this.deleteConfirmed} className="btn btn-raised btn-danger">
                                        Delete Forum Post
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        {/* {isAuthenticated().user &&
                            isAuthenticated().userId === "5e2611483f99fa3bb4dd922a" && (
                                <div className="card mt-5">
                                    <div className="card-body">
                                        <h5 className="card-title">Admin</h5>
                                        <p className="mb-2 text-danger">
                                            Edit/Delete as an Admin
                    </p>
                                        <Link
                                            to={`/forumpost/edit/${forumpost._id}`}
                                            className="btn btn-raised btn-warning btn-sm mr-5"
                                        >
                                            Update Forum Post
                    </Link>
                                        <button
                                            onClick={this.deleteConfirmed}
                                            className="btn btn-raised btn-danger"
                                        >
                                            Delete Forum Post
                    </button>
                                    </div>
                                </div>
                            )} */}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { forumpost, redirectToHome, redirectToSignin, comments } = this.state;

        if (redirectToHome) {
            return <Redirect to={`/usershome`} />;
        } else if (redirectToSignin) {
            return <Redirect to={`/signin`} />;
        }

        return (
            <div className="container">
                <div className="row flex-nowrap justify-content-between align-items-center">
                    <div className="col-6">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/usershome">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href={`/grouplist`}>
                                back to committee list
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Form Post</Breadcrumb.Item>
                        </Breadcrumb>

                        <h4 className="mt-3 mb-3">{forumpost.title}</h4>

                        {!forumpost ? (
                            <div className="jumbotron text-center">
                                <h2>Loading...</h2>
                            </div>
                        ) : (
                            this.renderPost(forumpost)
                        )}

                    </div>
                    <div className="col-6">
                        <Comment postId={forumpost._id} comments={comments.reverse()} updateComments={this.updateComments} />
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <Footer />
            </div>

        );
    }
}

export default SingleForumPost;