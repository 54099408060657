import React, { Component } from "react";
// import Posts from "../post/Posts";
import { Button, Form } from 'react-bootstrap';
// import BMLogo from '../images/Burns_and_McDonnell_Logo.jpg'
import Footer from '../core/Footer';
import { sendMessage } from "../group/apigroup";



class publicVirtual23 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            success: ''
        }
    }

    handleChange = name => event => {
        if (name === "email") {
            this.setState({ error: "" });
            this.setState({ responseTo: name, [name]: event.target.value });
        }
    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Your on the list!</h3>
                <p>Don't forget to check your inbox and your spam folder for updates from the SDSIC.</p>
                <p>We will use the email you've submitted to send you updates as we finalize the upcoming event.</p>
            </div>
        )
    };

    submitEmail = e => {
        const { email } = this.state;
        e.preventDefault();
        if (email === "") { this.setState({ error: "email required!" }) }

        if (email !== "") {
            const mailMessage = {
                "subject": "SDSIC 2023 Fall event list",
                "sender": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
                "to": [{ "name": "Trevor", "email": "trevor@sdsconsortium.com" }],
                "htmlContent": `<html><body><p>Please add ${email} to the SDSIC 2023 Fall event list.</p></body></html>`
            }

            
            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                this.setState({
                    email: '',
                    success: true,
                });
            });

            const userMessage = {
                "subject": "SDSIC 2023 Fall event",
                "sender": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
                "to": [{ "name": email, "email": email }],
                "htmlContent": `<html><body><p>Your name is on the SDSIC 2023 Fall event list.</p><p>You will be kept up to date " +
                    "as we finalize the event details.</p><p>Please do not reply to this email.</p>"</p></body></html>`
            }


            // make request to api to send message
            sendMessage(userMessage).then(data => { });
        };



    };


    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">

                            <div className="jumbotron">
                                <h2>
                                    Our SDSIC 2023 Fall Virtual Event
                                </h2>
                                <p>This event event was held on November 1st and 2nd, 2023!</p>
                                
                                <h5>
                                    This event is now archived and all presentations are available to our SDSIC members. Simply sign into this website to 
                                    view the recorded presentations under the Events menu titled <u>"Fall Virtual 2023".</u>
                                </h5>
                                
                                {/* <div className="row">
                                    <hr />
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                       
                                        <h4>Register</h4>
                                        <Form.Text className="muted">*Required</Form.Text>
                                        <Form.Control
                                            placeholder="Your Work Email"
                                            name="email"
                                            className="form-control"
                                            type="text"
                                            onChange={this.handleChange("email")}
                                            value={this.state.email}
                                        />
                                        <br />
                                        {this.showError()}
                                        <Button
                                            variant="primary"
                                            onClick={this.submitEmail}
                                        >   Join the list
                                        </Button>
                                        {this.showSuccess()}
                                        <br/>
                                        <br/>
                                    </div>
                                   
                                </div> */}

                               
                            </div>

                        </div>


                    </div >
                    <hr />
                    {/* <div>
                    <div className="row">                    
                            <p>
                                If you are interested in presenting at this Fall Virtual, please register your Presentation
                                on our call for proposals page.
                            </p>
                                <div className="col-md-4">
                                    <a className="btn btn-primary btn-large" href="/publicCallforPapers" target="_blank">Call for Proposals</a>
                                </div>
                            </div>
                    </div> */}
                   
                    <div className="row">
                        <div className="col-md-8">
                            <h2>
                                Sign up for an SDSIC membership!
                            </h2>
                            <p>
                                Don't miss out on the chance to view almost two days of content from vendors and
                                industry peers all from the comfort of your home.
                            </p>
                            <div className="row">
                                <div className="col-md-4">
                                    <a className="btn btn-primary btn-large" href="/signup" target="_blank">Register for an SDSIC Membership</a>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                    <br/>
                    <br/>
                    <hr />
                    <br/>
                    <br/>
                </div >
                <Footer />
            </>
        )
    }
}

export default publicVirtual23