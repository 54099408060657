import React, { Component } from "react";
import { listGroups } from "./apigroup";
import { isAuthenticated } from "../auth";

class Groups extends Component {

    constructor() {
        super();
        this.state = {
            groups: [],
            userId: isAuthenticated() && isAuthenticated().userId,
            token: isAuthenticated().token,
            userEmail: isAuthenticated().email,
        };
    }

    
    loadGroups = () => {
        listGroups(this.state.token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({ groups: data });
            }
        });
    };

    componentDidMount() {
        this.loadGroups();
    }


    render() {
        return (
            <div id="accordion">
                {this.state.groups.map((group, i) => {
                    return (
                        <div className="card">
                            <div className="card-header" id="headingLeader">
                                <h5 className="mb-0">
                                    <a href={`/singlegroup/${group._id}`} className="btn btn-link " data-toggle="collapse" data-target="#collapseleader"
                                        aria-expanded="true" aria-controls="collapseleader">
                                        {group.name}</a>
                                </h5>
                            </div>

                            <div id="collapseleader" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                {group.description}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        )
    }
};

export default Groups;