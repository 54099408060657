import React, { Component } from 'react';
import { isAuthenticated } from '../auth';
import { UploadToS3 } from 'react-upload-to-s3';
import { sendMessage } from '../group/apigroup';
// import Badge from 'react-bootstrap/Badge'
// import Button from 'react-bootstrap/Button'
// import Accordion from 'react-bootstrap/Accordion'
import 'bootstrap/dist/css/bootstrap.min.css';


class EventPhotos extends Component {

    constructor() {
        super();
        this.state = { events: [] }
        this.renderEmpty = this.renderEmpty.bind(this);
    }

    token = isAuthenticated() && isAuthenticated().token;


    renderEmpty(count) {
        //alert(count)
        if (count === 0) {
            return (
                <div>
                    There are currently no photos
                </div>
            )
        }
    }

    componentDidMount() {



    }

    logToEmail(data) {
        const mailMessage = {
            "subject": "SDSIC Image Uploaded",
            "sender": { "email": isAuthenticated().email, "name": isAuthenticated().email },
            "to": [{ "name": "Trevor", "email": "trevor@sdsconsortium.com" }],
            "htmlContent": `<html><body><p>added photo by: ${isAuthenticated().email} to the Nashville photos.<br/> ${data.url}</p></body></html>`
        }
        // make request to api to send message
        sendMessage(mailMessage).then(data => {
            this.setState({
                email: '',
                emailSent: true,
                emailError: false
            });
        });
      }


    render() {

        return (
            <>

                <UploadToS3
                    bucket="nashville-event"
                    awsRegion="us-east-1"
                    awsKey="AKIAJTACW7QVN7PPWLYQ"
                    awsSecret="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"                    
                    type="image"                   
                    onResult={(result) => {
                        console.log('on Result', result);
                        this.logToEmail(result)
                    }}
                />  
           
            </>
        )
    };
};

export default EventPhotos;
