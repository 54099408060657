import React, { Component } from 'react'
import { getEventsForGroup } from './apiEvents';
import { isAuthenticated } from '../auth';
import Card from 'react-bootstrap/Card'
// import Badge from 'react-bootstrap/Badge'
// import Button from 'react-bootstrap/Button'
// import Accordion from 'react-bootstrap/Accordion'
import 'bootstrap/dist/css/bootstrap.min.css';
import Moment from 'moment';


class PastEventList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            groupId: '602bbd7001dde56620236b01'  //virtual event committee
        };
    }

    token = isAuthenticated() && isAuthenticated().token;


    listEvents = () => {
        getEventsForGroup(this.state.groupId, this.token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({
                    events: data
                });
            }
        });
    };

    componentDidMount() {
        this.listEvents();
    }


    renderFiles = (events, groupId, history) => {
        
        return (
            <div>

                {events.map((event, i) => {
                    console.log(event)
                    return (
                        <Card>
                            <Card.Header as="h5">

                                {event._id}

                            </Card.Header>

                            <Card.Body>
                            <li>Date of event: <u>{Moment(event.data[0].startDate).format('MMMM Do YYYY')}</u></li>
                                
                            <li>Type of event: <u>{event.data[0].type}</u></li>
                                <br/>
                                <button onClick={()=> window.location.href=`/showcaseEvent/${event.data[0]._id}`} className="btn btn-outline-primary">Go to Event</button>
                            </Card.Body>

                        </Card>
                    )
                })}

            </div>
        )
    }

    render() {
        const { events, groupId } = this.state;
        return (
            <>
                {this.renderFiles(events, groupId)}
            </>
        )
    };
};

export default PastEventList;