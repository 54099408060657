import React, { Component } from "react";
// import { signup, isAuthenticated } from "../auth";
// import { Link } from "react-router-dom";
// import SocialLogin from "./SocialLogin";
// import { sendMessage } from "./apiUser";
// import { Redirect } from "react-router-dom";


class SignupPending extends Component {
    constructor() {
        super();
        this.state = {
            
        };
    }



    render() {            
        return (
            <div className="row">
		<div className="col-md-12">
			<div className="jumbotron">
				<h2>
					Your account is currently pending!
				</h2>
                <br/>
				<p>
					We appologize for this minor inconvenience but you will recieve and email confirmation of your registration to the SDSIC
                    however, to protect our members, each registration is personally vetted and authenticated by another member. This ensures
                    that our community continues to freely open in an envionrment that is void of solications and other distractions.
				</p>
                <p>
                    You will recieve a follow up email once this process is complete. This is usually done the same day and then you can login
                    and enjoy our free website.
                </p>
				<p>
					<a className="btn btn-primary btn-large" href="/signin">Sign in Page</a>
				</p>
			</div>
		</div>
	</div>
        );
    }
}

export default SignupPending;