import React, { Component } from "react";
import { isAuthenticated } from "../auth";
import { singleForumPost, update } from "./apiForum";
import { Redirect } from "react-router-dom";
// import Select from 'react-select'
import { getFilesForGroup } from '../file/apiFiles';
import Footer from '../core/Footer';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';


class EditForumPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            body: "",
            error: "",
            user: {},
            loading: false,
            redirectToProfile: false,
            groupId: '',
            allGroupFiles: [],
            filetoLink: '',
            editorState: EditorState.createEmpty()
        };
    }

    init = forumpostId => {
        singleForumPost(forumpostId).then(data => {
            if (data.error) {
                this.setState({ redirectToProfile: true });
            } else {                
                this.setState({
                    id: data.forumpostedBy,
                    title: data.title,
                    body: data.body,
                    groupId: data.postedgroupId,
                    error: "",                    
                    editorState : EditorState.createWithContent(convertFromRaw(JSON.parse(data.body)))
                });
                
                
            }
        });

    };

    convertCommentFromJSONToHTML = (text) => { return stateToHTML(convertFromRaw(JSON.parse(text))) }

    componentDidMount() {
        this.forumpostData = new FormData();
        const forumpostId = this.props.match.params.forumpostId;
        this.init(forumpostId);
        this.setState({ user: isAuthenticated().user });
        getFilesForGroup(this.state.groupId ? undefined : '5e2a5e764175b97d84ec6cae', isAuthenticated().token).then(files => {
            if (files.error) {
                console.log(files.error);
            } else {
                this.setState({
                    allGroupFiles: files
                })
                console.log(files)
            }
        })
    }

    isValid = () => {
        const { title, body } = this.state;
        if (title.length === 0 || body.length === 0) {
            this.setState({ error: "All fields are required", loading: false });
            return false;
        }
        return true;
    };

    handleChange = name => event => {
        let value = "";
        if (name === 'filetoLink') {
            value = event.value
        } else {
            value = event.target.value
        }

        this.setState({ error: "" });

        this.forumpostData.set(name, value);
        this.setState({ [name]: value });
    };

    onChange = (editorState) => this.setState({ editorState });

    clickSubmit = event => {
        event.preventDefault();
        this.setState({ loading: true });

        const rawState = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));

        this.forumpostData.set("body", rawState);

        if (this.isValid()) {
            const forumpostId = this.props.match.params.forumpostId;
            const token = isAuthenticated().token;


            update(forumpostId, token, this.forumpostData).then(data => {
                if (data.error) this.setState({ error: data.error });
                else {
                    this.setState({
                        loading: false,
                        title: "",
                        body: "",
                        filetoLink: '',
                        redirectToProfile: true
                    });
                }
            });
        }
    };

    newPostForm = (title, body, groupId, allGroupFiles, editorState) => (

        <form>
            <div className="form-group">
                <label className="text-muted">Title</label>
                <input
                    onChange={this.handleChange("title")}
                    type="text"
                    className="form-control"
                    value={title}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Body</label>
                <Editor
                    editorState={this.state.editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="editer-content"
                    onEditorStateChange={this.onChange}
                    wrapperStyle={{ border: "2px solid blue", marginBottom: "10px" }}
                    editorStyle={{ height: "300px", padding: "10px"}}   
                />
                {/* <textarea
                    onChange={this.handleChange("body")}
                    type="text"
                    className="form-control"
                    value={body}
                    style={{ height: "200px" }}
                /> */}
            </div>
            {/* <div className="form-group">
                <Select
                    name="filetoLink"
                    options={allGroupFiles.map((c, i) => (
                        {
                            value: c._id,
                            label: c.fileCategory + " : " + c.title
                        }
                    ))}
                    onChange={this.handleChange("filetoLink")}
                    isSearchable={true}
                    placeholder={'type a file to search'}
                />
            </div> */}

            <button
                onClick={this.clickSubmit}
                className="btn btn-raised btn-primary"
            >
                Update Forum Message
            </button>
        </form>
    );

    render() {

        const {
            title,
            body,
            redirectToProfile,
            error,
            loading,
            groupId,
            allGroupFiles,
            editorState
        } = this.state;

        if (redirectToProfile) {
            return <Redirect to={`/forumpost/${this.props.match.params.forumpostId}`} />;
        }

        return (
            <div className="container">
                <h2 className="mt-5 mb-5">Editing Post</h2>
                <div
                    className="alert alert-danger"
                    style={{ display: error ? "" : "none" }}
                >
                    {error}
                </div>

                {loading ? (
                    <div className="jumbotron text-center">
                        <h2>Loading...</h2>
                    </div>
                ) : (
                        ""
                    )}

                {this.newPostForm(title, body, groupId, allGroupFiles, editorState)}
                <br />
                <br />
                <Footer />
            </div>

        );

    }
}

export default EditForumPost;