export const appointments = [
    {
      title: "Website Re-Design Plan",
      startDate: '2021-11-03T15:00:00.000Z',
      endDate: '2021-11-03T15:50:00.000Z',
      id: 100,
      location: "Room 1",
      general: [],
      notes: '',
      committeeType: 'general',
      zoomId: '',
      zoompass: '',
      zoomLink: ''
    }
  ];
  