import React, { Component } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import Select from 'react-select'
import { submitSurvey } from '../event/apiEvents'
import { sendMessage } from "../user/apiUser";
import Footer from './Footer';
import { isAuthenticated } from "../auth";

class ContentSurvey2022 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question1: '',
            question2: '',
            question3: '',
            company: '',
            email: "",
            error: "",
            success: false
        }
    }

    handleChange = name => event => {
     
        this.setState({[name]: event.target.value})
        
    }

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Thank you for your responses!</h3>
                <p>We will use the email you've submitted to update you on this survey once completed.</p>
            </div>
        )
    };

    submitSurvey = e => {
        const {
            question1, question2, question3, company, email, error
        } = this.state;
        e.preventDefault();

        if (email === '' || company === '') {
            this.setState({ error: "email or company is missing!" })
        } else {
            if (error == "") {
         
                const mailMessage = {
                    to: 'trevor@sdsconsortium.com',
                    from: email,
                    subject: "SDSIC Content Survey From " + isAuthenticated().email,
                    html: `From user ${email}, <p>completed the content survey with the following answers</p>
                    <p>Question 1: ${question1}</p>
                    <p>Question 2: ${question2}</p>
                    <p>Question 3: ${question3}</p>
                    <p>Company Name: ${company}</p>`
                }

                // make request to api to send message
                sendMessage(mailMessage).then(data => {
                    this.setState({  
                        question1: '',
                        question2: '',     
                        question3: '',                 
                        success: true,                        
                    });
                });

            }
        }


    }

    surveyForm() {
        const {
            question1, question2, question3, email
        } = this.state;
        return (
            <div style={{ display: (this.state.success == false) ? "" : "none" }}>

                <Form>

                    <Card border="primary">
                        <Card.Header>Question 1</Card.Header>
                        <Card.Body>
                            <Card.Title>Approximately how many 3D models would you say that your company could potentially share with the SDSIC community?</Card.Title>
                            <Card.Text>

                                <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    rows="3"
                                    placeholder="Please enter your answer here"
                                    onChange={this.handleChange("question1")}>
                                </Form.Control>

                            </Card.Text>

                        </Card.Body>
                    </Card>
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 2</Card.Header>
                        <Card.Body>
                            <Card.Title>What 3D model file types are in your library (ie. DWG, Inventor, 3D DGN)?</Card.Title>
                            <Card.Text>

                                <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    rows="3"
                                    placeholder="Please enter your answer here"
                                    onChange={this.handleChange("question2")}>
                                </Form.Control>

                            </Card.Text>

                        </Card.Body>
                    </Card>
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 3</Card.Header>
                        <Card.Body>
                            <Card.Title>Would you like to add any other comments to help with this initiative?</Card.Title>
                            <Card.Text>

                                <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    rows="3"
                                    placeholder="Please enter your answer here"
                                    onChange={this.handleChange("question3")}>
                                </Form.Control>

                            </Card.Text>

                        </Card.Body>
                    </Card>
                    <br />
                    <Form.Text className="muted">*Required</Form.Text>
                    <Form.Control
                        placeholder="Your Company Name"
                        name="company"
                        className="form-control"
                        type="text"
                        onChange={this.handleChange("company")}
                        value={this.state.company}
                    />
                    
                    <br />
                    <Form.Text className="muted">*Required</Form.Text>
                    <Form.Control
                        placeholder="Your Work Email"
                        name="email"
                        className="form-control"
                        type="text"
                        onChange={this.handleChange("email")}
                        value={this.state.email}
                    />
                    <br />
                    {this.showError()}
                    <Button
                        variant="primary"
                        onClick={this.submitSurvey}
                    >   Submit Survey
                    </Button>
                </Form>

            </div>
        )
    }

    render() {
       
        return (
            <div className="container-fluid">
                <div className="jumbotron">
                    <h2>NAUCSI Survey</h2>
                    <br />
                    <p>The SDSIC Physical committee has joined an SBS initiative to enhance the 3D content freely hosted on UtilityContent.com
                    This initiative has been named "The North American Utilities Content Sharing initiative" or NAUCSI for short.</p>
                    <p>We could really use your help in determining the potentially scope of this initiative if we can find the correct mechanisms to enable sharing.
                    Keep in mind, this is just to try and get a general estimate on the overall size and does not reflect any commitments on your part. </p>
                    <p>Our UtilityContent.com platform will handle conversions of the file types for free. We use Autodesk Forge model derivative services to convert files. So if your 
                        company was to share 3D DGN files for example, we would auto-generate Native Inventor, DWG and other possible formats from them and make them available to the community.
                    </p>
                    {/* <p>Your can read more about this initiative here: <a href="https://www.utilitycontent.com/naucsi" target="_blank">NAUCSI page</a></p> */}
                </div>
                <div className="row justify-content-md-center">

                    <div className="col-md-10">
                        {this.state.email}
                        {this.surveyForm()}
                        <br />

                        {this.showSuccess()}
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

export default ContentSurvey2022;