import React, { Component } from 'react';
import { Card, Button, Form, Col } from 'react-bootstrap';

import Select from 'react-select'
import { submitSurvey } from '../event/apiEvents'
import { sendMessage } from "../user/apiUser";
import Footer from '../core/Footer';

class Survey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: "6001c0547024a75b1a789ab1",
            question1: [],
            question2: [],
            question3: [],
            question3_other: [],
            question4: [],
            question4_other: [],
            question5: [],
            question6: [],
            question7: [],
            question8: [],
            question9: [],
            question9_other: [],
            email: "",
            error: "",
            success: false
        }
    }

    handleChange = name => event => {

        if (name == "question1") {
            const finalVal = (event && event.map((c, i) => (
                { responseTo: name, id: c.value, label: c.label }
            )))
            
            this.setState({ [name]: finalVal });
        }

        if (name == "question2") {
            let items = [...this.state.question2];
            items[items.length] = { responseTo: name, id: event.value, label: event.label }
            this.setState({ items, [name]: items });
        }

        if (name == "question3") {
            const finalVal = (event && event.map((c, i) => (
                { responseTo: name, id: c.value, label: c.label }
            )))
            this.setState({ [name]: finalVal });
        }

        // always grab the "other text as well and add it to the items."
        if (name == "question3_other") {
            const finalVal = [{ responseTo: name, [name]: event.target.value}]            
            this.setState({ [name]: finalVal });            
        }

        if (name == "question4") {
            const finalVal = [{ responseTo: name, id: event.value, label: event.label }]            
            this.setState({ [name]: finalVal });
        }

        // always grab the "other text as well and add it to the items."
        if (name == "question4_other") {
            const finalVal = [{ responseTo: name, [name]: event.target.value} ]           
            this.setState({ [name]: finalVal });            
        }

        if (name == "question5") {
            const finalVal = [{ responseTo: name, [name]: event.target.value} ]           
            this.setState({ [name]: finalVal });   
        }
        // always grab the "other text as well and add it to the items."
        if (name == "question5_other") {
            const finalVal = [{ responseTo: name, [name]: event.target.value} ]           
            this.setState({ [name]: finalVal });   
        }

        if (name == "question6") {
            const finalVal = [{ responseTo: name, [name]: event.target.value}  ]          
            this.setState({ [name]: finalVal });   
        }

        if (name == "question7") {
            const finalVal = [{ responseTo: name, [name]: event.target.value}]            
            this.setState({ [name]: finalVal });   
        }

        if (name == "question8") {
            const finalVal = [{ responseTo: name, [name]: event.target.value} ]           
            this.setState({ [name]: finalVal });   
        }

        if (name == "question9") {
            const finalVal = (event && event.map((c, i) => (
                { responseTo: name, id: c.value, label: c.label }
            )))
            this.setState({ [name]: finalVal });
        }

        // always grab the "other text as well and add it to the items."
        if (name == "question9_other") {
            const finalVal = [{ responseTo: name, [name]: event.target.value} ]           
            this.setState({ [name]: finalVal });   
        }

        if (name == "email") {
            this.setState({ error: "" });
            this.setState({ responseTo: name, [name]: event.target.value });
        }

    }

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };
    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Thank you for your responses!</h3>
                <p>Don't forget to check your inbox and your spam folder for updates from the SDSIC.</p>
                <p>We will use the email you've submitted to send you future updates as we finalize the upcoming event.</p>
            </div>
        )
    };

    submitSurvey = e => {
        const {
            question1, question2, question3, question3_other, question4, question4_other, question5, question6,
            question7, question9, question9_other, email, error, eventId
        } = this.state;
        e.preventDefault();

        if (email == "") { this.setState({ error: "email required!"}) }
        
        if (error == "") {

            // structure Json body to send
            const fullBody = {
                submittedByemail: email,
                eventId: eventId,
                answers: [
                    question1,
                    question2,
                    question3,
                    question3_other,
                    question4,
                    question4_other,
                    question5,
                    question6,
                    question7,                    
                    question9,
                    question9_other
                ]
            }
            console.log(fullBody)
            submitSurvey(fullBody, eventId).then(data => {
                if (data.error) {
                    this.setState({
                        error: data.error,
                        success: false
                    });
                } else {
                    
                    const adminMessage = {
                        "subject": 'SDSIC Spring event Survey Submitted',
                        "sender": { "email": `${email}`, "name": `${email}`  },
                        "to": [{ "name": 'trevor@sdsconsortium.com', "email": 'trevor@sdsconsortium.com' }],
                        "htmlContent": `From email ${email}, <p>${fullBody}</p>
                        <p>subbmitted</p>`
                    }

                    const usersMessage = {
                        "subject": 'SDSIC Spring 2021 event survey',
                        "sender": { "email": "info@sdsconsortium.com", "name": "info@sdsconsortium.com"  },
                        "to": [{ "name": `${email}`, "email": `${email}` }],
                        "htmlContent": `From email ${email}, <p>${fullBody}</p>
                        <p>subbmitted</p>`
                    }

                    // make request to api to send message
                    sendMessage(usersMessage).then(data => {
                        this.setState({
                            firstName: "",
                            lastName: "",
                            companyName: "",
                            country: "",
                            stateProvince: "",
                            zipPostal: "",
                            workphone: "",
                            email: "",
                            meetings: "",
                            industry: "",
                            jobLevel: "",
                            role: "",
                            terms: false,
                            mailing: false,
                            success: true
                        });
                    });
                    // make request to api to send the admin message
                    sendMessage(adminMessage).then(data => { });
                }
            });
        } 
    }

    surveyForm() {
        const {
            question1, question2, question3, question3_other, question4, question4_other, question5, question6,
            question7, question8, question9, question9_other, email
        } = this.state;
        return (
            <div style={{ display: (this.state.success == false) ? "" : "none" }}>
                    
                <Form>
                    <Card border="primary">
                        <Card.Header>Question 1</Card.Header>
                        <Card.Body>
                            <Card.Title>Which committees would you plan on attending?</Card.Title>
                            <Form.Text muted>
                                To select multiple options, simply click and select over and over.
                                        </Form.Text>

                            <Select
                                as="select"
                                isMulti
                                style={{ height: 150, width: 300 }}
                                options={[
                                    { value: "5e2a5e764175b97d84ec6cae", label: "Physical" },
                                    { value: "5e44050f4e6ed37ab467d0f2", label: "Electrical" },
                                    { value: "5e4405864e6ed37ab467d0f4", label: "Civil" },
                                    { value: "5e44052e4e6ed37ab467d0f3", label: "Technology" },
                                    { value: "5e4406454e6ed37ab467d0f5", label: "Business Strategy" }
                                ]}
                                onChange={this.handleChange("question1")}
                            />
                        </Card.Body>
                    </Card>
                    <br />

                    <Card border="primary">
                        <Card.Header>Question 2</Card.Header>
                        <Card.Body>
                            <Card.Title>What would you say your level of experience/ knowledge is with the following solutions?</Card.Title>
                            <Card.Text>

                                <Form.Group controlId="inventorSDS" as={Col} >
                                    <Form.Label><u>Inventor with SDS</u></Form.Label>
                                    <Form.Text className="text-muted">
                                        Choose from: [1 = I am completely Green to ... 5 = I’m a total expert ]
                                        </Form.Text>
                                    <Select
                                        as="select"
                                        style={{ width: 300 }}
                                        options={[
                                            { value: "inventorSDS", label: "[1]: I am completely Green" },
                                            { value: "inventorSDS", label: "[2]: I have some basic knowledge" },
                                            { value: "inventorSDS", label: "[3]: I have a solid background" },
                                            { value: "inventorSDS", label: "[4]: I am highly skilled" },
                                            { value: "inventorSDS", label: "[5]: I’m a total expert" }
                                        ]}
                                        onChange={this.handleChange("question2")}
                                    />
                                </Form.Group>
                                <Form.Group controlId="inventor" as={Col} >
                                    <Form.Label><u>Inventor Out of the Box</u></Form.Label>
                                    <Form.Text className="text-muted">
                                    Choose from: [1 = I am completely Green to ... 5 = I’m a total expert ]
                                            </Form.Text>
                                    <Select
                                        as="select"
                                        style={{ width: 300 }}
                                        options={[
                                            { value: "inventor", label: "[1]: I am completely Green" },
                                            { value: "inventor", label: "[2]: I have some basic knowledge" },
                                            { value: "inventor", label: "[3]: I have a solid background" },
                                            { value: "inventor", label: "[4]: I am highly skilled" },
                                            { value: "inventor", label: "[5]: I’m a total expert" }
                                        ]}
                                        onChange={this.handleChange("question2")}
                                    />
                                </Form.Group>
                                <Form.Group controlId="autocadSDS" as={Col} >
                                    <Form.Label><u>AutoCAD Electrical with SDS</u></Form.Label>
                                    <Form.Text className="text-muted">
                                    Choose from: [1 = I am completely Green to ... 5 = I’m a total expert ]
                                            </Form.Text>
                                    <Select
                                        as="select"
                                        style={{ width: 300 }}
                                        options={[
                                            { value: "autocadSDS", label: "[1]: I am completely Green" },
                                            { value: "autocadSDS", label: "[2]: I have some basic knowledge" },
                                            { value: "autocadSDS", label: "[3]: I have a solid background" },
                                            { value: "autocadSDS", label: "[4]: I am highly skilled" },
                                            { value: "autocadSDS", label: "[5]: I’m a total expert" }
                                        ]}
                                        onChange={this.handleChange("question2")}
                                    />
                                </Form.Group>
                                <Form.Group controlId="autocadelectric" as={Col} >
                                    <Form.Label><u>AutoCAD Electrical Out of the Box</u></Form.Label>
                                    <Form.Text className="text-muted">
                                    Choose from: [1 = I am completely Green to ... 5 = I’m a total expert ]
                                            </Form.Text>
                                    <Select
                                        as="select"
                                        style={{ width: 300 }}
                                        options={[
                                            { value: "autocadelectric", label: "[1]: I am completely Green" },
                                            { value: "autocadelectric", label: "[2]: I have some basic knowledge" },
                                            { value: "autocadelectric", label: "[3]: I have a solid background" },
                                            { value: "autocadelectric", label: "[4]: I am highly skilled" },
                                            { value: "autocadelectric", label: "[5]: I’m a total expert" }
                                        ]}
                                        onChange={this.handleChange("question2")}
                                    />
                                </Form.Group>

                                <Form.Group controlId="civil3d" as={Col} >
                                    <Form.Label><u>AutoCAD Civil 3D</u></Form.Label>
                                    <Form.Text className="text-muted">
                                    Choose from: [1 = I am completely Green to ... 5 = I’m a total expert ]
                                    </Form.Text>
                                    <Select
                                        as="select"
                                        style={{ width: 300 }}
                                        options={[
                                            { value: "civil3d", label: "[1]: I am completely Green" },
                                            { value: "civil3d", label: "[2]: I have some basic knowledge" },
                                            { value: "civil3d", label: "[3]: I have a solid background" },
                                            { value: "civil3d", label: "[4]: I am highly skilled" },
                                            { value: "civil3d", label: "[5]: I’m a total expert" }
                                        ]}
                                        onChange={this.handleChange("question2")}
                                    />
                                </Form.Group>

                                <Form.Group controlId="infraworks" as={Col} >
                                    <Form.Label><u>Autodesk Infraworks</u></Form.Label>
                                    <Form.Text className="text-muted">
                                    Choose from: [1 = I am completely Green to ... 5 = I’m a total expert ]
                                    </Form.Text>
                                    <Select
                                        as="select"
                                        style={{ width: 300 }}
                                        options={[
                                            { value: "infraworks", label: "[1]: I am completely Green" },
                                            { value: "infraworks", label: "[2]: I have some basic knowledge" },
                                            { value: "infraworks", label: "[3]: I have a solid background" },
                                            { value: "infraworks", label: "[4]: I am highly skilled" },
                                            { value: "infraworks", label: "[5]: I’m a total expert" }
                                        ]}
                                        onChange={this.handleChange("question2")}
                                    />
                                </Form.Group>

                                <Form.Group controlId="bim360" as={Col} >
                                    <Form.Label><u>BIM 360</u></Form.Label>
                                    <Form.Text className="text-muted">
                                    Choose from: [1 = I am completely Green to ... 5 = I’m a total expert ]
                                    </Form.Text>
                                    <Select
                                        as="select"
                                        style={{ width: 300 }}
                                        options={[
                                            { value: "bim360", label: "[1]: I am completely Green" },
                                            { value: "bim360", label: "[2]: I have some basic knowledge" },
                                            { value: "bim360", label: "[3]: I have a solid background" },
                                            { value: "bim360", label: "[4]: I am highly skilled" },
                                            { value: "bim360", label: "[5]: I’m a total expert" }
                                        ]}
                                        onChange={this.handleChange("question2")}
                                    />
                                </Form.Group>

                                <Form.Group controlId="vault" as={Col} >
                                    <Form.Label><u>Autodesk Vault</u></Form.Label>
                                    <Form.Text className="text-muted">
                                    Choose from: [1 = I am completely Green to ... 5 = I’m a total expert ]
                                    </Form.Text>
                                    <Select
                                        as="select"
                                        style={{ width: 300 }}
                                        options={[
                                            { value: "vault", label: "[1]: I am completely Green" },
                                            { value: "vault", label: "[2]: I have some basic knowledge" },
                                            { value: "vault", label: "[3]: I have a solid background" },
                                            { value: "vault", label: "[4]: I am highly skilled" },
                                            { value: "vault", label: "[5]: I’m a total expert" }
                                        ]}
                                        onChange={this.handleChange("question2")}
                                    />
                                </Form.Group>

                                <Form.Group controlId="forge" as={Col} >
                                    <Form.Label><u>Autodesk Forge</u></Form.Label>
                                    <Form.Text className="text-muted">
                                    Choose from: [1 = I am completely Green to ... 5 = I’m a total expert ]
                                    </Form.Text>
                                    <Select
                                        as="select"
                                        style={{ width: 300 }}
                                        options={[
                                            { value: "forge", label: "[1]: I am completely Green" },
                                            { value: "forge", label: "[2]: I have some basic knowledge" },
                                            { value: "forge", label: "[3]: I have a solid background" },
                                            { value: "forge", label: "[4]: I am highly skilled" },
                                            { value: "forge", label: "[5]: I’m a total expert" }
                                        ]}
                                        onChange={this.handleChange("question2")}
                                    />
                                </Form.Group>

                            </Card.Text>

                        </Card.Body>
                    </Card>
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 3</Card.Header>
                        <Card.Body>
                            <Card.Title>What would you say would be your key reasons for attending an SDSIC virtual event?</Card.Title>
                            <Card.Text>
                                <Form.Text muted>
                                    To select multiple options, simply click and select over and over.
                                    </Form.Text>
                            </Card.Text>
                            <Select
                                as="select"
                                style={{ height: 200 }}
                                isMulti
                                options={[
                                    { value: "1", label: "Gain a broad understanding of multiple solutions" },
                                    { value: "2", label: "Gain more understanding of solutions within a given subject like ‘Physical design with SDS’" },
                                    { value: "3", label: "Keep up with the latest updates on specific solutions" },
                                    { value: "4", label: "Get some introductions to various tools in use" },
                                    { value: "5", label: "Hear from peers on what they are up to" },
                                    { value: "6", label: "Keep up to date" },
                                    { value: "7", label: "Get some credits toward engineering hours" }
                                ]}
                                onChange={this.handleChange("question3")}
                            />
                            <br />
                            <Form.Control
                                className="form-control"
                                as="textarea"
                                rows="3"
                                placeholder="Other: type your answer"
                                onChange={this.handleChange("question3_other")}>
                            </Form.Control>
                        </Card.Body>
                    </Card>
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 4</Card.Header>
                        <Card.Body>
                            <Card.Title>Which technology trend will have the greatest impact on our industry in the next year?</Card.Title>
                            <Card.Text>
                                <Select
                                    as="select"
                                    style={{ height: 150 }}
                                    options={[
                                        { value: "1", label: "Internet of Things" },
                                        { value: "2", label: "Augmented reality (AR) and virtual reality (VR)" },
                                        { value: "3", label: "Machine learning" },
                                        { value: "4", label: "Automation" },
                                        { value: "5", label: "Humanized Big Data" }
                                    ]}
                                    onChange={this.handleChange("question4")}
                                />

                                <br />
                                <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    rows="3"
                                    placeholder="Other: type your answer"
                                    onChange={this.handleChange("question4_other")}>
                                </Form.Control>

                            </Card.Text>

                        </Card.Body>
                    </Card>
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 5</Card.Header>
                        <Card.Body>
                            <Card.Title>What are you hoping to get out of the event?</Card.Title>
                            <Card.Text>

                                <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    rows="3"
                                    placeholder="Other: type your answer"
                                    onChange={this.handleChange("question5")}>
                                </Form.Control>

                            </Card.Text>

                        </Card.Body>
                    </Card>
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 6</Card.Header>
                        <Card.Body>
                            <Card.Title>If you’ve attended past events (live or virtual) what would be your
                                recommendations for us as we plan for our next one?</Card.Title>
                            <Card.Text>

                                <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    rows="3"
                                    placeholder="Other: type your answer"
                                    onChange={this.handleChange("question6")}>
                                </Form.Control>

                            </Card.Text>

                        </Card.Body>
                    </Card>
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 7</Card.Header>
                        <Card.Body>
                            <Card.Title>If you have complaints about past events, please enter them below. Don’t be shy! We want to improve!</Card.Title>
                            <Card.Text>

                                <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    rows="3"
                                    placeholder="Other: type your answer"
                                    onChange={this.handleChange("question7")}>
                                </Form.Control>

                            </Card.Text>

                        </Card.Body>
                    </Card>
                    {/* <br />
                    <Card border="primary">
                        <Card.Header>Question 8</Card.Header>
                        <Card.Body>
                            <Card.Title>Will you be focused on one committee or multiple?</Card.Title>
                            <Card.Text>
                                <Select
                                    as="select"
                                    multiple
                                    style={{ height: 150 }}
                                    options={[
                                        { value: "1", label: "One Committee" },
                                        { value: "2", label: "Mulitple Committees" },
                                        { value: "3", label: "Everything I can see" },
                                        { value: "4", label: "Not sure. It's my first time attending." }
                                    ]}
                                    onChange={this.handleChange("question8")}
                                />

                            </Card.Text>

                        </Card.Body>
                    </Card> */}
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 8</Card.Header>
                        <Card.Body>
                            <Card.Title>What is your role in your organization?</Card.Title>
                            <Card.Text>
                                <Form.Text muted>
                                    To select multiple options, simply click and select over and over.
                                    </Form.Text>
                                <Select
                                    as="select"
                                    style={{ height: 150 }}
                                    isMulti
                                    options={[
                                        { value: "1", label: "CAD User" },
                                        { value: "2", label: "Manager" },
                                        { value: "3", label: "IT" },
                                        { value: "4", label: "Field Support" }
                                    ]}
                                    onChange={this.handleChange("question9")}
                                />

                                <br />
                                <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    rows="3"
                                    placeholder="Other: type your answer"
                                    onChange={this.handleChange("question9_other")}>
                                </Form.Control>

                            </Card.Text>

                        </Card.Body>
                    </Card>
                    <br />
                    <Form.Text className="muted">*Required</Form.Text>
                    <Form.Control
                        placeholder="Your Work Email"
                        name="email"
                        className="form-control"
                        type="text"
                        onChange={this.handleChange("email")}
                        value={this.state.email}
                    />
                    <br />
                    {this.showError()}
                    <Button
                        variant="primary"
                        onClick={this.submitSurvey}
                    >   Submit Survey
                    </Button>
                </Form>

            </div>
        )
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="jumbotron">
                <h2>Welcome to the SDSIC event Survey</h2>
                <br/>
                <p>By answering these few, short questions, you will be helping the SDSIC leadership committee plan and create better
                event experiences for all attendees. We review every single response so please don't hesitate to send us requests and we
                thank you in advance.
                </p>  
                <p>We will send you updates as the events near and we thank you in advance for helping us improve our virtual events.</p>             
                </div>
                <div className="row justify-content-md-center">
                    
                    <div className="col-md-10">  
                                        
                        {this.surveyForm()}
                        <br/>
                        
                        {this.showSuccess()}
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

export default Survey;