import React, { Component } from "react";
// import Posts from "../post/Posts";
import { Button, Form } from 'react-bootstrap';
import { sendMessage } from "../user/apiUser";
import Footer from './Footer';
import moment from "moment";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

class publicCallforPapers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            success: false,
            committee: 'Business Strategy',
            sessionFormat: '',
            proposal: '',
            proposalTitle: ''
        }
    }

    handleChange = name => event => {
        this.setState({
            error: "",
            [name]: event.target.value
        });
    };

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Thank you for your proposal!</h3>
                <p>Don't forget to check your inbox and your spam folder for a confirmation email.</p>
                <p>You will hear back from the committee as soon as decisions are made on propsed presentations.</p>
                <p>Thank you!</p>
            </div>
        )
    };


    submitProposal = e => {
        const { email, committee, sessionFormat, proposal, proposalTitle } = this.state;
        e.preventDefault();
        var sendOk = true
        if (email === "") {
            this.setState({ error: "email required!" })
            sendOk = false
        }
        if (proposal === "") {
            this.setState({ error: "Proposal Information can't be blank!" })
            sendOk = false
        }

        if (sendOk) {

            const usersMessage = {
                "subject": 'Thank you for your SDSIC 2024 Spring Conference presentation proposal',
                "sender": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
                "to": [{ "name": `${email}`, "email": `${email}` }],
                "bcc": [{ "Trevor Scullion": "Trevor Scullion", "email": "trevor@sdsconsortium.com" }],
                "htmlContent": `<html><body>Hi ${email}, <br/><p> Thank you for your presentation proposal.</p>
            <p>You submitted a presentation for the: <b>${committee} </b> committee. </p>
            <p>The presentation format you selected was:<b> ${sessionFormat} </b></p>
            <p>The proposal title was: <b>${proposalTitle}</b></p>
            <p>The outline submitted was: <b>${proposal}</b></p>            
            <p>The commitee chair and co-chair will vote on submissions and respond accordingly. Read over our guidelines to understand more about the submissions.</p>
            <p>Thank you for helping advance the SDSIC in 2024! </p>
            <p></p></html>`
            }

            // make request to api to send message
            sendMessage(usersMessage).then(data => {
                this.setState({
                    email: '',
                    error: '',
                    success: true,
                    committee: '',
                    sessionFormat: '',
                    proposal: ''
                });
            });
        }

    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };


    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">
                            <div className="jumbotron p-3 p-md-5 text-white rounded bg-dark">
                                <div className="row mb-2">
                                    <div className="col-md-12">
                                        <h5 className="display-5 font-italic">Call for 2024 SDSIC Spring Event presentations</h5>
                                        <h5>Presentations must be submitted by 11:59 pm EST on February 1st, 2024</h5>

                                        <p className="lead my-3">SDSIC is dedicated to supporting the success of our members and their engagement with professional engineers and designers. Our roles as professionals in the Electric Utility Industry often requires innovation,
                                            problem solving, entrepreneurial spirit, nimble growth, and collaboration as we respond to industry and community needs.
                                        </p>
                                        <p className="lead mb-0">We want to hear about your projects and efforts to improve the Power Grid and the lives of people in your community and beyond.</p>
                                    </div>

                                </div>
                            </div>

                            <main role="main" className="container">
                                <div className="row">
                                    <div className="col-md-10">
                                        <h2 className="blog-post-title">How it works</h2>
                                        <p>
                                            Our committees will evaluate your presentation propsal and make selections based on topic, content, and time availability. 
                                            All authors will be notified after the selection process by February 15th 2024.
                                        </p>
                                        <br />


                                    </div>



                                    <h2>Why Present at SDSIC Live event in 2024?</h2>
                                    <ul>
                                        <li>You get the chance to share your project successes with an international audience</li>
                                        <li>Recognition on the 1,900+ SDSIC website</li>                                        
                                        <li>You will receive a <b>conference pass for free!</b></li>
                                        <li>Your presentation will be published on our website for all time</li>
                                    </ul>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-8">
                                        {this.showSuccess()}
                                        {this.showError()}
                                        <h2>Submit a presentation proposal</h2>
                                        <Form>
                                            <Form.Group
                                                controlId="exampleForm.ControlInput1">
                                                <Form.Label>Your email address:</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="name@example.com"
                                                    onChange={this.handleChange("email")}
                                                    value={this.state.email}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                controlId="exampleForm.ControlSelect1">
                                                <Form.Label>Committee to present in:</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    onChange={this.handleChange("committee")}
                                                    value={this.state.committee}
                                                >
                                                    <option>Business Strategy</option>
                                                    <option>Civil/Structural</option>
                                                    <option>Electrical</option>
                                                    <option>Innovative Solutions</option>
                                                    <option>Physical</option>
                                                    <option>Technology</option>
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group
                                                controlId="exampleForm.ControlSelect2">
                                                <Form.Label>Session Format (see below):</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    onChange={this.handleChange("sessionFormat")}
                                                    value={this.state.sessionFormat}
                                                >
                                                    <option>Open Discussion</option>
                                                    <option>Panel</option>
                                                    <option>Presentation</option>
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group
                                                controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Your Presentation Outline (150 words max):</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={1}
                                                    placeholder="enter your proposal title here"
                                                    value={this.state.proposalTitle}
                                                    onChange={this.handleChange("proposalTitle")}
                                                />
                                            </Form.Group>

                                            <Form.Group
                                                controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Your Presentation Outline (350 words max):</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={4}
                                                    placeholder="enter your proposal details here"
                                                    value={this.state.proposal}
                                                    onChange={this.handleChange("proposal")}
                                                />
                                            </Form.Group>

                                            <Button
                                                variant="primary"
                                                type="submit"
                                                onClick={this.submitProposal}
                                            >
                                                Submit
                                            </Button>
                                        </Form>

                                    </div>
                                </div>
                                <br />
                                <hr />
                                <div className="row">
                                    <h2>Session Formats</h2>
                                    <ul>
                                        <li><b>Open Discussion:</b> Moderator-facilitated, in-depth conversation with participants serving as key contributors around a specific issue or topic concerning electric utilities. Participants are responsible for much of the content shared, as well as which issues receive the most discussion.</li>
                                        <li><b>Panel:</b> Moderator-led discussion with experts in the field with an opportunity for audience Q&A. Features multiple SDSIC members, Electric Utility employees, or other experts in conversation with each other to advance participant understanding about an important issue in our industry.</li>
                                        <li><b>Member Presentation:</b> Formal presentation by an expert, often featuring a visual component (e.g., PowerPoint, etc.) designed to inform the audience about a specific issue or topic and demonstrate key components of a particular approach. Selling is NOT allowed.</li>
                                    </ul>
                                </div>
                                <br />
                                <hr />

                                <div className="row">
                                    <h2>Tips and guidelines</h2>
                                    <p>Submissions should tell inspiring stories that illustrate how you are using solutions, specific technology, unique workflows are tell your project story and how it positively impacted your communities or industries.</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Submission guidelines</h3>
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                        Be clear
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        What you submit is what you’ll present. Nothing upsets attendees more than signing up for a session that’s not as advertised.
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                        Be enthusiastic
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body>Make sure your content is not only relevant but also exciting. What invaluable information will you share during the session?</Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                                        Be specific
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="2">
                                                    <Card.Body>Identify your target audience and the roles that will specifically benefit from this session. Make sure your presentation stays on topic and is relevant to the session category.</Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                                        Be original
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="3">
                                                    <Card.Body>Attendees want to see new presentations that cover the latest innovations in technology. Take the time to create well-written titles, abstracts, and outlines, and select the appropriate audience and expertise level.</Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                                        Be educational
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="4">
                                                    <Card.Body>Be sure your proposal doesn't sound like a sales pitch but rather like an exciting opportunity for attendees to learn something new.</Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </div>

                                    <br />
                                    <div className="col-md-6">

                                        <h3>Helpful tips</h3>
                                        <Accordion defaultActiveKey="0a">
                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                        Include relevant details
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0a">
                                                    <Card.Body>
                                                        Please provide any pertinent information that will help us understand your selection of discipline. This helps us determine the appropriate topic category for your presentation.
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1a">
                                                        No product promotion allowed
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1a">
                                                    <Card.Body>All papers and presentations must be noncommercial. At no time is it permissible for presenters to use their time slot to advertise or promote a product, service, or company.</Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                        </Accordion>
                                    </div>
                                </div>
                            </main>
                            <br />
                            <br />
                            <br />



                        </div>

                    </div >

                </div >
                <Footer />
            </>
        )
    }
}

export default publicCallforPapers;