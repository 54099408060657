import React, { Component } from 'react';
import { Card, Button, Form, Col, Jumbotron } from 'react-bootstrap';
import Select from 'react-select'
import { submitSurvey } from '../event/apiEvents'
import { sendMessage } from "../user/apiUser";
import Footer from '../core/Footer';
import ReactPlayer from 'react-player'

class Survey extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {

        return (
            <div className="container-fluid">
                <div>
                    <img src="//logo.clearbit.com/atce.com" />
                    <img src="//logo.clearbit.com/broadspectrum.com" />
                    <img src="//logo.clearbit.com/Cantega.com" />
                    <img src="//logo.clearbit.com/comed.com" />
                    <img src="//logo.clearbit.com/deainc.com" />
                    {/* <img alt="eciblgs.com" src="//logo.clearbit.com/eciblgs.com" />
                    <img alt="ecsourceservices.com" src="//logo.clearbit.com/ecsourceservices.com" />
                    <img alt="bba.ca" src="//logo.clearbit.com/bba.ca"
                        onerror="this.onerror=null;this.src=/SDSLogo10Year.png';" /> */}
                 <div className='player-wrapper'>
                    <ReactPlayer
                        className='react-player'
                        url='https://sdsic-images.s3.amazonaws.com/Spring2021Event/May_4th_OpeningSessionVideo.mp4'
                        height='500px'
                        width='800px'
                        controls={true}
                        light={'images/GeneralSession.png'}
                    />
                    </div>
                </div>
                
                   
            
                <Footer />
            </div>

        )
    }
}

export default Survey;