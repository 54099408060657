import React, { Component } from 'react';
import { getEventsForGroup, getAllEvents } from './apiEvents';
import { isAuthenticated } from '../auth';
import Card from 'react-bootstrap/Card'
// import Badge from 'react-bootstrap/Badge'
// import Button from 'react-bootstrap/Button'
// import Accordion from 'react-bootstrap/Accordion'
import 'bootstrap/dist/css/bootstrap.min.css';
import Moment from 'moment';

class EventList extends Component {

    constructor() {
        super();
        this.state = { events: [] }
        this.renderEmpty = this.renderEmpty.bind(this);
    }

    token = isAuthenticated() && isAuthenticated().token;


    loadEvents = () => {
        getEventsForGroup(this.props.groupId, this.token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({ events: data });
            }
        });
    };

    loadAllEvents() {
        getAllEvents(this.token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({ events: data });
            }
        });
    };

    renderEmpty(count) {
        //alert(count)
        if (count === 0) {
            return (
                <div>
                    There are currently no events for the committee

                </div>
            )
        }
    }

    componentDidMount() {

        if (this.props.groupId === '99') {
            this.loadAllEvents();
        } else {
            this.loadEvents();
        }

    }

    formattedReport(event) {
        if (this.props.groupId === '99') {
            return (
                <div>
                    <p>Held on: {Moment(event.startDate).format('MM/DD/YYYY')}</p>
                    <p>{event.notes.substring(0, 50) + " ... "}</p>
                    <p><a href={`/singleEvent/${event._id}`}> get Details...</a></p>
                </div>
            )
        } else {
            return (
                <div>
                    <p>Held on: {Moment(event.data[0].startDate).format('MM/DD/YYYY')}</p>
                    <p>{event.data[0].notes.substring(0, 50) + " ... "}</p>
                    <p><a href={`/singleEvent/${event.data[0]._id}`}> get Details...</a></p>
                    
                </div>
            )
        }

    }


    renderEvents = events => {
        return (
            <div>

                {events.map((event, i) => {
                    return (
                        <Card>
                            <Card.Header>
                                <b>{event.title}</b>
                            </Card.Header>

                            <Card.Body>
                                {this.formattedReport(event)}
                            </Card.Body>

                        </Card>
                    )
                })}


            </div>
        )
    }

    render() {
        const { events } = this.state;
        return (
            <>
                {this.renderEmpty(events.length)}
                {this.renderEvents(events)}
            </>
        )
    };
};

export default EventList;
