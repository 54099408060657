import React, { Component } from "react";
import { isAuthenticated } from "../auth";
import { read, update, updateUser } from "./apiUser";
import { Redirect } from "react-router-dom";
// import DefaultProfile from "../images/avatar.png";

class EditProfile extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      name: "",
      lastName: "",
      companyName: "",
      companyType: "",
      password: "",
      redirectToProfile: false,
      error: "",
      fileSize: 0,
      loading: false,
      about: ""
    };
  }

  init = userId => {
    const token = isAuthenticated().token;
    read(userId, token).then(data => {
      if (data.error) {
        this.setState({ redirectToProfile: true });
      } else {        
        this.setState({
          id: data._id,
          name: data.name,         
          companyName: data.companyName,
          lastName: data.lastName,
          companyType: data.companyType,
          error: "",
          about: data.about
        });
      }
    });
  };

  componentDidMount() {
    this.userData = new FormData();
    const userId = this.props.match.params.userId;
    this.init(userId);
  }

  // isValid = () => {
  //   const { name, password } = this.state;
  //   // if (fileSize > 1000000) {
  //   //   this.setState({
  //   //     error: "File size should be less than 100kb",
  //   //     loading: false
  //   //   });
  //   //   return false;
  //   // }
  //   if (this.state.name.length === 0) {
  //     this.setState({ error: "Name is required", loading: false });
  //     return false;
  //   }
    
  //   if (password.length >= 1 && password.length <= 5) {
  //     this.setState({
  //       error: "Password must be at least 6 characters long",
  //       loading: false
  //     });
  //     return false;
  //   }
  //   return true;
  // };

  handleChange = name => event => {
    this.setState({ error: "" });
    const value = name === "photo" ? event.target.files[0] : event.target.value;

    const fileSize = name === "photo" ? event.target.files[0].size : 0;
    this.userData.set(name, value);
    this.setState({ [name]: value, fileSize });
  };


  clickSubmit = event => {
    event.preventDefault();
    this.setState({ loading: true });

    // if (this.isValid()) {
      const userId = this.props.match.params.userId;
      const token = isAuthenticated().token;
      update(userId, token, { name: this.state.name, lastName: this.state.lastName, companyName: this.state.companyName,
                              companyType: this.state.companyType, about: this.state.about }).then(data => {
        if (data.error) {
          this.setState({ error: data.error });
        } else if (isAuthenticated().userId === "5e2611483f99fa3bb4dd922a") {
          this.setState({
            redirectToProfile: true
          });
        } else {
          updateUser(data, () => {
            this.setState({
              redirectToProfile: true
            });
          });
        }
      });
    // }
  };

  signupForm = (name, lastName, companyName, companyType, password, about) => (    
    <form>
      {/* <div className="form-group">
        <label className="text-muted">Profile Photo</label>
        <input
          onChange={this.handleChange("photo")}
          type="file"
          accept="image/*"
          className="form-control"
        />
      </div> */}
      <div className="form-group">
        <label className="text-muted">First Name</label>
        <input
          onChange={this.handleChange("name")}
          type="text"
          className="form-control"
          value={this.state.name}
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Last Name</label>
        <input
          onChange={this.handleChange("lastName")}
          type="text"
          className="form-control"
          value={this.state.lastName}
        />
      </div>
      
      <div className="form-group">
        <label className="text-muted">Company Name</label>
        <input
          onChange={this.handleChange("companyName")}
          type="text"
          className="form-control"
          value={this.state.companyName}
        />
      </div>      

      <div className="form-group">
        <label className="text-muted">Select a company "Type"</label>
        <select onChange={this.handleChange('companyType')} value={this.state.companyType} className="form-control">
          <option value="" selected></option>
          <option value="Utility">Utility Company</option>
          <option value="Services">Engineering Services Provider</option>
          <option value="Vendor">Vendor</option>
        </select>
      </div>


      <div className="form-group">
        <label className="text-muted">About</label>
        <textarea
          onChange={this.handleChange("about")}
          type="text"
          className="form-control"
          value={this.state.about}
        />
      </div>

      <div className="form-group">
        <label className="text-muted">Password</label>
        <input
          onChange={this.handleChange("password")}
          type="password"
          className="form-control"
          value={password}
        />
      </div>
      <button onClick={this.clickSubmit} className="btn btn-raised btn-primary">
        Update
      </button>
    </form>
  );

  render() {
    const {
      id,
      name,
      lastName,
      companyName,
      companyType,
      email,
      password,
      redirectToProfile,
      error,
      loading,
      about
    } = this.state;

    if (redirectToProfile) {
      return <Redirect to={`/user/${id}`} />;
    }

    // const photoUrl = id
    //   ? `${
    //   process.env.REACT_APP_API_URL
    //   }/user/photo/${id}?${new Date().getTime()}`
    //   : DefaultProfile;

    return (
      <div className="container">
        <h2 className="mt-5 mb-5">Edit {this.state.name}'s' Profile</h2>
        <div
          className="alert alert-danger"
          style={{ display: error ? "" : "none" }}
        >
          {error}
        </div>

        {loading ? (
          <div className="jumbotron text-center">
            <h2>Loading...</h2>
          </div>
        ) : (
            ""
          )}

        {/* <img
          style={{ height: "200px", width: "auto" }}
          className="img-thumbnail"
          src={photoUrl}
          onError={i => (i.target.src = `${DefaultProfile}`)}
          alt={name}
        /> */}

        {/* {isAuthenticated().userId === "5e2611483f99fa3bb4dd922a" &&
          this.signupForm(name, lastName, email, companyName, companyType, password, about)} */}

        {isAuthenticated().userId === id &&
          this.signupForm(name, lastName, email, companyName, companyType, password, about)}
      </div>
    );
  }
}

export default EditProfile;