import React, { Component, Link } from "react";
import { Form, Button, Row, Col } from 'react-bootstrap';
import { registerUser } from '../event/apiEvents'
import { sendMessage } from "../user/apiUser";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Select from 'react-select'
import Footer from './Footer';
import { isAuthenticated } from "../auth";
import Moment from 'moment';
import LiveEventCalendar from '../event/LiveEventCalendar';
import LoadingOverlay from 'react-loading-overlay';
 
class PublicSpringEvent2022 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: "62054cf16f4c6a6808384c1e",
            firstName: "",
            lastName: "",
            companyName: "",
            country: "",
            stateProvince: "",
            zipPostal: "",
            workphone: "",
            email: "",
            meetings: [],
            fixedMeetings: [],
            industry: "",
            jobLevel: "",
            role: "",
            message: "",
            error: "",
            success: false,
            mailing: false,
            terms: false,
            token: isAuthenticated() && isAuthenticated().token,
            loading: true,
            isAdmin: false
        }
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    }

    

    resourceList = [
        {
            fieldName: 'general',
            title: 'General Sessions',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Opening Session', color: '#4caf50' },
                { id: 2, text: 'Closing Session', color: '#4caf50' },
                { id: 3, text: 'Committee Update', color: '#4caf50' },
                { id: 4, text: 'Vendor Company Update', color: '#4caf50' },
                { id: 5, text: 'Vendor Presentation', color: '#4caf50' },
                { id: 6, text: 'Leadership Meeting', color: '#4caf50' }
            ],
        },
        {
            fieldName: 'bizstrategy',
            title: 'Business Strategy Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#000099' },
                { id: 2, text: 'Committee Round Table', color: '#000099' },
                { id: 3, text: 'Committee Presentation', color: '#000099' },
                { id: 4, text: 'Vendor Presentation', color: '#000099' },
                { id: 5, text: 'User Presentation', color: '#000099' },
                { id: 6, text: 'Best Practice Discussion', color: '#000099' },
                { id: 7, text: 'Committee Closing Session', color: '#000099' },
                { id: 8, text: 'IACET Presentation', color: '#000099' }
            ],
        },
        {
            fieldName: 'civil',
            title: 'Civil Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#795548' },
                { id: 2, text: 'Committee Round Table', color: '#795548' },
                { id: 3, text: 'Committee Presentation', color: '#795548' },
                { id: 4, text: 'Vendor Presentation', color: '#795548' },
                { id: 5, text: 'User Presentation', color: '#795548' },
                { id: 6, text: 'Best Practice Discussion', color: '#795548' },
                { id: 7, text: 'Committee Closing Session', color: '#795548' },
                { id: 8, text: 'IACET Presentation', color: '#795548' }
            ],
        },
        {
            fieldName: 'electrical',
            title: 'Electrical Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#f44336' },
                { id: 2, text: 'Committee Round Table', color: '#f44336' },
                { id: 3, text: 'Committee Presentation', color: '#f44336' },
                { id: 4, text: 'Vendor Presentation', color: '#f44336' },
                { id: 5, text: 'User Presentation', color: '#f44336' },
                { id: 6, text: 'Best Practice Discussion', color: '#f44336' },
                { id: 7, text: 'Committee Closing Session', color: '#f44336' },
                { id: 8, text: 'IACET Presentation', color: '#f44336' }
            ],
        },
        {
            fieldName: 'physical',
            title: 'Physical Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#993399' },
                { id: 2, text: 'Committee Round Table', color: '#993399' },
                { id: 3, text: 'Committee Presentation', color: '#993399' },
                { id: 4, text: 'Vendor Presentation', color: '#993399' },
                { id: 5, text: 'User Presentation', color: '#993399' },
                { id: 6, text: 'Best Practice Discussion', color: '#993399' },
                { id: 7, text: 'Committee Closing Session', color: '#993399' },
                { id: 8, text: 'IACET Presentation', color: '#993399' }
            ],
        },
        {
            fieldName: 'technology',
            title: 'Technology Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#eb851e' },
                { id: 2, text: 'Committee Round Table', color: '#eb851e' },
                { id: 3, text: 'Committee Presentation', color: '#eb851e' },
                { id: 4, text: 'Vendor Presentation', color: '#eb851e' },
                { id: 5, text: 'User Presentation', color: '#eb851e' },
                { id: 6, text: 'Best Practice Discussion', color: '#eb851e' },
                { id: 7, text: 'Committee Closing Session', color: '#eb851e' },
                { id: 8, text: 'IACET Presentation', color: '#eb851e' }
            ],
        }
    ]
    selectCountry(val) {
        this.setState({ country: val });
    }

    selectRegion(val) {
        this.setState({ stateProvince: val });
    }

    handleChange = name => event => {
        if (name === 'mailing') {
            this.setState({ error: "", [name]: event.target.checked });
        }
        if (name === 'terms') {
            this.setState({ error: "", [name]: event.target.checked });
        } else {

            if (name === "meetings") {
                this.setState({ error: "" });
                // let value = Array.from(event.target.selectedOptions, option => option.value);
                // this.setState({ [name]: value });
                const finalVal = (event && event.map((c, i) => (
                    [
                        c.label,
                        c.value
                    ]
                )))
                this.setState({ [name]: finalVal });
                // alert(finalVal)
            } else {
                this.setState({ error: "" });
                this.setState({ [name]: event.target.value });
            }
        }

    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };
    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Thank you for your registration!</h3>
                <p>Don't forget to check your inbox and your spam folder for a confirmation email.</p>
                <p>We will use the email you've submitted to send you future updates as we finalize the event.</p>
            </div>
        )
    };

    committeeAdmin = ''
    

    setCommitteeName() {
        
        switch (isAuthenticated().userId) {
            case '5e606fd24588f11c8f7736fa':
                this.committeeAdmin = 'physical'
                this.setState({isAdmin: false})               
            case '5e68d03ffdec0825509b8e18':
                this.committeeAdmin = 'physical'
                this.setState({isAdmin: false})          
            case '5e68c0483c55ce6f9a306b13':
                this.committeeAdmin = 'electrical'
                this.setState({isAdmin: false})          
            case '6012c335ca3c531f2bf10dc5':
                this.committeeAdmin = 'electrical'
                this.setState({isAdmin: false})          
            case '5fb0551e3ffeb261363b4a21':
                this.committeeAdmin = 'technology'
                this.setState({isAdmin: false})          
            case '5ea9ecda06aea5d11b3b030f':
                this.committeeAdmin = 'technology'
                this.setState({isAdmin: false})          
            case '5e68d0a0fdec0825509b8e19':
                this.committeeAdmin = 'civil'
                this.setState({isAdmin: false})          
            case '61658720a3a95854a99c3aef':
                this.committeeAdmin = 'civil'
                this.setState({isAdmin: false})          
            case '5e68d1cffdec0825509b8e1f':
                this.committeeAdmin = 'business'
                this.setState({isAdmin: false})          
            case '5e99ef77f8bc2c4f51caea96':
                this.committeeAdmin = 'business'
                this.setState({isAdmin: false})          
            case '5e61524763f778901ce39fc6':
                this.committeeAdmin = 'general'
                this.setState({isAdmin: false})          
            case '5e61560e33b0177e5838012d':
                this.committeeAdmin = 'general'
                this.setState({isAdmin: false})          
            case '5e2611483f99fa3bb4dd922a':
                this.committeeAdmin = 'general'
                this.setState({isAdmin: true})          
        }
    };
    componentWillMount(){this.setCommitteeName()}

    componentDidMount() {
        
        
        this.setState({ loading: false })
        // Eventually this will be replaced with a bunch of Zoom meeting ids
        this.setState(
            {
                fixedMeetings: [
                    {
                        title: "SDSIC Spring Virtual Event: day 1",
                        startDate: "May 3rd, 2022",
                        id: "6001c0547024a75b1a789ab1"
                    },
                    {
                        title: "SDSIC Spring Virtual Event: day 2",
                        startDate: "May 4th, 2022",
                        id: "6001c0817024a75b1a789ab2"
                    }
                ]
            }
        )

        


    }

   
    register = e => {
        e.preventDefault();
        const { eventId, firstName, lastName, companyName, country, stateProvince,
            zipPostal, workphone, email, meetings, industry, jobLevel, role, error,
            terms, mailing, success
        } = this.state;
        // if there are no left over error messages, run the registration
        if (error == "") {
            const body = {
                firstName,
                lastName,
                companyName,
                country,
                stateProvince,
                zipPostal,
                workphone,
                email,
                meetings,
                industry,
                jobLevel,
                role,
                terms,
                mailing
            }

            registerUser(body, eventId).then(data => {
                if (data.error) {
                    this.setState({
                        error: data.error,
                        success: false
                    });
                } else {
                    const adminMessage = {
                        to: 'trevor@sdsconsortium.com',
                        from: email,
                        subject: 'Signed up for the SDSIC Spring Event 2022',
                        html: `From user ${firstName} ${lastName}, <p>from company: ${companyName}, has signed up for the Spring
                        SDSIC 2022 event with email: ${email}</p>
                        <p>As a <b>${industry}</b></p>`
                    }
                    const usersMessage = {
                        to: email,
                        from: "info@sdsconsortium.com",
                        subject: 'SDSIC Spring 2022 event registration',
                        html: `Hi ${firstName} ${lastName}, <p> Thank you for registering to attend the SDSIC Spring 2022 Event.</p>
                        <p> your currently signed up for: ${meetings} </p>
                        <p>Please submit any questions to: trevor@sdsconsortium.com </p>
                        <p>Save the dates: May 3rd and 4th, 11am to 5pm (Eastern Time. US and Canada)  </p>
                        <p>If you would like to help us improve events, please fill out our event survey at https://sdsconsortium.com/survey </p>
                        <p>More information will be sent to you with this email address as we finalize the schedule of events.</p>
                        <p>Stay Tuned! </p>`
                    }
                    // make request to api to send message
                    sendMessage(usersMessage).then(data => {
                        this.setState({
                            firstName: "",
                            lastName: "",
                            companyName: "",
                            country: "",
                            stateProvince: "",
                            zipPostal: "",
                            workphone: "",
                            email: "",
                            meetings: "",
                            industry: "",
                            jobLevel: "",
                            role: "",
                            terms: false,
                            mailing: false,
                            success: true
                        });
                    });
                    // make request to api to send the admin message
                    sendMessage(adminMessage).then(data => { });
                }
            });
        }
    }


    render() {
       
        return (
            <LoadingOverlay
            active={this.state.loading}
            spinner
            text='Uploading your file ...'
            >
           
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">
                            <div className="jumbotron">
                                <h2>
                                    The SDSIC 2022 Spring Virtual Event
				                </h2>
                                <p> This page is for members only and all meeting links should be kept confidential for tracking
                                    purposes. The calendar below will be updated regularly all the way up to the event so please
                                    bookmark this page to stay on track and use the calendar links on the day of the meetings.
                                </p>                                
                                <p>If you haven't registered for this event yet, please register below. </p>                 
                                    <a href="/spring2022event" className="btn btn-primary">Registration</a>
                                
                            </div>

                        </div>
                        

                    </div >
                    <hr />
                    <div className="row justify-content-md-center">
                        <hr />
                        <div className="col-md-12">
                            <h3>Our Spring 2022 Virtual Event Schedule</h3>
                            <p><i>Note: The calendar automatically adjusts to your local time.</i></p>
                            <div>
                                <LiveEventCalendar
                                    scheduleName={'Spring 2022 General'}
                                    isAdmin={this.state.isAdmin}
                                    chooseTracks={true}
                                    chooseDays={true}
                                    currentDate={'2022-05-3'}
                                    excludedDays={[0, 1, 4, 5, 6, 7]}
                                    weekView={true}
                                    resourceList={this.resourceList}
                                    mainResourceName={'general'}
                                    calendarHeight={1000}
                                    startTime={7}
                                    endTime={22}
                                />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row justify-content-md-center">
                        <br />
                        <br />
                        <div className="col-md-10">
                            <div className="jumbotron">
                                <h4>
                                    Our event surveys
				                </h4>
                                <p> Now that you have registered, why not help us make the event even better by answering a few short questions
                                on our event survey.
	                            </p>
                                
                                <a href="/survey" className="btn btn-primary">Take the Survey</a>
                            </div>
                        </div>
                        <br />
                        <br />
                    </div>                    
                </div >

                <Footer />
            
            </LoadingOverlay>
        );
    }
}

export default PublicSpringEvent2022;