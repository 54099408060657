import React, { Component } from "react";
import { isAuthenticated } from "../auth";
import { singleGroup, groupDetails, sendMessage } from "./apigroup";
import { Breadcrumb } from 'react-bootstrap';
// import moment from "moment";

class GroupAccess extends Component {

    state = {
        groupId: this.props.match.params.groupId,
        group: '',
        groupName: '',
        redirectToHome: false,
        redirectToSignin: false,
        chair: '',
        cochair: '',
        chairEmail: '',
        cochairEmail: '',
        message: '',
        success: false,
        allowedAccess: false
    };

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                Message Sent Successfully. You should hear back from {this.state.chair} shortly!
            </div>
        )
    };


    handleChange = name => event => {
        let value = event.target.value
        this.setState({ [name]: value });
    };

    validateMembership = (group) => {
        switch (group) {
            case '5e5591a97ff73f6d34835a05': //leadership
                return false
            case '65ce5e76e54fd763dfc9c0ae': //content
                return false
            default:
                return true
        }

    }

    componentDidMount = () => {
        
        singleGroup(this.state.groupId).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({
                    group: data,
                    message: '',
                    success: false
                });

            }
        });

        groupDetails(this.state.groupId).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({
                    groupName: data.name,
                    chair: data.chair.name,
                    chairLastName: data.chair.lastName,
                    chairCompany: data.chair.companyName,
                    cochair: data.cochair.name,
                    cochairLastName: data.cochair.lastName,
                    cochairCompanyName: data.cochair.companyName,
                    chairEmail: data.chair.email,
                    cochairEmail: data.cochair.email,
                })
                // alert(data.name)
            }

        });
        this.setState({ user: isAuthenticated().user, allowedAccess: this.validateMembership(this.state.groupId) });

    };


    sendMessage = (e) => {
        e.preventDefault();

        const mailMessage = {
            "subject": "Requesting access to the " + this.state.groupName,
            "sender": { "email": `${isAuthenticated().email}`, "name": `${isAuthenticated().email}` },
            "replyTo": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
            "to": [{ "name": `${this.state.chairEmail}`, "email": `${this.state.chairEmail}` }],
            "cc": [{ "name": `${this.state.cochairEmail}`, "email": `${this.state.cochairEmail}` }],
            "htmlContent": "Message from the SDSIC site, <br/> From user: " + isAuthenticated().name + " (email is: "
                + isAuthenticated().email + ") <br/>" +
                "Requests was:<b> Please grant me access to the " + this.state.groupName + "</b><br/> <br/>" +
                "Additional Message was: " + this.state.message + "<br/> <br/>" +
                "copy and paste this url into your web browser to access the admin page for the group: <br/> <br/>" +
                "https://sdsconsortium.com/admin/committee/" + this.props.match.params.groupId
        }

        // make request to api to send message
        sendMessage(mailMessage).then(data => {
            this.setState({
                message: '',
                success: true
            });

        });
    };


    render() {
        const { group, chair, chairLastName, chairCompany, cochair, cochairLastName, cochairCompanyName } = this.state;
        // const userName = isAuthenticated() && isAuthenticated().name;

        return (
            <div className="container-fluid">
                {this.state.allowedAccess && <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-12">
                                <p><h3>
                                    {this.state.allowedAccess}
                                    Request Access to the <b>{group.name}</b>

                                </h3></p>
                                <p>Your Committee Chair is: <b> {chair} {chairLastName}, {chairCompany}</b></p>
                                <p>Your Committee co-chair is: <b>{cochair} {cochairLastName}, {cochairCompanyName}</b></p>
                            </div>
                        </div>
                        {this.showSuccess()}
                        <form onSubmit={this.sendMessage} >
                            <div className="form-group">
                                <label for="exampleInputEmail1">Send request to your committee chair:</label>
                                <input type="email" disabled="true" value={this.state.chairEmail} className="form-control" id="emailto" />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputPassword1">
                                    Subject</label>
                                <input type="text" disabled="true" value={`Requesting access to the ${this.state.groupName}`} className="form-control" id="subject" />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputFile">your message:</label>
                                <textarea className="form-control" value={this.state.message} name='message' onChange={this.handleChange('message')} rows="4" cols="50"></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">Send Request</button>
                        </form>
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>
                }
                {!this.state.allowedAccess && <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-12">
                                
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/usershome">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href={`/grouplist`}>
                                        back to committee list
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>Committee Access Request</Breadcrumb.Item>
                                </Breadcrumb>
                                <br/>
                                <p><h3>
                                    You cannot Request Access to the <b>{this.state.groupName}</b>
                                </h3></p>
                                <div>
                                    <p>
                                        If you are supposed to have access to this committee, please contact: <br/>
                                        <label for="exampleInputEmail1">Send request to your committee chair:</label>
                                        <a href={`mailto:${this.state.chairEmail}`} target="_blank"> {this.state.chair} {this.state.chairLastName}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>
                }
            </div>


        )
    }
};

export default GroupAccess;