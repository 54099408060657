import React, { Component } from "react";

class TermsStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {

        return (
            <>
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                <h4>The SDSConsortium.com</h4> is a member only website built by Automationforce Inc, specifically for the Substation Design Suite 
                                Industry Consortium (SDSIC). These Terms govern your use of the website and related content and member access.
                            </p>                           
                            <p>
                                BY USING THE SERVICE, YOU AGREE TO BE BOUND BY THESE TERMS AND REPRESENT AND CERTIFY THAT YOU ARE OF LEGAL AGE TO FORM A BINDING 
                                CONTRACT AND ARE NOT A PERSON BARRED FROM RECEIVING SERVICES UNDER THE LAWS OF THE UNITED STATES OR OTHER APPLICABLE JURISDICTIONS.
                            </p>
                            <p>
                                <b>Definitions.</b> In addition to terms defined elsewhere in these Terms, the following terms shall have the meanings given to them 
                                (whether used in the singular or plural).
                            </p>
                            <p>
                                <b>Content:</b> Any digital material that is downloadable from sdsconsortium.com, such as video files, documents, presentations, 
                                and any related images.
                            </p>
                            <p>
                                <b>Vendors:</b> Any SDSIC member that provides software or services to the Electric Utility Industry.
                            </p>                           
                            <p>
                                <b>SDSIC:</b> Substation Design Suite Industry Consortium (or “we”, “our”, “us”).
                            </p>
                            <p>
                                <b>Automationforce inc:</b> The website application developer.
                            </p>                            
                            <p>
                                <b>Service:</b> All features, functionalities, tasks and services made available through sdsconsortium.com, including the ability to 
                                view and/or download documents and presentations.
                            </p>
                            <p>
                                <b>Terms:</b> These Terms of Service, as may be updated from time to time as described herein.
                            </p>
                            <p>
                                <b>User:</b> Any person or company that registers for or otherwise uses the Service.
                            </p>
                            
                            <h4 className="mbr-header__text">1. Legal Relationship</h4>
                            <p>
                                (a) In order to use the Service you are required to register an account with us and agree to these Terms. 
                                You may not use the Service and you may not accept these Terms if you are not of legal age and otherwise 
                                competent to enter into a legal agreement with SDSIC.
                            </p>
                            <p>
                                (b) As part of account registration and subsequent interactions with us, you may be required to provide information about 
                                yourself (such as contact details) and you agree that any information you provide to us will be and remain accurate and complete.
                            </p>
                            <p>
                                (c) By using the Service or otherwise indicating your acceptance of these Terms, you specifically agree to be bound by these 
                                Terms, as updated from time to time. Your use of the Service (excluding any services provided to you by us under a separate 
                                written agreement) is subject to these Terms, as well as our Privacy Policy, which is incorporated in these Terms by reference under Section 5(a).
                            </p>
                            <p>
                                (d) If you are also a Vendor or Services provider, these Terms are in addition to (not in lieu) of any separate written 
                                agreement between you and us regarding your role as vendor or Services provider.
                            </p>
                            <h4 className="mbr-header__text">2. Provision of the Service</h4>
                           
                            <p>
                                (b) SDSIC intends to continually improve the Service in order to provide the best possible experience for its Users. 
                                Therefore, the form, nature, functionality and content of the Service may change from time to time without prior notice 
                                to you; provided, however, that SDSIC will not materially downgrade the core functionality and quality of the Service. 
                                SDSIC will use good-faith efforts to notify all members of any forthcoming material changes to the Service.
                            </p>
                            <p>
                                (c) SDSIC reserves the right to temporarily suspend the Service in connection with scheduled or emergency maintenance. 
                                SDSIC will use reasonable efforts to minimize any such interferences with your use of the Service, such as advance 
                                notice of scheduled maintenance.
                            </p>
                            <p>
                                (d) SDSIC will not disable your account without cause or good reason (e.g., system tampering). If SDSIC disables your account, 
                                you may be prevented from accessing the Service, your account details or any files or other content which is contained in your account.
                            </p>
                            <h4 className="mbr-header__text">3. Use of the Service</h4>
                            <p>
                                (a) You may use the Service to view and share SDSIC Content and to otherwise use the accessible features and Service as they are 
                                designed and intended
                            </p>                            
                            <p>
                                (c) Without limiting the generality of the foregoing, you specifically agree not to:
                                <ul>
                                    <li>access (or attempt to access) the Service by any means other than through the interface of the Service, unless you have been 
                                        specifically allowed to do so in a separate written agreement with SDSIC;</li>
                                    <li>access (or attempt to access) the Service through any automated means, including through use of scripts, robots, agents or 
                                        web crawlers); </li>
                                    <li>interfere with or disrupt the Service, or servers or networks connected to the Service; </li>
                                    <li>use the Service to collect or store personal data about any person, including other Users;</li>
                                    <li>take any other actions in relation to the Service, or Content designed or likely to downgrade or harm SDSIC, the Service, Content 
                                        or any Partners</li>
                                    <li>create, transmit, submit, upload, display or otherwise make available anything that (i) violates, misappropriates or infringes 
                                        another person’s or company’s rights, including but not limited to intellectual property rights; (ii) contains software viruses, 
                                        harmful materials or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of the 
                                        Service, or Content</li>
                                </ul>
                            </p>
                            <p>
                                (d) You acknowledge and agree that you are solely responsible for (and SDSIC and Automationforce Inc has no responsibility to you or 
                                to any third party for) any content or materials that you create, transmit, submit, upload, display, or otherwise make available 
                                while using the Service, or Content.
                            </p>
                            <h4 className="mbr-header__text">4. Passwords</h4>
                            <p>
                                You acknowledge and agree that you are responsible for maintaining the confidentiality of passwords associated with the account 
                                you use to access the Service. You will be solely responsible to SDSIC for all activities that occur under your account, except 
                                for any unauthorized activity caused.by a data breach on SDSIC systems.
                            </p>
                            <p>
                                <h4 className="mbr-header__text">5. Data Privacy</h4>
                            </p>
                            <p>
                                (a) Your access to and use of the Service is also subject to SDSIC's<a href="/privacystatement" className="link">Privacy Policy</a>, 
                                as modified by SDSIC from time to time, and by accepting these Terms you agree to the use of your personal data in accordance with the 
                                Privacy Policy. Please read the Privacy Policy carefully.
                            </p>
                            <p>
                                (b) The Service may enable you and our Partners to communicate directly with one another. SDSIC and Automationforce inc assumes no 
                                responsibility for the form, manner or substance of any such communications but will offer opt-out functions where appropriate.
                            </p>
                            <h4 className="mbr-header__text">6. Service Content</h4>
                            <p>
                                (a) You acknowledge and agree that SDSIC Content included in the Service are sourced from various volunteer members (who are typically 
                                identified in connection with such Content) and that all information included in Content are the sole responsibility of the Member. 
                                SDSIC has no obligation, but reserves the right, to pre-screen, review, flag, filter, modify, refuse or remove any or all Content 
                                from the Service.
                            </p>
                            <p>
                                (b) All SDSIC Content is made available by SDSIC on an AS-IS, AS-AVAILABLE basis. SDSIC makes no representations or warranties of any 
                                kind, express or implied, about the completeness, accuracy, reliability, integrity, quality or suitability for any purpose of any Content. 
                                Neither SDSIC nor Automationforce Inc will in any case be held liable for any damage any Content may cause, whether because of its 
                                accuracy, quality, integrity or otherwise.
                            </p>
                            <p>
                                (c) The Service, and Content specifically, may be protected by intellectual property rights belonging to SDSIC or a Partner or other 
                                third party. You are on notice that any use thereof by you in a manner not expressly permitted by these Terms could infringe or 
                                violate those rights.
                            </p>
                           
                            <h4 className="mbr-header__text">7. Proprietary Rights</h4>
                            <p>
                                (a) SDSIC and its Partners own all legal right, title and interest in and to the Content and other Service components, including 
                                any intellectual property rights which subsist therein. You do not acquire any right, title or interest in or to the Service, 
                            or Content.
                            </p>
                            <p>
                                (b) Nothing in these Terms gives you a right to use any SDSIC or Partner trade names, trademarks, service marks, logos, domain names 
                                or other distinctive brand features, unless otherwise agreed in writing with SDSIC.
                            </p>
                            <p>
                                (c) The Service may enable you to upload your own (or a third party’s) Content and other materials. By uploading any Content or other
                                 materials to or through the Service, you give SDSIC and its Partners a worldwide, non-exclusive, irrevocable, royalty-free and 
                                 perpetual license and right to reproduce, display, modify, prepare derivative works of and otherwise use such Content and other 
                                 materials (provided we keep confidential your personal data) for purposes including improvement of the Service. You represent and 
                                 warrant that you have the right to grant this license and right to SDSIC, free and clear of any conflicting third-party rights.
                            </p>
                            <h4 className="mbr-header__text">8. External Links</h4>
                            <p>
                                (a) The Service may include hyperlinks to other web sites, content or resources. SDSIC may have no control over any web sites, 
                                content or resources which are not provided by SDSIC.
                            </p>
                            <p>
                                (b) You acknowledge and agree that SDSIC and Automationforce Inc is not responsible for the availability of any external sites (including any Third Party Application) 
                                content or resources, and does not endorse any advertising, products or other materials on or available from such web sites, 
                                content or resources and that SDSIC and Automationforce Inc is not liable for any loss or damage which may be incurred by you as a result of the availability 
                                of those external sites, content or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence 
                                of any advertising, products, information or other materials on, or available from, such web sites, content or resources.
                            </p>
                            <h4 className="mbr-header__text">9. Term and Termination</h4>
                            <p>
                                (a) These Terms take effect on the date you first agree to them or use the Service and will continue in force thereafter until terminated 
                                by either you or SDSIC as set out below. Upon termination of these Terms for any reason, you must immediately cease using the Service.
                            </p>
                            <p>
                                (b) SDSIC may, at its sole discretion, at any time and for any reason, including a period of account inactivity, terminate these 
                                Terms and/or your use of the Service and/or suspend or terminate your account.
                            </p>
                            <p>
                                (c) When these Terms terminate, all rights afforded to you hereunder shall terminate; each party will remain responsible for outstanding fees and other 
                                liabilities; and the terms herein that should reasonably be construed as intended for survival, including indemnities, disclaimers, liability limits, 
                                dispute resolution and other general provisions, shall survive.
                            </p>
                            <h4 className="mbr-header__text">10. Fees and Payment</h4>
                            <p>
                                Certain conference attendance may now, or in the future, be provided for a fee. Any such fees will be posted with the Service and are subject to 
                                change without notice. 
                            </p>
                            <h4 className="mbr-header__text">11. Indemnity</h4>
                            <p>
                                You agree to hold harmless and indemnify SDSIC, Automationforce inc, its Partners, other franchisee’s, partners, subsidiaries and each of 
                                their respective directors, officers, agents, and employees from and against any third party claim arising from or in any way related 
                                to your use of the Service, Content, violation of these Terms, any Content or materials that you make available, or any other actions 
                                connected with your use of the Service, including any liability or expense arising from all claims, losses, damages (actual and consequential), 
                                suits, judgments, litigation costs and attorneys' fees, of every kind and nature. In such a case, SDSIC will provide you with written notice of
                                 such claim, suit or action. SDSIC shall have the right to participate, at its own expense, in the defense of any claim. You shall not agree to
                                  any settlement of any claim as set out above without the prior written consent of SDSIC.
                            </p>
                            <h4 className="mbr-header__text">12. Disclaimer; Liability Limits</h4>
                            <p>
                                (a) THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND WITHOUT WARRANTIES OF ANY KIND. SDSIC, AUTOMATIONFORCE INC AND 
                                ITS PARTNERS EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICE, INCLUDING IMPLIED WARRANTIES 
                                OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. NO ADVICE OR INFORMATION GIVEN BY SDSIC OR PARTNERS SHALL CREATE A
                                 WARRANTY.
                            </p>
                            <p>
                                (b) SDSIC AND AUTOMATIONFORCE INC MAKES NO WARRANTY THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, THAT ITS 
                                SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT DATA WILL BE SECURE FROM UNAUTHORIZED ACCESS. YOU ACKNOWLEDGE THAT 
                                YOUR ACCESS TO AND USE OF THE SERVICE AND ANY RELIANCE UPON THE SAME IS AT YOUR SOLE RISK.
                            </p>
                            <p>
                                (d) TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL SDSIC AND AUTOMATIONFORCE INC OR A PARTNER BE 
                                LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING LOSS OF PROFITS, USE, IMAGES, DATA OR OTHER INTANGIBLES, 
                                EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, THAT RESULT FROM THE USE OR THE INABILITY TO USE THE SERVICES, FROM ANY 
                                CHANGES TO THE SERVICES OR FROM UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR USER MATERIALS. YOU SPECIFICALLY ACKNOWLEDGE 
                                THAT DOWN-TIME AND COMPUTER VIRUSES ARE RISKS INHERENT IN THE USE OF THE INTERNET AND SOFTWARE PRODUCTS, AND YOU AGREE TO ASSUME 
                                RESPONSIBILITY FOR ANY HARM OR DAMAGES OF ANY KIND OR CHARACTER WHATSOEVER RESULTING FROM THESE POSSIBLE HARMS. YOU ALSO SPECIFICALLY 
                                ACKNOWLEDGE THAT YOU MAY BE DISCLOSING SENSITIVE, PRIVATE AND CONFIDENTIAL INFORMATION ABOUT YOURSELF IN YOUR USE OF THE SERVICES 
                                AND YOU AGREE TO ASSUME RESPONSIBILITY FOR ANY HARM OR DAMAGES OF ANY KIND OR CHARACTER WHATSOEVER RESULTING FROM YOUR RELEASE OF 
                                SUCH USER MATERIALS.
                            </p>
                            <p>
                                11.3	IF YOU ARE DISSATISFIED WITH THE SERVICE OR WITH ANY OF THESE TERMS, OR FEEL SDSIC HAS BREACHED THESE TERMS, YOUR SOLE AND 
                                EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICE AND SEEK A REFUND OF PREPAID, UNUSED FEES (IF ANY). THE TOTAL LIABILITY OF SDSIC
                                 AND ITS PARTNERS TO YOU FOR ANY CLAIM ARISING FROM OR RELATING TO THESE TERMS OR USE OF THE PRODUCTS OR SERVICES SHALL NOT EXCEED 
                                 THE AMOUNT PAID BY YOU FOR THE SERVICE DURING THE TWELVE MONTHS PRECEDING THE EVENT GIVING RISE TO THE LIABILITY. IT IS THE 
                                 INTENTION OF YOU AND US THAT THIS PROVISION BE CONSTRUED BY A COURT AS BEING THE BROADEST LIMITATION OF LIABILITY CONSISTENT 
                                 WITH APPLICABLE LAW.
                            </p>
                            <h4 className="mbr-header__text">13. Changes of the Terms</h4>
                            <p>
                                SDSIC may make changes to these Terms from time to time. Any changes to these Terms will be notified to you when you log in to your 
                                account on the Service for the first time after any changes of these Terms have been made. You acknowledge and agree that your 
                                continued use of the Service after the date on which these Terms have changed as set out above will constitute your acceptance 
                                of the changed Terms to the fullest extent permissible.
                            </p>
                            <h4 className="mbr-header__text">14. Dispute Resolution</h4>
                            <p>
                                These Terms shall be governed by the laws of the State of Delaware without giving effect to any conflict of laws principles 
                                that may require the application of the law of another jurisdiction. If you believe that SDSIC has not adhered to these Terms, 
                                please contact us. We will do our best to address your concerns. If you feel that your complaint has been addressed incompletely, 
                                we invite you to let us know for further investigation. 
                            </p>
                            <h4 className="mbr-header__text">15. General Provisions</h4>
                            <p>
                                (a) These Terms, together with any order instruments accepted by both parties, constitutes the entire agreement and understanding 
                                between you and SDSIC relating to the subject matter hereof (but excluding any services which SDSIC may provide to you under a 
                                separate written agreement) and supersedes all written or oral commitments, undertakings and agreements which have preceded these Terms.
                            </p>
                            <p>
                                (b) In no event shall any delay, failure or omission of a party in enforcing, exercising or pursuing any right, claim or remedy under 
                                this Agreement be deemed as a waiver thereof, unless such right, claim or remedy has been expressly waived in writing.
                            </p>
                            <p>
                                (c) If any court of law, having the jurisdiction to decide on this matter, finds that any provision of these Terms (or the application thereof) 
                                shall be declared or deemed void, invalid or unenforceable in whole or in part for any reason, that provision shall be enforced to the 
                                maximum extent permissible so as to affect the intent of these Terms and the remaining provisions of these Terms shall continue in full 
                                force and effect.
                            </p>
                            <p>
                                (d) We may provide you with notices, including those regarding changes to these Terms, by email, regular mail, or postings on or
                                 through the Service. The English language shall be the governing language in your relationship with us.
                            </p>
                            <p>
                                (e) Our Partners are each an intended third-party beneficiaries of these Terms and shall be entitled to directly enforce, and rely 
                                upon, any provision of these Terms which confers a benefit on (or rights in favor of) them. Except as otherwise expressly provided 
                                in these Terms, there shall be no other third party beneficiaries to these Terms.
                            </p>
                            <p>
                                (f) You may not assign any of your rights and/or obligations under these Terms without SDSIC prior written consent. SDSIC shall be 
                                entitled to assign its rights and/or obligations under these Terms without consent.
                            </p>



                        </div>
                    </div>
                </div>
            </>
        )
    };
}


export default TermsStatement;