import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import { isAuthenticated } from "../auth";
import Footer from '../core/Footer';
import { getAllUsersTodate } from './apiEvents';
// import moment from 'moment';

class EventRegReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAdmin: true,     // if admin, open and edit are available            
            registrationList: [],
            schedule: [],
            token: isAuthenticated() && isAuthenticated().token,
            userList: []
        }
    }

    componentWillMount() {
        // getAllRegistrations().then(data => {
        //     if (data.error) {
        //         console.log(data.error);
        //     } else {
        //         this.setState({ registrationList: data });
        //     }
        // });
        // getSchedule('Spring 2021', this.state.token).then(data => {
        //     if (data.error) {
        //         console.log(data.error);
        //     } else {
        //         this.setState({ schedule: data });
        //         console.log(data)
        //     }
        // });

        getAllUsersTodate(this.state.token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({ userList: data });
            }
        });


    };

    render() {
        const { userList } = this.state

        return (

            <Container fluid>
                {/* <div>
                    <table>
                                    <tr>
                                        <th>Title</th>
                                        <th>description</th>
                                        <th>Committee</th>
                                        <th>Start Date</th>                                       
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>ZoomLink</th>
                                        <th>Zoom Meeting ID</th>
                                        <th>Zoom PassCode</th>
                                       
                                       
                                       

                                    </tr>
                    {schedule && schedule.map((reg, i) => {
                        var startDate = moment(reg.startDate)
                        var endDate = moment(reg.endDate)
                        return (
                            <>
                                
                                    <tr>
                                        <td>{reg.title}</td>
                                        <td>{reg.notes}</td>
                                        <td>{reg.committeeType}</td>
                                        <td>{startDate.format("YYYY-MM-DD")}</td>                                        
                                        <td>{startDate.format("hh:mm")}</td>
                                        <td>{endDate.format("hh:mm")}</td>
                                        <td><a href={reg.zoomLink}>{reg.zoomLink}</a></td>
                                        <td>{reg.zoomId}</td>
                                        <td>{reg.zoompass}</td>                                                                                
                                    </tr>
                               
                                </>
                            )
                        })}
                </table>
                </div> */}
                
                {/* <div>
                    <h2>SDSIC Spring Event Registration List</h2>
                    <table>
                        <tr>
                            <th>Firstname</th>
                            <th>Lastname</th>
                            <th>email</th>
                            <th>Company</th>
                            <th>Country</th>
                            <th>Industry</th>
                            <th>Job Level</th>
                            <th>Role</th>
                           
                        </tr>
                        {(registrationList.length > 0) && registrationList.map((reg, i) => {
                            return (
                                <>
                                <tr>
                                    <td>{reg.firstName}</td>
                                    <td>{reg.lastName}</td>
                                    <td>{reg.email}</td>
                                    <td>{reg.companyName}</td>
                                    <td>{reg.country}</td>
                                    <td>{reg.industry}</td>
                                    <td>{reg.jobLevel}</td>
                                    <td>{reg.role}</td>
                                    
                            
                                </tr>
                                </>
                            )
                        })}
                    </table>
                </div> */}

                        <div>
                    <h2>SDSIC User List</h2>
                    <table>
                        <tr>
                            <th>name</th>
                            <th>email</th>
                            <th>created</th>                                               
                        </tr>
                        {(userList.length > 0) && userList.map((reg, i) => {
                            return (
                                <>
                                <tr>
                                    <td>{reg.name}</td>
                                    <td>{reg.email}</td>
                                    <td>{reg.created}</td>
                                    
                                </tr>
                                </>
                            )
                        })}
                    </table>
                </div>


                        <Footer />
            </Container >

        )
    }
};

export default EventRegReport;