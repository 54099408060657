import React, { useState, useEffect } from 'react';
import {
    useStripe, useElements,
    CardNumberElement, CardExpiryElement, CardCvcElement
} from '@stripe/react-stripe-js';
import { stripePaymentMethodHandler } from './stripeScript';
import { sendMessage } from "../user/apiUser";

import { v4 as uuid } from 'uuid';
import { ReactComponent as IconEnvelope } from "bootstrap-icons/icons/envelope.svg";
import { ReactComponent as IconTruck } from "bootstrap-icons/icons/truck.svg";
import { ReactComponent as IconCreditCard2Front } from "bootstrap-icons/icons/credit-card-2-front.svg";
import { ReactComponent as IconCart3 } from "bootstrap-icons/icons/cart3.svg";

import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';


const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            lineHeight: "27px",
            color: "#212529",
            padding: "0.375rem 0.75rem",
            lineHeight: "27px",
            color: "#212529",
            fontSize: "1.1rem",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

export default function CheckoutForm(props) {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const [nameOnCard, setNameOnCard] = useState('');
    const [phone, setPhone] = useState('');

    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');

    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('');
    const [stateProvince, setStateProvince] = useState('');

    const [productTitle, setProductTitle] = useState('');
    const [productId, setProductId] = useState('');
    const [productSize, setProductSize] = useState('');
    const [productColor, setProductColor] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productSku, setProductSku] = useState('');

    const [error, setError] = useState('');

    const stripe = useStripe();
    const elements = useElements();


    useEffect(() => {

        setEmail(props.email)
        setName(props.firstName + ' ' + props.lastName)
        setProductTitle(props.productTitle);
        setProductId(props.productId);
        setProductSize(props.productSize);
        setProductColor(props.productColor);
        setProductPrice(props.productPrice);
        setProductDescription(props.productDescription);
        setProductSku(props.productSku);

    }, []);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);
        setErrorMsg('');

        const paymentMethodObj = {
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name,
                email
            }           
        };
        const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodObj);

        stripePaymentMethodHandler({
            result: paymentMethodResult,
            amount: props.productPrice,
            user: email,
            receipt_email: email
        }, handleResponse);

    };

    // callback method to handle the response
    const handleResponse = response => {
        setLoading(false);
        if (response.error) {
            setErrorMsg(typeof response.error === 'string' ? response.error : response.error.message);
            return;
        }
        props.setPaymentCompleted(response.success ? true : false);
        props.setReceiptUrl(response.receipt_url)
        props.setReceiptEmail(email)
        props.setReceiptName(name)

        sendEmailReceipt(response.receipt_url, email, name);
        sendOrderToSpod()
    };

    const sendOrderToSpod = async () => {
        const uniqueOrderId = uuid();

        const orderData = {
            "orderItems": [
              {
                "sku": productSku,
                "quantity": 1,
                "externalOrderItemReference": uniqueOrderId,
                "customerPrice": {
                  "amount": productPrice,
                  "taxRate": 0,
                  "taxAmount": 0,
                  "currency": "USD"
                }
              }
            ],
            "shipping": {
              "address": {
                // "company": "Example Ltd.",
                "firstName": name,
                // "lastName": "Anderson",
                "street": address,
                "streetAnnex" : address2,
                "city": city,
                "country": country,
                "state": stateProvince,
                "zipCode": zip,
              },
              "preferredType": "STANDARD",
              "customerPrice": {
                "amount": productPrice,
                "taxRate": 0,
                "taxAmount": 0,
                "currency": "USD"
              }
            },
            "phone": phone,
            "email": email,
            "externalOrderReference": uniqueOrderId,
            "externalOrderName": "ORDER-2933",
            "state": "NEW",
            "customerTaxType": "SALESTAX",
            "origin": "string"
          }

    }

    const sendEmailReceipt = async (receiptUrl, email, name) => {

        const mailMessage = {
            "subject": "Your SDSIC Order",
            "sender": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
            "to": [{ "name": `${this.state.email}`, "email": `${this.state.email}` }],
            "bcc": { "email": "trevor@sdsconsortium.com", "name": "trevor@sdsconsortium.com" },
            "htmlContent": `<html><body><h4>Thank you for your order!</h4><br/> <p>Get your receipt here: ${receiptUrl}</p><br/>
                <p></p>
                <p>We will update you with the shipping information once your order is processed.</p><br/>
                <p>Product Ordered ${productTitle}</p><br/>
                    <p>Sku ${productSku}</p><br/>
                    <p>Sku ${productSize}</p><br/>
                    <p>Sku ${productColor}</p><br/>
                    <p>Sku ${productPrice}</p><br/>
                    <p>Sku ${productDescription}</p><br/>
                <p>Thank you for supporting the SDSIC!</p></body></html>`           
        }

        await sendMessage(mailMessage).then(data => { });

    };

    const selectCountry = (val) => {
        setCountry(val);
        setError("");
    }

    const selectRegion = (val) => {
        setStateProvince(val);
        setError("");
    }


    return (

        <React.Fragment>
            <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Pay with card</span>
            </h4>

            <div className="bg-secondary border-top p-4 text-white mb-3">
                <h1 className="display-6">Checkout</h1>
            </div>
            <div className="container mb-3">
                <div className="row">
                    <div className="col-md-8">
                        <form onSubmit={handleSubmit}>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <IconEnvelope className="i-va" /> Contact Info
                                </div>
                                <div className="card-body">
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <input
                                                type="email"
                                                id="cc-email"
                                                className="form-control"
                                                placeholder="Email Address"
                                                aria-label="Email Address"
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                type="tel"
                                                className="form-control"
                                                placeholder="Mobile no"
                                                aria-label="Mobile no"
                                                onChange={e => setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-3">
                                <div className="card-header">
                                    <IconTruck className="i-va" /> Shipping Infomation
                                </div>
                                <div className="card-body">
                                    <div className="row g-3">
                                        <div className="col-md-12">
                                            <input
                                                id="cc-name"
                                                type="text"
                                                className="form-control"
                                                placeholder="Name"
                                                required
                                                onChange={e => setName(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                id="cc-address"
                                                type="text"
                                                className="form-control"
                                                placeholder="Addresss"
                                                onChange={e => setAddress(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Address 2 (Optional)"
                                                onChange={e => setAddress2(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CountryDropdown
                                                className="form-control"
                                                name="country"
                                                placeholder="Country"
                                                value={country}
                                                onChange={(val) => selectCountry(val)} />


                                        </div>
                                        <div className="col-md-4">
                                            <RegionDropdown
                                                className="form-control"
                                                placeholder="State / Province"
                                                country={country}
                                                value={stateProvince}
                                                onChange={(val) => selectRegion(val)} />
                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Zip"
                                                onChange={e => setZip(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="card mb-3 border-info">
                                <div className="card-header bg-info">
                                    <IconCreditCard2Front className="i-va" /> Payment Method
                                </div>
                                <div className="card-body">
                                   
                                   
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <input
                                                id="cc-nameOnCard"
                                                type="text"
                                                className="form-control"
                                                onChange={e => setNameOnCard(e.target.value)}
                                                placeholder="Name on card"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <CardNumberElement
                                                placeholder="Card Number"
                                                id="cc-number"
                                                className="form-control"
                                                options={CARD_ELEMENT_OPTIONS}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="expiry">Expiration Date</label>
                                            <CardExpiryElement
                                                id="expiry"
                                                className="form-control"
                                                options={CARD_ELEMENT_OPTIONS}
                                            />
                                        </div>

                                        <div className="col-md-4">
                                            <label htmlFor="cvc">CVC</label>
                                            <CardCvcElement
                                                id="cvc"
                                                className="form-control"
                                                options={CARD_ELEMENT_OPTIONS}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr className="mb-4" />
                                <button className="btn btn-dark w-100" type="submit" disabled={loading}>
                                    {loading ? <div className="spinner-border spinner-border-sm text-light" role="status">
                                    </div> : `PAY $ ${props.productPrice} USD`}
                                </button>
                                {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}


                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">
                                <IconCart3 className="i-va" /> Your Order{" "}
                                <span className="badge bg-secondary float-end">1</span>
                            </div>
                            <ul className="list-group list-group-flush">

                                <li className="list-group-item d-flex justify-content-between lh-sm">

                                    <div>
                                        <h6 className="my-0">{props.productTitle}</h6>
                                        <br />
                                        <h6 className="my-0">cost each: ${props.productPrice}</h6>
                                        <br />
                                        <h6 className="my-0">Color Chosen: {props.productColor}</h6>
                                        <br />
                                        <h6 className="my-0">Size Chosen: {props.productSize}</h6>
                                        <br />
                                        <small className="text-muted"><div contentEditable='false' dangerouslySetInnerHTML={{ __html: props.productDescription }} style={{ whiteSpace: 'pre-wrap' }} ></div></small>

                                    </div>

                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}