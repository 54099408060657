import React, { Component } from "react";
import { findPeople, follow } from "./apiUser";
// import DefaultProfile from "../images/avatar.png";
import { Link } from "react-router-dom";
import { isAuthenticated } from "../auth";
import ReactGA from 'react-ga';

class FindPeople extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            error: "",
            open: false,
            searchText: ''           
        };
        this.clickSearch = this.clickSearch.bind(this)
    }


    componentDidMount() {
        ReactGA.initialize('UA-331790-15');
        ReactGA.pageview('/findpeople');

        // this.setState({ userId: isAuthenticated().userid, token: isAuthenticated().token})
        // findPeople(this.userId, this.state.searchText, this.token).then(data => {
        //     if (data.error) {
        //         console.log(data.error);
        //     } else {
        //         this.setState({ users: data });
        //     }
        // });
    }

    clickSearch(){
        findPeople(isAuthenticated() && isAuthenticated().userId, this.state.searchText, isAuthenticated() && isAuthenticated().token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                console.log(data)
                this.setState({ users: data });
            }
        });
    };

    handleChange = name => event => {
        
        const input = event.target;
        const value = event.target.type === 'checkbox' ? input.checked : input.value;
   
        this.setState({ error: "" });
        this.setState({ [name]: value});
    };

    clickFollow = (user, i) => {

        follow(isAuthenticated() && isAuthenticated().userId, isAuthenticated() && isAuthenticated().token, user._id).then(data => {
            if (data.error) {
                this.setState({ error: data.error });
            } else {
                let toFollow = this.state.users;
                toFollow.splice(i, 1);
                this.setState({
                    users: toFollow,
                    open: true,
                    followMessage: `Following ${user.name}`
                });
            }
        });
    };

    renderUsers = users => (
        <div className="row">
            {users.map((user, i) => (
                <div className="card col-md-4" key={i}>
                    {/* <img
                        style={{ height: "200px", width: "200px" }}
                        className="img-thumbnail"
                        src={`${process.env.REACT_APP_API_URL}/user/photo/${
                            user._id
                        }`}
                        onError={i => (i.target.src = `${DefaultProfile}`)}
                        alt={user.name}
                    /> */}
                    <div className="card-body">
                        <h5 className="card-title">{user.name} {user.lastName}</h5>
                        <p className="card-text">{user.companyName}</p>
                        <Link
                            to={`/user/${user._id}`}
                            className="btn btn-raised btn-primary btn-sm"
                        >
                            View Profile
                        </Link>

                        <button
                            onClick={() => this.clickFollow(user, i)}
                            className="btn btn-raised btn-info float-right btn-sm"
                        >
                            Follow
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );

    render() {
        const { users, open, followMessage, searchText } = this.state;
        return (
            <div className="container">
                <h2 className="mt-5 mb-5">Find People</h2>
            
                    <div className="form-group">
                        <label className="col-form-label">Search for Users by first name (note: capitals are important so enter 'Bob' not 'bob'):</label>
                        <input name="searchText" value={searchText} type="text" onChange={this.handleChange("searchText")} className="form-control" id="searchText" />
                    </div>
                    <div className="form-group">
                        <button onClick={this.clickSearch} className="btn btn-raised btn-primary">
                            Submit
                        </button>
                    </div>

      
                {open && (
                    <div className="alert alert-success">{followMessage}</div>
                )}

                {this.renderUsers(users)}
            </div>
        );
    }
}

export default FindPeople;