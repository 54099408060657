import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import { signin, authenticate, signinZendesk, getIpData } from "../auth";
// import LinkedInLogin from './LinkedInLogin';
// import SBSLogin from '../images/SBSLoginLogo.png'
import SBSLogo from '../images/sbs-logo-130x50.png'
import ReactGA from "react-ga";

class Signin extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            rememberMe: false,
            password: "",
            error: "",
            redirectToReferer: false,
            loading: false,
            recaptcha: false,
            show: false,
            SBSEmail: '',
            SBSPassword: '',
            success: false,
            ipInfo: {}
        };
    }


    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });


    handleChange = name => event => {

        const input = event.target;
        const value = event.target.type === 'checkbox' ? input.checked : input.value;

        this.setState({ error: "" });
        this.setState({ [name]: value });
    };

    recaptchaHandler = e => {
        this.setState({ error: "" });
        let userDay = e.target.value.toLowerCase();
        let dayCount;

        if (userDay === "sunday") {
            dayCount = 0;
        } else if (userDay === "monday") {
            dayCount = 1;
        } else if (userDay === "tuesday") {
            dayCount = 2;
        } else if (userDay === "wednesday") {
            dayCount = 3;
        } else if (userDay === "thursday") {
            dayCount = 4;
        } else if (userDay === "friday") {
            dayCount = 5;
        } else if (userDay === "saturday") {
            dayCount = 6;
        }

        if (dayCount === new Date().getDay()) {
            this.setState({ recaptcha: true });
            return true;
        } else {
            this.setState({
                recaptcha: false
            });
            return false;
        }
    };

    componentDidMount() {
        ReactGA.initialize('UA-331790-15');
        ReactGA.pageview('/signin');

        // const rememberMe = localStorage.getItem('rememberMe') === 'true';
        // const email = rememberMe ? localStorage.getItem('email') : '';
        const rememberMe = '';
        const email = '';

        getIpData().then(data => {
            if (data) {
                // alert(data.IPv4)
                this.setState({ ipInfo: data })
            }
        });

        this.setState({ email, rememberMe });
    };

    clickSubmit = event => {
        event.preventDefault();
        this.setState({ loading: true });
        const { email, rememberMe, password } = this.state;

        // localStorage.setItem('rememberMe', rememberMe);
        // localStorage.setItem('email', rememberMe ? email : '');

        const user = {
            email: email.toLowerCase(),
            password,
            ipInfo: this.state.ipInfo
        };

        // console.log(user);
        if (this.state.recaptcha) {
            signin(user).then(data => {
                if (data.error) {
                    this.setState({ error: data.error, loading: false });
                } else {
                    // authenticate
                    authenticate(data, () => {
                        this.setState({ redirectToReferer: true });
                    });
                }
            });
        } else {
            this.setState({
                loading: false,
                error: "What day is today? Please write a correct answer!"
            });
        }
    };

    signinForm = (email, password, rememberMe, recaptcha) => (
        <form>
            <div className="form-group">
                <label className="text-muted">Email</label>
                <input
                    onChange={this.handleChange("email")}
                    type="email"
                    className="form-control"
                    value={email}
                />
            </div>
            <div className="form-group">
                <label className="text-muted">Password</label>
                <input
                    onChange={this.handleChange("password")}
                    type="password"
                    className="form-control"
                    value={password}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">
                    {recaptcha ? "Thanks. You got it!" : "What day is today?"}
                </label>

                <input
                    onChange={this.recaptchaHandler}
                    type="text"
                    className="form-control"
                />
            </div>

            <div className="form-group">
                <input name="rememberMe" checked={rememberMe} onChange={this.handleChange("rememberMe")} type="checkbox" /> Remember me
            </div>

            <button
                onClick={this.clickSubmit}
                className="btn btn-raised btn-primary"
            >
                Submit
            </button>
        </form>
    );

    launchZendesk = e => {
        e.preventDefault();
        ReactGA.event({
            category: 'Form',
            action: 'Form Submit',
            transport: 'beacon'
        });
        this.setState({ error: "" })
        this.setState({ success: false });

        const body = {
            username: this.state.SBSEmail,
            password: this.state.SBSPassword
        }
        signinZendesk({ body }).then(data => {
            if (data.error) {
                this.setState({ error: data.error })
                this.handleClose();
            } else {
                authenticate(data, () => {
                    this.setState({ redirectToReferer: true });
                });
                this.handleClose();
            }
        });
    };

    render() {
        const {
            email,
            password,
            rememberMe,
            error,
            redirectToReferer,
            loading,
            recaptcha
        } = this.state;

        if (redirectToReferer) {
            return <Redirect to="/usershome" />;
        }

        return (
            <div className="container col-md-8 offset-md-2">
                <h2 className="mt-5 mb-5">SignIn</h2>
                <div className="row">
                    {/* <div className="col-md-6">                        
                        <input type="image" onClick={this.handleShow}  id="SBSlogin" src={SBSLogin} alt="Login with SBS" style={{ height: "2.5em" }} />
                    </div> */}
                    {/* <div className="col-md-6">
                        <LinkedInLogin />
                    </div> */}
                </div>


                <div
                    className="alert alert-danger"
                    style={{ display: error ? "" : "none" }}
                >
                    {error}
                </div>

                {loading ? (
                    <div className="jumbotron text-center">
                        <h2>Loading...</h2>
                    </div>
                ) : (
                    ""
                )}
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title><img src={SBSLogo} alt="SBS"></img></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Welcome Back</h4>
                        <p>Use your SBS login information here and use a single username and password for all <b>SBS</b> sites.</p>
                        <form>
                            <div className="form-group">
                                <label className="col-form-label">Email:</label>
                                <input name="SBSEmail" value={this.state.SBSEmail} type="text" onChange={this.handleChange("SBSEmail")} className="form-control" id="SBSEmail" />
                            </div>
                            <div className="form-group">
                                <label className="col-form-label">Password:</label>
                                <input name="SBSPassword" value={this.SBSPassword} onChange={this.handleChange("SBSPassword")} className="form-control" type="password" id="SBSPassword"></input>
                            </div>

                            <div className="form-group">

                                <Button variant="primary" onClick={this.launchZendesk} style={{ width: "100%" }}>
                                    Login with SBS</Button>
                            </div>

                            <div className="form-group">
                                <Button variant="secondary" onClick={this.handleClose} style={{ width: "100%" }}>
                                    forget it!</Button>
                            </div>

                        </form>



                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>
                {this.signinForm(email, password, rememberMe, recaptcha)}
                <br />
                <p>
                    <Link
                        to="/forgot-password"
                        className="text"
                    >
                        {" "}
                        Forgot Password?
                    </Link>
                </p>
                <div className="row">
                    <p>If you need an account sign up here. It's free!</p>
                    <div className="col-md-12">
                        <a className="btn btn-primary btn-large" href="/signup" target="_blank">Sign up for an SDSIC account</a>
                    </div>
                </div>
            </div>

        );
    }
}

export default Signin;