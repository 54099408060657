import qrcode from 'qrcode';


export const read = (userId, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSDSICMerch = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/shirtList`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getFullUserList = (token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/allPendingUsers`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }        
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateUserRole = (userId, role, token) => {
    
    return fetch(`${process.env.REACT_APP_API_URL}/user/updaterole/${userId}`, {
        method: 'POST',
        'headers': {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({role: role})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getMerchArticle = (articleId) => {
    return fetch(`${process.env.REACT_APP_API_URL}/articles/${articleId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const sendMessage = async (message) => {
    return fetch(`${process.env.REACT_APP_API_URL}/mail/sendinBlueEmail`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(message)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};



export const update = (userId, token, user) => {
    
    return fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const remove = (userId, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const list = (token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// export const updateUser = (user, next) => {
//     if (typeof window !== "undefined") {
//         if (localStorage.getItem("jwt")) {
//             let auth = JSON.parse(localStorage.getItem("jwt"));
//             auth.user = user;
//             localStorage.setItem("jwt", JSON.stringify(auth));
//             next();
//         }
//     }
// };

export const updateUser = (user, next) => {
    if (typeof window !== "undefined") {
        if (sessionStorage.getItem("zoeked")) {
            let auth = JSON.parse(sessionStorage.getItem("zoeked"));
            auth.user = user;
            sessionStorage.setItem("zoeked", JSON.stringify(auth));
            next();
        }
    }
};

export const follow = (userId, token, followId) => {
    return fetch(`${process.env.REACT_APP_API_URL}/user/follow`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ userId, followId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const unfollow = (userId, token, unfollowId) => {
    return fetch(`${process.env.REACT_APP_API_URL}/user/unfollow`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ userId, unfollowId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const findPeople = (userId, searchText, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/user/findpeople/${userId}?searchText=${searchText}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const mfa_qr_code = async (req, res) =>{
    return fetch(`${process.env.REACT_APP_API_URL}/user/generateQRCode/5e2611483f99fa3bb4dd922a`, {
        method: "GET",
        headers: {
            // Accept: "application/json",
            "Content-Type": "image/png"
        }
    })
        .then(response => {
            return response;
        })
        .catch(err => console.log(err));
}