import { v4 as uuid } from 'uuid';

export const eventscheduleD2 = [
  {
    title: "On the Horizon",
    startDate: new Date(2022, 9, 31, 15, 0),
    endDate: new Date(2022, 9, 31, 17, 0),
    id: uuid(),
    description: `Presentations include: The future of design in power delivery,  Design Squared - the Opportunity of Digital Twins, Moving to the BIM World, When Design and Automation Meets GIS, Autodesk Construction/Infraworks, Bentley’s Investment in Energy`,
    location: "Burns & McDonnell HQ",
    general: [1],
    ownerId: 1,
    geomap: 'https://goo.gl/maps/fXh4FdGMgann8vpc9'
  },
  {
    title: "BBQ Event Hosted by Burns & McDonnell",
    startDate: new Date(2022, 9, 31, 17, 0),
    endDate: new Date(2022, 9, 31, 21, 0),
    description:'Transportation from Burns & McDonnell to the Sheraton hotel stops at 9:30',
    id: uuid(),
    location: "Room 1",
    events: [1],
    notes: "This is a general session to kick off day 2. This is for all attendees…",
    committeeType: "events",
    ownerId: 2,
    geomap: 'https://goo.gl/maps/fXh4FdGMgann8vpc9'
  }
]

export const eventscheduleD3 = [
  {
    title: "Breakfast",
    startDate: new Date(2022, 10, 1, 8, 0),
    endDate: new Date(2022, 10, 1, 9, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    meals: [1],
    notes: "Lunch hosted by …",
    committeeType: "meals",
    ownerId: 8,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Welcome presentation (All)",
    startDate: new Date(2022, 10, 1, 9, 0),
    endDate: new Date(2022, 10, 1, 12, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    bizzstrategy: [0,1],
    notes: "This is a general session to kick off day 2. This is for all attendees…",
    committeeType: "general",
    ownerId: 1,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Lunch",
    startDate: new Date(2022, 10, 1, 12, 0),
    endDate: new Date(2022, 10, 1, 13, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    meals: [1],
    notes: "Lunch hosted by …",
    committeeType: "meals",
    ownerId: 8,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Autodesk and SBS Event",
    startDate: new Date(2022, 10, 1, 18, 0),
    endDate: new Date(2022, 10, 1, 22, 0),
    description:'Autodesk and SBS have teamed up to throw an awesome event at Pinstripes for an evening of dinner, drinks and bowling on the Tuesday evening.',
    id: uuid(),
    location: "https://pinstripes.com/overland-park/",
    events: [1],
    notes: "Lunch hosted by …",
    committeeType: "events",
    ownerId: 2,
    geomap: 'https://goo.gl/maps/RSXrULDANh6bPjoP7'
  },
  {
    title: "Data Import & Migration with Vault",
    startDate: new Date(2022, 10, 1, 13, 0),
    endDate: new Date(2022, 10, 1, 13, 50),
    description:'Demonstration and presentation by Hagerman',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Model QA/QC Round table discussion.",
    startDate: new Date(2022, 10, 1, 14, 0),
    endDate: new Date(2022, 10, 1, 14, 50),
    description:'Moderated by Josh Clark and Kelby Dardeau.',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "PPL Project Spotlight",
    startDate: new Date(2022, 10, 1, 15, 0),
    endDate: new Date(2022, 10, 1, 15, 50),
    description:'Presented by Josh Clark',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "What's new in P&C 8.3",
    startDate: new Date(2022, 10, 1, 13, 0),
    endDate: new Date(2022, 10, 1, 13, 50),
    description:'Presented by Nate Holt',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Customizing AutoCAD Electrical Symbol Builder for you company",
    startDate: new Date(2022, 10, 1, 14, 0),
    endDate: new Date(2022, 10, 1, 14, 50),
    description:'Presented by Tim Timler Ampirical',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Working with ACADE and Stream Deck ",
    startDate: new Date(2022, 10, 1, 15, 0),
    endDate: new Date(2022, 10, 1, 15, 50),
    description:'Presented by Jared Driggers from Power Engineers',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Interactive Digital Environments with Game Engines",
    startDate: new Date(2022, 10, 1, 13, 0),
    endDate: new Date(2022, 10, 1, 13, 50),
    description:'Presentation on how game engines are enabling engineering companies the ability to train and educate personnel, simulate situations, and review projects all in the same tool by Charlie Cox from Burns and McDonnell',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "IACET Presentation - IEEE Power System Security and Communications Documentation Standard ",
    startDate: new Date(2022, 10, 1, 14, 0),
    endDate: new Date(2022, 10, 1, 14, 50),
    description:'This presentation introduces the audience to a new IEEE Standard being developed by the IEEE-PES Technical Committee on Power System Communications and Cybersecurity. IEEE Std P2808 creates a mechanism for sharing cybersecurity and communications design parameters machine to machine and provides a way of documenting these aspects of a design on engineering drawings. Benefits of this standard will also be outlined and includes ability of automating the design and drawing creation with data specific to individual roles and permission levels. Presented by Nathan Wallace from Cybirical',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "SDS-P4A Implementation at NV Energy",
    startDate: new Date(2022, 10, 1, 15, 0),
    endDate: new Date(2022, 10, 1, 15, 50),
    description:'NV Energy’s Reno Substation Engineering group implemented our first SDS P4A project in February 2022.  By the end of August, we were ready to show our Directors and Vice Presidents the time savings and accuracy we were building into our 3D models, the drawing sheets, and overall project.  We will share our lessons learned, and what we would have done differently if we were to start over again by Dolores Henderson from NV Energy',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "The BIM platform Part A",
    startDate: new Date(2022, 10, 1, 13, 0),
    endDate: new Date(2022, 10, 1, 13, 50),
    description:'Autodesk Construction Cloud vision Presented by Autodesk',
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "The BIM platform Part B",
    startDate: new Date(2022, 10, 1, 14, 0),
    endDate: new Date(2022, 10, 1, 14, 50),
    description:'Presented by Slavica Bozic, Principal Product Manager from Bentley',
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Scan to BIM Round table",
    startDate: new Date(2022, 10, 1, 15, 0),
    endDate: new Date(2022, 10, 1, 15, 50),
    description:'Moderated by Joey Baker',
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "What's New with Civil 3D and Recap Pro",
    startDate: new Date(2022, 10, 1, 13, 0),
    endDate: new Date(2022, 10, 1, 13, 50),
    description:'Join this session to see the newest features in Civil 3D® civil engineering design software. Learn about grading optimization to automate tedious site grading processes. See how Project Explorer provides an easy-to-use tool for design-data review and user-configurable report generation, helping you navigate, interact, and share Civil 3D design information with ease.  We’ll also touch on using the Geotechnical Module to visualize boring hole data, create surfaces from the geological attribution, and then display selected data in a Civil 3D profile view.Presented by Richard Morrow, IMAGINiT Engagement Engineer, ISD', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "What standards currently exist for site design and how could we improve/create them?",
    startDate: new Date(2022, 10, 1, 15, 0),
    endDate: new Date(2022, 10, 1, 15, 50),
    description:'Presented by Markus Gentry', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
]

export const eventscheduleD4 = [
  {
    title: "Breakfast",
    startDate: new Date(2022, 10, 2, 8, 0),
    endDate: new Date(2022, 10, 2, 9, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    meals: [1],
    notes: "Lunch hosted by …",
    committeeType: "meals",
    ownerId: 8,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Lunch",
    startDate: new Date(2022, 10, 2, 12, 0),
    endDate: new Date(2022, 10, 2, 13, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    meals: [1],
    notes: "Lunch hosted by …",
    committeeType: "meals",
    ownerId: 8,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "POWER Engineers Event",
    startDate: new Date(2022, 10, 2, 18, 0),
    endDate: new Date(2022, 10, 2, 22, 0),
    description:'For our final night of revelry, POWER Engineers will host an evening of Golf, appetizers and refreshments at the wonderful overland TopGolf on the Wednesday evening.',
    id: uuid(),
    location: "https://topgolf.com/us/overland-park/",
    events: [1],
    notes: "Lunch hosted by …",
    committeeType: "events",
    ownerId: 2,
    geomap: 'https://goo.gl/maps/1wi3f74pzXNyvieL7'
  },
  {
    title: "Physical Committee Elections",
    startDate: new Date(2022, 10, 2, 16, 0),
    endDate: new Date(2022, 10, 2, 16, 15),
    description:'Moderated by Josh Clark',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "How to assemble a 3D substation",
    startDate: new Date(2022, 10, 2, 9, 0),
    endDate: new Date(2022, 10, 2, 9, 50),
    description:'Moderated by Josh Clark',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "IACET Presentation on 3D Cloud Tech",
    startDate: new Date(2022, 10, 2, 10, 0),
    endDate: new Date(2022, 10, 2, 10, 50),
    description:'Presented by Trevor Scullion',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Integrating Substation Civil Site Plans",
    startDate: new Date(2022, 10, 2, 11, 0),
    endDate: new Date(2022, 10, 2, 11, 50),
    description:'Presented by SBS and Burns & McDonnell',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Revit and Inventor",
    startDate: new Date(2022, 10, 2, 13, 0),
    endDate: new Date(2022, 10, 2, 13, 50),
    description:'Presented by POWER Engineers',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Inventor iLogic",
    startDate: new Date(2022, 10, 2, 14, 0),
    endDate: new Date(2022, 10, 2, 14, 50),
    description:'Presented by Michael, Josh and Kelby',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Q&A with SBS development team",
    startDate: new Date(2022, 10, 2, 15, 0),
    endDate: new Date(2022, 10, 2, 15, 50),
    description:'Moderated by Josh Clark',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "P & C Workflow and Organization",
    startDate: new Date(2022, 10, 2, 9, 0),
    endDate: new Date(2022, 10, 2, 9, 50),
    description:'Presented by - Justin Brian Martin - Duke Energy',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "ACADE Project Management with Vault",
    startDate: new Date(2022, 10, 2, 10, 0),
    endDate: new Date(2022, 10, 2, 10, 50),
    description:'Presented by Jared Driggers from Power Engineers',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Quality Checking",
    startDate: new Date(2022, 10, 2, 11, 0),
    endDate: new Date(2022, 10, 2, 11, 50),
    description:'Presented by Nate Holt',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "SDS Refonly Tool – Change duplicate Master/Reference relationships project-wide",
    startDate: new Date(2022, 10, 2, 13, 0),
    endDate: new Date(2022, 10, 2, 13, 50),
    description:'Presented by Tim Timler from Ampirical',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Round Table 1",
    startDate: new Date(2022, 10, 2, 14, 0),
    endDate: new Date(2022, 10, 2, 14, 50),
    description:'Moderated by Terry Shave',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Round Table 1",
    startDate: new Date(2022, 10, 2, 15, 0),
    endDate: new Date(2022, 10, 2, 15, 50),
    description:'Moderated by Terry Shave',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "A Cloud Based Construction Solution:  Autodesk Construction Cloud",
    startDate: new Date(2022, 10, 2, 9, 0),
    endDate: new Date(2022, 10, 2, 9, 50),
    description:'The Autodesk Construction Cloud offerings are unified by utilizing Autodesk Docs as the common data environment. With centralized file management, you can navigate and have integrated workflows throughout a project. In this Overview we will focus on how to enables project teams to align and execute on design intent by managing the entire design collaboration and coordination workflows from a single solution to reduce rework, improve productivity and accelerate project delivery by Scott Wolslager and Richar Morrow IMAGINIT',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "From physical to Digital Twin - Digitalization evolves from Showcase to Use-Case",
    startDate: new Date(2022, 10, 2, 10, 0),
    endDate: new Date(2022, 10, 2, 10, 50),
    description:'A compressed journey from the physical twin of one of the largest German TSO substations using a gigantic pointcloud, OSR and prDi to a lean Industry 4.0 Digital Twin by Nils Weber from Primtech.',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Bentley Intelligent Substation Design Solution tool set",
    startDate: new Date(2022, 10, 2, 11, 0),
    endDate: new Date(2022, 10, 2, 11, 50),
    description:'Bentley Intelligent Substation Design Solution tool set by Todd Lenhardt from Bentley',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Integration Panel (AEP, NVE, SCE, Kiewit, Mesa)",
    startDate: new Date(2022, 10, 2, 13, 0),
    endDate: new Date(2022, 10, 2, 13, 50),
    description:'Integration Panel (AEP, NVE, SCE, Kiewit, Mesa) Moderated by Joey Baker',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Workforce Recruiting and Retention ROUND TABLE DISCUSSION",
    startDate: new Date(2022, 10, 2, 14, 0),
    endDate: new Date(2022, 10, 2, 14, 50),
    description:'Moderated by Mike Grabowski, Arnold Fry and Jason Phillpott',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Business Strategy Committee Meeting and Chair Elections if necessary",
    startDate: new Date(2022, 10, 2, 15, 0),
    endDate: new Date(2022, 10, 2, 15, 50),
    description:'Roundtable committee discussion, and realted topics, and the election for committee chair',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Managing content throughout the BIM process ",
    startDate: new Date(2022, 10, 2, 14, 0),
    endDate: new Date(2022, 10, 2, 14, 50),
    description:'Round Table (ADSK Vault and Document, Bentley Projectwise) Will get into the roles content management can play and where it should be within the BIM process.',
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Distributing content to field operations through the BIM process Round Table ",
    startDate: new Date(2022, 10, 2, 9, 0),
    endDate: new Date(2022, 10, 2, 9, 50),
    description:'Continuation of last years panel Moderated by Joey Baker', 
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "BIM and GIS – Substation and Transmission come together ",
    startDate: new Date(2022, 10, 2, 10, 0),
    endDate: new Date(2022, 10, 2, 10, 50),
    description:'Autodesk and Bentley PLS-CADD', 
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "The Digital Twin as the holy grail ",
    startDate: new Date(2022, 10, 2, 11, 0),
    endDate: new Date(2022, 10, 2, 11, 50),
    description:'Presented by Slavica Bozic, Principal Product Manager from Bentley ', 
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Civil3D Styles",
    startDate: new Date(2022, 10, 2, 9, 0),
    endDate: new Date(2022, 10, 2, 9, 50),
    description:'Presented by Rob Baker', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Using integrated design and critical chain methodologies",
    startDate: new Date(2022, 10, 2, 10, 0),
    endDate: new Date(2022, 10, 2, 10, 50),
    description:'Presented by Chris Wilson', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Integrating Substation Civil Site Plans - Joint session with Physical",
    startDate: new Date(2022, 10, 2, 11, 0),
    endDate: new Date(2022, 10, 2, 11, 50),
    description:'Joint Presentation with the physical committee', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Pro Structures - No Title Yet",
    startDate: new Date(2022, 10, 2, 14, 0),
    endDate: new Date(2022, 10, 2, 14, 50),
    description:'Presented by Curtis (Steve Koval)', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "How could we best implement green technology into our designs?",
    startDate: new Date(2022, 10, 2, 15, 0),
    endDate: new Date(2022, 10, 2, 15, 50),
    description:'Presented by Michael Bussinger', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
]

export const eventscheduleD5 = [
  {
    title: "Breakfast",
    startDate: new Date(2022, 10, 3, 8, 0),
    endDate: new Date(2022, 10, 3, 9, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    meals: [1],
    notes: "Lunch hosted by …",
    committeeType: "meals",
    ownerId: 8,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Lunch",
    startDate: new Date(2022, 10, 3, 12, 0),
    endDate: new Date(2022, 10, 3, 13, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    meals: [1],
    notes: "Lunch hosted by …",
    committeeType: "meals",
    ownerId: 8,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Conference close out and announcements",
    startDate: new Date(2022, 10, 3, 13, 0),
    endDate: new Date(2022, 10, 3, 14, 30),
    description:'Presented by Audrey Marich and Mark Nelson',
    id: uuid(),
    location: "Room 1",
    general: [1],
    notes: "",
    committeeType: "general",
    ownerId: 1,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Highlighting Rules Based Substation Design",
    startDate: new Date(2022, 10, 3, 9, 0),
    endDate: new Date(2022, 10, 3, 9, 50),
    description:'Presented by SBS',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Joint Session with the Electrical Committee",
    startDate: new Date(2022, 10, 3, 10, 0),
    endDate: new Date(2022, 10, 3, 10, 50),
    description:'Teaming up with Electrical team ',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Browfield Ground Grid QC with Dynamo",
    startDate: new Date(2022, 10, 3, 11, 0),
    endDate: new Date(2022, 10, 3, 11, 50),
    description:'Presented by Borton Lawson',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Brownfield workflow",
    startDate: new Date(2022, 10, 3, 9, 0),
    endDate: new Date(2022, 10, 3, 9, 50),
    description:'Presented by Nate Holt',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Syncing Designs with Physical (Joint Session with Physical)",
    startDate: new Date(2022, 10, 3, 10, 0),
    endDate: new Date(2022, 10, 3, 10, 50),
    description:'Presented by Joey Baker',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Set up and insert circuits using SDS Add Circuit",
    startDate: new Date(2022, 10, 3, 11, 0),
    endDate: new Date(2022, 10, 3, 11, 50),
    description:'SDS ADDCIRC Tool Presented by Tim Timler from Ampirical ',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "3D Model Vendor Discussion Panel",
    startDate: new Date(2022, 10, 3, 9, 0),
    endDate: new Date(2022, 10, 3, 9, 50),
    description:'Q&A discussion about proper model creation, development and usage in the SDS tools and other similar software by Erica Huggins.',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "How Configuration Specific Training Can Assist Teams Struggling with Change Management",
    startDate: new Date(2022, 10, 3, 10, 0),
    endDate: new Date(2022, 10, 3, 10, 50),
    description:'A CAD software modernization plan will likely involve integration of several technologies requiring specific workflow and dataset training. There is no one size fits all when it comes to Training. Fortunately, there are tailored learning modalities.  Instructor-led online, multi-modal layered eLearning, in-person and strong over the shoulder coaching makes the difference for successful digital transformation.  Not sure which classes or methods are right for you and your team?  Wondering which training/support package would be the best fit, or can it be adapted to your team’s needs?  Other questions? Join us and explore configuration specific training and what would be the best experience for your team. We will take your questions live during the event by Scott Wolslager and Richard Morrow from IMAGINiT',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Mass data migration and validation from existing substations to Substation BIM data (vs. BIM) as a mandatory base for Digital Twin concepts.",
    startDate: new Date(2022, 10, 3, 11, 0),
    endDate: new Date(2022, 10, 3, 11, 50),
    description:'Presented by Nils Weber from entegra gmhh – primtech 3d',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "3D Model Vendor Discussion Panel",
    startDate: new Date(2022, 10, 3, 9, 0),
    endDate: new Date(2022, 10, 3, 9, 50),
    description:'Joint Session with Business Strategy to discuss modeling needs from vendors', 
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Brownfield Ground grid with Dynamo",
    startDate: new Date(2022, 10, 3, 11, 0),
    endDate: new Date(2022, 10, 3, 11, 50),
    description:'Joint Session with Physical', 
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Technology committee open discussion",
    startDate: new Date(2022, 10, 3, 10, 0),
    endDate: new Date(2022, 10, 3, 10, 50),
    description:'Future plans for monthly calls, vendor presentations and open discussion for the committee. Moderated by Kevin and Jason.', 
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Civil Committee Chair Elections",
    startDate: new Date(2022, 10, 3, 9, 0),
    endDate: new Date(2022, 10, 3, 9, 50),
    description:'Moderated by Michael Bussinger', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Automate in quality to your Civil3D workflows for Structural Engineers",
    startDate: new Date(2022, 10, 3, 10, 0),
    endDate: new Date(2022, 10, 3, 10, 50),
    description:'Presented by Jared Augustine/Michael Bussinger', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "What would we like to see out of Autodesk and Bentley?",
    startDate: new Date(2022, 10, 3, 11, 0),
    endDate: new Date(2022, 10, 3, 11, 50),
    description:'Presented by Michael Bussinger', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  }
]

export const eventscheduleD1 = [
  {
    title: "On the Horizon",
    startDate: new Date(2022, 9, 31, 15, 0),
    endDate: new Date(2022, 9, 31, 17, 0),
    id: uuid(),
    description: `Presentations include: The future of design in power delivery,  Design Squared - the Opportunity of Digital Twins, Moving to the BIM World, When Design and Automation Meets GIS, Autodesk Construction/Infraworks, Bentley’s Investment in Energy`,
    location: "Burns & McDonnell HQ",
    general: [1],
    ownerId: 1,
    geomap: 'https://goo.gl/maps/fXh4FdGMgann8vpc9'
  },
  {
    title: "BBQ Event Hosted by Burns & McDonnell",
    startDate: new Date(2022, 9, 31, 17, 0),
    endDate: new Date(2022, 9, 31, 21, 0),
    description:'Transportation from Burns & McDonnell to the Sheraton hotel stops at 9:30',
    id: uuid(),
    location: "Room 1",
    events: [1],
    notes: "This is a general session to kick off day 2. This is for all attendees…",
    committeeType: "events",
    ownerId: 2,
    geomap: 'https://goo.gl/maps/fXh4FdGMgann8vpc9'
  },
  {
    title: "Breakfast",
    startDate: new Date(2022, 10, 1, 8, 0),
    endDate: new Date(2022, 10, 1, 9, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    meals: [1],
    notes: "Lunch hosted by …",
    committeeType: "meals",
    ownerId: 8,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Welcome presentation (All)",
    startDate: new Date(2022, 10, 1, 9, 0),
    endDate: new Date(2022, 10, 1, 12, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    bizzstrategy: [0,1],
    notes: "This is a general session to kick off day 2. This is for all attendees…",
    committeeType: "general",
    ownerId: 1,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Lunch",
    startDate: new Date(2022, 10, 1, 12, 0),
    endDate: new Date(2022, 10, 1, 13, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    meals: [1],
    notes: "Lunch hosted by …",
    committeeType: "meals",
    ownerId: 8,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Autodesk and SBS Event",
    startDate: new Date(2022, 10, 1, 18, 0),
    endDate: new Date(2022, 10, 1, 22, 0),
    description:'Autodesk and SBS have teamed up to throw an awesome event at Pinstripes for an evening of dinner, drinks and bowling on the Tuesday evening.',
    id: uuid(),
    location: "https://pinstripes.com/overland-park/",
    events: [1],
    notes: "Lunch hosted by …",
    committeeType: "events",
    ownerId: 2,
    geomap: 'https://goo.gl/maps/RSXrULDANh6bPjoP7'
  },
  {
    title: "Breakfast",
    startDate: new Date(2022, 10, 2, 8, 0),
    endDate: new Date(2022, 10, 2, 9, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    meals: [1],
    notes: "Lunch hosted by …",
    committeeType: "meals",
    ownerId: 8,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Lunch",
    startDate: new Date(2022, 10, 2, 12, 0),
    endDate: new Date(2022, 10, 2, 13, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    meals: [1],
    notes: "Lunch hosted by …",
    committeeType: "meals",
    ownerId: 8,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "POWER Engineers Event",
    startDate: new Date(2022, 10, 2, 18, 0),
    endDate: new Date(2022, 10, 2, 22, 0),
    description:'For our final night of revelry, POWER Engineers will host an evening of Golf, appetizers and refreshments at the wonderful overland TopGolf on the Wednesday evening.',
    id: uuid(),
    location: "https://topgolf.com/us/overland-park/",
    events: [1],
    notes: "Lunch hosted by …",
    committeeType: "events",
    ownerId: 2,
    geomap: 'https://goo.gl/maps/1wi3f74pzXNyvieL7'
  },
  {
    title: "Breakfast",
    startDate: new Date(2022, 10, 3, 8, 0),
    endDate: new Date(2022, 10, 3, 9, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    meals: [1],
    notes: "Lunch hosted by …",
    committeeType: "meals",
    ownerId: 8,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Lunch",
    startDate: new Date(2022, 10, 3, 12, 0),
    endDate: new Date(2022, 10, 3, 13, 0),
    description:'',
    id: uuid(),
    location: "Room 1",
    meals: [1],
    notes: "Lunch hosted by …",
    committeeType: "meals",
    ownerId: 8,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Conference close out and announcements",
    startDate: new Date(2022, 10, 3, 13, 0),
    endDate: new Date(2022, 10, 3, 14, 30),
    description:'Presented by Audrey Marich and Mark Nelson',
    id: uuid(),
    location: "Room 1",
    general: [1],
    notes: "",
    committeeType: "general",
    ownerId: 1,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Data Import & Migration with Vault",
    startDate: new Date(2022, 10, 1, 13, 0),
    endDate: new Date(2022, 10, 1, 13, 50),
    description:'Demonstration and presentation by Hagerman',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Model QA/QC Round table discussion.",
    startDate: new Date(2022, 10, 1, 14, 0),
    endDate: new Date(2022, 10, 1, 14, 50),
    description:'Moderated by Josh Clark and Kelby Dardeau.',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "PPL Project Spotlight",
    startDate: new Date(2022, 10, 1, 15, 0),
    endDate: new Date(2022, 10, 1, 15, 50),
    description:'Presented by Josh Clark',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Physical Committee Elections",
    startDate: new Date(2022, 10, 2, 16, 0),
    endDate: new Date(2022, 10, 2, 16, 15),
    description:'Moderated by Josh Clark',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "How to assemble a 3D substation",
    startDate: new Date(2022, 10, 2, 9, 0),
    endDate: new Date(2022, 10, 2, 9, 50),
    description:'Moderated by Josh Clark',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "IACET Presentation on 3D Cloud Tech",
    startDate: new Date(2022, 10, 2, 10, 0),
    endDate: new Date(2022, 10, 2, 10, 50),
    description:'Presented by Trevor Scullion',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Integrating Substation Civil Site Plans",
    startDate: new Date(2022, 10, 2, 11, 0),
    endDate: new Date(2022, 10, 2, 11, 50),
    description:'Presented by SBS and Burns & McDonnell',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Revit and Inventor",
    startDate: new Date(2022, 10, 2, 13, 0),
    endDate: new Date(2022, 10, 2, 13, 50),
    description:'Presented by POWER Engineers',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Inventor iLogic",
    startDate: new Date(2022, 10, 2, 14, 0),
    endDate: new Date(2022, 10, 2, 14, 50),
    description:'Presented by Michael, Josh and Kelby',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Q&A with SBS development team",
    startDate: new Date(2022, 10, 2, 15, 0),
    endDate: new Date(2022, 10, 2, 15, 50),
    description:'Moderated by Josh Clark',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Highlighting Rules Based Substation Design",
    startDate: new Date(2022, 10, 3, 9, 0),
    endDate: new Date(2022, 10, 3, 9, 50),
    description:'Presented by SBS',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Joint Session with the Electrical Committee",
    startDate: new Date(2022, 10, 3, 10, 0),
    endDate: new Date(2022, 10, 3, 10, 50),
    description:'Teaming up with Electrical team ',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Browfield Ground Grid QC with Dynamo",
    startDate: new Date(2022, 10, 3, 11, 0),
    endDate: new Date(2022, 10, 3, 11, 50),
    description:'Presented by Borton Lawson',
    id: uuid(),
    location: "Room 1",
    physical: [1],
    notes: "",
    committeeType: "physical",
    ownerId: 3,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "What's new in P&C 8.3",
    startDate: new Date(2022, 10, 1, 13, 0),
    endDate: new Date(2022, 10, 1, 13, 50),
    description:'Presented by Nate Holt',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Customizing AutoCAD Electrical Symbol Builder for you company",
    startDate: new Date(2022, 10, 1, 14, 0),
    endDate: new Date(2022, 10, 1, 14, 50),
    description:'Presented by Tim Timler Ampirical',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Working with ACADE and Stream Deck ",
    startDate: new Date(2022, 10, 1, 15, 0),
    endDate: new Date(2022, 10, 1, 15, 50),
    description:'Presented by Jared Driggers from Power Engineers',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "P & C Workflow and Organization",
    startDate: new Date(2022, 10, 2, 9, 0),
    endDate: new Date(2022, 10, 2, 9, 50),
    description:'Presented by - Justin Brian Martin - Duke Energy',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "ACADE Project Management with Vault",
    startDate: new Date(2022, 10, 2, 10, 0),
    endDate: new Date(2022, 10, 2, 10, 50),
    description:'Presented by Jared Driggers from Power Engineers',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Quality Checking",
    startDate: new Date(2022, 10, 2, 11, 0),
    endDate: new Date(2022, 10, 2, 11, 50),
    description:'Presented by Nate Holt',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "SDS Refonly Tool – Change duplicate Master/Reference relationships project-wide",
    startDate: new Date(2022, 10, 2, 13, 0),
    endDate: new Date(2022, 10, 2, 13, 50),
    description:'Presented by Tim Timler from Ampirical',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Round Table 1",
    startDate: new Date(2022, 10, 2, 14, 0),
    endDate: new Date(2022, 10, 2, 14, 50),
    description:'Moderated by Terry Shave',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Round Table 1",
    startDate: new Date(2022, 10, 2, 15, 0),
    endDate: new Date(2022, 10, 2, 15, 50),
    description:'Moderated by Terry Shave',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Brownfield workflow",
    startDate: new Date(2022, 10, 3, 9, 0),
    endDate: new Date(2022, 10, 3, 9, 50),
    description:'Presented by Nate Holt',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Syncing Designs with Physical (Joint Session with Physical)",
    startDate: new Date(2022, 10, 3, 10, 0),
    endDate: new Date(2022, 10, 3, 10, 50),
    description:'Presented by Joey Baker',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Set up and insert circuits using SDS Add Circuit",
    startDate: new Date(2022, 10, 3, 11, 0),
    endDate: new Date(2022, 10, 3, 11, 50),
    description:'SDS ADDCIRC Tool Presented by Tim Timler from Ampirical ',
    id: uuid(),
    location: "Room 1",
    electrical: [1],
    notes: "",
    committeeType: "electrical",
    ownerId: 4,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Interactive Digital Environments with Game Engines",
    startDate: new Date(2022, 10, 1, 13, 0),
    endDate: new Date(2022, 10, 1, 13, 50),
    description:'Presentation on how game engines are enabling engineering companies the ability to train and educate personnel, simulate situations, and review projects all in the same tool by Charlie Cox from Burns and McDonnell',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "IACET Presentation - IEEE Power System Security and Communications Documentation Standard ",
    startDate: new Date(2022, 10, 1, 14, 0),
    endDate: new Date(2022, 10, 1, 14, 50),
    description:'This presentation introduces the audience to a new IEEE Standard being developed by the IEEE-PES Technical Committee on Power System Communications and Cybersecurity. IEEE Std P2808 creates a mechanism for sharing cybersecurity and communications design parameters machine to machine and provides a way of documenting these aspects of a design on engineering drawings. Benefits of this standard will also be outlined and includes ability of automating the design and drawing creation with data specific to individual roles and permission levels. Presented by Nathan Wallace from Cybirical',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "SDS-P4A Implementation at NV Energy",
    startDate: new Date(2022, 10, 1, 15, 0),
    endDate: new Date(2022, 10, 1, 15, 50),
    description:'NV Energy’s Reno Substation Engineering group implemented our first SDS P4A project in February 2022.  By the end of August, we were ready to show our Directors and Vice Presidents the time savings and accuracy we were building into our 3D models, the drawing sheets, and overall project.  We will share our lessons learned, and what we would have done differently if we were to start over again by Dolores Henderson from NV Energy',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "A Cloud Based Construction Solution:  Autodesk Construction Cloud",
    startDate: new Date(2022, 10, 2, 9, 0),
    endDate: new Date(2022, 10, 2, 9, 50),
    description:'The Autodesk Construction Cloud offerings are unified by utilizing Autodesk Docs as the common data environment. With centralized file management, you can navigate and have integrated workflows throughout a project. In this Overview we will focus on how to enables project teams to align and execute on design intent by managing the entire design collaboration and coordination workflows from a single solution to reduce rework, improve productivity and accelerate project delivery by Scott Wolslager and Richar Morrow IMAGINIT',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "From physical to Digital Twin - Digitalization evolves from Showcase to Use-Case",
    startDate: new Date(2022, 10, 2, 10, 0),
    endDate: new Date(2022, 10, 2, 10, 50),
    description:'A compressed journey from the physical twin of one of the largest German TSO substations using a gigantic pointcloud, OSR and prDi to a lean Industry 4.0 Digital Twin by Nils Weber from Primtech.',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Bentley Intelligent Substation Design Solution tool set",
    startDate: new Date(2022, 10, 2, 11, 0),
    endDate: new Date(2022, 10, 2, 11, 50),
    description:'Bentley Intelligent Substation Design Solution tool set by Todd Lenhardt from Bentley',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Integration Panel (AEP, NVE, SCE, Kiewit, Mesa)",
    startDate: new Date(2022, 10, 2, 13, 0),
    endDate: new Date(2022, 10, 2, 13, 50),
    description:'Integration Panel (AEP, NVE, SCE, Kiewit, Mesa) Moderated by Joey Baker',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Workforce Recruiting and Retention ROUND TABLE DISCUSSION",
    startDate: new Date(2022, 10, 2, 14, 0),
    endDate: new Date(2022, 10, 2, 14, 50),
    description:'Moderated by Mike Grabowski, Arnold Fry and Jason Phillpott',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Business Strategy Committee Meeting and Chair Elections if necessary",
    startDate: new Date(2022, 10, 2, 15, 0),
    endDate: new Date(2022, 10, 2, 15, 50),
    description:'Roundtable committee discussion, and realted topics, and the election for committee chair',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "3D Model Vendor Discussion Panel",
    startDate: new Date(2022, 10, 3, 9, 0),
    endDate: new Date(2022, 10, 3, 9, 50),
    description:'Q&A discussion about proper model creation, development and usage in the SDS tools and other similar software by Erica Huggins.',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "How Configuration Specific Training Can Assist Teams Struggling with Change Management",
    startDate: new Date(2022, 10, 3, 10, 0),
    endDate: new Date(2022, 10, 3, 10, 50),
    description:'A CAD software modernization plan will likely involve integration of several technologies requiring specific workflow and dataset training. There is no one size fits all when it comes to Training. Fortunately, there are tailored learning modalities.  Instructor-led online, multi-modal layered eLearning, in-person and strong over the shoulder coaching makes the difference for successful digital transformation.  Not sure which classes or methods are right for you and your team?  Wondering which training/support package would be the best fit, or can it be adapted to your team’s needs?  Other questions? Join us and explore configuration specific training and what would be the best experience for your team. We will take your questions live during the event by Scott Wolslager and Richard Morrow from IMAGINiT',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Mass data migration and validation from existing substations to Substation BIM data (vs. BIM) as a mandatory base for Digital Twin concepts.",
    startDate: new Date(2022, 10, 3, 11, 0),
    endDate: new Date(2022, 10, 3, 11, 50),
    description:'Presented by Nils Weber from entegra gmhh – primtech 3d',
    id: uuid(),
    location: "Room 1",
    business: [1],
    notes: "",
    committeeType: "business",
    ownerId: 5,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "The BIM platform Part A",
    startDate: new Date(2022, 10, 1, 13, 0),
    endDate: new Date(2022, 10, 1, 13, 50),
    description:'Autodesk Construction Cloud vision Presented by Autodesk',
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "The BIM platform Part B",
    startDate: new Date(2022, 10, 1, 14, 0),
    endDate: new Date(2022, 10, 1, 14, 50),
    description:'Presented by Slavica Bozic, Principal Product Manager from Bentley',
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Scan to BIM Round table",
    startDate: new Date(2022, 10, 1, 15, 0),
    endDate: new Date(2022, 10, 1, 15, 50),
    description:'Moderated by Joey Baker',
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Managing content throughout the BIM process ",
    startDate: new Date(2022, 10, 2, 14, 0),
    endDate: new Date(2022, 10, 2, 14, 50),
    description:'Round Table (ADSK Vault and Document, Bentley Projectwise) Will get into the roles content management can play and where it should be within the BIM process.',
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Distributing content to field operations through the BIM process Round Table ",
    startDate: new Date(2022, 10, 2, 9, 0),
    endDate: new Date(2022, 10, 2, 9, 50),
    description:'Continuation of last years panel Moderated by Joey Baker', 
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "BIM and GIS – Substation and Transmission come together ",
    startDate: new Date(2022, 10, 2, 10, 0),
    endDate: new Date(2022, 10, 2, 10, 50),
    description:'Autodesk and Bentley PLS-CADD', 
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "The Digital Twin as the holy grail ",
    startDate: new Date(2022, 10, 2, 11, 0),
    endDate: new Date(2022, 10, 2, 11, 50),
    description:'Presented by Slavica Bozic, Principal Product Manager from Bentley ', 
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "3D Model Vendor Discussion Panel",
    startDate: new Date(2022, 10, 3, 9, 0),
    endDate: new Date(2022, 10, 3, 9, 50),
    description:'Joint Session with Business Strategy to discuss modeling needs from vendors', 
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Brownfield Ground grid with Dynamo",
    startDate: new Date(2022, 10, 3, 11, 0),
    endDate: new Date(2022, 10, 3, 11, 50),
    description:'Joint Session with Physical', 
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Technology committee open discussion",
    startDate: new Date(2022, 10, 3, 10, 0),
    endDate: new Date(2022, 10, 3, 10, 50),
    description:'Future plans for monthly calls, vendor presentations and open discussion for the committee. Moderated by Kevin and Jason.', 
    id: uuid(),
    location: "Room 1",
    technology: [1],
    notes: "",
    committeeType: "technology",
    ownerId: 6,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "What's New with Civil 3D and Recap Pro",
    startDate: new Date(2022, 10, 1, 13, 0),
    endDate: new Date(2022, 10, 1, 13, 50),
    description:'Join this session to see the newest features in Civil 3D® civil engineering design software. Learn about grading optimization to automate tedious site grading processes. See how Project Explorer provides an easy-to-use tool for design-data review and user-configurable report generation, helping you navigate, interact, and share Civil 3D design information with ease.  We’ll also touch on using the Geotechnical Module to visualize boring hole data, create surfaces from the geological attribution, and then display selected data in a Civil 3D profile view.Presented by Richard Morrow, IMAGINiT Engagement Engineer, ISD', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "What standards currently exist for site design and how could we improve/create them?",
    startDate: new Date(2022, 10, 1, 15, 0),
    endDate: new Date(2022, 10, 1, 15, 50),
    description:'Presented by Markus Gentry', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Civil3D Styles",
    startDate: new Date(2022, 10, 2, 9, 0),
    endDate: new Date(2022, 10, 2, 9, 50),
    description:'Presented by Rob Baker', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Using integrated design and critical chain methodologies",
    startDate: new Date(2022, 10, 2, 10, 0),
    endDate: new Date(2022, 10, 2, 10, 50),
    description:'Presented by Chris Wilson', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Integrating Substation Civil Site Plans - Joint session with Physical",
    startDate: new Date(2022, 10, 2, 11, 0),
    endDate: new Date(2022, 10, 2, 11, 50),
    description:'Joint Presentation with the physical committee', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Pro Structures - No Title Yet",
    startDate: new Date(2022, 10, 2, 14, 0),
    endDate: new Date(2022, 10, 2, 14, 50),
    description:'Presented by Curtis (Steve Koval)', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "How could we best implement green technology into our designs?",
    startDate: new Date(2022, 10, 2, 15, 0),
    endDate: new Date(2022, 10, 2, 15, 50),
    description:'Presented by Michael Bussinger', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Civil Committee Chair Elections",
    startDate: new Date(2022, 10, 3, 9, 0),
    endDate: new Date(2022, 10, 3, 9, 50),
    description:'Moderated by Michael Bussinger', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "Automate in quality to your Civil3D workflows for Structural Engineers",
    startDate: new Date(2022, 10, 3, 10, 0),
    endDate: new Date(2022, 10, 3, 10, 50),
    description:'Presented by Jared Augustine/Michael Bussinger', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  },
  {
    title: "What would we like to see out of Autodesk and Bentley?",
    startDate: new Date(2022, 10, 3, 11, 0),
    endDate: new Date(2022, 10, 3, 11, 50),
    description:'Presented by Michael Bussinger', 
    id: uuid(),
    location: "Room 1",
    civil: [1],
    notes: "",
    committeeType: "civil",
    ownerId: 7,
    geomap: 'https://goo.gl/maps/yNgMq39MFgf2rjT78'
  }

];


export const committees = [
  {
    text: 'General',
    id: 1,
    color: '#42a5f5',
  },
  {
    text: 'Event',
    id: 2,
    color: '#EEC643',
  },  
  {
    text: 'Physical',
    id: 3,
    color: '#F46036',
  }, 
  {
    text: 'Electrical',
    id: 4,
    color: '#1B998B',
  }, 
  {
    text: 'Business',
    id: 5,
    color: '#508484',
  },
  {
    text: 'Technology',
    id: 6,
    color: '#79C99E',
  }, 
  {
    text: 'Civil',
    id: 7,
    color: '#4A4238',
  },
  {
    text: 'Meal',
    id: 8,
    color: '#C6C5B9',
  }
];

