import React, { Component } from "react";
import { isAuthenticated } from "../auth";
import { listGroups } from "./apigroup";
import Footer from '../core/Footer'; 
// import { Button, Modal } from 'react-bootstrap';
// import moment from "moment";
import ReactGA from 'react-ga';
import LoadingOverlay from 'react-loading-overlay';

class Groups extends Component {
    constructor() {
        super();
        this.state = {
            groups: [],
            userId: isAuthenticated() && isAuthenticated().userId,
            token: isAuthenticated() && isAuthenticated().token,
            userEmail: isAuthenticated() && isAuthenticated().email,
            loading: false
        };
    }

    handleChange = name => event => {
        let value = event.target.value
        this.setState({ [name]: value });
    };

    loadGroups = () => {
        this.setState({loading: true })
        listGroups(this.state.token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({ 
                    groups: data,
                    loading: false 
                });
            }
        });
    };

    componentDidMount() {
       
        ReactGA.initialize('UA-331790-15');
        ReactGA.pageview('/grouplist');

        this.loadGroups();
    }


    verifyAccess(members, groupId, userId) {
        if(groupId === '5e971eab6d698b2e84041439') {
            return <a href={`/singlegroup/${groupId}`} className="btn btn-success">View Group</a>
        } else {
        let arefString = '';
        if(members.length === 0) {
            arefString = <a href={`/group/requestaccess/${groupId}`} className="btn btn-primary">Request to Join</a>
        } else {
            arefString = <a href={`/group/requestaccess/${groupId}`} className="btn btn-primary">Request to Join</a>
        }
        members.forEach(element => {
            if(element === userId){                
                arefString = <a href={`/singlegroup/${groupId}`} className="btn btn-success">View Group</a>   
            // }else {
            //     arefString = <a href={`/group/requestaccess/${groupId}`} className="btn btn-primary">Request to Join</a>   
            }
        });
        return arefString 
        } 
    }


    render = () => {
        return (
            <LoadingOverlay
            active={this.state.loading}
            spinner
            text='Loading data ...'
            >
            <div>
                <div role="main" className="container-fluid" style={{ width: "100%" }}>
                    <div className="jumbotron p-3 p-md-5 text-white" style={{ width: "100%", height: "100%", background: "#245aa6" }}>
                        <div className="col-md-12 px-0">
                            <h1 className="display-4 font-italic">Here are all of the Committees</h1>
                            <p className="lead my-3">This lists all of the SDSIC committees.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <h2> Committees to Join</h2>
                </div>

                <div className="card-columns">

                    {this.state.groups.map((group, i) => {
                        return (
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">{group.name}</h5>
                                    <p className="card-text">{group.description}</p>       
                                    {this.verifyAccess(group.members, group._id, this.state.userId)}
                                </div>
                            </div>
                        );
                    })}
                    
                </div>
                
                <br/>
                <br/>
                <br/>
                <Footer />
            </div>
            </LoadingOverlay>
        )        
    }
}

export default Groups;