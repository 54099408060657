import React, { Component } from "react";
// import Posts from "../post/Posts";
import { Button, Form } from 'react-bootstrap';
// import BMLogo from '../images/Burns_and_McDonnell_Logo.jpg'
import Footer from '../core/Footer';
import { sendMessage } from "../group/apigroup";
import moment from "moment";

class publiceventsping2022 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            success: ''
        }
    }

    handleChange = name => event => {
        if (name === "email") {
            this.setState({ error: "" });
            this.setState({ responseTo: name, [name]: event.target.value });
        }
    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Your on the list!</h3>
                <p>Don't forget to check your inbox and your spam folder for updates from the SDSIC.</p>
                <p>We will use the email you've submitted to send you updates as we finalize the upcoming event.</p>
            </div>
        )
    };

    submitEmail = e => {
        const { email } = this.state;
        e.preventDefault();
        if (email === "") { this.setState({ error: "email required!" }) }

        if (email !== "") {
            const mailMessage = {
                to: 'trevor.scullion@automationforce.com',
                from: email,
                subject: "SDSIC 2022 SPRING event list",
                Message: 'Please add ' + email + ' to the SDSIC 2022 SPRING event list.',
                html: "<p>Please add " + email + " to the SDSIC 2022 SPRING event list.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }
            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                this.setState({
                    email: '',
                    success: true,
                });
            });

            const userMessage = {
                to: email,
                from: 'donotreply@sdsconsortium.com',
                subject: "SDSIC 2022 SPRING event",
                Message: 'Your name is on the SDSIC 2022 SPRING event list.',
                html: "<p>Your name is on the SDSIC 2022 SPRING event list.</p><p>You will be kept up to date " +
                    "as we finalize the event details.</p><p>Please do not reply to this email.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }

            // make request to api to send message
            sendMessage(userMessage).then(data => { });
        };



    };


    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">

                            <div className="jumbotron">
                                <h2>
                                    Our SDSIC 2022 Spring Virtual Events
                                </h2>
                                <p>Our annual spring conference will once again be Virtua for 2022!</p>
                                <p>
                                    Due to the Covid-19 Pandemic, our annual events have been virtual since 2019 and thanks to Power Engineers,
                                    We will once again host our 2022 spring event via the zoom platform.
                                </p>
                                <p>
                                    We are finalizing the agenda but for now, save the following dates to attend in person;
                                    May 3rd and 4th.
                                </p>
                                <div className="row">
                                    <hr />
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                       
                                        <h4>Register</h4>
                                        <Form.Text className="muted">*Required</Form.Text>
                                        <Form.Control
                                            placeholder="Your Work Email"
                                            name="email"
                                            className="form-control"
                                            type="text"
                                            onChange={this.handleChange("email")}
                                            value={this.state.email}
                                        />
                                        <br />
                                        {this.showError()}
                                        <Button
                                            variant="primary"
                                            onClick={this.submitEmail}
                                        >   Join the list
                                        </Button>
                                        {this.showSuccess()}
                                        <br/>
                                        <br/>
                                    </div>
                                   
                                </div>

                               
                            </div>

                        </div>


                    </div >
                    <hr />
                    <div>
                    <div className="row">                    
                            <p>
                                We are trying to gather some information for our 2022 event planning and we could use your help by
                                taking 2 minutes to complete our survey.
                            </p>
                                <div className="col-md-4">
                                    <a className="btn btn-primary btn-large" href="/survey2022" target="_blank">Please take our 2022 event survey</a>
                                </div>
                            </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-8">
                            <h2>
                                Sign up for an SDSIC membership!
                            </h2>
                            <p>
                                Don't miss out on the chance to view almost two days of content from vendors and
                                industry peers all from the comfort of your home.
                            </p>
                            <div className="row">
                                <div className="col-md-4">
                                    <a className="btn btn-primary btn-large" href="/signup" target="_blank">Register for an SDSIC Membership</a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div >
                <Footer />
            </>
        )
    }
}

export default publiceventsping2022