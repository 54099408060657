import React, { Component } from "react";
// import Posts from "../post/Posts";
import ForumPosts from "../forum/ForumPosts";
import EventLists from "../event/EventList";
import Groups from "../group/Groups";
import { chatbotapi } from '../group/apigroup'
import FileList from "../file/FileList";
import ScheduleComp from "../event/ScheduleComp";
import logo from '../images/sdsic_fred.png';
import Footer from '../core/Footer';


import { isAuthenticated } from '../auth';

class PublicHome extends Component {

    componentDidMount = () => {
        localStorage.removeItem('jwt');
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('email');

        // var userMessage = 'Welcome to the SDSIC! What prompted you to visit the SDSIC website today?'
        // if (isAuthenticated()) {
        //     userMessage = `Hey ${isAuthenticated().name}, ! Great to see you again! What brings you back today?`
        // } 
        // addResponseMessage(userMessage);
    }

    // handleNewUserMessage = (newMessage) => {

    //     toggleMsgLoader()
    //     setTimeout(function () {
    //       toggleMsgLoader()        
    //       chatbotapi(newMessage).then(data => {
    //         if (data.error) {
    //           console.log(data.error);
    //         } else {
    //           addResponseMessage(data.answer);   
    //         }    
    //       });
    //     }, 2000);

    //   };

    render() {

        return ( 
            <>
                <div className="container-fluid">

                    <div className="row justify-content-md-center">
                        <div className="col-md-12">

                            <div className="jumbotron">
                                
                                <h2>
                                    Welcome to the Substation Design Solution (SDS) Industry Consortium site.
                                </h2>
                                <p>
                                    Substation Design Solution (SDS) Industry Consortium is made up of member utilities
                                    and contract/design firms which have adopted or are investigating for adoption the
                                    new technologies  or workflows to improve design, engineering, field construction,
                                    operation and maintenance of utility projects.
                                </p>
                                <p>
                                    The Consortium will meet, as needed, to establish common workflows, common toolkit
                                    functionality, shared libraries, common standards, technology needs and any other
                                    topics that help drive our vendor’s software development efforts according to an industry standard.
                                </p>
                                <p>
                                    The SDS Industry Consortium will drive standards to promote common library development from
                                    internal resources, external partners, and/or equipment manufacturers.
                                </p>
                                <p>
                                    <a className="btn btn-primary btn-large" href="/about">More on the SDSIC</a>
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="text-center">
                                        <p>
                                            <u>Here are just a few of the ways we work together.</u>
                                        </p>
                                    </h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-deck">
                                        <div className="card bg-default">
                                            <h5 className="card-header">
                                                Committees
                                            </h5>
                                            <div className="card-body">
                                                <p className="card-text">
                                                    We have several committees, all run by utility member volunteers, with large participation from
                                                    various engineering firms, Utility companies and equipment vendors. These committees produce
                                                    best practices, recommendations, techniques and even workflow examples that can help members
                                                    advance in their respective design disciplines quickly.
                                                </p>
                                            </div>
                                            <div className="card-footer">
                                                <a className="btn btn-primary btn-large" href="/signup">Get Involved</a>
                                            </div>
                                        </div>
                                        <div className="card bg-default">
                                            <h5 className="card-header">
                                                Collaboration
                                            </h5>
                                            <div className="card-body">
                                                <p className="card-text">
                                                    All committees hold monthly web meetings and members participate via various web meeting platforms.
                                                    The openness of these monthly calls is what produces the committee documents but also the
                                                    recommendations
                                                    sent back to software vendors, the developers of the various tools used by the committees.
                                                </p>
                                            </div>
                                            <div className="card-footer">
                                                <a className="btn btn-primary btn-large" href="/signup">Get Involved</a>
                                            </div>
                                        </div>
                                        <div className="card bg-default">
                                            <h5 className="card-header">
                                                Face to Face
                                            </h5>
                                            <div className="card-body">
                                                <p className="card-text">
                                                    Once a year, one of our member companies hosts an annual event that allows us all to meet face to face,
                                                    gain valuable contacts and even receive free training. These events are keeping in the spirit of TED talks
                                                    and are well attended by Utility Companies, Engineering firms and vendors alike. All focused on Substation
                                                    Design.
                                                </p>
                                            </div>
                                            <div className="card-footer">
                                                <a className="btn btn-primary btn-large" href="/signup">Get Involved</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    {/* <Widget
                        handleNewUserMessage={this.handleNewUserMessage}
                        ProfileAvatar={this.logo}
                        title="your SDSIC chatbot"
                        subtitle="find answers here!"
                    /> */}
                </div>

                <Footer />
            </>
        )
    }

}

export default PublicHome;