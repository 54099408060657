import React, { Component } from "react";
import Carousel from 'react-bootstrap/Carousel';

class MerchCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageList: props.images
    }
  }

  render() {
    return (
      <Carousel variant="dark">
        {this.state.imageList.map((image, i) => {
          return (
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={image.imageUrl}
                alt={image.perspective}
              />
              <Carousel.Caption>
                <h5>{image.perspective}</h5>
                <p>{image.appearanceName}</p>
              </Carousel.Caption>
            </Carousel.Item>
          )
        })}
      </Carousel>
    )
  }
}

export default MerchCarousel;