/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import { isAuthenticated } from "../auth";
import { Redirect, Link } from "react-router-dom";
import { read, mfa_qr_code, getFullUserList, updateUserRole, findPeople } from "./apiUser";
import DefaultProfile from "../images/avatar.png";
import DeleteUser from "./DeleteUser";
import FollowProfileButton from "./FollowProfileButton";
import ProfileTabs from "./ProfileTabs";
import { listByUser } from "../post/apiPost";
import { ResponsiveEmbed } from "react-bootstrap";
import codeFile from "../codeFiles/Mass.iLogic.txt"
import { FaCheckCircle } from 'react-icons/fa';
import { sendMessage } from "../group/apigroup";
import moment from "moment";

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      user: { following: [], followers: [] },
      redirectToSignin: false,
      following: false,
      error: "",
      posts: [],
      qrCode: null,
      userList: [],
      users: [],
      fullUserList: [],
      searchText: '',
      updateMessage: '',
    };
  }

  updateUserList = () => {
    getFullUserList(isAuthenticated().token).then(data => {
      this.setState({ ...this.state, userList: data });
    })

  }
  // check follow
  checkFollow = user => {

    const match = user.followers.find(follower => {
      // one id has many other ids (followers) and vice versa
      return follower._id === isAuthenticated().userId;
    });
    return match;
  };



  clickFollowButton = callApi => {
    const userId = isAuthenticated().userId;
    const token = isAuthenticated().token;

    callApi(userId, token, this.state.userId).then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
        this.setState({ user: data, following: !this.state.following });
      }
    });
  };

  init = userId => {
    const token = isAuthenticated().token;
    read(userId, token).then(data => {
      if (data.error) {
        this.setState({ redirectToSignin: true });
      } else {
        let following = this.checkFollow(data);
        this.setState({ user: data, following });
        this.loadPosts(data._id);
      }
    });
    this.setState({ profileimage: `` })
  };

  loadPosts = userId => {
    const token = isAuthenticated().token;
    listByUser(userId, token).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({ posts: data });
      }
    });
  };

  componentDidMount() {
    const userId = this.props.match.params.userId;
    this.init(userId);
    this.generateQrCode();
    this.updateUserList()
  }


  generateQrCode() {
    mfa_qr_code().then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        console.log('qrcode', data)
        this.setState({ qrCode: data });
      }
    });
  };

  updateUserList = () => {
    getFullUserList(isAuthenticated().token).then(data => {
      this.setState({
        ...this.state,
        userList: data,
      });
    })

  }


  // changes user status to approved and sends email
  approveUser = (userId, email, firstName) => {
    updateUserRole(userId, 'subscriber', isAuthenticated().token).then(approved => {

      const mailMessage = {
        "subject": "SDSIC Membership request",
        "sender": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
        "to": [{ "name": `${email}`, "email": `${email}` }],
        "htmlContent": `<html><body><p>Your SDSIC Membership has been approved with email: ${email} and you can now login to our site.</p></body></html>`
      }

      // make request to api to send message
      sendMessage(mailMessage).then(data => {
        this.updateUserList()
        this.setState({
          ...this.state,
          updateMessage: approved.message
        });
      });
    })
  }


  componentWillReceiveProps(props) {
    const userId = props.match.params.userId;
    this.init(userId);

  }

  handleChange = name => event => {

    const input = event.target;
    const value = event.target.type === 'checkbox' ? input.checked : input.value;

    this.setState({
      ...this.state,
      error: "",
      [name]: value
    });

  };

  holdUser = (userId) => {
    updateUserRole(userId, 'pending', isAuthenticated().token).then(approved => {
      console.log(approved)
      this.setState({
        ...this.state,
        updateMessage: approved.message
      });
      this.updateUserList()
    })
  }


  renderUsers = users => (
    <div className="row">
      {users.map((user, i) => (
        <div className="card col-md-4" key={i}>
          <div className="card-body">
            <h5 className="card-title">{user.name} {user.lastName}</h5>
            <p className="card-text">{user.companyName} has the role of <b>{user.role}</b></p>

            <button onClick={() => { this.holdUser(user._id) }}
              className="btn btn-outline-success mt-2 mb-2 card-btn-1">
              Put on Hold <FaCheckCircle />
            </button>

            <button onClick={() => { this.makeVendor(user._id, user.email, user.name) }}
              className="btn btn-outline-info mt-2 mb-2 card-btn-1">
              Make Vendor <FaCheckCircle />
            </button>

          </div>
        </div>
      ))}
    </div>
  );


  clickSearch = () => {
    findPeople(isAuthenticated().userId, this.state.searchText, isAuthenticated().token).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({
          ...this.state,
          users: data
        });
      }
    });
  };



  render() {
    const { redirectToSignin, user, posts } = this.state;
    if (redirectToSignin) return <Redirect to="/signin" />;

    const photoUrl = user._id
      ? user.linkedInPhoto
      : DefaultProfile;

    return (
      <div className="container">
        <h2 className="mt-5 mb-5">{user.name}'s Profile</h2>
        <div className="row">
          <div className="col-md-4">
            <img
              style={{ height: "200px", width: "auto" }}
              className="img-thumbnail"
              src={photoUrl}
              onError={i => (i.target.src = `${DefaultProfile}`)}
              alt={user.name}
            />
          </div>
          {/* <img src={this.state.qrCode} /> */}
          <div className="col-md-8">
            <div className="lead mt-2">
              <p>User's full name: <b>{user.name} {user.lastName}</b></p>
              <p>from Company:<b> {user.companyName}</b></p>
              <p>Company Type is a: <b>{user.companyType}</b></p>
              <p>Email: <b> {user.email}</b></p>
              <p>{`Joined ${new Date(user.created).toDateString()}`}</p>
            </div>

            {isAuthenticated().user &&
              isAuthenticated().userId === user._id ? (
              <div className="d-inline-block">
                {/* <Link
                  className="btn btn-raised btn-info mr-5"
                  to={`/post/create`}
                >
                  Create Post
                </Link> */}

                <Link
                  className="btn btn-raised btn-success mr-5"
                  to={`/user/edit/${user._id}`}
                >
                  Edit Profile
                </Link>
                <DeleteUser userId={user._id} />
              </div>
            ) : (
              <FollowProfileButton
                following={this.state.following}
                onButtonClick={this.clickFollowButton}
              />
            )}

            <div>
              {isAuthenticated().userId && (
                <div className="card mt-5">
                  <div className="card-body">
                    <h5 className="card-title">Edit your profile</h5>
                    <p className="mb-2 text-danger">
                      Changes to your profile will be live as soon as you update.
                    </p>
                    <Link
                      className="btn btn-raised btn-success mr-5"
                      to={`/user/edit/${user._id}`}
                    >
                      Edit Profile
                    </Link>
                    {/*<DeleteUser userId={user._id} />*/}
                    {/* <DeleteUser /> */}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>


          </div>
        </div>
        <div className="row">
          {/* {(isAuthenticated().email === "trevor.scullion@automationforce.com") && <div>
            <br />
            <pre><code>

              <div style={{ color: 'white' }}> var total = 0;</div>
              <div>
                <em style={{ color: 'green' }}>
                  // Add 1 to total and display in a paragraph
                </em>
              </div>
              <div><strong style={{ color: 'blue' }}>document.write('&lt;p&gt;Sum: ' + (total + 1) + '&lt;/p&gt;');</strong></div>
            </code></pre>
          </div>
          } */}
          <div className="col md-12 mt-5 mb-5">
            <hr />
            <p className="lead">About me: {user.about}</p>
            <hr />

            <ProfileTabs
              followers={user.followers}
              following={user.following}
              posts={posts}
            />
          </div>
        </div>
        {(isAuthenticated().email === "trevor.scullion@automationforce.com") &&
          <div className="row">
            <div className="col-md-12">
              <div className="row">

                {(this.state.userList.length > 0) && <div className="col-md-12">
                  <div>All users waiting to signin </div>
                  <table className="table table-sm table-hover">
                    <thead className="thead-dark">
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>email</th>
                        <th>Current Role</th>
                        <th>Approve user</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.userList.map((user, pIndex) => (
                        <tr key={pIndex}>
                          <td>{user.name}</td>
                          <td>{user.lastName}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td>
                            <button onClick={() => { this.approveUser(user._id, user.email, user.name) }}
                              className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                              Approve <FaCheckCircle />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>}

                {this.state.userList.length === 0 && <div className="alert alert-info" ><h4>There are no users waiting for approval to join! </h4></div>}

              </div>
              {this.state.updateMessage === 'User Updated Successfully' && <div className="alert alert-info" ><h4>User was approved!</h4></div>}
            </div>
            <div className="container">
              <h2 className="mt-5 mb-5">Find Any Existing User </h2>

              <div className="form-group">
                <label className="col-form-label">Search for Users by first name (note: capitals are important so enter 'Bob' not 'bob'):</label>
                <input name="searchText" value={this.state.searchText} type="text" onChange={this.handleChange("searchText")} className="form-control" id="searchText" />
              </div>
              <div className="form-group">
                <button onClick={this.clickSearch} className="btn btn-raised btn-primary">
                  Submit
                </button>
              </div>
              {this.renderUsers(this.state.users)}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Profile;