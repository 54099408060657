import React, { useState } from "react";
import { Container, Tab, Tabs, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const Proposals = () => {

    const [key, setKey] = useState('personalInfo');

    return (
        <Container>
            <h2>Submit New Proposal for 2025</h2>
            <Tabs id="registration-tabs" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Tab eventKey="personalInfo" title="Account">
                    <Form>
                        <p><b>Note:</b> Verify the correct SDSIC profile displays below. If something different is shown, sign out and sign in with the
                        correct profile. You are required to use the same profile to register for SDSIC 2025.</p>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter first name" />
                        </Form.Group>

                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter last name" />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                        </Form.Group>

                        <Button variant="primary" onClick={() => setKey('contactDetails')}>
                            Next
                        </Button>
                    </Form>
                </Tab>

                <Tab eventKey="contactDetails" title="Contact Details">
                    <Form>
                        <Form.Group controlId="formPhone">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control type="tel" placeholder="Enter phone number" />
                        </Form.Group>

                        <Form.Group controlId="formJob">
                            <Form.Label>Job Title</Form.Label>
                            <Form.Control type="text" placeholder="Your title" />
                        </Form.Group>

                        
                        <Form.Group controlId="formCompany">
                            <Form.Label>Company</Form.Label>
                            <Form.Control type="text" placeholder="Your company" />
                        </Form.Group>

                        <Form.Group controlId="formcountry">
                            <Form.Label>Country</Form.Label>
                            <Form.Control type="text" placeholder="Country" />
                        </Form.Group>

                        <Form.Group controlId="formState">
                            <Form.Label>State/Province</Form.Label>
                            <Form.Control type="text" placeholder="Enter state or province" />
                        </Form.Group>

                        <Form.Group controlId="formState">
                            <Form.Label>State/Province</Form.Label>
                            <Form.Control type="text" placeholder="Enter state/province" />
                        </Form.Group>

                        <Form.Group controlId="formSpeaker">
                            <Form.Label>Will you be the primary speaker or are you submitting on behalf of someone else?</Form.Label>
                            <Form.Control as="select">
                                <option>Choose...</option>
                                <option>I will be the primary speaker</option>
                                <option>I am submitting on behalf of someone else</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formOther">
                            <Form.Label>Since you are submitting on behalf of someone else, provide the primary speaker details. 
                                Format: Full Name, job title, company
                            </Form.Label>
                            <Form.Control type="text" placeholder="" />
                        </Form.Group>

                        <Form.Group controlId="formFirstTime">
                            <Form.Label>Is this the primary speakers first time presenting at the SDSIC conference?</Form.Label>
                            <Form.Control as="select">
                                <option>Choose...</option>
                                <option>Yes</option>
                                <option>No</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formSpeaker">
                            <Form.Label>Tell us about the primary speaker's experience presenting.</Form.Label>
                            <Form.Control as="select">
                                <option>Choose...</option>
                                <option>New to presenting</option>
                                <option>Presented 1-4 times at the SDSIC</option>
                                <option>Presented 5+ times at the SDSIC</option>
                                <option>Presented at many other conferences</option>
                                <option>I'm unsure</option>
                            </Form.Control>
                        </Form.Group>

                        <Button variant="secondary" onClick={() => setKey('contactDetails')}>
                            Back
                        </Button>
                        <Button variant="primary" onClick={() => setKey('proposalDetails')}>
                            Next
                        </Button>
                    </Form>
                </Tab>

                <Tab eventKey="proposalDetails" title="Proposal Details">
                    <Form>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Proposal title: As you would like it to appear in the schedule.</Form.Label>
                            <Form.Control type="text" placeholder="Enter your proposal Ttitle" />
                        </Form.Group>

                        <Form.Group controlId="formDesc">
                            <Form.Label>Proposal description: As displayed in schedule (Max length 1000 characters).</Form.Label>
                            <Form.Control type="text" placeholder="Write a one or two paragraph abstract describing your proposal idea. No bullets or numbered lists." />
                        </Form.Group>


                        <Form.Group controlId="formType">
                            <Form.Label>Proposal Format</Form.Label>
                            <Form.Control as="select">
                                <option>Choose...</option>
                                <option>Case study</option>
                                <option>Educational / training</option>
                                <option>Hands on session</option>
                                <option>Round table discussion</option>
                                <option>Idea or topic presentation</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formLearn1">
                            <Form.Label>Learning objective 1.</Form.Label>
                            <Form.Control type="text" placeholder="Describe what SDSIC members will learn." />
                        </Form.Group>

                        <Form.Group controlId="formLearn2">
                            <Form.Label>Learning objective 2.</Form.Label>
                            <Form.Control type="text" placeholder="Describe what SDSIC members will learn." />
                        </Form.Group>

                        <Form.Group controlId="formLearn3">
                            <Form.Label>Learning objective 3.</Form.Label>
                            <Form.Control type="text" placeholder="Describe what SDSIC members will learn." />
                        </Form.Group>

                        <Button variant="secondary" onClick={() => setKey('contactDetails')}>
                            Back
                        </Button>
                        <Button variant="primary" onClick={() => setKey('audienceDetails')}>
                            Next
                        </Button>
                    </Form>
                </Tab>


                <Tab eventKey="audienceDetails" title="Audience Details">
                    <Form>
                        <Form.Group controlId="formCommittee">
                            <Form.Label>Choose your target committee track for this proposal</Form.Label>
                            <Form.Control as="select">
                                <option>Choose...</option>
                                <option>Business Strategy</option>
                                <option>Civil</option>
                                <option>Electrical</option>
                                <option>Innovative Solutions</option>
                                <option>Physical</option>
                                <option>Technology</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formCommittee2">
                            <Form.Label>If applicable, what would be your second choice?</Form.Label>
                            <Form.Control as="select">
                                <option>Choose...</option>
                                <option>Business Strategy</option>
                                <option>Civil</option>
                                <option>Electrical</option>
                                <option>Innovative Solutions</option>
                                <option>Physical</option>
                                <option>Technology</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formAudienceRole">
                            <Form.Label>Define your primary audience role?</Form.Label>
                            <Form.Control as="select">
                                <option>Choose...</option>
                                <option>Business leaders</option>
                                <option>Newbie</option>
                                <option>Seasoned design specialist</option>
                                <option>General solution sharing</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formLearn3">
                            <Form.Label>Describe any prerequisite skills or knowledge, including a breif description of who will benefit from attending
                                your session (consider disciplines, job titles, specialities, interests, etc.).</Form.Label>
                            <Form.Control type="text" placeholder="Describe what SDSIC members will learn." />
                        </Form.Group>


                        <Button variant="secondary" onClick={() => setKey('proposalDetails')}>
                            Back
                        </Button>
                        <Button variant="primary" onClick={() => setKey('paymentInformation')}>
                            Next
                        </Button>
                    </Form>
                </Tab>

                <Tab eventKey="paymentInformation" title="Payment Information">
                    <Form>
                        <Form.Group controlId="formCardNumber">
                            <Form.Label>Credit Card Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter credit card number" />
                        </Form.Group>

                        <Form.Group controlId="formExpiration">
                            <Form.Label>Expiration Date</Form.Label>
                            <Form.Control type="text" placeholder="MM/YY" />
                        </Form.Group>

                        <Form.Group controlId="formCVV">
                            <Form.Label>CVV</Form.Label>
                            <Form.Control type="text" placeholder="CVV" />
                        </Form.Group>

                        <Button variant="secondary" onClick={() => setKey('audienceDetails')}>
                            Back
                        </Button>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Tab>
            </Tabs>
        </Container>
    )

}


export default Proposals;