export const create = (userId, token, forumpostdata) => {
    return fetch(`${process.env.REACT_APP_API_URL}/forumpost/new/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: forumpostdata
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


// with pagination
export const list = page => {
    return fetch(`${process.env.REACT_APP_API_URL}/forumposts/?page=${page}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// with pagination
export const listByGroup = (groupId, page) => {
    return fetch(`${process.env.REACT_APP_API_URL}/forumpostsbygroup/${groupId}?page=${page}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const singleForumPost = forumpostId => {
    return fetch(`${process.env.REACT_APP_API_URL}/forumpost/${forumpostId}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};



export const listByUser = (userId, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/forumposts/by/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getGroupLatestPost = (groupId) => {
    return fetch(`${process.env.REACT_APP_API_URL}/latestforumpostsbygroup/${groupId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};



export const getChairsLatestMessage = (userId) => {
    return fetch(`${process.env.REACT_APP_API_URL}/onetopforumposts/by/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const remove = (forumpostId, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/forumpost/${forumpostId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const update = (forumpostId, token, forumpost) => {
    console.log(forumpost)
    return fetch(`${process.env.REACT_APP_API_URL}/forumpost/${forumpostId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: forumpost
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const like = (userId, token, forumpostId) => {
    return fetch(`${process.env.REACT_APP_API_URL}/forumpost/like`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ userId, forumpostId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const unlike = (userId, token, forumpostId) => {
    return fetch(`${process.env.REACT_APP_API_URL}/forumpost/unlike`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ userId, forumpostId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const comment = (userId, token, forumpostId, comment) => {
    return fetch(`${process.env.REACT_APP_API_URL}/forumpost/comment`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ userId, forumpostId, comment })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const uncomment = (userId, token, forumpostId, comment) => {
    return fetch(`${process.env.REACT_APP_API_URL}/forumpost/uncomment`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ userId, forumpostId, comment })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

