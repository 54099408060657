import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,  
  DayView,
  WeekView,
  ViewSwitcher,
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  MonthView,
  EditRecurrenceMenu,
  AllDayPanel,
  ConfirmationDialog,
  Toolbar,
  DateNavigator,
  TodayButton
} from '@devexpress/dx-react-scheduler-material-ui';

import { getAllEvents, addEventForGroup, updateEvent, createNewEvent, removeEvent } from './apiEvents';
import { isAuthenticated } from "../auth";
import { v4 as uuidv4 } from 'uuid';

import { appointments } from './appointments';
var month = new Date().getMonth() + 1; //Current Month
var year = new Date().getFullYear(); //Current Year
var date = new Date().getDate(); //Current Date


export default class Demo extends React.PureComponent {

  loadEvents() {
    getAllEvents(this.state.token).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({ data: data });
        this.setState({ totalEvents: data.length })
        console.log('total events:' + data.length)
      }
    });
  };

  addEvent() {
    addEventForGroup(this.state.groupId, this.state.token, this.eventData).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        console.log(data);       
      }
    });
  };

  token = isAuthenticated() && isAuthenticated().token;

  constructor(props) {
    super(props);
    this.state = {
      totalEvents: 0,
      eventData: {},
      data: appointments,
      currentDate: year + "-" + month + "-" + date,
      addedAppointment: {},
      appointmentChanges: {},
      editingAppointmentId: undefined,
      groupId: this.props.groupId,
      userId: isAuthenticated() && isAuthenticated().userId,
      username: isAuthenticated() && isAuthenticated().name
    };
    this.currentDateChange = (currentDate) => { this.setState({ currentDate }); };
    this.commitChanges = this.commitChanges.bind(this);
    this.changeAddedAppointment = this.changeAddedAppointment.bind(this);
    this.changeAppointmentChanges = this.changeAppointmentChanges.bind(this);
    this.changeEditingAppointmentId = this.changeEditingAppointmentId.bind(this);
    this.loadEvents = this.loadEvents.bind(this);
  }

  componentDidMount() {
    this.loadEvents()
  };

  changeAddedAppointment(addedAppointment) {
    this.setState({ addedAppointment });
  }

  
  changeAppointmentChanges(appointmentChanges) {
    this.setState({ appointmentChanges });      
  }

  changeEditingAppointmentId(editingAppointmentId) {
    this.setState({ editingAppointmentId });           
  }



  commitChanges({ added, changed, deleted }) {
    this.setState((state) => {
      let { data } = state;
      if (added) {
        const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
        data = [...data, { id: startingAddedId, ...added }];
        const newEventId = uuidv4() // random ID for active Calendar work only.
        createNewEvent(this.state.token, this.state.groupId, this.state.userId, newEventId, added)
      }
      if (changed) {
        data = data.map(appointment => (
          changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment)          
        );  
        // Break down ONLY the changed events
        const edited = data.map(appointment => (
          changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment[appointment.id])          
        );       
        // loop through changed events and get only the ones that modeified.
        for (let i = 1; i < edited.length; i++) {
          if(edited[i] !== undefined){
            updateEvent(this.state.groupId, this.state.userId, edited[i])
          }
        }
      }
      if (deleted !== undefined) {
        data = data.filter(appointment => appointment.id !== deleted);        

        removeEvent(this.state.token, deleted)
      }
      return { data };
    });
  }

  render() {
    const {
      currentDate, data, addedAppointment, appointmentChanges, editingAppointmentId,
    } = this.state;

    return (
      <Paper>
        <Scheduler
          data={data}
          
        >
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={this.currentDateChange}
            defaultCurrentViewName="Month"
          />
          <DayView
            startDayHour={9}
            endDayHour={18}
          />
          <WeekView
            startDayHour={10}
            endDayHour={19}
          />
          <EditingState
            onCommitChanges={this.commitChanges}

            // addedAppointment={addedAppointment}
            // onAddedAppointmentChange={this.changeAddedAppointment}

            // appointmentChanges={appointmentChanges}
            // onAppointmentChangesChange={this.changeAppointmentChanges}

            // editingAppointmentId={editingAppointmentId}
            // onEditingAppointmentIdChange={this.changeEditingAppointmentId}
          />
          <MonthView
            startDayHour={9}
            endDayHour={17}
          />
          <Toolbar />
          <ViewSwitcher />
          <Appointments />
          <DateNavigator />
          <TodayButton />
          <AllDayPanel />
          <EditRecurrenceMenu />
          <ConfirmationDialog />
          <Appointments />
          <AppointmentTooltip
          showOpenButton
          showCloseButton
        />
        <AppointmentForm 
          readOnly
        />
        </Scheduler>
      </Paper>
    );
  }
}
