import React, { Component } from 'react'
import { createDownloadLink } from './apiFiles';
import { isAuthenticated } from '../auth';

class SecureLink extends Component {

    constructor(props){
        super(props)
        this.state ={
            fileLink: ''
        }
        this.createSecureLink = this.createSecureLink.bind(this);
    }
    token = isAuthenticated() && isAuthenticated().token;

    componentDidMount() {
        this.createSecureLink()
    }

    createSecureLink() {
        const fileInfo= {
            fileName: this.props.file.fileName,
            awsVersion: this.props.file.awsVersion
        }        
        
        createDownloadLink(this.token, fileInfo).then(data => {
                    if (data.error) {
                        console.log(data.error);
                    } else {
                        //console.log(data);
                        //return data
                        this.setState({ ...this.state, fileLink: data });                        
                    }
                });
        
    };

    render() {
        return (
            <div>            
                <a href={`${this.state.fileLink}`} title="Click to Download." >{this.props.file.title}</a>
            </div>
                
        )
    }
};

export default SecureLink;
