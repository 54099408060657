import React, { Component } from "react";
import { getEventById, getPresentationsByEvent } from "./apiEvents";
import { isAuthenticated } from "../auth";
import { Container, Row, Col } from 'react-bootstrap';
import Moment from 'moment';
import { getSchedule } from './apiEvents';
// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'; 
import { Table } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { ArrowDown } from 'react-bootstrap-icons';
import { ArrowUp } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class ShowCaseEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            event: '',
            schedule: [],
            presentations: [],
            eventId: this.props.match.params.eventId
        };
    }

    componentWillMount() {
        this.setState({ eventId: this.props.match.params.eventId })

        // this.setState({ userId: isAuthenticated().userId})

        getEventById(this.state.eventId, isAuthenticated() && isAuthenticated().token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {

                this.setState({ event: data[0] });
            }
        });

        getPresentationsByEvent(this.state.eventId, "title", "asc", isAuthenticated() && isAuthenticated().token).then(presentations => {
            if (presentations.error) {
                console.log(presentations.error);
            } else {
                this.setState({ presentations: presentations });
            }
        });

        getSchedule('Spring 2021', isAuthenticated() && isAuthenticated().token).then(scheduleData => {
            if (scheduleData.error) {
                console.log(scheduleData.error);
            } else {
                this.setState({ schedule: scheduleData });
            }
        });
    }

    getCaret(direction) {
        if (direction === 'asc') {
            return (
                <span> [up]</span>
            );
        }
        if (direction === 'desc') {
            return (
                <span> [down]</span>
            );
        }
        return (
            <span> [up/down]</span>
        );
    }


    formatLinkField(presId) {
        return (
            <a href={`/presentation/${presId}`} className="btn btn-outline-primary" target="_blank" rel="noopener noreferrer">View Presentation</a>
        )
    }

    sortPresentations(eventId, sortField, sortDirection) {

        getPresentationsByEvent(eventId, sortField, sortDirection, isAuthenticated() && isAuthenticated().token).then(presentations => {
            if (presentations.error) {
                console.log(presentations.error);
            } else {
                this.setState({ presentations: presentations });
            }
        });
    };

    render() {
        const { event, presentations } = this.state;

        return (
            <Container>
                <Row>
                    <div className="jumbotron p-3 p-md-5 text-white" style={{ width: "100%", height: "60%", background: "#245aa6" }}>
                        <div className="col-md-12 px-0">
                            <h1 className="display-4 font-weight-normal">{event.title}</h1>
                            <p className="lead my-3 font-weight-normal">This was a <b>{event.type}</b></p>
                            <p className="lead my-3 font-weight-normal">There were <b>{event.attendance}</b> attendees.</p>
                            <p className="lead my-3 font-weight-normal">Meetings started on <b>{Moment(event.startDate).format('MMMM Do YYYY')}</b></p>
                        </div>
                    </div>
                </Row>

                {(presentations.length === 0) && <Row>
                    <Col>
                        <h2 className="mt-5 mb-5">Content is not yet available</h2>
                        <h4><a href={`/grouplist`}>Back to Committees</a></h4>
                    </Col>
                </Row>}
                {(presentations.length > 0) && <Row>
                    <Col><h2 className="mt-5 mb-5">Here's a list of Presentations done during this event</h2></Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>
                                    <Container>
                                        <Row>
                                            <Col sm="4" md="4" xl lg="8"><h5> Title </h5></Col>
                                            <Col sm="2" md="2" xl="2" lg="2">
                                                <OverlayTrigger placement="top" overlay={<Tooltip>sort ascending</Tooltip>}>
                                                    <Button onClick={() => this.sortPresentations(event._id, "title", "desc")} variant="primary" size="sm">
                                                        <ArrowUp />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col sm="2" md="2" xl="2" lg="2">
                                                <OverlayTrigger placement="top" overlay={<Tooltip>sort descending</Tooltip>}>
                                                    <Button onClick={() => this.sortPresentations(event._id, "title", "asc")} variant="primary" size="sm">
                                                        <ArrowDown />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    </Container>
                                </th>
                                <th>
                                    <Container>
                                        <Row>
                                            <Col sm="4" md="4" xl lg="8"><h5> Description </h5></Col>
                                            <Col sm="2" md="2" xl="2" lg="2">
                                                <OverlayTrigger placement="top" overlay={<Tooltip>sort ascending</Tooltip>}>
                                                    <Button onClick={() => this.sortPresentations(event._id, "description", "desc")} variant="primary" size="sm">
                                                        <ArrowUp />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col sm="2" md="2" xl="2" lg="2">
                                                <OverlayTrigger placement="top" overlay={<Tooltip>sort descending</Tooltip>}>
                                                    <Button onClick={() => this.sortPresentations(event._id, "description", "asc")} variant="primary" size="sm">
                                                        <ArrowDown />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    </Container>

                                </th>
                                <th>
                                    <Container>
                                        <Row>
                                            <Col sm="4" md="4" xl lg="8"><h5> Committee </h5></Col>
                                            <Col sm="2" md="2" xl="2" lg="2">
                                                <OverlayTrigger placement="top" overlay={<Tooltip>sort ascending</Tooltip>}>
                                                    <Button onClick={() => this.sortPresentations(event._id, "committeeName", "desc")} variant="primary" size="sm">
                                                        <ArrowUp />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col sm="2" md="2" xl="2" lg="2">
                                                <OverlayTrigger placement="top" overlay={<Tooltip>sort descending</Tooltip>}>
                                                    <Button onClick={() => this.sortPresentations(event._id, "committeeName", "asc")} variant="primary" size="sm">
                                                        <ArrowDown />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    </Container>


                                </th>
                                <th>
                                    <Container>
                                        <Row>
                                            <Col sm="3" md="3" xl="4" lg="4"><h5> Date </h5></Col>
                                            <Col sm="2" md="2" xl="3" lg="3">
                                                <OverlayTrigger placement="top" overlay={<Tooltip>sort ascending</Tooltip>}>
                                                    <Button onClick={() => this.sortPresentations(event._id, "datePresented", "desc")} variant="primary" size="sm">
                                                        <ArrowUp />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col sm="2" md="2" xl="3" lg="3">
                                                <OverlayTrigger placement="top" overlay={<Tooltip>sort descending</Tooltip>}>
                                                    <Button onClick={() => this.sortPresentations(event._id, "datePresented", "asc")} variant="primary" size="sm">
                                                        <ArrowDown />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    </Container>


                                </th>
                                <th><h5>Link</h5>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {presentations.map((presentation, i) => {
                                return (
                                    <tr>
                                        <td key={i}>{presentation.title}</td>
                                        <td key={i + 1000}>{presentation.description}</td>
                                        <td key={i + 2000}> {presentation.committeeName}</td>
                                        <td key={i + 3}> {presentation.datePresented}</td>
                                        <td key={i + 4}>{this.formatLinkField(presentation._id)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>

                    </Table>


                </Row>}
            </Container>

        );
    }
}

export default ShowCaseEvent;
