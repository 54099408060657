import React, { Component } from "react";
import { Form, Accordion, Card, Button, Tabs, Tab, Container, Breadcrumb } from 'react-bootstrap';
import { isAuthenticated } from "../auth";
import ForumPosts from "../forum/ForumPosts";
import EventLists from "../event/EventList";
import FileList from "../file/FileList";
import {
    singleGroup, addUsertoGroup, removeUserFromGroup,
    sendMessage, loadSurveyData, groupDetails
} from "../group/apigroup";
import { Link } from "react-router-dom";
import { list } from "../user/apiUser"
import Select from 'react-select'
// import ScheduleComp from "../event/ScheduleComp";
import { Redirect } from "react-router-dom";
import moment from "moment";
import momentTZ from 'moment-timezone'
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import EventCalendar from "../event/EventCalendar";
import Footer from '../core/Footer';
import Table from 'react-bootstrap/Table';
import ButtonMaterial from '@mui/material/Button';
import Alert from 'react-bootstrap/Alert';

// import SurveyData from './surveys_fall2021.json';

class AdminGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groupId: this.props.match.params.groupId,
            currentUserId: isAuthenticated().userId,
            currentUserName: isAuthenticated().name,
            token: isAuthenticated().token,
            groupChairId: '',
            groupCoChairId: '',
            group: '',
            mainResourceName: 'general',
            eventResourceName: 'general',
            calendarName: 'General',
            redirectToHome: false,
            redirectToSignin: false,
            chair: '',
            cochair: '',
            members: [],
            allUsers: [],
            userToAdd: '',
            groupMessage: '',
            groupName: '',
            groupMessageSubject: '',
            editorState: EditorState.createEmpty(),
            emailSuccess: false,
            SurveyData: [],
            removeMember: true,
            userTorRemove: '',
            memberAdded: false,
            memberRemoved: false,
        };
        this.verifyAdmin = this.verifyAdmin.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }


    componentWillMount = () => {
        // var tz = momentTZ.tz.guess()
        // var a = momentTZ.utc(moment.now()).tz(tz);
        // var b = momentTZ.utc(a).tz("America/Toronto");


        // alert(b.format("h"))

        singleGroup(this.props.match.params.groupId).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {

                this.setState({
                    group: data
                });
            }
        });



        groupDetails(this.props.match.params.groupId).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({
                    chair: data.chair.name + " " + data.chair.lastName,
                    groupChairId: data.chair._id,
                    groupCoChairId: data.cochair._id,
                    cochair: data.cochair.name,
                    members: data.members,
                    groupName: data.name
                })
            }

        });


        list(this.state.token).then(users => {
            if (users.error) {
                console.log(users.error);
            } else {
                this.setState({
                    allUsers: users
                })
            }
        })

        if (this.props.match.params.groupId == '5e5591a97ff73f6d34835a05') { this.setState({ mainResourceName: 'General Committee', calendarName: 'General' }) }
        if (this.props.match.params.groupId == '5e2a5e764175b97d84ec6cae') { this.setState({ mainResourceName: 'Physical Committee', calendarName: 'Physical' }) }
        if (this.props.match.params.groupId == '5e44050f4e6ed37ab467d0f2') { this.setState({ mainResourceName: 'Electrical Committee', calendarName: 'Electrical' }) }
        if (this.props.match.params.groupId == '5e44052e4e6ed37ab467d0f3') { this.setState({ mainResourceName: 'Technology Committee', calendarName: 'Technology' }) }
        if (this.props.match.params.groupId == '5e4405864e6ed37ab467d0f4') { this.setState({ mainResourceName: 'Civil Committee', calendarName: 'Civil' }) }
        if (this.props.match.params.groupId == '5e4406454e6ed37ab467d0f5') { this.setState({ mainResourceName: 'Business Strategy Committee', calendarName: 'Business' }) }
        if (this.props.match.params.groupId == '639242d4971a30726884fd85') { this.setState({ mainResourceName: 'Innovation Commitee', calendarName: 'Innovation' }) }

        loadSurveyData(this.state.token).then(surveyResults => {
            if (surveyResults.error) {
                console.log(surveyResults.error);
            } else {
                this.setState({
                    SurveyData: surveyResults
                })
            }
        })

        this.setState({ eventResourceName: 'General Sessions' })
    }

    handleChange = name => event => {
        let value = "";
        if (name === 'userToAdd') {
            value = event.value
        } else {
            value = event.target.value
        }
        this.setState({ [name]: value });
    };

    onChange = (editorState) => this.setState({ editorState });


    addedMessage() {
        return (
            <div className="alert alert-info" style={{ display: this.state.memberAdded ? "" : "none" }}>
                Member was added successfully.
            </div>
        )
    };

    removedMessage() {
        return (
            <div className="alert alert-info" style={{ display: this.state.memberRemoved ? "" : "none" }}>
                Member was removed successfully.
            </div>
        )
    };



    addUser = e => {
        e.preventDefault();
        //alert(this.state.userToAdd)
        const token = isAuthenticated().token;
        if (this.state.userToAdd) {
            addUsertoGroup(token, this.props.match.params.groupId, this.state.userToAdd).then(data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    this.setState({
                        userToAdd: '',
                        memberAdded: true,
                        memberRemoved: false
                        // redirectToHome: true
                    });
                    singleGroup(this.props.match.params.groupId).then(data => {
                        if (data.error) {
                            console.log(data.error);
                        } else {

                            this.setState({
                                group: data
                            });
                        }
                    });

                    groupDetails(this.props.match.params.groupId).then(data => {
                        if (data.error) {
                            console.log(data.error);
                        } else {
                            this.setState({
                                chair: data.chair.name + " " + data.chair.lastName,
                                groupChairId: data.chair._id,
                                groupCoChairId: data.cochair._id,
                                cochair: data.cochair.name,
                                members: data.members,
                                groupName: data.name
                            })
                        }

                    });
                }
            });
        }

    };

    sendGroupMessage = (e) => {
        e.preventDefault();

        let userEmailList = []

        const rawState = stateToHTML(this.state.editorState.getCurrentContent());

        this.state.members.map((c, i) => (
            userEmailList.push({ "name": `${c.name}`, "email": `${c.email}` })
        ))

        // test: userEmailList.push({"name": "Automationforce", "email":"automationforcescullion@gmail.com"})

        userEmailList.forEach((user) => {
            const mailMessage = {
                "subject": "SDSIC Message From " + this.state.groupName + ': ' + this.state.groupMessageSubject,
                "sender": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
                "replyTo": { "email": "donotreply@sdsconsortium.com", "name": "sdsconsortium.com" },
                "to": [{ "name": user.name, "email": user.email }],
                // "to": [{ "name": isAuthenticated().email, "email": isAuthenticated().email }],
                // "bcc": userEmailList,
                "htmlContent": `<html><body><h4>Group Message from the ${this.state.groupName},</h4>${rawState}</body></html>`
            }
            // console.log(mailMessage)
            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                this.setState({
                    groupMessage: '',
                    emailSuccess: true,
                    groupMessageSubject: '',
                    editorState: EditorState.createEmpty()
                });
            });
        });
    };

    emptyData() {

        if (this.state.SurveyData.length === 0) {
            return (
                <div className="alert alert-info">
                    There is no survey data yet!
                </div>
            )
        }
    }

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.emailSuccess ? "" : "none" }}>
                Email sent successfully!
            </div>
        )
    };

    calendarTypeChange = name => event => {

        this.setState({ calendarName: event.target.value });
    };

    formatResponse(question, id, label, ans) {

        if (label === '') { label = ' ______ ' }
        switch (question) {
            case 'question1':
                return (<p>Is your company currently approving corporate travel for 2022? <b>{label}</b></p>)
            case 'question2':
                return (<p>Are you expecting that your company will approve corporate travel to Kansas City for the 2022 meeting of the SDSIC? <b>{label}</b></p>)
            case 'question3':
                return (<p>If approved, do you plan to attend the meeting in May? <b>{label}</b></p>)
            case 'question4':
                return (
                    <>
                        <p>
                            The meeting will be held Tuesday, May 3rd thru Thursday May 5th. At past events we included a ½ day session on Monday, to introduce new members to the SDSIC suite of tools.
                        </p>
                        <p>
                            Is this new member session something you have found to be beneficial in the past? <b>{label}</b>
                        </p>
                    </>)
            case 'question5':
                return (<><p>If this session is held Monday afternoon, would it be something you will attend? <b>{label}</b></p></>)
            case 'question6':
                return (<p>How many meetings of the SDSIC have you personally attended? <b>{ans.question6}</b></p>)
            case 'question7':
                return (<p>What would you most like to see included in the event. For example, project spotlights, how-to’s, round table discussions <b>{ans.question7}</b></p>)
            case 'question8':
                return (<p>Will you be using the SDSIC meeting suggested accommodations or book thru your corporate travel desk? <b>{ans.question8}</b></p>)

        }

    };

    removeUser() {
        const token = isAuthenticated().token;

        removeUserFromGroup(token, this.state.groupId, this.state.userTorRemove).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({
                    removeMember: true,
                    memberId: '',
                    memberAdded: false,
                    memberRemoved: true
                });
                singleGroup(this.props.match.params.groupId).then(data => {
                    if (data.error) {
                        console.log(data.error);
                    } else {

                        this.setState({
                            group: data
                        });
                    }
                });

                groupDetails(this.props.match.params.groupId).then(data => {
                    if (data.error) {
                        console.log(data.error);
                    } else {
                        this.setState({
                            chair: data.chair.name + " " + data.chair.lastName,
                            groupChairId: data.chair._id,
                            groupCoChairId: data.cochair._id,
                            cochair: data.cochair.name,
                            members: data.members,
                            groupName: data.name
                        })
                    }

                });
            }
        });

    };

    handleMemberChange(memberId) {
        this.setState({
            removeMember: false,
            userTorRemove: memberId,
            memberAdded: false,
            memberRemoved: false
        })
    };


    verifyAdmin(chairId, cochairId, userId, chair, cochair, members, users) {
        // 5e61524763f778901ce39fc6 <- Audrey's UserId
        // 5e2611483f99fa3bb4dd922a <- Trevor's UserId

        // alert(cochairId + " : " + userId)
        if (chairId === userId || cochairId === userId || userId === '5e2611483f99fa3bb4dd922a' ||
            userId === '5e2611483f99fa3bb4dd922a' || userId === '5e61524763f778901ce39fc6') {
            // the current user is indeed the admin of this committee

            return (
                <Container fluid>

                    <div className="jumbotron p-3 p-md-5 text-white rounded" style={{ background: "#245aa6" }}>
                        <div className="row">
                            <h4 className="display-5 font-italic">Administration Page for the {this.state.groupName}</h4>
                        </div>
                        <div className="row">
                            <p>All modifications and/or communications on this page will be live for all members listed below
                                you are currently logged in as: {this.state.currentUserName}</p>
                            <p>current chair is: {chair}</p>

                        </div>

                    </div>

                    <Breadcrumb>
                        <Breadcrumb.Item href="/usershome">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href={`/singlegroup/${this.props.match.params.groupId}`}>
                            back to committee
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>group admin</Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="col-md-12">
                        <Tabs defaultActiveKey="virtual" id="uncontrolled-tab-example">
                            <Tab eventKey="virtual" title="Meeting Calendar admin">
                                <br />

                                {/* <div className="Row">
                                        <div className="col-md-6">
                                            <h5>Meeting Calendar admin</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <Form>
                                                <Form.Group controlId="exampleForm.SelectCustom">
                                                    <Form.Label>Choose a calendar to admininster</Form.Label>
                                                    <Form.Control as="select" custom onChange={this.calendarTypeChange()}>
                                                        <option value={this.state.calendarName}>Monthly Meetings</option>
                                                        <option value="Fall 2021">Fall 2021 Virtual Event</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div> */}
                                <div className="Row">
                                    <h5>{this.state.group.name} meeting calendar</h5>
                                </div>
                                <div className="Row">
                                    <br />
                                    <EventCalendar
                                        scheduleName={this.state.calendarName}
                                        isAdmin={true}
                                        chooseTracks={false}
                                        chooseDays={false}
                                        currentDate={moment().format("YYYY-MM-DD")}
                                        excludedDays={[0, 6, 7]}
                                        weekView={false}
                                        resourceList={this.getresourceList()}
                                        mainResourceName={this.state.mainResourceName}
                                    />
                                </div>


                            </Tab>
                            {/* <Tab eventKey="fall" title="Fall 2021 Virtual Event">
                                <div className="Row">
                                    <h5>{this.state.group.name} meeting calendar</h5>
                                </div>
                                <p><i>Note: The calendar automatically adjusts to your local time.</i></p>
                                <div>
                                    <EventCalendar
                                        scheduleName={'Fall 2021 ' + this.state.calendarName}
                                        isAdmin={true}
                                        chooseTracks={true}
                                        chooseDays={true}
                                        currentDate={'2021-11-02'}
                                        excludedDays={[0, 1, 5, 6, 7]}
                                        weekView={true}
                                        resourceList={this.getresourceList()}
                                        mainResourceName={this.state.mainResourceName}
                                        calendarHeight={1000}
                                        startTime={7}
                                        endTime={22}
                                    />
                                </div>
                            </Tab> */}


                            <Tab eventKey="members" title="Members Admin">

                                <div className="row">
                                    <div className="col-md-6">
                                        <p>{members.length} Current Members</p>
                                        <Form.Control onChange={e => {
                                            this.handleMemberChange(e.target.value)
                                        }}
                                            as="select"
                                            multiple style={{ width: "400px", height: "160" }}>
                                            {members.map(d =>
                                                <option key={d._id} value={d._id}>{d.name}: email: {d.email}</option>
                                            )}
                                        </Form.Control>
                                        <br />
                                        <ButtonMaterial onClick={this.removeUser} variant="outlined" disabled={this.state.removeMember} >Remove Selected Member</ButtonMaterial>
                                    </div>
                                    <div className="col-md-6">
                                        <p>There are {users.length} active SDSIC Members</p>
                                        <form onSubmit={this.addUser}>
                                            <label htmlFor="users" className="text-muted">Search for Users to add:</label>
                                            <Select
                                                name="userToAdd"
                                                options={users.map((c, i) => (
                                                    {
                                                        value: c._id,
                                                        label: c.email
                                                    }
                                                ))}
                                                onChange={this.handleChange("userToAdd")}
                                                isSearchable={true}
                                                placeholder={'type a user name to search'}
                                            />
                                            <br />
                                            <button className="btn btn-outline-primary">Add this User to your committee</button>
                                        </form>
                                        <br />
                                        <br />
                                    </div>

                                </div>
                            </Tab>


                            <Tab eventKey="message" title="Group Message">

                                <div className="col-md-12">
                                    <h4>send email communication to all members in this committee</h4>
                                    {this.showSuccess()}
                                    <form role="form" onSubmit={this.sendGroupMessage}>
                                        <div className="form-group">
                                            <label htmlFor="exampleSubject">
                                                Subject:
                                            </label>
                                            <input type="text" value={this.state.groupMessageSubject} onChange={this.handleChange('groupMessageSubject')} className="form-control" id="subject" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlTextarea1">Message:</label>
                                            {/* <div contentEditable='true' className="form-control" dangerouslySetInnerHTML={{ __html: this.state.groupMessage }} name='message' value={this.state.groupMessage} style={{ whiteSpace: 'pre-wrap' }} onChange={this.handleChange('groupMessage')}> */}
                                            {/* <textarea className="form-control" value={this.state.groupMessage} name='message' onChange={this.handleChange('groupMessage')} rows="4" cols="50"></textarea> */}
                                            <Editor
                                                editorState={this.state.editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="editer-content"
                                                onEditorStateChange={this.onChange}
                                                wrapperStyle={{ border: "2px solid blue", marginBottom: "10px" }}
                                                editorStyle={{ height: "300px", padding: "10px" }}
                                            />
                                            {/* </div> */}
                                        </div>
                                        {/* <div className="form-group">

                                    <label for="exampleInputFile">
                                        attach a file:
					            </label>
                                    <input type="file" className="form-control-file" id="exampleInputFile" />
                                </div> */}
                                        {this.showSuccess()}
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </form>
                                </div>
                            </Tab>

                            {/* <Tab eventKey="userReport" title="User Report">
                                <div className="col-md-12">
                                    <h4>SDSIC User Report</h4>
                                    <iframe width="100%" height="600" src="https://app.powerbi.com/view?r=eyJrIjoiZmE1ZTY1YTktZDdhNi00YjE0LTk2OTktOTk2NTJmZGI5Mjg3IiwidCI6ImI4ZWM1NWM3LWNiZjctNDlkNC1iZjE5LTViNzc5Y2YxYmVkZSJ9" frameborder="0" allowFullScreen="true"></iframe>
                                </div>
                            </Tab> */}
                            {/* <Tab eventKey="events" title="Virtual Events">
                                <div className="row">

                                    <div className="col-md-6">
                                        <br />
                                        <br />
                                        <Link
                                            to={`/eventdemo`}
                                            className="btn btn-raised btn-warning btn-sm mr-5"
                                        >
                                            Administer the Spring 2021 Virtual Event Calendar
                                    </Link>
                                    </div>
                                </div>
                            </Tab> */}
                            {/* <Tab eventKey="surveys" title="Surveys">
                                <div className="row">

                                    <div className="col-md-10">
                                        <br />
                                        <h3>Survey for 2022 event planning</h3>
                                        <br />
                                        {this.emptyData()}
                                        {this.state.SurveyData.map((survey, i) => (
                                            <>
                                                <Card>
                                                    <Card.Header>
                                                        <h5>Responses Submitted by: <u>{survey.submittedByemail}</u></h5>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        {survey.answers.map((answer, n) => (
                                                            <div key={n}>
                                                                <ol>
                                                                    {answer.map((ans, p) => (
                                                                        <>

                                                                            <ul key={p}>
                                                                                <li>
                                                                                    {this.formatResponse(ans.responseTo, ans.id, ans.label, ans)}
                                                                                </li>
                                                                            </ul>
                                                                        </>
                                                                    ))}
                                                                </ol>
                                                            </div>
                                                        ))}
                                                    </Card.Body>
                                                </Card>
                                                <br />
                                                <br />
                                            </>
                                        ))}

                                    </div>
                                </div>
                            </Tab> */}
                        </Tabs>
                    </div>
                </Container>

            )
        } else {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <p>Sorry {this.state.currentUserName}, you cannot administer the {this.state.group.name}</p>
                            <p>Current userId is: {this.state.currentUserId}</p>
                        </div>

                    </div>
                </div>
            )
        }
    };



    getresourceList() {
        switch (this.props.match.params.groupId) {
            case '5e2a5e764175b97d84ec6cae':
                return (
                    [
                        {
                            fieldName: 'physical',
                            title: 'Physical Committee',
                            allowMultiple: true,
                            instances: [
                                { id: 1, text: 'Committee Update', color: '#993399' },
                                { id: 2, text: 'Monthly Meeting', color: '#993399' },
                                { id: 3, text: 'Committee Presentation', color: '#993399' },
                                { id: 4, text: 'Vendor Presentation', color: '#993399' },
                                { id: 5, text: 'Committee Round Table', color: '#993399' },
                                { id: 6, text: 'User Presentation', color: '#993399' },
                                { id: 7, text: 'Best Practice Discussion', color: '#993399' },
                                { id: 8, text: 'Committee Closing Session', color: '#993399' },
                                { id: 9, text: 'IACET Presentation', color: '#993399' }
                            ]
                        }
                    ]
                )
            case '5e44050f4e6ed37ab467d0f2':
                return (
                    [
                        {
                            fieldName: 'electrical',
                            title: 'Electrical Committee',
                            allowMultiple: true,
                            instances: [
                                { id: 1, text: 'Committee Update', color: '#f44336' },
                                { id: 2, text: 'Monthly Meeting', color: '#f44336' },
                                { id: 3, text: 'Committee Presentation', color: '#f44336' },
                                { id: 4, text: 'Vendor Presentation', color: '#f44336' },
                                { id: 5, text: 'Committee Round Table', color: '#f44336' },
                                { id: 6, text: 'User Presentation', color: '#f44336' },
                                { id: 7, text: 'Best Practice Discussion', color: '#f44336' },
                                { id: 8, text: 'Committee Closing Session', color: '#f44336' },
                                { id: 9, text: 'IACET Presentation', color: '#f44336' }
                            ],
                        }
                    ]
                )
            case '5e44052e4e6ed37ab467d0f3':

                return (
                    [
                        {
                            fieldName: 'technology',
                            title: 'Technology Committee',
                            allowMultiple: true,
                            instances: [
                                { id: 1, text: 'Committee Update', color: '#339966' },
                                { id: 2, text: 'Monthly Meeting', color: '#339966' },
                                { id: 3, text: 'Committee Presentation', color: '#339966' },
                                { id: 4, text: 'Vendor Presentation', color: '#339966' },
                                { id: 5, text: 'Committee Round Table', color: '#339966' },
                                { id: 6, text: 'User Presentation', color: '#339966' },
                                { id: 7, text: 'Best Practice Discussion', color: '#339966' },
                                { id: 8, text: 'Committee Closing Session', color: '#339966' },
                                { id: 9, text: 'IACET Presentation', color: '#339966' }
                            ],
                        }
                    ]
                )
            case '5e4405864e6ed37ab467d0f4':
                return (
                    [
                        {
                            fieldName: 'civil',
                            title: 'Civil Committee',
                            allowMultiple: true,
                            instances: [
                                { id: 1, text: 'Committee Update', color: '#795548' },
                                { id: 2, text: 'Monthly Meeting', color: '#795548' },
                                { id: 3, text: 'Committee Presentation', color: '#795548' },
                                { id: 4, text: 'Vendor Presentation', color: '#795548' },
                                { id: 5, text: 'Committee Round Table', color: '#795548' },
                                { id: 6, text: 'User Presentation', color: '#795548' },
                                { id: 7, text: 'Best Practice Discussion', color: '#795548' },
                                { id: 8, text: 'Committee Closing Session', color: '#795548' },
                                { id: 9, text: 'IACET Presentation', color: '#795548' }
                            ],
                        }
                    ]
                )
            case '5e4406454e6ed37ab467d0f5':
                return (
                    [
                        {
                            fieldName: 'bizstrategy',
                            title: 'Business Strategy Committee',
                            allowMultiple: true,
                            instances: [
                                { id: 1, text: 'Committee Update', color: '#000099' },
                                { id: 2, text: 'Monthly Meeting', color: '#000099' },
                                { id: 3, text: 'Committee Presentation', color: '#000099' },
                                { id: 4, text: 'Vendor Presentation', color: '#000099' },
                                { id: 5, text: 'Committee Round Table', color: '#000099' },
                                { id: 6, text: 'User Presentation', color: '#000099' },
                                { id: 7, text: 'Best Practice Discussion', color: '#000099' },
                                { id: 8, text: 'Committee Closing Session', color: '#000099' },
                                { id: 9, text: 'IACET Presentation', color: '#000099' }
                            ],
                        }
                    ]
                )
            default:

                return (
                    [
                        {
                            fieldName: 'general',
                            title: 'General Sessions',
                            allowMultiple: true,
                            instances: [
                                { id: 1, text: 'Committee Update', color: '#4caf50' },
                                { id: 2, text: 'Monthly Meeting', color: '#4caf50' },
                                { id: 3, text: 'Committee Presentation', color: '#4caf50' },
                                { id: 4, text: 'Vendor Presentation', color: '#4caf50' },
                                { id: 5, text: 'Committee Round Table', color: '#4caf50' },
                                { id: 6, text: 'User Presentation', color: '#4caf50' },
                                { id: 7, text: 'Best Practice Discussion', color: '#4caf50' },
                                { id: 8, text: 'Committee Closing Session', color: '#4caf50' },
                                { id: 9, text: 'IACET Presentation', color: '#4caf50' }

                            ],
                        }
                    ]
                )
        };
    };


    render() {

        const userName = isAuthenticated() && isAuthenticated().name;

        if (this.state.redirectToHome) {
            return <Redirect to={`/singlegroup/${this.props.match.params.groupId}`} />;
        }
        return (


            <>

                <Container fluid>
                    {this.verifyAdmin(this.state.groupChairId, this.state.groupCoChairId, this.state.currentUserId, this.state.chair, this.state.cochair, this.state.members, this.state.allUsers)}
                    {this.addedMessage()}
                    {this.removedMessage()}
                </Container>
                <Container fluid>
                    <br />
                    <Footer />
                </Container>
            </>

        )
    }
};

export default AdminGroup;