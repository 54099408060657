import React, { Component } from "react";
// import Posts from "../post/Posts";
import AWS from 'aws-sdk';
import Footer from './Footer';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Accordion, Card, Button } from 'react-bootstrap';

class publicNashvillePhotos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: []
        }
    }

    componentDidMount() {
        this.fetchImages();
    }

    fetchImages = async () => {
        // Configure AWS SDK
        AWS.config.update({
            region: 'us-east-1',
            accessKeyId: "AKIAJTACW7QVN7PPWLYQ",
            secretAccessKey: "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",
        });
        const bucketName = "nashville-event"
        // Create a new S3 instance
        const s3 = new AWS.S3();

        // Fetch objects from the specified bucket
        try {
            const response = await s3.listObjects({ Bucket: bucketName }).promise();
            const imageUrls = response.Contents.map(
                (item) => `https://${bucketName}.s3.amazonaws.com/${item.Key}`
            );

            this.setState({ images: imageUrls })
        } catch (error) {
            console.error('Error fetching images from S3:', error);
        }
    };

    render() {
        return (
            <>
                <div className="container-fluid">
                <Card>
                        <Card.Header>
                            <h4>Nashville 10th Anniversary</h4>
                           
                        </Card.Header>
                </Card>
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">

                            <div className="image-gallery">
                            <Carousel dynamicHeight>
                                {this.state.images.map((url, index) => (
                                    <img key={index} src={url} alt="" />
                                ))}
                            </Carousel>
                            </div>

                        </div>

                    </div >

                </div >


                <Footer />
            </>
        )
    }
}

export default publicNashvillePhotos