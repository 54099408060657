import React, { Component } from "react";
import { Container, Row, Breadcrumb } from 'react-bootstrap';
import { isAuthenticated } from "../auth";
import Footer from '../core/Footer';
import EventCalendar from './EventCalendar';




class EventSim extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAdmin: true,     // if admin, open and edit are available            
            token: isAuthenticated() && isAuthenticated().token,
        }
    }

    resourceList = [
        {
            fieldName: 'general',
            title: 'General Sessions',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Opening Session', color: '#4caf50' },
                { id: 2, text: 'Closing Session', color: '#4caf50' },
                { id: 3, text: 'Committee Update', color: '#4caf50' },
                { id: 4, text: 'Vendor Company Update', color: '#4caf50' },
                { id: 5, text: 'Vendor Presentation', color: '#4caf50' },
                { id: 6, text: 'Leadership Meeting', color: '#4caf50' }
            ],
        },
        {
            fieldName: 'bizstrategy',
            title: 'Business Strategy Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#000099' },
                { id: 2, text: 'Committee Round Table', color: '#000099' },
                { id: 3, text: 'Committee Presentation', color: '#000099' },
                { id: 4, text: 'Vendor Presentation', color: '#000099' },
                { id: 5, text: 'User Presentation', color: '#000099' },
                { id: 6, text: 'Best Practice Discussion', color: '#000099' },
                { id: 7, text: 'Committee Closing Session', color: '#000099' },
                { id: 8, text: 'IACET Presentation', color: '#000099' }
            ],
        },
        {
            fieldName: 'civil',
            title: 'Civil Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#795548' },
                { id: 2, text: 'Committee Round Table', color: '#795548' },
                { id: 3, text: 'Committee Presentation', color: '#795548' },
                { id: 4, text: 'Vendor Presentation', color: '#795548' },
                { id: 5, text: 'User Presentation', color: '#795548' },
                { id: 6, text: 'Best Practice Discussion', color: '#795548' },
                { id: 7, text: 'Committee Closing Session', color: '#795548' },
                { id: 8, text: 'IACET Presentation', color: '#795548' }
            ],
        },
        {
            fieldName: 'electrical',
            title: 'Electrical Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#f44336' },
                { id: 2, text: 'Committee Round Table', color: '#f44336' },
                { id: 3, text: 'Committee Presentation', color: '#f44336' },
                { id: 4, text: 'Vendor Presentation', color: '#f44336' },
                { id: 5, text: 'User Presentation', color: '#f44336' },
                { id: 6, text: 'Best Practice Discussion', color: '#f44336' },
                { id: 7, text: 'Committee Closing Session', color: '#f44336' },
                { id: 8, text: 'IACET Presentation', color: '#f44336' }
            ],
        },
        {
            fieldName: 'physical',
            title: 'Physical Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#993399' },
                { id: 2, text: 'Committee Round Table', color: '#993399' },
                { id: 3, text: 'Committee Presentation', color: '#993399' },
                { id: 4, text: 'Vendor Presentation', color: '#993399' },
                { id: 5, text: 'User Presentation', color: '#993399' },
                { id: 6, text: 'Best Practice Discussion', color: '#993399' },
                { id: 7, text: 'Committee Closing Session', color: '#993399' },
                { id: 8, text: 'IACET Presentation', color: '#993399' }
            ],
        },
        {
            fieldName: 'technology',
            title: 'Technology Committee',
            allowMultiple: true,
            instances: [
                { id: 1, text: 'Committee Update', color: '#339966' },
                { id: 2, text: 'Committee Round Table', color: '#339966' },
                { id: 3, text: 'Committee Presentation', color: '#339966' },
                { id: 4, text: 'Vendor Presentation', color: '#339966' },
                { id: 5, text: 'User Presentation', color: '#339966' },
                { id: 6, text: 'Best Practice Discussion', color: '#339966' },
                { id: 7, text: 'Committee Closing Session', color: '#339966' },
                { id: 8, text: 'IACET Presentation', color: '#339966' }
            ],
        }
    ]

    render() {
        // const { isAdmin } = this.state

        return (

            <Container fluid>
                <div>
                    <h2>SDSIC Spring Event Simulation</h2>
                    <p>
                        This schedule represents a condensed version of our upcoming virtual event.
                        This is for training and testing purposes only. If you see this calendar AFTER
                        March 24th, 2021, please ignore.
                    </p>
                </div>
                <hr />
                    <Breadcrumb>
                        <Breadcrumb.Item href="/usershome">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href={`/grouplist`}>
                            back to committee list
                    </Breadcrumb.Item>
                      <Breadcrumb.Item active>Virtual Event Simulation</Breadcrumb.Item>
                    </Breadcrumb>

                <Row> 
                    <EventCalendar
                        scheduleName={'Spring SIM'}
                        isAdmin={false}
                        chooseTracks={true}
                        chooseDays={false}
                        currentDate={'2021-03-24'}
                        excludedDays={[0, 1,2, 4,5, 6, 7]}
                        weekView={true}
                        resourceList={this.resourceList}
                        mainResourceName={'general'}
                        calendarHeight={450}
                        startTime={10}
                        endTime={13}
                    />
                </Row>

                <Footer />
            </Container >

        )
    }
};

export default EventSim;