 
export const stripePaymentMethodHandler = async (data, cb) => {
  const { amount, result, description, user, email } = data;
  if (result.error) {
    // show error in payment form
    cb(result);
  } else {
    const paymentResponse = await stripePayment({
      payment_method_id: result.paymentMethod.id,
      name: result.paymentMethod.billing_details.name,
      email: email,
      amount: amount,
      user,
      description: description,
    });
    console.log(paymentResponse);
    cb(paymentResponse);
  }
}
 
// place backend API call for payment
const stripePayment = async data => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/checkoutpay`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  return await res.json();
}