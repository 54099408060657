import React, { Component } from "react";
import { isAuthenticated } from "../auth";
// import ScheduleSingleEvent from "../event/ScheduleSingleEvent";
import { getEventById } from './apiEvents';
import { appointments } from './appointments';
import Moment from 'moment';
import { createDownloadLink } from '../file/apiFiles'; 
import Footer from '../core/Footer'; 

class AllEvents extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: appointments,
      eventId: this.props.match.params.eventId,
      eventTime: '',
      eventRules: '',
      notes: '',
      fileName: '',
      fileLink: '',
      awsVersion: ''
    }
  }

  token = isAuthenticated() && isAuthenticated().token;
  
  createSecureLink() {
    const fileInfo = {
      fileName: this.state.fileName,
      awsVersion: this.state.awsVersion
    }
    console.log('fileinfo' + fileInfo.fileName + " : "  + fileInfo.awsVersion)
    // createDownloadLink(this.token, fileInfo).then(data => {
    //   if (data.error) {
    //     console.log(data.error);
    //   } else {
    //     //console.log(data);
    //     //return data
    //     this.setState({ ...this.state, fileLink: data });
    //   }
    // });

  };

  loadEvent() {

    getEventById(this.state.eventId, this.token).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        const eventDate = Moment(data[0].startDate);
        const eventRules = String(data[0].rRules);

        const notesIn = data[0].notes
        console.log(data);
        this.setState({
          data: data,
          eventTime: String(eventDate),
          eventRules: eventRules,
          notes: notesIn,
          awsVersion: data[0].awsVersion,
          fileName: data[0].fileName
        }); 
        const fileInfo = {
          fileName: data[0].fileName,
          awsVersion: data[0].awsVersion
        }
        createDownloadLink(this.token, fileInfo).then(data => {
          if (data.error) {
            console.log(data.error);
          } else {
            //console.log(data);
            //return data
            this.setState({ ...this.state, fileLink: data });
          }
        });

      }
    });
  };


  componentDidMount() {
    this.loadEvent();
    this.createSecureLink()
  };

  render() {
    const { data, eventTime, notes, fileLink, fileName } = this.state;
    return (
      <div className="container-fluid">
        <h4>{data[0].title}</h4>
        <br />
        <p>Time: <b>{eventTime}</b></p>
        <p>
          Download the invite: <a href={`${fileLink}`} title="Click to Download." >{fileName}</a>
        </p>

        <div contentEditable='true' dangerouslySetInnerHTML={{ __html: notes }} style={{ whiteSpace: 'pre-wrap' }} ></div>
        
        {/* <ScheduleSingleEvent
          eventId={this.state.eventId}
        /> */}
        <Footer />
      </div>
      
    )
  }
};

export default AllEvents;