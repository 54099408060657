import { v4 as uuid } from 'uuid';


export const eventscheduleD1 = [
  {
    title: "Virtual Event Opening Session",
    startDate: new Date(2023, 10, 1, 12, 0),
    endDate: new Date(2023, 10, 1, 12, 15),
    id: uuid(),
    description: `Audrey Marich, SDSIC Chair and Mark Nelson, SDSIC Co-Chair, will kick off our virtual event for 2023.`,
    location: "Virtual Zoom Meeting",
    general: [1],
    ownerId: 1,
    notes: "",
    committeeType: "general",
    zoomId: "815 5322 6683",
    zoompass: "553425",
    zoomLink: "https://sdsconsortium.zoom.us/j/81553226683?pwd=V0peDXYYaUwaaITpD6tAhQLch5tJRE.1"
  },
  {
    title: "P4A and P&C Coordination with SBS Design Sync by SBS",
    startDate: new Date(2023, 10, 1, 12, 15),
    endDate: new Date(2023, 10, 1, 13, 0),
    id: uuid(),
    description: `P4A and P&C Coordination with SBS Design Sync arman.birang@spatialbiz.com`,
    location: "Virtual Zoom Meeting",
    general: [4,3],
    ownerId: 4,
    notes: "This is a combined event with the Physical Committee",
    committeeType: "electrical",
    zoomId: "810 9765 5345",
    zoompass: "839467",
    zoomLink: "https://sdsconsortium.zoom.us/j/81097655345?pwd=SQ8kfFpf9uz6WSBx2AwwoxhHMj2rTi.1"
  },
  {
    title: "A Complete Guide to Part Building for P4A by SBS",
    startDate: new Date(2023, 10, 1, 13, 15),
    endDate: new Date(2023, 10, 1, 14, 0),
    id: uuid(),
    description: `A Complete Guide to Part Building for P4A via Inventor-INEX by  gabriel.watson@spatialbiz.com`,
    location: "Virtual Zoom Meeting",
    general: [3],
    ownerId: 3,
    notes: " ",
    committeeType: "physical",
    zoomId: "835 0513 3510",
    zoompass: "614498",
    zoomLink: "https://sdsconsortium.zoom.us/j/83505133510?pwd=FzJpnyedb5LjAWIQKhQa2LWlGk7SDr.1"
  },
  {
    title: "VR tour for public relations - a real customer example by Entegra",
    startDate: new Date(2023, 10, 1, 14, 15),
    endDate: new Date(2023, 10, 1, 15, 0),
    id: uuid(),
    description: `VR tour for public relations – a real customer example n.weber@entegra.de`,
    location: "Virtual Zoom Meeting",
    general: [8],
    ownerId: 8,
    notes: " ",
    committeeType: "innovation",
    zoomId: "869 4388 9677",
    zoompass: "155915",
    zoomLink: "https://sdsconsortium.zoom.us/j/86943889677?pwd=cEGPjeDFFhspCJQ62baYw6nXGMxlbP.1"
  },
  {
    title: "Navigating the 5Ws of Documentation for a Successful Implementation by ImaginIT",
    startDate: new Date(2023, 10, 1, 15, 15),
    endDate: new Date(2023, 10, 1, 16, 0),
    id: uuid(),
    description: `Navigating the 5Ws of Documentation for a Successful Implementation, jmacmillan@rand.com`,
    location: "Virtual Zoom Meeting",
    general: [5],
    ownerId: 5,
    notes: " ",
    committeeType: "business",
    zoomId: "848 0013 8573",
    zoompass: "692249",
    zoomLink: "https://sdsconsortium.zoom.us/j/84800138573?pwd=VL5l5qpobQJ7XzP2oSS2j2pDy6NuO8.1"
  },
  {
    title: "3D approaches in Nuclear, Oil & Gas industries by Borton Lawson",
    startDate: new Date(2023, 10, 1, 16, 15),
    endDate: new Date(2023, 10, 1, 17, 0),
    id: uuid(),
    description: `Presentation will focus on some of the work we are doing in other industries (specifically nuclear, oil & gas) and how those clients are leveraging a 3D approach to create intelligent tools and a virtualized workspace. tschoen@borton-lawson.com`,
    location: "Virtual Zoom Meeting",
    general: [8],
    ownerId: 8,
    notes: "Combined with the Technology committee",
    committeeType: "innovation",
    zoomId: "880 5587 6948",
    zoompass: "609213",
    zoomLink: "https://sdsconsortium.zoom.us/j/88055876948?pwd=2DxJUP7KtqhNEnlGHoRLSrIrIE9lFx.1"
  },
  {
    title: "LiDAR Scanning to 3D As-built Model Workflow by Tier 1 Utility Design",
    startDate: new Date(2023, 10, 1, 12, 15),
    endDate: new Date(2023, 10, 1, 13, 0),
    id: uuid(),
    description: `LiDAR Scanning to 3D As-built Model Workflow presented by James Presto`,
    location: "Virtual Zoom Meeting",
    general: [8],
    ownerId: 8,
    notes: "",
    committeeType: "innovation",
    zoomId: "813 9949 3731",
    zoompass: "721451",
    zoomLink: "https://sdsconsortium.zoom.us/j/81399493731?pwd=HbILN1jWCF6XLhVXeSHEMzUSmSRiBF.1"
  },
  {
    title: "Panel BOM Re-Index Item Number by SECO",
    startDate: new Date(2023, 10, 1, 13, 15),
    endDate: new Date(2023, 10, 1, 14, 0),
    id: uuid(),
    description: `Panel BOM Re-Index Item Number presented by James Presto`,
    location: "Virtual Zoom Meeting",
    general: [4],
    ownerId: 4,
    notes: " ",
    committeeType: "electrical",
    zoomId: "856 6281 4756",
    zoompass: "640944",
    zoomLink: "https://sdsconsortium.zoom.us/j/85662814756?pwd=SevRT9ltxcmg4B9uCoIvPr6Qdhs3Ds.1"
  },
  {
    title: "Connecting Autodesk Vault to Business Systems by ImaginIT",
    startDate: new Date(2023, 10, 1, 14, 15),
    endDate: new Date(2023, 10, 1, 15, 0),
    id: uuid(),
    description: `Connecting Autodesk Vault to Business Systems swolslager@rand.com`,
    location: "Virtual Zoom Meeting",
    general: [6],
    ownerId: 6,
    notes: "",
    committeeType: "technology",
    zoomId: "874 3569 3481",
    zoompass: "162204",
    zoomLink: "https://sdsconsortium.zoom.us/j/87435693481?pwd=eCW5h1b0QVWoC2yPQLUzNUuXBQ60ra.1"
  },
  {
    title: "SDS P&C Toolkit 8.5 - What's New by Holt Design co.",
    startDate: new Date(2023, 10, 1, 15, 15),
    endDate: new Date(2023, 10, 1, 16, 0),
    id: uuid(),
    description: `SDS P&C Toolkit 8.5 - What's New  nate.holt@holtdesignco.com`,
    location: "Virtual Zoom Meeting",
    general: [4],
    ownerId: 4,
    notes: "",
    committeeType: "electrical",
    zoomId: "810 7828 6951",
    zoompass: "706568",
    zoomLink: "https://sdsconsortium.zoom.us/j/81078286951?pwd=EaeaYH7IG1oeyvQhTPFwyNjbEOVqbI.1"
  },
  {
    title: "Let´s design a Substation in primtech 3d live by Entegra ",
    startDate: new Date(2023, 10, 1, 16, 15),
    endDate: new Date(2023, 10, 1, 17, 0),
    id: uuid(),
    description: `Let´s design live a Substation in primtech 3d, n.weber@entegra.de`,
    location: "Virtual Zoom Meeting",
    general: [3],
    ownerId: 3,
    notes: " ",
    committeeType: "physical",
    zoomId: "895 5522 3836",
    zoompass: "598669",
    zoomLink: "https://sdsconsortium.zoom.us/j/89555223836?pwd=p9KtYUkDU5U1yKIBbc1OkUbgnBmOe7.1"
  }
]

export const eventscheduleD2 = [
  {
    title: "Unveiling data's power: Transforming substation asset management for a resilient future by eSmart Systems",
    startDate: new Date(2023, 10, 2, 12, 15),
    endDate: new Date(2023, 10, 2, 13, 0),
    id: uuid(),
    description: `Unveiling data's power: Transforming substation asset management for a resilient future, maria.cervantes@esmartsystems.com`,
    location: "Virtual Zoom Meeting",
    general: [6],
    ownerId: 6,
    notes: " ",
    committeeType: "technology",
    zoomId: "830 8953 6324",
    zoompass: "164464",
    zoomLink: "https://sdsconsortium.zoom.us/j/83089536324?pwd=tEWpVDkrYThWPzanaYjvBbNxaZ9OEW.1"
  },
  {
    title: "Intelligent 3D model for stakeholders by Southern Company",
    startDate: new Date(2023, 10, 2, 13, 15),
    endDate: new Date(2023, 10, 2, 14, 0),
    id: uuid(),
    description: `Intelligent 3D model for stakeholders, gpinero@southernco.com`,
    location: "Virtual Zoom Meeting",
    general: [3],
    ownerId: 3,
    notes: " ",
    committeeType: "physical",
    zoomId: "893 0287 8745",
    zoompass: "235264",
    zoomLink: "https://sdsconsortium.zoom.us/j/89302878745?pwd=PY8bCvgwOySXWPqRhk5g488qzwJ2hd.1"
  },
  {
    title: "How to Manage & Collaborate your Project in the Cloud by ImaginIT",
    startDate: new Date(2023, 10, 2, 14, 15),
    endDate: new Date(2023, 10, 2, 15, 0),
    id: uuid(),
    description: `How to Manage & Collaborate your Project in the Cloud, rmorrow@rand.com`,
    location: "Virtual Zoom Meeting",
    general: [7],
    ownerId: 7,
    notes: " ",
    committeeType: "civil",
    zoomId: "849 3407 9087",
    zoompass: "517827",
    zoomLink: "https://sdsconsortium.zoom.us/j/84934079087?pwd=RL2ONie5GGt3pxmbjSbUZrcECcRUVb.1"
  },
  {
    title: "Substitution Design with Inventor Model States by SBS",
    startDate: new Date(2023, 10, 2, 15, 15),
    endDate: new Date(2023, 10, 2, 16, 0),
    id: uuid(),
    description: `Substitution Design with Inventor Model States, steve.kaufman@spatialbiz.com`,
    location: "Virtual Zoom Meeting",
    general: [3],
    ownerId: 3,
    notes: " ",
    committeeType: "physical",
    zoomId: "858 4377 1303",
    zoompass: "496645",
    zoomLink: "https://sdsconsortium.zoom.us/j/85843771303?pwd=QQL5VbdlybppuRF3srIvLdUiKCyubc.1"
  },
  {
    title: "Updated presentation on BIM fundamentals, and starting Transistions by TRC Companies",
    startDate: new Date(2023, 10, 2, 16, 15),
    endDate: new Date(2023, 10, 2, 17, 0),
    id: uuid(),
    description: `Updated presentation on BIM fundamentals, and starting Transistions, ndwelley@trccompanies.com`,
    location: "Virtual Zoom Meeting",
    general: [8],
    ownerId: 8,
    notes: " ",
    committeeType: "innovation",
    zoomId: "828 4159 6797",
    zoompass: "212911",
    zoomLink: "https://sdsconsortium.zoom.us/j/82841596797?pwd=VA0Mnj54e6NSjp12n0QBvHWhyB1WF3.1"
  },
  {
    title: "Asset Inspection in the AI Era by Mindtrace",
    startDate: new Date(2023, 10, 2, 12, 15),
    endDate: new Date(2023, 10, 2, 13, 0),
    id: uuid(),
    description: "Asset Inspection in the AI Era, nan.wang@mindtrace.ai",
    location: "Virtual Zoom Meeting",
    general: [8],
    ownerId: 8,
    notes: " ",
    committeeType: "innovation",
    zoomId: "810 8995 7783",
    zoompass: "990285",
    zoomLink: "https://sdsconsortium.zoom.us/j/81089957783?pwd=McxFdzuA3PCYGFmdzspDQXakUxkI9b.1"
  },
  {
    title: "File based software vs. Database software: The evolution of lifecycle data management into a digital twin by Entegra",
    startDate: new Date(2023, 10, 2, 13, 15),
    endDate: new Date(2023, 10, 2, 14, 0),
    id: uuid(),
    description: `File based software vs. Database software: The evolution of lifecycle data management into a digital twin n.weber@entegra.de`,
    location: "Virtual Zoom Meeting",
    general: [6],
    ownerId: 6,
    notes: " ",
    committeeType: "technology",
    zoomId: "828 6571 9234",
    zoompass: "179239",
    zoomLink: "https://sdsconsortium.zoom.us/j/82865719234?pwd=vYL92vsTVNaYPDN9UhnP9a35NlYJU3.1"
  },
  {
    title: "AutoCAD Electrical Road map – Protection & Control Design Applications by Ampirical",
    startDate: new Date(2023, 10, 2, 14, 15),
    endDate: new Date(2023, 10, 2, 15, 0),
    id: uuid(),
    description: `AutoCAD Electrical Road map – Protection & Control Design Applications, TTimler@Ampirical.com`,
    location: "Virtual Zoom Meeting",
    general: [4],
    ownerId: 4,
    notes: " ",
    committeeType: "electrical",
    zoomId: "848 8264 0786",
    zoompass: "566777",
    zoomLink: "https://sdsconsortium.zoom.us/j/84882640786?pwd=jz3ubqMOuiLiuBXJwGA3patEHREelJ.1"
  },
  {
    title: "Improving the QA/QC using the SDS P&C Toolkit and Other Tools by SBS",
    startDate: new Date(2023, 10, 2, 15, 15),
    endDate: new Date(2023, 10, 2, 16, 0),
    id: uuid(),
    description: `Improving the QA/QC using the SDS P&C Toolkit and Other Tools, randy.brunette@spatialbiz.com`,
    location: "Virtual Zoom Meeting",
    general: [4],
    ownerId: 4,
    notes: "",
    committeeType: "electrical",
    zoomId: "881 0419 5143",
    zoompass: "588816",
    zoomLink: "https://sdsconsortium.zoom.us/j/88104195143?pwd=z3adA61jRtVUWQRNa7Skob8vBaaX1g.1"
  },
  {
    title: "Autodesk Vault What's New by nFluencr",
    startDate: new Date(2023, 10, 2, 16, 15),
    endDate: new Date(2023, 10, 2, 17, 0),
    id: uuid(),
    description: `Autodesk Vault What's New, melissa.thomas@nfluencr.net`,
    location: "Virtual Zoom Meeting",
    general: [6],
    ownerId: 6,
    notes: "",
    committeeType: "technology",
    zoomId: "838 9144 5968",
    zoompass: "741435",
    zoomLink: "https://sdsconsortium.zoom.us/j/83891445968?pwd=gkggivylLnuj0oaHNsbyJgcwkhVbwr.1"
  },
  {
    title: "Closing Session",
    startDate: new Date(2023, 10, 2, 17, 0),
    endDate: new Date(2023, 10, 2, 17, 5),
    id: uuid(),
    description: `General Daily wrap up and next day preparation`,
    location: "Virtual Zoom Meeting",
    general: [1],
    ownerId: 1,
    notes: "",
    committeeType: "general",
    zoomId: "992 4884 9887",
    zoompass: "596855",
    zoomLink: "https://sdsconsortium.zoom.us/j/99248849887?pwd=TDAvdysyak5yNkdETm9wT2…"
  }
]



export const committees = [
  {
    text: 'General',
    id: 1,
    color: '#42a5f5',
  },
  {
    text: 'Event',
    id: 2,
    color: '#EEC643',
  },
  {
    text: 'Physical',
    id: 3,
    color: '#F46036',
  },
  {
    text: 'Electrical',
    id: 4,
    color: '#1B998B',
  },
  {
    text: 'Business',
    id: 5,
    color: '#508484',
  },
  {
    text: 'Technology',
    id: 6,
    color: '#79C99E',
  },
  {
    text: 'Civil',
    id: 7,
    color: '#4A4238',
  },
  {
    text: 'Innovation',
    id: 8,
    color: '#fcdb03',
  }
];

