import React, { Component } from "react";
import { isAuthenticated } from "../auth";
import { create } from "./apiForum";
import { singleGroup, groupDetails, sendMessage } from '../group/apigroup';
import { Redirect } from "react-router-dom";
import Select from 'react-select'
import { getFilesForGroup } from '../file/apiFiles';
import Footer from '../core/Footer'; 
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html'; 
import moment from "moment";

class NewForumPost extends Component {
    state = {
        postedgroupId: this.props.match.params.groupId,
        title: "",
        body: "",
        error: "",
        user: {},
        loading: false,
        redirectToProfile: false,
        groupName: '',
        chairEmail: '',
        cochairEmail: '',
        allGroupFiles: [],
        filetoLink: '',
        editorState: EditorState.createEmpty()
    }; 

    getGroupInformation = () => {
        singleGroup(this.props.match.params.groupId).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {

                this.setState({
                    group: data
                });
            }
        });



        groupDetails(this.props.match.params.groupId).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({
                    chairEmail: data.chair.email,
                    cochairEmail: data.cochair.email,
                    groupName: data.name
                })
            }
        })
    };

    componentDidMount() {
        this.forumpostData = new FormData();
        this.setState({ user: isAuthenticated().user });
        this.getGroupInformation();
        getFilesForGroup(this.state.postedgroupId, isAuthenticated().token).then(files => {
            if (files.error) {
                console.log(files.error);
            } else {
                this.setState({
                    allGroupFiles: files
                })
                console.log(files)
            }
        })
    }

    isValid = () => {
        const { title } = this.state;
        if (title.length === 0 ) {
            this.setState({ error: "All fields are required", loading: false });
            return false;
        }
        return true;
    };

    handleChange = name => event => {
        let value = "";
        if (name === 'filetoLink') {
            value = event.value
        } else {
            value = event.target.value
        }
        // if (name === 'title') {
        //     this.forumpostData.set("title", event.value);
        // }

        this.setState({ error: "" });

        this.forumpostData.set(name, value);
        this.setState({ [name]: value });
    };

    onChange = (editorState) => this.setState({ editorState });

    clickSubmit = event => {
        event.preventDefault();
        this.setState({ loading: true });

        const rawState = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
        this.forumpostData.set("body", rawState);

        if (this.isValid()) {
            const userId = isAuthenticated().userId;
            const token = isAuthenticated().token;
            this.forumpostData.set('postedgroupId', this.state.postedgroupId)
        
            create(userId, token, this.forumpostData).then(data => {
                if (data.error) this.setState({ error: data.error });
                else {
                    this.setState({
                        loading: false,
                        title: "",
                        body: "",                        
                    });
                }                
            });
            this.sendPostMessage(rawState)
        }
    };


    sendPostMessage(raw) {
        const messageBody = stateToHTML(convertFromRaw(JSON.parse(raw)))

        // const mailMessage = {
        //     to: this.state.chairEmail,
        //     bcc: this.state.cochairEmail,
        //     from: 'donotreply@sdsconsortium.com', //isAuthenticated().email,
        //     subject: "New Post From the " + this.state.groupName,
        //     Message: messageBody,
        //     html: "<p>New Post from the " + this.state.groupName + "</p><p>Post was: </p><p>" +
        //     messageBody + "</p><br />",
        //     dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
        // }

        const mailMessage = {
            "subject": "New Post From the SDSIC " + this.state.groupName,
            "sender": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
            "replyTo": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
            "to": [{ "name": `${this.state.groupName}`, "email": this.state.chairEmail }],
            "cc": [{ "name": `${this.state.groupName}`, "email": this.state.cochairEmail }],
            "htmlContent": `<html><body><h4>New Post in the ${this.state.groupName} page,</h4>${messageBody}</body></html>`           
        }

            console.log(mailMessage)
        sendMessage(mailMessage).then(data => {
            this.setState({
                loading: false,
                redirectToProfile: true
            });
        });
    };

    newPostForm = (title, body, groupId, allGroupFiles, editorState) => (
        <form>
            <div className="form-group">
                <label className="text-muted">Title</label>
                <input
                    onChange={this.handleChange("title")}
                    type="text"
                    id="title"
                    className="form-control"
                    value={title}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Body</label>
                <Editor
                    editorState={this.state.editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="editer-content"
                    onEditorStateChange={this.onChange}  
                    wrapperStyle={{ border: "2px solid blue", marginBottom: "10px" }}
                    editorStyle={{ height: "300px", padding: "10px"}}     
                />
                {/* <textarea
                    onChange={this.handleChange("body")}
                    type="text"
                    className="form-control"
                    value={body}
                    style={{ height: "200px" }}
                /> */}
            </div>

            <div className="form-group">
                <Select
                    name="filetoLink"
                    options={allGroupFiles.map((c, i) => (
                        {
                            value: c._id,
                            label: c.fileCategory + " : " + c.title
                        }
                    ))}
                    onChange={this.handleChange("filetoLink")}
                    isSearchable={true}
                    placeholder={'type a file to search'}
                />
            </div>

            <button
                onClick={this.clickSubmit}
                className="btn btn-raised btn-primary"
            >
                Create Forum Message
            </button>
        </form>
    );

    render() {
        const {
            title,
            body,
            error,
            loading,
            redirectToProfile,
            groupId,
            allGroupFiles,
            editorState
        } = this.state;

        if (redirectToProfile) {
            return <Redirect to={`/singlegroup/${this.state.postedgroupId}`} />;
        }

        return (
            <div className="container">
                <h2 className="mt-5 mb-5">You are creating a new post in the {this.state.groupName}</h2>
                <div
                    className="alert alert-danger"
                    style={{ display: error ? "" : "none" }}
                >
                    {error}
                </div>

                {loading ? (
                    <div className="jumbotron text-center">
                        <h2>Loading...</h2>
                    </div>
                ) : (
                        ""
                    )}

                {this.newPostForm(title, body, groupId, allGroupFiles, editorState)}
                <br/>
                <br/>
                <Footer />
            </div>
        );
    }
}

export default NewForumPost;