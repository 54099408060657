import React, { Component } from "react";
// import Posts from "../post/Posts";
import Users from "../user/Users";
import { isAuthenticated } from "../auth";
import { Redirect } from "react-router-dom";

class Admin extends Component {

    constructor() {
        super();
        this.state = {
            groups: [],
            userId: isAuthenticated() && isAuthenticated().userId,
            username: isAuthenticated() && isAuthenticated().name,
            token: isAuthenticated().token,
            userEmail: isAuthenticated().email,
            redirectToHome: false
        };
    }

    componentDidMount() {
        if (isAuthenticated().userId !== "5e2611483f99fa3bb4dd922a") {
            this.setState({ redirectToHome: true });
        }
    }

    render() {
        if (this.state.redirectToHome) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <div className="jumbotron">
                    <h2>Admin Dashboard</h2>
                    <p className="lead">Welcome Administrator: <b>{this.state.username}</b></p>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <h3>You are listed as Admin on:</h3>
                            <ul>
                                <li className="list-item">
                                    Physical
                                </li>
                                <li className="list-item">
                                    Technology
				                </li>
                                <li className="list-item">
                                    Leadership
				                </li>                                
                                
                            </ul>

                        </div>
                        <div className="col-md-6">
                            <h3>current Users</h3>
                            <ul>
                                <li className="list-item">
                                    Trevor Scullion
                                </li>
                                <li className="list-item">
                                    Steve Kaufman
				                </li>
                                <li className="list-item">
                                    Shawn Weekly
				                </li>                                
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Admin;