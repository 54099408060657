/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
// import Posts from "../post/Posts";
import { Button, Form } from 'react-bootstrap';
import Footer from './Footer';
import { sendMessage } from "../group/apigroup";
import moment from "moment";
import KC_IMAGE from '../images/KC-Skyline.jpg';
import BV_LOGO from '../images/Black_&_Veatch-Logo.png';

class publicKansasClosed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            success: ''
        }
    }

    handleChange = name => event => {
        if (name === "email") {
            this.setState({ error: "" });
            this.setState({ responseTo: name, [name]: event.target.value });
        }
    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Your on the list!</h3>
                <p>Don't forget to check your inbox and your spam folder for updates from the SDSIC.</p>
                <p>We will use the email you've submitted to send you updates as we finalize the upcoming event.</p>
            </div>
        )
    };

    submitEmail = e => {
        const { email } = this.state;
        e.preventDefault();
        if (email === "") { this.setState({ error: "email required!" }) }

        if (email !== "") {
            const mailMessage = {
                to: 'trevor.scullion@automationforce.com',
                from: email,
                subject: "SDSIC 2022 FALL event list",
                Message: 'Please add ' + email + ' to the SDSIC 2022 FALL event list.',
                html: "<p>Please add " + email + " to the SDSIC 2022 FALL event list.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }
            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                this.setState({
                    email: '',
                    success: true,
                });
            });

            const userMessage = {
                to: email,
                from: 'donotreply@sdsconsortium.com',
                subject: "SDSIC 2022 FALL event",
                Message: 'Your name is on the SDSIC 2022 FALL event list.',
                html: "<p>Your name is on the SDSIC 2022 FALL event list.</p><p>You will be kept up to date " +
                    "as we finalize the event details.</p><p>Please do not reply to this email.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }

            // make request to api to send message
            sendMessage(userMessage).then(data => { });
        };
    };


    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">
                            <div className="jumbotron p-3 p-md-5 text-white rounded bg-dark">
                                <div className="row mb-2">
                                    <div className="col-md-12">
                                        <h4 className="display-5 font-italic">The SDSIC Spring 2024 Live Event</h4>
                                        <h4>April 22nd to the 25th 2024</h4>

                                        <p className="lead my-3">We are excited to be holding our only 2024 event this spring in beautiful Kansas City, Missouri hosted by our 
                                        amazing partner, Black & Veatch at the Kansas City Convention Center. </p>
                                        <p className="lead mb-0">As we finalize the agenda of events, we will send out notifications but for now, register below and save your spot!</p>
                                    </div>

                                </div>
                            </div>

                            <main role="main" className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2 className="blog-post-title">We're Full!</h2>
                                        <p>
                                            We have had another record turnout for this year's spring event and our registration is now closed. We hope you've had a chance to grab
                                            a spot and we will see you in Kansas City.
                                        </p>
                                        <p>
                                            <h3 className="blog-post-title">The Venue</h3>
                                            <h5 className="mb-0">
                                                <a className="text-dark" href="https://kcconvention.com/" target="_blank">Kansas City Convention Center</a>                                               
                                            </h5>
                                            <br/>
                                            <p className="card-text mb-auto">
                                            301 West 13th Street, Suite 100<br />
                                            Kansas City, MO 64105<br />
                                            </p>
                                            
                                            
                                        </p>
                                       
                                    </div>
                                    <div className="col-md-6">
                                        <h2>Hosted by: <img src={BV_LOGO} alt="Black & Veatch" width="300"  /></h2>
                                    <br/>
                                        <img className="d-block mx-auto mb-1" src={KC_IMAGE} alt="KC Syline"  />
                                        <br/>
                                            <p>
                                                The venues offered at the Kansas City Convention Center offer a diverse mix of historic, versatile and 
                                                sophisticated properties in the heart of downtown Kansas City with each equipped to handle events 
                                                of all sizes. 
                                            </p>
                                    </div>
                                </div>
                                <br />
                                <hr />

                            
                            </main>



                        </div>

                    </div >

                </div >
                <br/>
                <br/>
                <br/>
                <br/>
                <Footer />
            </>
        )
    }
}

export default publicKansasClosed