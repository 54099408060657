import React, { Component } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import Select from 'react-select'
import { submitSurvey } from '../event/apiEvents'
import { sendMessage } from "../user/apiUser";
import Footer from './Footer';
import { isAuthenticated } from "../auth";

class Survey2022 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: "61c2002ece75d94290ea7a5a",    //SDSIC Spring Event 2022
            question1: [],
            question2: [],
            question3: [],
            question3_other: [],
            question4: [],
            question4_other: [],
            question5: [],
            question6: [],
            question7: [],
            question8: [],
            question9: [],
            question9_other: [],
            email: "",
            error: "",
            success: false
        }
    }

    handleChange = name => event => {

        if (name == "question1") {
            const finalVal = [{ responseTo: name, id: event.value, label: event.label }]
            this.setState({ [name]: finalVal });
        }

        if (name == "question2") {
            const finalVal = [{ responseTo: name, id: event.value, label: event.label }]
            this.setState({ [name]: finalVal });
        }

        if (name == "question3") {
            const finalVal = [{ responseTo: name, id: event.value, label: event.label }]
            this.setState({ [name]: finalVal });
        }


        if (name == "question4") {
            const finalVal = [{ responseTo: name, id: event.value, label: event.label }]
            this.setState({ [name]: finalVal });
        }

    
        if (name == "question5") {
            const finalVal = [{ responseTo: name, id: event.value, label: event.label }]
            this.setState({ [name]: finalVal });
        }
        // always grab the "other text as well and add it to the items."


        if (name == "question6") {
            const finalVal = [{ responseTo: name, [name]: event.target.value }]
            this.setState({ [name]: finalVal });
        }

        if (name == "question7") {
            const finalVal = [{ responseTo: name, [name]: event.target.value }]
            this.setState({ [name]: finalVal });
        }

        if (name == "question8") {
            const finalVal = [{ responseTo: name, [name]: event.target.value }]
            this.setState({ [name]: finalVal });
        }

        if (name == "email") {
            this.setState({ error: "" });
            this.setState({ responseTo: name, [name]: event.target.value });
        }

    }

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };
    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Thank you for your responses!</h3>
                <p>Don't forget to check your inbox and your spam folder for updates from the SDSIC.</p>
                <p>We will use the email you've submitted to send you future updates as we finalize the 2022 event.</p>
            </div>
        )
    };

    submitSurvey = e => {
        const {
            question1, question2, question3, question3_other, question4, question4_other, question5, question6,
            question7, question8, email, error, eventId
        } = this.state;
        e.preventDefault();

        if (email == "") { 
            this.setState({ error: "email required!" }) 
        } else {
            if (error == "") {
            
                // structure Json body to send
                const fullBody = {
                    submittedByemail: email,
                    eventId: eventId,
                    answers: [
                        question1,
                        question2,
                        question3,
                        question4,
                        question5,
                        question6,
                        question7,
                        question8
                    ]
                }
                console.log(fullBody)
                submitSurvey(fullBody, eventId).then(data => {
                    if (data.error) {
                        this.setState({
                            error: data.error,
                            success: false
                        });
                    } else {
                        const adminMessage = {
                            to: 'trevor@sdsconsortium.com',
                            from: email,
                            subject: 'SDSIC 2022 event Survey Submitted',
                            html: `${email} submitted a survey`                                                
                        }
                        const usersMessage = {
                            to: email,
                            from: "info@sdsconsortium.com",
                            subject: 'SDSIC 2022 event Survey Submitted',
                            html: `Hi ${email}, <p> Thank you for submitting your survey answers.</p>                        
                            <p>Please submit any questions to: trevor@sdsconsortium.com </p>
                            <p>Save the dates: May 3rd through the 5th</p>
                            <p>More information will be sent to you with this email address as we finalize the schedule of events.</p>
                            <p>Stay Tuned! </p>`
                        }
                        // make request to api to send message
                        sendMessage(usersMessage).then(data => {
                            this.setState({
                                firstName: "",
                                lastName: "",
                                companyName: "",
                                country: "",
                                stateProvince: "",
                                zipPostal: "",
                                workphone: "",
                                email: "",
                                meetings: "",
                                industry: "",
                                jobLevel: "",
                                role: "",
                                terms: false,
                                mailing: false,
                                success: true
                            });
                        });
                        // make request to api to send the admin message
                        sendMessage(adminMessage).then(data => { });
                    }
                });
            }
        }
        
        
    }

    surveyForm() {
        const {
            question1, question2, question3, question4, question4_other, question5, question6,
            question7, question8, email
        } = this.state;
        return (
            <div style={{ display: (this.state.success == false) ? "" : "none" }}>

                <Form>
                    <Card border="primary">
                        <Card.Header>Question 1</Card.Header>
                        <Card.Body>
                            <Card.Title>Is your company currently approving corporate travel for 2022?</Card.Title>
                            <Select
                                as="select"
                                style={{ height: 150 }}
                                options={[
                                    { value: "Yes", label: "Yes" },
                                    { value: "No", label: "No" }
                                ]}
                                onChange={this.handleChange("question1")}
                            />
                        </Card.Body>
                    </Card>
                    <br />

                    <Card border="primary">
                        <Card.Header>Question 2</Card.Header>
                        <Card.Body>
                            <Card.Title>Are you expecting that your company will approve corporate travel to Kansas City for the 2022 meeting of the SDSIC?</Card.Title>
                            <Select
                                as="select"
                                style={{ height: 150 }}
                                options={[
                                    { value: "Yes", label: "Yes" },
                                    { value: "No", label: "No" }
                                ]}
                                onChange={this.handleChange("question2")}
                            />

                        </Card.Body>
                    </Card>
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 3</Card.Header>
                        <Card.Body>
                            <Card.Title>If approved, do you plan to attend the meeting in May?  </Card.Title>
                            <Select
                                as="select"
                                style={{ height: 150 }}
                                options={[
                                    { value: "Yes", label: "Yes" },
                                    { value: "No", label: "No" }
                                ]}
                                onChange={this.handleChange("question3")}
                            />
                        </Card.Body>
                    </Card>
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 4 and 5</Card.Header>
                        <Card.Body>
                            <Card.Title>
                                <p>The meeting will be held Tuesday, May 3rd thru Thursday May 5th.
                                    At past events we included a ½ day session on Monday, to introduce new members
                                    to the SDSIC suite of tools.
                                </p>
                                <p>Is this new member session something you have found to be beneficial in the past?</p>
                            </Card.Title>
                            <Card.Text>
                                <Select
                                    as="select"
                                    style={{ height: 150 }}
                                    options={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" }
                                    ]}
                                    onChange={this.handleChange("question4")}
                                />
                                <br />
                                <Card.Title>
                                    <p>If this session is held Monday afternoon, would it be something you will attend? </p>
                                </Card.Title>
                                <Select
                                    as="select"
                                    style={{ height: 150 }}
                                    options={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" }
                                    ]}
                                    onChange={this.handleChange("question5")}
                                />
                            </Card.Text>

                        </Card.Body>
                    </Card>
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 6</Card.Header>
                        <Card.Body>
                            <Card.Title>How many meetings of the SDSIC have you personally attended?</Card.Title>
                            <Card.Text>

                                <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    rows="3"
                                    placeholder="Please enter your answer here"
                                    onChange={this.handleChange("question6")}>
                                </Form.Control>

                            </Card.Text>

                        </Card.Body>
                    </Card>
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 7</Card.Header>
                        <Card.Body>
                            <Card.Title>What would you most like to see included in the event.  For example, project spotlights, how-to’s, round table discussions</Card.Title>
                            <Card.Text>

                                <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    rows="3"
                                    placeholder="Please enter your answer here"
                                    onChange={this.handleChange("question7")}>
                                </Form.Control>

                            </Card.Text>

                        </Card.Body>
                    </Card>
                    <br />
                    <Card border="primary">
                        <Card.Header>Question 8</Card.Header>
                        <Card.Body>
                            <Card.Title>Will you be using the SDSIC meeting suggested accommodations or book thru your corporate travel desk? </Card.Title>
                            <Card.Text>

                                <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    rows="3"
                                    placeholder="Please enter your answer here"
                                    onChange={this.handleChange("question8")}>
                                </Form.Control>

                            </Card.Text>

                        </Card.Body>
                    </Card>

                    <br />
                    <Form.Text className="muted">*Required</Form.Text>
                    <Form.Control
                        placeholder="Your Work Email"
                        name="email"
                        className="form-control"
                        type="text"
                        onChange={this.handleChange("email")}
                        value={this.state.email}
                    />
                    <br />
                    {this.showError()}
                    <Button
                        variant="primary"
                        onClick={this.submitSurvey}
                    >   Submit Survey
                    </Button>
                </Form>

            </div>
        )
    }

    render() {

        if (isAuthenticated() == true) {
            this.setState({email : isAuthenticated().email})
        }
        return (
            <div className="container-fluid">
                <div className="jumbotron">
                    <h2>Greetings SDSIC Members</h2>
                    <br />
                    <p>Your SDSIC leadership team is actively working with host, Burns and McDonnell, to give you all a
                        fantastic experience when attending the 2022 SDSIC meeting held Kansas City.
                    </p>
                    <p>Please take a few moments to complete the below survey, helping us make this the best possible experience for all. </p>
                    <p>Keep in mind, we are currently planning this meeting as in-person event only. Not virtual or hybrid. </p>
                </div>
                <div className="row justify-content-md-center">

                    <div className="col-md-10">
                        {this.state.email}
                        {this.surveyForm()}
                        <br />

                        {this.showSuccess()}
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

export default Survey2022;