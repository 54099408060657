import React, { Component } from "react";
import { Breadcrumb } from 'react-bootstrap';
import { isAuthenticated } from "../auth";
import ForumPosts from "../forum/ForumPosts";
// import EventLists from "../event/EventList";
import FileList from "../file/FileList";
import PresentationList from '../group/PresentationList';
import PastEventList from '../event/PastEventList';
import { singleGroup, groupDetails } from "./apigroup";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import ReactGA from 'react-ga';

import EventCalendar from "../event/EventCalendar";
import moment from "moment";
import Footer from '../core/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ForumIcon from '@mui/icons-material/Forum';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SourceIcon from '@mui/icons-material/Source';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import RsvpIcon from '@mui/icons-material/Rsvp';



import MonthlyMeetingList from "./monthlymeeting";

class SingleGroup extends Component {

  state = {
    userId: isAuthenticated() && isAuthenticated().userId, 
    username: isAuthenticated() && isAuthenticated().email,
    groupId: this.props.match.params.groupId,
    group: '',
    chairId: '',
    redirectToHome: false,
    redirectToSignin: false,
    chair: '',
    cochair: '',
    redirectToSignJoinRequest: false,
    mainResourceName: undefined,
    calendarName: undefined,
    activeContent: 'posts'
  };


  theme = {
    background: 'white',
    fontFamily: 'Arial, Helvetica, sans-serif',
    headerBgColor: "#327fa8",
    headerFontColor: "#fff",
    headerFontSize: "25px",
    botBubbleColor: "#327fa8",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4c4c4c"
  };


  componentDidMount = () => {

    singleGroup(this.state.groupId).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({
          group: data,
          // chair: data.chair,
          // chairId: data.chair._id,
          // cochair: data.cochair
        });

        groupDetails(this.state.groupId).then(data => {
          if (data.error) {
            console.log(data.error);
          } else {
            this.setState({
              group: data,
              chair: data.chair,
              chairId: data.chair._id,
              cochair: data.cochair
            })

          }
          this.verifyAccess(data._id, data.members, this.state.userId);
        });
      }

    });

    ReactGA.initialize('UA-331790-15')
    ReactGA.pageview('/singlegroup/' + this.state.groupId);

    if (this.state.groupId === '5e2a5e764175b97d84ec6cae') { this.setState({ mainResourceName: 'Physical Committee', calendarName: 'Physical' }) }
    if (this.state.groupId === '5e44050f4e6ed37ab467d0f2') { this.setState({ mainResourceName: 'Electrical Committee', calendarName: 'Electrical' }) }
    if (this.state.groupId === '5e44052e4e6ed37ab467d0f3') { this.setState({ mainResourceName: 'Technology Committee', calendarName: 'Technology' }) }
    if (this.state.groupId === '5e4405864e6ed37ab467d0f4') { this.setState({ mainResourceName: 'Civil Committee', calendarName: 'Civil' }) }
    if (this.state.groupId === '5e4406454e6ed37ab467d0f5') { this.setState({ mainResourceName: 'Business Strategy Committee', calendarName: 'Business' }) }
    if (this.state.groupId === '5e5591a97ff73f6d34835a05') { this.setState({ mainResourceName: 'General Committee', calendarName: 'General' }) }
    if (this.state.groupId === '639242d4971a30726884fd85') { this.setState({ mainResourceName: 'Innovation Commitee', calendarName: 'Innovation' }) }
    if (this.state.groupId === '65ce5e76e54fd763dfc9c0ae') { this.setState({ mainResourceName: 'Content Sub Committee', calendarName: 'Content' }) }

  };

  handleSideMenu = (menuItem) => {
    this.setState({
      activeContent: menuItem
    })
  };

  verifyAccess = (groupId, members, userId) => {

    let userisMember = false

    if (groupId !== '5e971eab6d698b2e84041439') {
      if (members.length === 0) {
        userisMember = true
      } else {

        const b = members.find((member) => member._id === userId)
        if (b) {
          userisMember = false
        } else {
          userisMember = true
        }

      }

      this.setState({ redirectToSignJoinRequest: userisMember })
    }
  }



  render() {

    const { group, chair, cochair } = this.state;
    const userName = isAuthenticated() && isAuthenticated().email;

    if (this.state.redirectToSignJoinRequest) {
      return <Redirect to={`/group/requestaccess/${this.state.groupId}`} />
    }




    const getresourceList = () => {
      switch (this.state.groupId) {
        case '5e2a5e764175b97d84ec6cae':
          return (
            [
              {
                fieldName: 'physical',
                title: 'Physical Committee',
                allowMultiple: true,
                instances: [
                  { id: 1, text: 'Committee Update', color: '#993399' },
                  { id: 2, text: 'Monthly Meeting', color: '#993399' },
                  { id: 3, text: 'Committee Presentation', color: '#993399' },
                  { id: 4, text: 'Vendor Presentation', color: '#993399' }
                ]
              }
            ]
          )
        case '5e44050f4e6ed37ab467d0f2':
          return (
            [
              {
                fieldName: 'electrical',
                title: 'Electrical Committee',
                allowMultiple: true,
                instances: [
                  { id: 1, text: 'Committee Update', color: '#f44336' },
                  { id: 2, text: 'Monthly Meeting', color: '#f44336' },
                  { id: 3, text: 'Committee Presentation', color: '#f44336' },
                  { id: 4, text: 'Vendor Presentation', color: '#f44336' }
                ],
              }
            ]
          )
        case '5e44052e4e6ed37ab467d0f3':
          return (
            [
              {
                fieldName: 'technology',
                title: 'Technology Committee',
                allowMultiple: true,
                instances: [
                  { id: 1, text: 'Committee Update', color: '#339966' },
                  { id: 2, text: 'Monthly Meeting', color: '#339966' },
                  { id: 3, text: 'Committee Presentation', color: '#339966' },
                  { id: 4, text: 'Vendor Presentation', color: '#339966' }
                ],
              }
            ]
          )
        case '5e4405864e6ed37ab467d0f4':
          return (
            [
              {
                fieldName: 'civil',
                title: 'Civil Committee',
                allowMultiple: true,
                instances: [
                  { id: 1, text: 'Committee Update', color: '#795548' },
                  { id: 2, text: 'Monthly Meeting', color: '#795548' },
                  { id: 3, text: 'Committee Presentation', color: '#795548' },
                  { id: 4, text: 'Vendor Presentation', color: '#795548' }
                ],
              }
            ]
          )
        case '5e4406454e6ed37ab467d0f5':
          return (
            [
              {
                fieldName: 'bizstrategy',
                title: 'Business Strategy Committee',
                allowMultiple: true,
                instances: [
                  { id: 1, text: 'Committee Update', color: '#000099' },
                  { id: 2, text: 'Monthly Meeting', color: '#000099' },
                  { id: 3, text: 'Committee Presentation', color: '#000099' },
                  { id: 4, text: 'Vendor Presentation', color: '#000099' }
                ],
              }
            ]
          )
        case '639242d4971a30726884fd85':
          return (
            [
              {
                fieldName: 'innovative',
                title: 'Innovative Solutions Committee',
                allowMultiple: true,
                instances: [
                  { id: 1, text: 'Committee Update', color: '#000099' },
                  { id: 2, text: 'Monthly Meeting', color: '#000099' },
                  { id: 3, text: 'Committee Presentation', color: '#000099' },
                  { id: 4, text: 'Vendor Presentation', color: '#000099' }
                ],
              }
            ]
          )
        case '65ce5e76e54fd763dfc9c0ae':
          return (
            [
              {
                fieldName: 'content',
                title: 'Content Sub Committee',
                allowMultiple: true,
                instances: [
                  { id: 1, text: 'Committee Update', color: '#000099' },
                  { id: 2, text: 'Monthly Meeting', color: '#000099' }
                ],
              }
            ]
          )
        default:

          return (
            [
              {
                fieldName: 'general',
                title: 'General Sessions',
                allowMultiple: true,
                instances: [
                  { id: 1, text: 'Committee Update', color: '#4caf50' },
                  { id: 2, text: 'Monthly Meeting', color: '#4caf50' },
                  { id: 3, text: 'Committee Presentation', color: '#4caf50' },
                  { id: 4, text: 'Vendor Presentation', color: '#4caf50' }
                ],
              }
            ]
          )
      };
    };



    return (

      <div>
        <div role="main" className="container">
          <div className="jumbotron p-3 p-md-5 text-white rounded" style={{ background: "#245aa6" }}>
            <div className="row">
              <Container>
                {/* Stack the columns on mobile by making one full-width and the other half-width */}
                <Row>
                  <Col xl={12} >
                    <h4 className="display-5 font-italic">Welcome to the {group.name}</h4>
                    <p className="lead my-3">{group.description}</p>
                  </Col>

                </Row>

                <Row>
                  <Col xl={6}>

                    <div className="card-body text-white">
                      <h4 className="card-title">Chair: {chair.name} {chair.lastName} </h4>
                      <p className="card-text">Voluntering from: <b>{chair.companyName}</b></p>
                      <a href={`/user/${chair._id}`} className="btn btn-primary">More about {chair.name}</a>

                    </div>

                  </Col>
                  <Col xl={6}>

                    <div className="card-body text-white">
                      <h4 className="card-title">Co-Chair: {cochair.name} {cochair.lastName}</h4>
                      <p className="card-text">Voluntering from: <b>{cochair.companyName}</b></p>
                      <a href={`/user/${cochair._id}`} className="btn btn-primary">More about {cochair.name}</a>
                    </div>

                  </Col>
                </Row>


              </Container>
            </div>

          </div>
        </div>

        <Container>
          <Row>
            <Col xl={8}>
              <div className="card-body">
                <h4> Hello {isAuthenticated().name}</h4>
              </div>
            </Col>
            <Col xl={4}>
              <div className="card-body">               
                <a href={`/admin/committee/${this.state.groupId}`} className="btn btn-danger">Administer committee</a>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href="/usershome">Home</Breadcrumb.Item>
            <Breadcrumb.Item href={`/grouplist`}>
              back to committee list
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{group.name}</Breadcrumb.Item>
          </Breadcrumb>
          <br />
        </Container>

     
        <div className="row" >
          <aside className="col-md-4 blog-sidebar">
            <Nav className="flex-column">
              <Nav.Link onClick={() => this.handleSideMenu('posts')} eventKey="link-1"><ForumIcon /> All Posts</Nav.Link>
              <Nav.Link onClick={() => this.handleSideMenu('calendar')} eventKey="link-2"><CalendarMonthIcon />  Commitee Calendar</Nav.Link>
              <Nav.Link onClick={() => this.handleSideMenu('events')} eventKey="link-3"><EmojiEventsIcon />  SDSIC Events</Nav.Link>
              <Nav.Link onClick={() => this.handleSideMenu('content')} eventKey="link-4"><SourceIcon />  Shared Content</Nav.Link>
              <Nav.Link onClick={() => this.handleSideMenu('monthly')} eventKey="link-5"><RecordVoiceOverIcon />  Monthly Recordings</Nav.Link>
              <Nav.Link onClick={() => this.handleSideMenu('monthlyinvite')} eventKey="link-5"><RsvpIcon />  Monthly Meeting Invite</Nav.Link>

            </Nav>
          </aside>

          <div className="col-md-8 blog-main">
            <h3 className="pb-3 mb-4 font-italic border-bottom">Latest {group.name} Updates</h3>

            <div className="blog-post">
              {(this.state.activeContent === 'monthlyinvite') &&
                <>
                  <MonthlyMeetingList groupId={this.props.match.params.groupId} />
                  <br />
                </>
              }

              {(this.state.activeContent === 'posts') &&
                <>
                  <Link to={`/forumpost/create/${this.props.match.params.groupId}`}>Create a New Post</Link>
                  <ForumPosts groupId={this.state.groupId} />
                </>
              }
              {(this.state.activeContent === 'events') && <PastEventList />}

              {(this.state.activeContent === 'content') &&
                <>
                  <h4 className="font-italic">Shared Content</h4>
                  <FileList groupId={this.props.match.params.groupId} />
                  <a href={`/file/create/${this.props.match.params.groupId}`} className="btn btn-primary">add file</a>
                  <br />
                </>
              }

              {(this.state.activeContent === 'monthly') &&
                <>
                  <PresentationList groupId={this.props.match.params.groupId} />
                  <br />
                </>
              }

              {(this.state.activeContent === 'calendar') &&
                <>
                  <Row>
                    <h4 className="font-italic">{group.name} events</h4>
                    {this.state.calendarName &&
                      <EventCalendar
                        scheduleName={this.state.calendarName}
                        isAdmin={false}
                        chooseTracks={false}
                        chooseDays={false}
                        currentDate={moment().format("YYYY-MM-DD")}
                        excludedDays={[0, 1, 6, 7]}
                        weekView={false}
                        resourceList={getresourceList()}
                        mainResourceName={this.state.mainResourceName}
                        calendarHeight={800}
                        startTime={10}
                        endTime={19}
                      />
                    }
                  </Row>
                </>
              }
              <br />
              <br />
            </div>

          </div>

        </div>

        <Footer />
      </div >
    )
  }
};


export default SingleGroup;