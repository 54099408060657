import React from "react";
// import Posts from "../post/Posts";
import Footer from '../core/Footer';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const memberList = [
    { companyLogo: 'https://logo.clearbit.com/3phaseassociates.com' },
    { companyLogo: 'https://logo.clearbit.com/aecc.com' },
    { companyLogo: 'https://logo.clearbit.com/aecom.com' },
    { companyLogo: 'https://logo.clearbit.com/aep.com' },
    { companyLogo: 'https://logo.clearbit.com/aetco.us' },
    { companyLogo: 'https://logo.clearbit.com/allenhoshall.com' },
    { companyLogo: 'https://logo.clearbit.com/amce.com' },
    { companyLogo: 'https://logo.clearbit.com/amecfw.com' },
    { companyLogo: 'https://logo.clearbit.com/ameren.com' },
    { companyLogo: 'https://logo.clearbit.com/ampirical.com' },
    { companyLogo: 'https://logo.clearbit.com/aps.com' },
    { companyLogo: 'http://aseng.com/' },
    { companyLogo: 'https://logo.clearbit.com/asplundh.com' },
    { companyLogo: 'https://logo.clearbit.com/assetcompany.com' },
    { companyLogo: 'https://asteng.com/assets/images/ast-logo-nav.png' },
    { companyLogo: 'https://logo.clearbit.com/asti.com' },
    { companyLogo: 'https://logo.clearbit.com/atce.com' },
    { companyLogo: 'https://logo.clearbit.com/atwell-group.com' },
    { companyLogo: 'https://logo.clearbit.com/austinenergy.com' },
    { companyLogo: 'https://logo.clearbit.com/autodesk.com' },
    { companyLogo: 'https://logo.clearbit.com/automationforce.com' },
    { companyLogo: 'https://logo.clearbit.com/balfourbeatty.com' },
    { companyLogo: 'https://barkleytech.ca/wp-content/uploads/2020/02/Barkley_Technologies-05.png' },
    { companyLogo: 'https://logo.clearbit.com/bba.ca' },
    { companyLogo: 'https://logo.clearbit.com/bchydro.com' },
    { companyLogo: 'https://logo.clearbit.com/beca.com' },
    { companyLogo: 'https://logo.clearbit.com/beld.com' },
    { companyLogo: 'https://logo.clearbit.com/BENTLEY.COM' },
    { companyLogo: 'https://logo.clearbit.com/betaengineering.com' },
    { companyLogo: 'https://logo.clearbit.com/bge.com' },
    { companyLogo: 'https://logo.clearbit.com/bhge.com' },
    { companyLogo: 'https://logo.clearbit.com/bhienergy.com' },
    { companyLogo: 'https://logo.clearbit.com/bhmg.com' },
    { companyLogo: 'https://logo.clearbit.com/bki.cc' },
    { companyLogo: 'https://logo.clearbit.com/blattnercompany.com' },
    { companyLogo: 'https://logo.clearbit.com/blattnerenergy.com' },
    { companyLogo: 'https://logo.clearbit.com/booth-assoc.com' },
    { companyLogo: 'https://logo.clearbit.com/borton-lawson.com' },
    { companyLogo: 'https://logo.clearbit.com/bpu.com' },
    { companyLogo: 'https://logo.clearbit.com/brandt.us' },
    { companyLogo: 'https://logo.clearbit.com/broadspectrum.com' },
    { companyLogo: 'https://sdsconsortium.com/images/BruTecLogo.png' },
    { companyLogo: 'https://logo.clearbit.com/burnsmcd.com' },
    { companyLogo: 'https://logo.clearbit.com/bv.com' },
    { companyLogo: 'https://logo.clearbit.com/cai-engr.com' },
    { companyLogo: 'https://logo.clearbit.com/Cantega.com' },
    { companyLogo: 'https://logo.clearbit.com/cap-az.com' },
    { companyLogo: 'https://castlegateeng.com/index_htm_files/9423.png' },
    { companyLogo: 'https://le-cdn.hibuwebsites.com/e80ab386b5cd46afb7574456c64ac27c/dms3rep/multi/opt/Logo_Transparent+Background-366w.png' },
    { companyLogo: 'https://logo.clearbit.com/cei.com' },
    { companyLogo: 'https://logo.clearbit.com/centerpointenergy.com' },
    { companyLogo: 'https://logo.clearbit.com/centurylink.net' },
    { companyLogo: 'https://logo.clearbit.com/charter.net' },
    { companyLogo: 'https://www.chimax.ca/sites/all/themes/chimax/logo.png' },
    { companyLogo: 'https://logo.clearbit.com/CIMA.CA' },
    { companyLogo: 'https://logo.clearbit.com/cityofhomestead.com' },
    { companyLogo: 'https://logo.clearbit.com/cityutilities.net' },
    { companyLogo: 'https://clayelectric.com/sites/all/themes/clay/img/logo-cec.png' },
    { companyLogo: 'https://logo.clearbit.com/comed.com' },
    { companyLogo: 'https://logo.clearbit.com/coned.com' },
    { companyLogo: 'https://logo.clearbit.com/constellium.com' },
    { companyLogo: 'https://logo.clearbit.com/coolorange.com' },
    { companyLogo: 'https://logo.clearbit.com/cormorantutility.com' },
    { companyLogo: 'https://logo.clearbit.com/crossdiscipline.com' },
    { companyLogo: 'https://logo.clearbit.com/csu.org' },
    { companyLogo: 'https://logo.clearbit.com/cybirical.com' },
    { companyLogo: 'https://logo.clearbit.com/dashiell.com' },
    { companyLogo: 'https://logo.clearbit.com/deainc.com' },
    { companyLogo: 'https://irp-cdn.multiscreensite.com/729e0ef2/dms3rep/multi/DMCLogotype.svg' },
    { companyLogo: 'https://logo.clearbit.com/deloitte.com.au' },
    { companyLogo: 'https://logo.clearbit.com/deloney.com' },
    { companyLogo: 'https://logo.clearbit.com/dewberry.com' },
    { companyLogo: 'https://logo.clearbit.com/distran.com' },
    { companyLogo: 'https://logo.clearbit.com/dominionenergy.com' },
    { companyLogo: 'https://logo.clearbit.com/du.edu' },
    { companyLogo: 'https://logo.clearbit.com/duke-energy.com' },
    { companyLogo: 'https://logo.clearbit.com/easi.com' },
    { companyLogo: 'https://logo.clearbit.com/east-solutions.co.uk' },
    { companyLogo: 'https://logo.clearbit.com/ECIUSA.COM' },
    { companyLogo: 'https://logo.clearbit.com/ecsourceservices.com' },
    { companyLogo: 'https://logo.clearbit.com/eiffage.com' },
    { companyLogo: 'https://2zc0ic1ugt2f306g813cda6s-wpengine.netdna-ssl.com/wp-content/uploads/2020/01/Enercon_LOGO.svg' },
    { companyLogo: 'https://logo.clearbit.com/energyq.com.au' },
    { companyLogo: 'https://logo.clearbit.com/ennova.com.do' },
    { companyLogo: 'https://logo.clearbit.com/entergy.com' },
    { companyLogo: 'https://logo.clearbit.com/ergon.com.au' },
    { companyLogo: 'https://logo.clearbit.com/etesa.com.pa' },
    { companyLogo: 'https://logo.clearbit.com/eversource.com' },
    { companyLogo: 'https://logo.clearbit.com/execupower.com' },
    { companyLogo: 'https://logo.clearbit.com/FAYpwc.com' },
    { companyLogo: 'https://logo.clearbit.com/FIRSTENERGYCORP.COM' },
    { companyLogo: 'https://logo.clearbit.com/fisherarnold.com' },
    { companyLogo: 'https://logo.clearbit.com/fpl.com' },
    { companyLogo: 'https://logo.clearbit.com/gaiconsultants.com' },
    { companyLogo: 'https://logo.clearbit.com/gatrans.com' },
    { companyLogo: 'https://logo.clearbit.com/gcpud.org' },
    { companyLogo: 'https://logo.clearbit.com/ge.com' },
    { companyLogo: 'https://logo.clearbit.com/gissurveyors.com' },
    { companyLogo: 'https://logo.clearbit.com/gmail.com' },
    { companyLogo: 'https://logo.clearbit.com/gpdgroup.com' },
    { companyLogo: 'https://gvea.com/wp-content/uploads/2022/07/GV-Four-color-logo-no-TSE.jpg' },
    { companyLogo: 'https://logo.clearbit.com/hagerman.com' },
    { companyLogo: 'https://logo.clearbit.com/hdrinc.com' },
    { companyLogo: 'https://logo.clearbit.com/hepn.com' },
    { companyLogo: 'https://logo.clearbit.com/hmiservices.com' },
    { companyLogo: 'https://h-mv.com/img/logo-FC.png' },
    { companyLogo: 'https://logo.clearbit.com/holtdesignco.com' },
    { companyLogo: 'https://logo.clearbit.com/hydroone.com' },
    { companyLogo: 'https://logo.clearbit.com/idahopower.com' },
    { companyLogo: 'https://logo.clearbit.com/ieng.tech' },    
    { companyLogo: 'https://logo.clearbit.com/jacobs.com' },
    { companyLogo: 'https://logo.clearbit.com/JDRM.com' },
    { companyLogo: 'https://logo.clearbit.com/kapower.us' },
    { companyLogo: 'https://logo.clearbit.com/kcpl.com' },
    { companyLogo: 'https://static.wixstatic.com/media/8187cd_0f108b15e6e948ddb9d56b52be13c188.jpg/v1/fill/w_246,h_190,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/8187cd_0f108b15e6e948ddb9d56b52be13c188.jpg' },
    { companyLogo: 'https://logo.clearbit.com/kiewit.com' },
    { companyLogo: 'https://logo.clearbit.com/lcec.net' },
    { companyLogo: 'https://logo.clearbit.com/LCRA.org' },
    { companyLogo: 'https://logo.clearbit.com/leidos.com' },
    { companyLogo: 'https://logo.clearbit.com/les.com' },
    { companyLogo: 'https://logo.clearbit.com/lexeng.com' },
    { companyLogo: 'https://logo.clearbit.com/libertyutilities.com' },
    { companyLogo: 'https://logo.clearbit.com/librestream.com' },
    { companyLogo: 'https://logo.clearbit.com/live.com' },
    { companyLogo: 'https://logo.clearbit.com/manganinc.com' },
    { companyLogo: 'https://logo.clearbit.com/mccordeng.com' },
    { companyLogo: 'https://logo.clearbit.com/mcpheeusa.com' },
    { companyLogo: 'https://logo.clearbit.com/me.com' },
    { companyLogo: 'https://logo.clearbit.com/medicinehat.ca' },
    { companyLogo: 'https://logo.clearbit.com/mesainc.com' },
    { companyLogo: 'https://logo.clearbit.com/midamerican.com' },
    { companyLogo: 'https://logo.clearbit.com/minnkota.com' },
    { companyLogo: 'https://logo.clearbit.com/mkec.com' },
    { companyLogo: 'https://mleainc.com/images/icons/LogoWhole.png' },
    { companyLogo: 'https://logo.clearbit.com/mlgw.org' },
    { companyLogo: 'https://logo.clearbit.com/mortenson.com' },
    { companyLogo: 'https://logo.clearbit.com/mottmac.com' },
    { companyLogo: 'https://logo.clearbit.com/msn.com' },
    { companyLogo: 'https://logo.clearbit.com/muni.org' },
    { companyLogo: 'https://logo.clearbit.com/nationalgrid.com' },
    { companyLogo: 'https://logo.clearbit.com/neieng.com' },
    { companyLogo: 'https://logo.clearbit.com/nespower.com' },
    { companyLogo: 'https://logo.clearbit.com/nexgrade.com' },
    { companyLogo: 'https://logo.clearbit.com/nfluencr.net' },
    { companyLogo: 'https://logo.clearbit.com/nipco.coop' },
    { companyLogo: 'https://www.nordmin.com/site/templates/img/logo.png' },
    { companyLogo: 'https://logo.clearbit.com/novec.com' },
    { companyLogo: 'https://logo.clearbit.com/nppd.com' },
    { companyLogo: 'http://nrg-concepts.com/wp-content/themes/energy-concepts/images/energy-concepts-header-logo.png' },
    { companyLogo: 'https://logo.clearbit.com/nspower.ca' },
    { companyLogo: 'https://logo.clearbit.com/nu.com' },
    { companyLogo: 'https://logo.clearbit.com/nv5.com' },
    { companyLogo: 'https://logo.clearbit.com/nvenergy.com' },
    { companyLogo: 'https://logo.clearbit.com/nypa.gov' },
    { companyLogo: 'https://logo.clearbit.com/nyseg.com' },
    { companyLogo: 'https://logo.clearbit.com/olsson.com' },
    { companyLogo: 'https://logo.clearbit.com/oppd.com' },
    { companyLogo: 'https://logo.clearbit.com/orbitalengr.com' },
    { companyLogo: 'https://logo.clearbit.com/orgillce.com' },
    { companyLogo: 'https://logo.clearbit.com/osmose.com' },
    { companyLogo: 'https://logo.clearbit.com/otpco.com' },
    { companyLogo: 'https://logo.clearbit.com/outlook.com' },
    { companyLogo: 'https://logo.clearbit.com/PacifiCorp.Com' },
    { companyLogo: 'https://logo.clearbit.com/pan-energy.com' },
    { companyLogo: 'https://logo.clearbit.com/patrickco.com' },
    { companyLogo: 'https://logo.clearbit.com/pbworld.com' },
    { companyLogo: 'https://logo.clearbit.com/pdengineers.com' },
    { companyLogo: 'https://logo.clearbit.com/pec1.com' },
    { companyLogo: 'https://logo.clearbit.com/peco-energy.com' },
    { companyLogo: 'https://logo.clearbit.com/pennoni.com' },
    { companyLogo: 'https://logo.clearbit.com/pepco.com' },
    { companyLogo: 'https://logo.clearbit.com/pge.com' },
    { companyLogo: 'https://logo.clearbit.com/pgn.com' },
    { companyLogo: 'https://logo.clearbit.com/phalconusa.com' },
    { companyLogo: 'https://logo.clearbit.com/phasorllc.com' },
    { companyLogo: 'https://logo.clearbit.com/pickettusa.com' },
    { companyLogo: 'https://logo.clearbit.com/pike.com' },
    { companyLogo: 'https://logo.clearbit.com/pnodeinc.com' },
    { companyLogo: 'https://logo.clearbit.com/powercor.com.au' },
    { companyLogo: 'https://logo.clearbit.com/powerelectric.com' },
    { companyLogo: 'https://logo.clearbit.com/powereng.com' },
    { companyLogo: 'https://logo.clearbit.com/powergridmail.com' },
    { companyLogo: 'https://logo.clearbit.com/powersouth.com' },
    { companyLogo: 'https://logo.clearbit.com/powersystem.org' },
    { companyLogo: 'https://logo.clearbit.com/PPLWeb.com' },
    { companyLogo: 'https://logo.clearbit.com/protech.engineering' },
    { companyLogo: 'https://logo.clearbit.com/pse.com' },
    { companyLogo: 'https://logo.clearbit.com/pseg.com' },
    { companyLogo: 'https://logo.clearbit.com/ptd.net' },
    { companyLogo: 'https://logo.clearbit.com/pwrcentric.com' },
    { companyLogo: 'https://logo.clearbit.com/rand.com' },
    { companyLogo: 'https://logo.clearbit.com/rcmt.com' },
    { companyLogo: 'https://logo.clearbit.com/revamp-eng.com' },
    { companyLogo: 'https://logo.clearbit.com/risingedgegroup.com' },
    { companyLogo: 'https://logo.clearbit.com/riversideca.gov' },
    { companyLogo: 'https://logo.clearbit.com/root3engineering.com' },
    { companyLogo: 'https://logo.clearbit.com/rp-l.com' },
    { companyLogo: 'https://logo.clearbit.com/rrccompanies.com' },
    { companyLogo: 'https://logo.clearbit.com/rue-inc.com' },
    { companyLogo: 'https://logo.clearbit.com/saberpower.com' },
    { companyLogo: 'https://logo.clearbit.com/santeecooper.com' },
    { companyLogo: 'https://logo.clearbit.com/sapowernetworks.com.au' },
    { companyLogo: 'https://logo.clearbit.com/sargentlundy.com' },
    { companyLogo: 'https://logo.clearbit.com/saskpower.com' },
    { companyLogo: 'https://logo.clearbit.com/satel-sa.com' },
    { companyLogo: 'https://logo.clearbit.com/saulsbury.com' },
    { companyLogo: 'https://logo.clearbit.com/sce.com' },
    { companyLogo: 'https://logo.clearbit.com/scottishpower.com' },
    { companyLogo: 'https://logo.clearbit.com/sdi-ingenieria.cl' },
    { companyLogo: 'https://logo.clearbit.com/seattle.gov' },
    { companyLogo: 'https://www.sefcor.com/images/logo-revised.png' },
    { companyLogo: 'https://logo.clearbit.com/selectpowersystems.com' },
    { companyLogo: 'https://logo.clearbit.com/selinc.com' },
    { companyLogo: 'https://logo.clearbit.com/semprautilities.com' },
    { companyLogo: 'https://logo.clearbit.com/seucontractor.com' },
    { companyLogo: 'https://logo.clearbit.com/shaw.ca' },
    { companyLogo: 'https://logo.clearbit.com/siemens.com' },
    { companyLogo: 'https://logo.clearbit.com/siemens-energy.com' },
    { companyLogo: 'https://logo.clearbit.com/slakose.com' },
    { companyLogo: 'https://logo.clearbit.com/socalgas.com' },
    { companyLogo: 'https://logo.clearbit.com/southernco.com' },
    { companyLogo: 'https://logo.clearbit.com/southernstatesllc.com' },
    { companyLogo: 'https://logo.clearbit.com/spatialbiz.com' },
    { companyLogo: 'https://logo.clearbit.com/specservices.com' },
    { companyLogo: 'https://logo.clearbit.com/srpnet.com' },
    { companyLogo: 'https://logo.clearbit.com/stanleygroup.com' },
    { companyLogo: 'https://logo.clearbit.com/stantec.com' },
    { companyLogo: 'https://logo.clearbit.com/stillwater-sc.com' },
    { companyLogo: 'https://sdsconsortium.com/images/SECo_Logo.png' },
    { companyLogo: 'https://logo.clearbit.com/synergis.com' },
    { companyLogo: 'https://sdsconsortium.com/images/TIER 1 Logo_Trans.png' },
    { companyLogo: 'https://logo.clearbit.com/tatmansubstations.com' },
    { companyLogo: 'https://logo.clearbit.com/tdworld.com' },
    { companyLogo: 'https://logo.clearbit.com/teamfishel.com' },
    { companyLogo: 'https://logo.clearbit.com/teamworknet.com' },
    { companyLogo: 'https://logo.clearbit.com/tecoenergy.com' },
    { companyLogo: 'https://logo.clearbit.com/tep.com' },
    { companyLogo: 'https://logo.clearbit.com/test.com' },
    { companyLogo: 'https://logo.clearbit.com/thinkesc.com' },
    { companyLogo: 'https://logo.clearbit.com/tid.org' },
    { companyLogo: 'https://logo.clearbit.com/tnb.com' },
    { companyLogo: 'https://logo.clearbit.com/TOREngineering.com' },
    { companyLogo: 'https://logo.clearbit.com/tothassociates.com' },
    { companyLogo: 'https://logo.clearbit.com/transcendh2o.com' },
    { companyLogo: 'https://logo.clearbit.com/trccompanies.com' },
    { companyLogo: 'https://logo.clearbit.com/trcsolutions.com' },
    { companyLogo: 'https://logo.clearbit.com/trenwa.com' },
    { companyLogo: 'https://logo.clearbit.com/triaxiseng.com' },
    { companyLogo: 'https://logo.clearbit.com/tristategt.org' },
    { companyLogo: 'https://logo.clearbit.com/tva.gov' },
    { companyLogo: 'https://logo.clearbit.com/ueci.com' },
    { companyLogo: 'https://logo.clearbit.com/uinet.com' },
    { companyLogo: 'https://logo.clearbit.com/ulteig.com' },
    { companyLogo: 'https://logo.clearbit.com/us.abb.com' },
    { companyLogo: 'https://logo.clearbit.com/usppllc.com' },
    { companyLogo: 'https://logo.clearbit.com/utilitydesign.co' },
    { companyLogo: 'https://logo.clearbit.com/valmont.com' },
    { companyLogo: 'https://logo.clearbit.com/velco.com' },
    { companyLogo: 'https://logo.clearbit.com/vscenario.com' },
    { companyLogo: 'https://logo.clearbit.com/watanaengineering.com' },
    { companyLogo: 'https://logo.clearbit.com/wescodist.com' },
    { companyLogo: 'https://logo.clearbit.com/westarenergy.com' },
    { companyLogo: 'https://logo.clearbit.com/westwoodps.com' },
    { companyLogo: 'https://logo.clearbit.com/worley.com' },
    { companyLogo: 'https://logo.clearbit.com/worleyparsons.com' },
    { companyLogo: 'https://logo.clearbit.com/wsp.com' },
    { companyLogo: 'https://logo.clearbit.com/yurika.com.au' },
    { companyLogo: 'https://logo.clearbit.com/zachrygroup.com' }
]
const PublicMemberLogos = () => (
    <>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <br/>
                    <br/>
                    <h3>
                        Our Current SDSIC Member Logos
                    </h3>
                    <p>
                        The Substation Design Solution (SDS) Industry Consortium is growing weekly and our members
                        continue to contribute to our success.
                    </p>
                </div>
            </div>
            <div className="row">                
                <div className="col-md-12">
                    <Row xs={1} md={6} className="g-4">
                        {memberList.map((member, i) =>
                            <Col>
                                <Card key={i}>
                                    <Card.Body>
                                        <img src={member.companyLogo} alt={member.companyLogo} width={125} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </div>

            </div>
        </div>

        <Footer />

    </>
);

export default PublicMemberLogos;