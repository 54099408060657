const jwt = require('jsonwebtoken'); // to generate signed token
const CryptoJS = require("crypto-js");

export const signup = user => {
    // alert(user.name + " : " + user.lastName + " : " + user.companyName + " : " + user.companyType + " : " + user.email + " : " + user.password)
    return fetch(`${process.env.REACT_APP_API_URL}/signup`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const signin = user => {
    
    return fetch(`${process.env.REACT_APP_API_URL}/zoekedSecureSignin`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const verifyIPSource = (ipAddress) => {

    let decodedAndVerified = null;
    if (sessionStorage.getItem('zoeked')) {
        const token = JSON.parse(sessionStorage.getItem('zoeked'))
         decodedAndVerified = jwt.verify(token.token, process.env.CRYPTO_SECRET);
    
        if (!decodedAndVerified) {
            // throw Error("verification returned null");
            // console.log({'error': "verification returned null" })            
            return false;            
        } else {
            // found token and decoded
            var bytes  = CryptoJS.AES.decrypt(decodedAndVerified, process.env.CRYPTO_SECRET);
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));                        
            
            if (decryptedData.ipInfo.IPv4 === ipAddress) {
                // IP matches
                return true;
            } else {
                // Ip no match
                return false;
            }
        }
    } else {
        return false;
    }
};

export const authenticate = (data, next) => {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem('zoeked', JSON.stringify(data));
        localStorage.setItem('zoeked', JSON.stringify(data));
        next();
    }
};

export const setName = (name, next) => {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem('username', JSON.stringify(name));
        next();
    }
};

export const signout = next => {
    // if (typeof window !== 'undefined') localStorage.removeItem('jwt');
    if (typeof window !== 'undefined') {
        sessionStorage.removeItem('zoeked');
        localStorage.removeItem('zoeked');
    }
    next();
    return fetch(`${process.env.REACT_APP_API_URL}/zoekedSecureSignout`, {
        method: 'GET'
    })
        .then(response => {
            // console.log('signout', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getIpData = async () => {
    return await fetch('https://geolocation-db.com/json/', {
        method: 'GET'
    })
        .then(response => {
            // console.log('IPdata', response.json());
            return response.json();
        })
        .catch(err => console.log(err));
};

// export const isAuthenticated = () => {
//     if (typeof window == 'undefined') {
//         return false;
//     }

//     if (localStorage.getItem('zoeked')) {
//         return JSON.parse(localStorage.getItem('zoeked'));
//     } else {
//         return false;
//     }

//     // if (localStorage.getItem('jwt')) {
//     //     return JSON.parse(localStorage.getItem('jwt'));
//     // } else {
//     //     return false;
//     // }
// };


export const isAuthenticated = () => {
    let decodedAndVerified = null;

    // if (typeof window == 'undefined') {
    //     return false;
    // }
    var ipAddressIn = null
    
    if (localStorage.getItem('zoeked')) {
        const token = JSON.parse(localStorage.getItem('zoeked'))
        var finalTokenInfo = null;
        decodedAndVerified = jwt.verify(token.token, 'BEgriUqhJVjhGMKDAFLYAqYclmVUlPzh');
        if (!decodedAndVerified) {
            // throw Error("verification returned null");
            // console.log({'error': "verification returned null" })
            finalTokenInfo = {
                error: "verification returned null"
            }
        } else {

            var bytes  = CryptoJS.AES.decrypt(decodedAndVerified, 'BEgriUqhJVjhGMKDAFLYAqYclmVUlPzh');
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));            

            finalTokenInfo = {
                token: token.token,
                userId: decryptedData._id,
                name: decryptedData.name,
                lastName: decryptedData.lastName,
                email: decryptedData.email,
                companyName: decryptedData.companyName,
                companyType: decryptedData.companyType,
                role: decryptedData.role,
                ipInfo: decryptedData.ipInfo
            }

            // for decrypted testing only
            // finalTokenInfo = {
            //     token: token.token,
            //     userId: decodedAndVerified._id,
            //     name: decodedAndVerified.name,
            //     email: decodedAndVerified.email,
            //     companyName: decodedAndVerified.companyName,
            //     role: decodedAndVerified.role,
            //     ipInfo: decodedAndVerified.ipInfo
            // }

        }

        // return JSON.parse(localStorage.getItem('zoeked'));
        return finalTokenInfo
    } else {
        return false;
    }
};

export const forgotPassword = bodyData => {    
    return fetch(`${process.env.REACT_APP_API_URL}/forgot-password`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyData)
    })
        .then(response => {
            // console.log('forgot password response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

export const resetPassword = resetInfo => {
    // alert(JSON.stringify(resetInfo))
    return fetch(`${process.env.REACT_APP_API_URL}/reset-password/`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(resetInfo)
    })
        .then(response => {
            // console.log('forgot password response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

export const socialLogin = user => {
    return fetch(`${process.env.REACT_APP_API_URL}/social-login/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        // credentials: "include", // works only in the same origin
        body: JSON.stringify(user)
    })
        .then(response => {
            // console.log('signin response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

export const linkedInAuth = (formData) => {
    
    return fetch(`${process.env.REACT_APP_API_URL}/linkedin-auth`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        // credentials: "include", // works only in the same origin        
        body: formData
    })
        .then(response => {
            // console.log('signin response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

export const linkedInDetails = (token) => {
    
    return fetch(`${process.env.REACT_APP_API_URL}/linkedin-details`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        },
        // credentials: "include", // works only in the same origin        
        form: {}
    })
        .then(response => {
            // console.log('signin response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

export const linkedInEmail = (token) => {
    
    return fetch(`${process.env.REACT_APP_API_URL}/linkedin-email`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        },
        form: {}
    })
        .then(response => {
            // console.log('signin response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

export const linkedInLogin = user => {
    return fetch(`${process.env.REACT_APP_API_URL}/linkedin-login/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        // credentials: "include", // works only in the same origin
        body: JSON.stringify(user)
    })
        .then(response => {
            // console.log('signin response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

export const linkedInPic = (token) => {
    
    return fetch(`${process.env.REACT_APP_API_URL}/linkedIn-pic`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        },
        form: {}
    })
        .then(response => {
            // console.log('signin response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

export const signinZendesk = (body) => {

    return fetch(`${process.env.REACT_APP_API_URL}/zendesk-auth`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        // credentials: "include", // works only in the same origin        
        body: JSON.stringify(body)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};