import React, { Component } from "react";
// import Posts from "../post/Posts";
import { Card, Button, Form, Col, Jumbotron, CardGroup, Carousel, Row } from 'react-bootstrap';
import BMLogo from '../images/Burns_and_McDonnell_Logo.jpg'
import Footer from './Footer';
import { sendMessage } from "../group/apigroup";
import moment from "moment";
import PowerLogo from '../images/PowerSponsorLogo.png';
import Burns from '../images/BurnsAndMcDonnelSponsorLogo.png';
import SBS from '../images/SBS_SponsorLogo.png';
import Sefcor from '../images/logo-sefcor.png';
import TRC from '../images/trcSponsor.png';
import Hagerman from '../images/hagerman.png';
import Ampirical from '../images/AmpiricalSponsorLogo3.png';
import Cai_eng from '../images/cai_eng_Sponsorship.png';
import Messa from '../images/mesaSponsorlogo.png';
import Esmart from '../images/eSmart_Sponsor.png';

import AFL from '../images/AFL_sponsorLogo.png'
import BV_SponsorLogo from '../images/BV_SponsorLogo.png'
import EnerSYS_sponsor from '../images/EnerSYS_sponsor.jpg'
import ECI_sponsor from '../images/ECI_sponsor.jpg'
import AMFLogo from '../images/AutomationforceSponsorLogo.png'
import Autodesk from '../images/AdskSponsorLogo.png';
import Gray from '../images/dGraySponsor.png'
import AMCE from '../images/AMCESponsorLogo.png';
import SSLogo from '../images/ssLogo.png';
import SAMLogo from '../images/SAMSponsorLogo.png';
import Faro from '../images/FaroSponsorLogo.png';
import Astra from '../images/AstraLogo.png';
import Primtech from '../images/EntegraSponsor.png';
import Imaginit from '../images/ImaginIT.png';
import Kiewit from '../images/KeiwitSponsorLogo.png';
import Bentley from '../images/BentleySponsorLogo.png'
import ABB from '../images/AbbSponsor.png';
import Quanta from '../images/QuantaSponsorLogo.png'

const cardWidth = '22rem'

class OurSponsors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            success: ''
        }
    }

    handleChange = name => event => {
        if (name == "email") {
            this.setState({ error: "" });
            this.setState({ responseTo: name, [name]: event.target.value });
        }
    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Your on the list!</h3>
                <p>Don't forget to check your inbox and your spam folder for updates from the SDSIC.</p>
                <p>We will use the email you've submitted to send you updates as we finalize the upcoming event.</p>
            </div>
        )
    };

    submitEmail = e => {
        const { email, error, success } = this.state;
        e.preventDefault();
        if (email == "") { this.setState({ error: "email required!" }) }

        if (email != "") {
            const mailMessage = {
                to: 'trevor.scullion@automationforce.com',
                from: email,
                subject: "SDSIC 2022 SPRING event list",
                Message: 'Please add ' + email + ' to the SDSIC 2022 SPRING event list.',
                html: "<p>Please add " + email + " to the SDSIC 2022 SPRING event list.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }
            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                this.setState({
                    email: '',
                    success: true,
                });
            });

            const userMessage = {
                to: email,
                from: 'donotreply@sdsconsortium.com',
                subject: "SDSIC 2022 SPRING event",
                Message: 'Your name is on the SDSIC 2022 SPRING event list.',
                html: "<p>Your name is on the SDSIC 2022 SPRING event list.</p><p>You will be kept up to date " +
                    "as we finalize the event details.</p><p>Please do not reply to this email.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }

            // make request to api to send message
            sendMessage(userMessage).then(data => { });
        };



    };


    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">

                            <div className="jumbotron">
                                <h2>
                                    Our SDSIC 2024 Corporate Sponsors
                                </h2>

                                <p> The SDSIC would not be possibel without all of our corporate sponsors. Because of them, we are able to host and administer our own web platform, purchase an enterprise Zoom account,
                                    and hold an annual conference.
                                </p>
                                <hr />
                                <h2>GOLD SPONSORS</h2>
                                <br />

                                <Row xs={1} md={2} lg={2} className="g-4">


                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Ampirical} />
                                        <Card.Body>
                                            <Card.Title>Ampirical</Card.Title>
                                            <Card.Text>
                                                Since its inception, Ampirical has been a sponsor of the SDSIC, marking a longstanding partnership rooted in dedication to industry advancement.
                                                Ampirical, alongside its affiliate companies Cybirical and GridIntel, deliver advanced engineering, holistic cybersecurity, and power industry-focused
                                                software, demonstrating their commitment to providing sustainable solutions for a reliable grid.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://ampirical.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={AFL} />
                                        <Card.Body>
                                            <Card.Title>ALF</Card.Title>
                                            <Card.Text>
                                                AFL provides industry-leading solutions, products and services to the energy, service provider, enterprise and industrial markets as
                                                well as a number of emerging markets. We manufacture, engineer and install fiber optic products and associated equipment. This includes: fiber optic cable.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.aflglobal.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>


                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={BV_SponsorLogo} />
                                        <Card.Body>
                                            <Card.Title>Black & Veatch</Card.Title>
                                            <Card.Text>
                                                Black & Veatch is a global engineering, procurement, consulting and construction company based in the Kansas City metropolitan area. Founded in 1915 in Kansas
                                                City, Missouri it is now headquartered in Overland Park, Kansas. Black & Veatch is our sponsor for this spring's conference in Kansas City!
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.bv.com/company/about-us/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Burns} />
                                        <Card.Body>
                                            <Card.Title>Burns & McDonnell</Card.Title>
                                            <Card.Text>
                                                Burns & McDonnell is an American architecture and engineering company based in Kansas City, Missouri, and is owned 100% by its employees. It was
                                                established in 1898 by Clinton Sumner Burns and Robert Emmett McDonnell, two engineers. In October 2021, it had a workforce of 7,600.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.burnsmcd.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Hagerman} />
                                        <Card.Body>
                                            <Card.Title> Hagerman & Company</Card.Title>
                                            <Card.Text>
                                                Hagerman & Company has been an Autodesk partner since 1984, and over the years we have earned many awards, certifications, and specializations.
                                                We take pride in these accomplishments, as they would not be possible without the experience, expertise, and exceptional work ethic that is embodied by our team of professionals at Hagerman & Company.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.hagerman.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Imaginit} />
                                        <Card.Body>
                                            <Card.Title>IMAGINiT</Card.Title>
                                            <Card.Text>
                                                IMAGINiT helps businesses gain competitive advantages through technology, software development, professional services, training, and support.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.imaginit.com/industry-solutions/energy-and-utilities/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Messa} />
                                        <Card.Body>
                                            <Card.Title>Mesa Associates</Card.Title>
                                            <Card.Text>
                                                Mesa is a woman/minority-owned full service engineering, procurement, & construction management firm covering power, defense,
                                                aerospace, industrial, commercial and municipal entities and has been a consistent sponsor of the SDSIC and has once again
                                                stepped up for 2024.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.mesainc.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={PowerLogo} />
                                        <Card.Body>
                                            <Card.Title>POWER Engineers</Card.Title>
                                            <Card.Text>
                                                Power engineering, also called power systems engineering, is a subfield of electrical engineering that deals with the generation, transmission,
                                                distribution, and utilization of electric power, and the electrical apparatus connected to such systems.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.powereng.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Primtech} />
                                        <Card.Body>
                                            <Card.Title>Primtech 3d</Card.Title>
                                            <Card.Text>
                                                The German-based company entegra developed the intelligent 3D engineering software primtech3d. entegra has
                                                supported our conference in the past and will be sponsoring our Spring 2024 event.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.primtech.com/en/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>
                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Quanta} />
                                        <Card.Body>
                                            <Card.Title>Quanta</Card.Title>
                                            <Card.Text>
                                                Quanta Services is an American corporation that provides infrastructure services for electric power, pipeline, industrial and communications industries. Capabilities include the planning, design, installation, program management, maintenance and repair of most types of network infrastructure.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://quantaservices.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={SBS} />
                                        <Card.Body>
                                            <Card.Title>SBS</Card.Title>
                                            <Card.Text>
                                                Since 2016, SBS has been a cornerstone of support for the SDSIC, generously sponsoring every live event and contributing expert
                                                technical personnel for enriching presentations and advanced training classes.  SBS is continuing
                                                its legacy of commitment as a Gold Sponsor for our upcoming Spring 2024 event in Kansas City.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.spatialbiz.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                </Row>

                                <hr />
                                {/* <h2>SILVER SPONSORS</h2>
                                <br />

                                <Row xs={1} md={2} lg={2} className="g-4">
                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Ampirical} />
                                        <Card.Body>
                                            <Card.Title>Ampirical</Card.Title>
                                            <Card.Text>
                                                Ampirical has sponsored the SDSIC since the very beginning and along with their member companies, Cybirical and
                                                GridIntel, they are sponsoring our Fall 2022 event in Kansas City.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://ampirical.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                   

                                  <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Bentley} />
                                        <Card.Body>
                                            <Card.Title>Bentley</Card.Title>
                                            <Card.Text>
                                                The Infrastructure Engineering Software Company. Bentley’s commitment extends beyond delivering the most comprehensive and integrated software
                                                paired with exceptional service, training, and 24/7 technical support. For over 39 years, Bentley has served the engineers and other professionals
                                                responsible for designing, constructing, and operating sustainable infrastructure, essential to the quality of life for everyone, everywhere.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://ampirical.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card> 
                                {/* </Row> */}



                                <hr />
                                <h2>BRONZE SPONSORS</h2>
                                <br />

                                <Row xs={1} md={2} lg={2} className="g-4">

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={EnerSYS_sponsor} />
                                        <Card.Body>
                                            <Card.Title>Enersys</Card.Title>
                                            <Card.Text>
                                                EnerSys® is an industrial technology leader serving the global community with mission critical stored energy solutions that meet the growing demand
                                                for energy efficiency, reliability and sustainability. We are driven by a passion to provide people everywhere with accessible power to help them work and live better.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.enersys.com/en/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>
                                </Row>

                                <hr />
                                <h2>PLATFORM SPONSORS</h2>
                                <br />

                                <Row xs={1} md={2} lg={2} className="g-4">

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Ampirical} />
                                        <Card.Body>
                                            <Card.Title>Ampirical</Card.Title>
                                            <Card.Text>
                                                Since its inception, Ampirical has been a sponsor of the SDSIC, marking a longstanding partnership rooted in dedication to industry advancement.
                                                Ampirical, alongside its affiliate companies Cybirical and GridIntel, deliver advanced engineering, holistic cybersecurity, and power industry-focused
                                                software, demonstrating their commitment to providing sustainable solutions for a reliable grid. Ampirical generously sponsors all our
                                                communication platforms, including Zoom and Brevo email, ensuring seamless connectivity and collaboration throughout the year.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://ampirical.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={AMFLogo} />
                                        <Card.Body>
                                            <Card.Title>Automationforce</Card.Title>
                                            <Card.Text>
                                                Automationforce has been the core sponsor of the SDSIC since its inception. Automationforce hosts and administers the SDSIC website and
                                                communications throughout the year.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://automationforce.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                </Row>

                                <hr />

                                <h2>ESCALATOR SPONSORS</h2>
                                <br />

                                <Row xs={1} md={2} lg={2} className="g-4">
                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={ECI_sponsor} />
                                        <Card.Body>
                                            <Card.Title>ECI</Card.Title>
                                            <Card.Text>
                                                As one of the country’s leading power utility consulting design firms, ECI operates from more than 30 regional offices throughout the nation,
                                                continuously expanding to serve the needs of our clients.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://eciusa.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>


                                </Row>

                                {/* <hr />

                                <h2>COFFEE/SNACKS BREAK SPONSORS</h2>
                                <br />

                                <Row xs={1} md={2} lg={2} className="g-4">
                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={TRC} />
                                        <Card.Body>
                                            <Card.Title>TRC</Card.Title>
                                            <Card.Text>
                                                TRC is a leader in delivering professional services for public, private and government clients. We are thrilled to sponsor the SDSIC in 2023.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.trccompanies.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>


                                </Row> */}

                                <hr />

                                <h2>NOTEBOOK SPONSORS</h2>
                                <br />

                                <Row xs={1} md={2} lg={2} className="g-4">
                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Kiewit} />
                                        <Card.Body>
                                            <Card.Title>Kiewit</Card.Title>
                                            <Card.Text>
                                                Kiewit is one of North America's largest and most respected construction and engineering organizations. Kiewit’s 20 years in the power industry
                                                covers diverse engineering, procurement and construction expertise in transmission, substations and distribution; solar; wind; hydrogen; energy
                                                storage; natural gas generation; nuclear; and carbon capture technologies.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.Kiewit.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>
                                </Row>
                                <hr />
                                <h2>TECHNOLOGY SPONSORS</h2>
                                <br />

                                <Row xs={1} md={2} lg={2} className="g-4">
                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={TRC} />
                                        <Card.Body>
                                            <Card.Title>TRC</Card.Title>
                                            <Card.Text>
                                                TRC is a leader in delivering professional services for public, private and government clients. We are thrilled to sponsor the SDSIC in 2024.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.trccompanies.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                </Row>
                                <hr />
                                <h2>CONTRIBUTING SPONSORS</h2>
                                <br />

                                <Row xs={1} md={2} lg={2} className="g-4">


                                    {/* <Card style={{ width: cardWidth }}>
                                    <Card.Img variant="top" src={Cai_eng} />
                                    <Card.Body>
                                        <Card.Title>Commonwealth</Card.Title>
                                        <Card.Text>
                                            Our primary goal? To help every client succeed, with every project and every plan. Our clients are utilities; independent power producers; governmental entities; leading industrial companies; and some of the world’s most prestigious academic, research and health care institutions. Together, we're making a powerful difference everywhere we can.
                                        </Card.Text>
                                        <Button onClick={() => window.open("https://www.cai-engr.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                    </Card.Body>
                                </Card> */}

                                    {/* <Card style={{ width: cardWidth }}>
                                    <Card.Img variant="top" src={Gray} />
                                    <Card.Body>
                                        <Card.Title>DiGioia Gray</Card.Title>
                                        <Card.Text>
                                            At DiGioia Gray we build on our technical expertise, best practices, and access to cutting edge technology every day - this is part of our culture and standard of practice. Our innovative, value-engineering solutions will continue to be supplemented by our research and academic ties.
                                        </Card.Text>
                                        <Button onClick={() => window.open("https://www.digioiagray.com/index.html", "_blank")} variant="primary">Visit Sponsor</Button>
                                    </Card.Body>
                                </Card>

                                <Card style={{ width: cardWidth }}>
                                    <Card.Img variant="top" src={Esmart} />
                                    <Card.Body>
                                        <Card.Title>eSmartSystems.com/</Card.Title>
                                        <Card.Text>
                                            eSmart Systems is a leading provider of AI-powered services and solutions for the inspection and maintenance of critical energy infrastructure.
                                        </Card.Text>
                                        <Button onClick={() => window.open("https://www.esmartsystems.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                    </Card.Body>
                                </Card>

                                <Card style={{ width: cardWidth }}>
                                    <Card.Img variant="top" src={Hagerman} />
                                    <Card.Body>
                                        <Card.Title>Hagerman & Company</Card.Title>
                                        <Card.Text>
                                            Hagerman & Company combines best-in-class software and professional services with a comprehensive Customer Success strategy to make your projects more efficient, productive, and profitable.
                                        </Card.Text>
                                        <Button onClick={() => window.open("https://www.hagerman.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                    </Card.Body>
                                </Card>

                                <Card style={{ width: cardWidth }}>
                                    <Card.Img variant="top" src={Imaginit} />
                                    <Card.Body>
                                        <Card.Title>IMAGINiT</Card.Title>
                                        <Card.Text>
                                            IMAGINiT helps businesses gain competitive advantages through technology, software development, professional services, training, and support.
                                        </Card.Text>
                                        <Button onClick={() => window.open("https://www.imaginit.com/industry-solutions/energy-and-utilities/", "_blank")} variant="primary">Visit Sponsor</Button>
                                    </Card.Body>
                                </Card>

                                <Card style={{ width: cardWidth }}>
                                    <Card.Img variant="top" src={Primtech} />
                                    <Card.Body>
                                        <Card.Title>Primtech 3d</Card.Title>
                                        <Card.Text>
                                            The German-based company entegra developed the intelligent 3D engineering software primtech3d. entegra has supported our conference in the past and will be sponsoring our Fall 2022 event.
                                        </Card.Text>
                                        <Button onClick={() => window.open("https://www.primtech.com/en/", "_blank")} variant="primary">Visit Sponsor</Button>
                                    </Card.Body>
                                </Card> */}

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={ABB} />
                                        <Card.Body>
                                            <Card.Title>ABB</Card.Title>
                                            <Card.Text>
                                                ABB is a technology leader in electrification and automation, enabling a more sustainable and resource-efficient future.
                                                The company’s solutions connect engineering know-how and software to optimize how things are manufactured, moved, powered and operated.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://global.abb/group/en/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>
                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Astra} />
                                        <Card.Body>
                                            <Card.Title>AstraWorks</Card.Title>
                                            <Card.Text>
                                                AstraWorks is a national recruiting agency with a niche focus on finding talent in the power, renewable energy, design/build and IT markets and
                                                have once again stepped up to sponsor our 2024 event.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.astra-works.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>

                                    <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Sefcor} />
                                        <Card.Body>
                                            <Card.Title>Sefcor</Card.Title>
                                            <Card.Text>
                                                Sefcor is part of our leadership team helping to drive vendor adoption by understanding the needs of vendors
                                                across all content types and once again, they have sponsored our Kansas City Event in 2022.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.sefcor.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card>




                                    {/* <Card style={{ width: cardWidth }}>
                                        <Card.Img variant="top" src={Primtech} />
                                        <Card.Body>
                                            <Card.Title>Primtech 3d</Card.Title>
                                            <Card.Text>
                                                The German-based company entegra developed the intelligent 3D engineering software primtech3d. entegra has supported our conference in the past and will be sponsoring our Fall 2022 event.
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.primtech.com/en/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card> */}


                                    {/* <Card style={{ width: '22rem' }}>
                                        <Card.Img variant="top" src={SSLogo} />
                                        <Card.Body>
                                            <Card.Title>Southern States</Card.Title>
                                            <Card.Text>
                                                Southern States has been providing our members with high quality 3D models of their equipment
                                                for several years now and work with our content team closely. They are also sponsoring
                                                our Kansas City Event in 2022!
                                            </Card.Text>
                                            <Button onClick={() => window.open("https://www.southernstatesllc.com/", "_blank")} variant="primary">Visit Sponsor</Button>
                                        </Card.Body>
                                    </Card> */}

                                </Row>


                                {/* <p>
                                <div><a 
                                    href="https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Test%20Certificate&organizationId=123179&issueYear=2018&issueMonth=2&certUrl=https%3A%2F%2Fdocs.microsoft.com%2Fen-us%2Flearn%2Fcertifications%2Fd365-functional-consultant-sales&certId=1234" target="_blank">
                                        <img src="https://download.linkedin.com/desktop/add2profile/buttons/en_US.png " alt="LinkedIn Add to Profile button"/>
                                    </a>
                                </div>
                                </p> */}

                            </div>

                        </div>


                    </div >


                </div >
                <Footer />
            </>
        )
    }
}

export default OurSponsors