import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { isAuthenticated } from "../auth";
// import Footer from '../core/Footer';
import Paper from '@material-ui/core/Paper';
import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    Resources,
    WeekView,
    MonthView,
    Toolbar,
    DateNavigator,
    Appointments,
    AppointmentTooltip,
    AppointmentForm,
    DragDropProvider,
} from '@devexpress/dx-react-scheduler-material-ui';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Room from '@material-ui/icons/Room';
import IconButton from '@material-ui/core/IconButton';
// import MoreIcon from '@material-ui/icons/MoreVert';
import classNames from 'clsx';
import { getSchedule, updateSchedule } from './apiEvents';
import LoadingOverlay from 'react-loading-overlay';
// import { appointments } from './appointments.js';

// const style = ({ palette }) => ({
//     icon: {
//         color: palette.action.active,
//     },
//     textCenter: {
//         textAlign: 'center',
//     }
// });

const getClassByLocation = (classes, location) => {
    if (location === 'general') return classes.general;
    if (location === 'physical') return classes.physical;
    if (location === 'electrical') return classes.electrical;
    if (location === 'civil') return classes.civil;
    if (location === 'technology') return classes.technology;
    if (location === 'business') return classes.business;
    return classes.general;
};


const styles = theme => ({
    container: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-end',
    },
    text: {
        ...theme.typography.h7,
        marginRight: theme.spacing(2),
    },
    textCenter: {
        textAlign: 'center',
    },
    formControl: {
        // margin: theme.spacing(2),
        minWidth: 400,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    general: {
        background: 'url(images/GeneralSession.png)',
    },
    physical: {
        background: 'url(images/physcommittee.png)',
    },
    electrical: {
        background: 'url(images/ElectricalCommittee.png)',
    },
    civil: {
        background: 'url(images/civilCommittee.png)',
    },
    technology: {
        background: 'url(images/techcommittee.png)',
    },
    business: {
        background: 'url(images/bizStrategy.png)',
    },
    header: {
        height: '140px',
        backgroundSize: 'cover',
    },
    commandButton: {
        backgroundColor: 'rgba(255,255,255,0.65)',
    }
});

const Header = withStyles(styles, { name: 'Header' })(({
    children, appointmentData, classes, ...restProps
}) => (
    <AppointmentTooltip.Header
        {...restProps}
        className={classNames(getClassByLocation(classes, appointmentData.committeeType), classes.header)}
        appointmentData={appointmentData}
    >
        <IconButton
            /* eslint-disable-next-line no-alert */
            onClick={() => alert(JSON.stringify(appointmentData))}
            className={classes.commandButton}
        >
        </IconButton>
    </AppointmentTooltip.Header>
));

const Content = withStyles(styles, { name: 'Content' })(({
    children, appointmentData, classes, ...restProps
}) => (
    <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
        <Grid container alignItems="center">
            {/* <Grid item xs={2} className={classes.textCenter}>
                <Room className={classes.icon} />
            </Grid> */}
            <Grid item xs={10}>
                <hr />
                <p>{appointmentData.notes}</p>
                <hr />
                <h5>Zoom Meeting Information</h5>
                {/* {isAuthenticated() && */}
                <ul>
                    <li>Zoom ID: <b><u>{appointmentData.zoomId}</u></b></li>
                    <li>Zoom Passcode: <b><u>{appointmentData.zoompass}</u></b></li>
                    <li>Zoom Breakout Room (if applicable): <b><u>{appointmentData.zoomRoom} </u></b></li>
                    <li><a href={appointmentData.zoomLink}
                        target='_blank' rel="noopener noreferrer">Join Zoom Meeting
                    </a></li>
                </ul>
                {/* } */}
                {/* {!isAuthenticated() &&
                <p><i>You must be logged in to access the meeting details.</i></p>
                } */}

            </Grid>

        </Grid>
    </AppointmentTooltip.Content>
));



const ResourceSwitcher = withStyles(styles, { name: 'ResourceSwitcher' })(
    ({
        mainResourceName, onChange, classes, resources
    }) => (

        <div className={classes.container}>

            <div className={classes.text}>
                Choose a committee to follow:
            </div>
            <Select
                native
                value={mainResourceName}
                onChange={e => onChange(e.target.value)}
                inputProps={{
                    name: 'day',
                    id: 'filled-age-native-simple',
                }}
            >
                {resources.map(resource => (
                    <option key={resource.fieldName} value={resource.fieldName}>
                        {resource.title}
                        {resource.desc}
                    </option>
                ))}
            </Select>
        </div>
    ),
);

const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
    const onzoomIdChange = (nextValue) => {
        onFieldChange({ zoomId: nextValue });
    };
    const onPassCodeChange = (nextValue) => {
        onFieldChange({ zoompass: nextValue });
    };
    const onZoomLinkChange = (nextValue) => {
        onFieldChange({ zoomLink: nextValue });
    };

    const onRoomChange = (nextValue) => {
        onFieldChange({ zoomRoom: nextValue });
    };

    const onComTypeChange = (nextValue) => {
        onFieldChange({ committeeType: nextValue });
    };

    return (
        <AppointmentForm.BasicLayout
            appointmentData={appointmentData}
            onFieldChange={onFieldChange}
            {...restProps}
        >
            <AppointmentForm.Label
                text="Committe Name"
                type="title"
            />
            <AppointmentForm.TextEditor
                value={appointmentData.committeeType}
                
                onValueChange={onComTypeChange}
                placeholder="Committee name for header"
            />

            <AppointmentForm.Label
                text="Zoom Meeting ID"
                type="title"
            />
            <AppointmentForm.TextEditor
                value={appointmentData.zoomId}                
                onValueChange={onzoomIdChange}
                placeholder="Zoom Meeting ID"
            />

            <AppointmentForm.Label
                text="Zoom Passcode"
                type="title"
            />
            <AppointmentForm.TextEditor
                value={appointmentData.zoompass}
                onValueChange={onPassCodeChange}
                placeholder="Zoom Passcode"
            />

            <AppointmentForm.Label
                text="Breakout Room"
                type="title"
            />

            <AppointmentForm.TextEditor
                value={appointmentData.zoomRoom}
                onValueChange={onRoomChange}
                placeholder="Zoom Break Out Room"
            />


            <AppointmentForm.Label
                text="Zoom Web Link"
                type="title"
            />
            <AppointmentForm.TextEditor
                value={appointmentData.zoomLink}
                onValueChange={onZoomLinkChange}
                placeholder="Zoom WebLink"
            />


        </AppointmentForm.BasicLayout>
    );
};

const TextEditor = (props) => {
    // eslint-disable-next-line react/destructuring-assignment
    // if (props.type === 'multilineTextEditor') {
    //     return null;
    return <AppointmentForm.TextEditor {...props} />;
};


class LiveEventCalendar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAdmin: this.props.isAdmin,     // if admin, open and edit are available
            data: [],
            scheduleName: this.props.scheduleName,
            token: isAuthenticated() && isAuthenticated().token,
            mainResourceName: this.props.mainResourceName,
            viewName: this.props.excludedDays,
            addedAppointment: {},
            appointmentChanges: {},
            editingAppointment: undefined,
            resources: this.props.resourceList,
            loading: false,
            currentTimeZone: 10,
            endTime: 22,
            userId: isAuthenticated().userId,
            
        }

        this.changeMainResource = this.changeMainResource.bind(this);
        this.changeAddedAppointment = this.changeAddedAppointment.bind(this);
        this.changeAppointmentChanges = this.changeAppointmentChanges.bind(this);
        this.changeEditingAppointment = this.changeEditingAppointment.bind(this);
        this.commitChanges = this.commitChanges.bind(this);
        this.loadSchedule = this.loadSchedule.bind(this);
    }

    committeeAdmin = ''

    setCommitteeName() {
        
        switch (isAuthenticated().userId) {
            case '5e606fd24588f11c8f7736fa':
                this.committeeAdmin = 'physical'
                
            case '5e68d03ffdec0825509b8e18':
                this.committeeAdmin = 'physical'
            case '5e68c0483c55ce6f9a306b13':
                this.committeeAdmin = 'electrical'
            case '6012c335ca3c531f2bf10dc5':
                this.committeeAdmin = 'electrical'

            case '5fb0551e3ffeb261363b4a21':
                this.committeeAdmin = 'technology'
            case '5ea9ecda06aea5d11b3b030f':
                this.committeeAdmin = 'technology'

            case '5e68d0a0fdec0825509b8e19':
                this.committeeAdmin = 'civil'
            case '61658720a3a95854a99c3aef':
                this.committeeAdmin = 'civil'

            case '5e68d1cffdec0825509b8e1f':
                this.committeeAdmin = 'business'
            case '5e99ef77f8bc2c4f51caea96':
                this.committeeAdmin = 'business'

            case '5e61524763f778901ce39fc6':
                this.committeeAdmin = 'general'
            case '5e61560e33b0177e5838012d':
                this.committeeAdmin = 'general'

            case '5e2611483f99fa3bb4dd922a':
                this.committeeAdmin = 'general'
               
        }
    };

    async loadSchedule() {
        let allAppointments = []
        
        this.setState({ loading: true })
        await getSchedule(this.state.scheduleName, this.state.token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                allAppointments = data;
                this.setState({
                    data: data,
                });
                this.setState({ totalEvents: data.length })
                //   console.log( data)
            }
        });
        // await getSchedule('Fall 2022 Physical', this.state.token).then(data => {
        //     if (data.error) {
        //         console.log(data.error);
        //     } else {
        //         this.setState({
        //             data: data.concat(allAppointments),
        //         });
        //         allAppointments = data.concat(allAppointments);
        //         //   this.setState({ totalEvents: data.length })
        //         //   console.log(data)
        //     }
        // });
        // await getSchedule('Fall 2022 Electrical', this.state.token).then(data => {
        //     if (data.error) {
        //         console.log(data.error);
        //     } else {
        //         this.setState({
        //             data: data.concat(allAppointments),
        //         });
        //         allAppointments = data.concat(allAppointments);
        //         //   this.setState({ totalEvents: data.length })
        //         //   console.log(data)
        //     }
        // });
        // await getSchedule('Fall 2022 Technology', this.state.token).then(data => {
        //     if (data.error) {
        //         console.log(data.error);
        //     } else {
        //         this.setState({
        //             data: data.concat(allAppointments),
        //         });
        //         allAppointments = data.concat(allAppointments);
        //         //   this.setState({ totalEvents: data.length })
        //         //   console.log(data)
        //     }
        // });
        // await getSchedule('Fall 2022 Civil', this.state.token).then(data => {
        //     if (data.error) {
        //         console.log(data.error);
        //     } else {
        //         this.setState({
        //             data: data.concat(allAppointments),
        //         });
        //         allAppointments = data.concat(allAppointments);
        //         //   this.setState({ totalEvents: data.length })
        //         //   console.log(data)
        //     }
        // });
        // await getSchedule('Fall 2022 Business', this.state.token).then(data => {
        //     if (data.error) {
        //         console.log(data.error);
        //     } else {
        //         this.setState({
        //             data: data.concat(allAppointments),
        //         });
        //         allAppointments = data.concat(allAppointments);
        //         //   this.setState({ totalEvents: data.length })
        //         //   console.log(data)
        //     }
        // });
        this.setState({
            loading: false
        })
    };

    changeMainResource(mainResourceName) {
        this.setState({ mainResourceName });
    }

    setEventStartTime(timezone) {
        const timezoneOffset = (new Date()).getTimezoneOffset();


        var setTimeZoneTo = 10
        if (timezoneOffset === 'America/New_York') setTimeZoneTo = 10;
        if (timezoneOffset === 'America/Los_Angeles') setTimeZoneTo = 7;
        if (timezoneOffset === 'America/Chicago') setTimeZoneTo = 9;
        if (timezoneOffset === 'America/St_Johns') setTimeZoneTo = 11;
        if (timezoneOffset === 'America/Denver"') setTimeZoneTo = 8;
        // if (timezoneOffset === -480) setTimeZoneTo =  22;
        // alert(setTimeZoneTo)
        this.setState({
            currentTimeZone: setTimeZoneTo,
            endTime: 28
        })

    };

    componentDidMount() {
        // alert(this.props.mainResourceName)       
        this.handleScrollPosition();
        this.loadSchedule()

        this.setEventStartTime(Intl.DateTimeFormat().resolvedOptions().timeZone)
        this.setCommitteeName();
      
    };

    changeAddedAppointment(addedAppointment) {
        this.setState({ addedAppointment });
    }

    changeAppointmentChanges(appointmentChanges) {
        this.setState({ appointmentChanges });
    }

    changeEditingAppointment(editingAppointment) {
        this.setState({ editingAppointment });
    }

    commitChanges({ added, changed, deleted }) {

        this.setState({ loading: true })
        
        this.setState((state) => {
            let { data } = state;
            
            if (added) {
                const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
                data = [...data, { id: startingAddedId, ...added }];
            }
            if (changed) {
                data = data.map(appointment => (
                    changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment));                
            }
            if (deleted !== undefined) {
                data = data.filter(appointment => appointment.id !== deleted);
            }
            this.setState({
                data: data
            });

            // console.log(data)
            const scheduleBody = {
                eventName: this.state.scheduleName,
                updatedBy: isAuthenticated().userId,
                currentSchedule: data,
                lastSchedule: data
            }
            // createSchedule(scheduleBody) // first run, create schedule
            updateSchedule(scheduleBody)
            return { data };

        });

        this.setState({ loading: false })
       
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };


    // handle scroll position after content load
    handleScrollPosition = () => {
        const scrollPosition = sessionStorage.getItem("scrollPosition");
        if (scrollPosition) {
            window.scrollTo(0, parseInt(scrollPosition));
            sessionStorage.removeItem("scrollPosition");
        }
    };

    // store position in sessionStorage
    handleClick = e => {
        sessionStorage.setItem("scrollPosition", window.pageYOffset);
    };



    render() {
        const { data, resources, viewName, mainResourceName, isAdmin, addedAppointment, appointmentChanges, editingAppointment } = this.state
        console.log(this.state.isAdmin);
        return (
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading your calendar ...'
            >
                <Container>
                    <br />
                    <Row>

                        <Col className="col-md-6">
                            <ResourceSwitcher
                                resources={resources}
                                mainResourceName={mainResourceName}
                                onChange={this.changeMainResource}
                            />
                        </Col>

                        <Col className="col-md-6">

                            {this.props.chooseDays && <>
                                <InputLabel htmlFor="filled-day" >View Day:</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="viewName"
                                    value={'viewName'}
                                    onChange={this.handleChange("viewName")}
                                >
                                    <MenuItem value={[0, 1, 5, 6, 7]}>All</MenuItem>
                                    <MenuItem value={[0, 1, 3, 4, 5, 6, 7]}>May 3rd</MenuItem>
                                    <MenuItem value={[0, 1, 2, 4, 5, 6, 7]}>May 4th</MenuItem>
                                </Select>
                            </>
                            }
                        </Col>

                    </Row>
                    <br />


                    <Paper elevation={12} >
                        <Scheduler
                            data={data}
                            square={false}
                            showAllDayPanel={false}
                            height={this.props.calendarHeight}
                            crossScrollingEnabled={true}
                        >
                            <ViewState
                                defaultCurrentDate={this.props.currentDate}
                            />

                            <EditingState
                                onCommitChanges={this.commitChanges}

                                // addedAppointment={addedAppointment}
                                // onAddedAppointmentChange={this.changeAddedAppointment}

                                // appointmentChanges={appointmentChanges}
                                // onAppointmentChangesChange={this.changeAppointmentChanges}

                                // editingAppointment={editingAppointment}
                                // onEditingAppointmentChange={this.changeEditingAppointment}
                            />
                            <Toolbar />

                            <IntegratedEditing />
                            {/* <DayView /> */}
                            {!this.props.weekView && <MonthView />}
                            {!this.props.weekView && <DateNavigator />}

                            {this.props.weekView && <WeekView
                                groupOrientation={"Vertical"}
                                excludedDays={viewName}
                                startDayHour={7}
                            // endDayHour={this.state.endTime}
                            />}
                            <Appointments />
                            <AppointmentTooltip
                                onClick={this.handleClick}
                                headerComponent={Header}
                                contentComponent={Content}
                                showCloseButton
                                showOpenButton={isAdmin} // only admins can open
                            />
                            <AppointmentForm
                                basicLayoutComponent={BasicLayout}
                                textEditorComponent={TextEditor}
                                readOnly={!isAdmin} // only admins can edit
                            />

                            <Resources
                                data={resources}
                                mainResourceName={mainResourceName}
                            />
                            {/* <DragDropProvider /> */}
                        </Scheduler>
                    </Paper>


                </Container>
            </LoadingOverlay>
        )
    }
};

export default LiveEventCalendar;
