import React, { Component } from "react";
// import Posts from "../post/Posts";
import { Button, Form } from 'react-bootstrap';
import BMLogo from '../images/BurnsAndMcDonnelSponsorLogo.png';
import Hotel from '../images/hotel.JPG';
import Footer from './Footer';
import { sendMessage } from "../group/apigroup";
import moment from "moment";

class publiceventKC_Original extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            success: ''
        }
    }

    handleChange = name => event => {
        if (name === "email") {
            this.setState({ error: "" });
            this.setState({ responseTo: name, [name]: event.target.value });
        }
    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Your on the list!</h3>
                <p>Don't forget to check your inbox and your spam folder for updates from the SDSIC.</p>
                <p>We will use the email you've submitted to send you updates as we finalize the upcoming event.</p>
            </div>
        )
    };

    submitEmail = e => {
        const { email } = this.state;
        e.preventDefault();
        if (email === "") { this.setState({ error: "email required!" }) }

        if (email !== "") {
            const mailMessage = {
                to: 'trevor.scullion@automationforce.com',
                from: email,
                subject: "SDSIC 2022 FALL event list",
                Message: 'Please add ' + email + ' to the SDSIC 2022 FALL event list.',
                html: "<p>Please add " + email + " to the SDSIC 2022 FALL event list.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }
            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                this.setState({
                    email: '',
                    success: true,
                });
            });

            const userMessage = {
                to: email,
                from: 'donotreply@sdsconsortium.com',
                subject: "SDSIC 2022 FALL event",
                Message: 'Your name is on the SDSIC 2022 FALL event list.',
                html: "<p>Your name is on the SDSIC 2022 FALL event list.</p><p>You will be kept up to date " +
                    "as we finalize the event details.</p><p>Please do not reply to this email.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }

            // make request to api to send message
            sendMessage(userMessage).then(data => { });
        };
    };


    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">
                            <div className="jumbotron p-3 p-md-5 text-white rounded bg-dark">
                                <div className="row mb-2">
                                    <div className="col-md-6">
                                        <h1 className="display-4 font-italic">Our SDSIC 2022 FALL Events</h1>
                                        <h3>Oct 31st to Nov 3rd, 2022.</h3>

                                        <p className="lead my-3">Due to the Covid-19 Pandemic, our annual events have been virtual since 2019 but thanks to Burns & McDonnell,
                                            our 2022 FALL event will be hosted at the beautiful Sheraton Overland Park Hotel at the Convention Center in
                                            Overland Park, KS and we will finally be able to meet face to face.</p>
                                        <p className="lead mb-0">As we finalize the agenda of events, we will send out notifications but for now, register below and save your spot.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <iframe
                                            title="ifram1"
                                            src="https://sdsic-images.s3.amazonaws.com/MonthlyMeetingVideos/SDSIC_Spring_2022_Hype_Video_KC_Final_copy_New_Branding.mp4"
                                            width="600"
                                            height="400"
                                            frameBorder="0"
                                            allowFullScreen >
                                        </iframe>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-md-6">
                                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                                        <div className="card-body d-flex flex-column align-items-start">
                                            <strong className="d-inline-block mb-2 text-primary">Our 2022 Host</strong>
                                            <h3 className="mb-0">
                                                <a className="text-dark" href="https://www.burnsmcd.com/" target="_blank">Burns & McDonnell</a>
                                            </h3>
                                            <div className="mb-1 text-muted">Kansas City Headquarters</div>
                                            <p className="card-text mb-auto">Our annual events have been virtual since 2019 but thanks to Burns & McDonnell,
                                                our 2022 FALL event will be hosted at the beautiful Sheraton Overland Park Hotel at the Convention Center in
                                                Overland Park, KS and we will finally be able to meet face to face.</p>
                                            <a href="https://www.burnsmcd.com/locations/kansas-city-mo" target="_blank">Location Details</a>
                                        </div>
                                        <img className="card-img-right flex-auto d-none d-md-block" src={BMLogo} alt="BM" style={{ width: 300 }}></img>
                                        {/* <img className="card-img-right flex-auto d-none d-md-block" data-src="holder.js/200x250?theme=thumb" alt="Card image cap"/> */}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                                        <div className="card-body d-flex flex-column align-items-start">
                                            <strong className="d-inline-block mb-2 text-success">Our Conference Location</strong>
                                            <h4 className="mb-0">
                                                <a className="text-dark" href="https://www.marriott.com/en-us/hotels/mcicc-sheraton-overland-park-hotel-at-the-convention-center/overview/" target="_blank">Sheraton Overland Park Hotel and Convention Center</a>
                                                <br/>
                                                
                                            </h4>
                                            <br/>
                                            <p className="card-text mb-auto"> Our modern hotel adjoins the Overland Park Convention Center,
                                                just a short drive from Kansas City and MCI Airport. Rooms can be reserved at the Sheraton for a discounted rate of $139 a night <i>after you register</i>.</p>
                                            <br/>
                                            <a href="https://www.marriott.com/en-us/hotels/mcicc-sheraton-overland-park-hotel-at-the-convention-center/overview/" target="_blank">Hotel Details</a>
                                        </div>
                                        <img className="card-img-right flex-auto d-none d-md-block" src={Hotel} alt="hotel" style={{ width: 300 }}></img>
                                    </div>
                                </div>
                            </div>

                            <main role="main" className="container">
                                <div className="row">
                                    <div className="col-md-7 blog-main">
                                        <div className="blog-post">
                                            <h2 className="blog-post-title">Preliminary Conference Agenda</h2>
                                            <hr />
                                            
                                        </div>
                                       

                                    </div>
                                    <aside className="col-md-5 blog-sidebar">

                                        <div className="p-3">
                                            <h2 className="blog-post-title">
                                                Attendees
                                            </h2>
                                            <p>Registration fee is <strong>$525</strong> and covers three days of conference meetings, 
                                            three nights of fun evening events and meals.</p>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <a className="btn btn-lg btn-block btn-primary" href="/fallregistration">Register as an Attendee</a>
                                                </div>
                                            </div>
                                            <br />
                                            <hr />
                                            <br />
                                            <h2>
                                                Sponsors
                                            </h2>
                                            <p>If you are interested in Sponsoring this event, check out the details below.</p>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <a className="btn btn-lg btn-block btn-primary" width="100%" href="/fallSponsorRegistration">Register as a Sponsor</a>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <br />
                                        <div className="p-3 mb-3 bg-light rounded">
                                            <h4 className="font-italic">More Conference Details</h4>
                                            <p className="mb-0">
                                                Committee Breakout sessions are made up of each of our five committees building their own schedules made up of committee updates,
                                                peer Utility presentations, vendor presentations, round table discussions and interactive events throughout each day.
                                                Our schedule will be finalized as the conference nears and registered attendees will receive updated agendas prior to the conference kick-off.
                                            </p>
                                            <br />
                                            <p className="mb-0">
                                                Attendees should fly into Kansas City International Airport (MCI). The airport is located approximately 35 miles north of the Sheraton.
                                                Please utilize a rideshare service such as Uber or Lyft or rent a car for transportation to and from the airport
                                            </p>
                                        </div>



                                    </aside>

                                </div>
                                <br />
                                <br />
                                <div className="row">
                                    <div className="col-md-12 blog-main">
                                        <div className="blog-post">
                                            <h2 className="blog-post-title">Preliminary Committee Breakout Topics</h2>

                                            <hr />
                                            <h4>Business Strategy Committee Topics</h4>
                                            <ul>
                                                <li>Autodesk Construction Cloud (ACC)</li>
                                                <li>Autodesk Docs</li>
                                                <li>Autodesk Build</li>
                                                <li>Autodesk Takeoff</li>
                                                <li>BIM Collaborate Pro</li>
                                                <li>Panel discussion related to implementation of implementing the full suite of SDS and Autodesk products</li>
                                            </ul>
                                            <h4>Civil Committee Topics</h4>
                                            <ul>
                                                <li>Utilizing InfraWorks for Greenfield Designs</li>
                                                <li>Using Integrated Design and Critical Chain Methodologies</li>
                                                <li>Grading optimization tools for Civil3D</li>
                                                <li>Round tables to discuss a variety of topics surrounding best practices/workflows/standards</li>
                                            </ul>
                                            <h4>Electrical Committee Topics</h4>
                                            <ul>
                                                <li>SDS Refonly Tool – Change duplicate Master/Reference relationships project-wide</li>
                                                <li>SDS ADDCIRC Tool – Set up and insert circuits using SDS Add Circuit</li>
                                                <li>Customizing AutoCAD Electrical Symbol Builder for you company</li>
                                                <li>Project Workflow presentation by Duke Energy</li>
                                            </ul>
                                            <h4>Physical Committee Topics</h4>
                                            <ul>
                                                <li>3D Cloud tech (IACET presentation) - We’ll demo 3D cloud tech and various options to running inside firewalls.
                                                    - design automation
                                                    - translations
                                                    - Viewer tools
                                                </li>
                                                <li>How to Assemble a 3D substation by PPL - A substation is made up of a vast number of components, and how do we begin to build a 3D model of it. We’ll look at where to start with creating a 3D substation from parts to key assemblies. We’ll also discuss about getting started with that first 3D pilot.</li>
                                                <li>Model QA/QC - Discuss about doing 3d model QA/QC</li>
                                                <li>Spotlight on 3D at PPL - A look at the latest from PPL and their effort to move to full 3D  </li>
                                                <li>Vault by Hagerman & Company - Session featuring several functions and tips with Vault</li>
                                                <li>Highlighting rules-based Substation Design using SBS P4A</li>
                                                <li>Q&A with SBS Development Team - Focus on 3D with the likes of the Toolkit and P4A. </li>
                                                <li>Integrating Substation Civil Site Plans by SBS - Joint session with Civil/Structural committee </li>
                                                <li>Inventor iLogic - We’ll take at how a few consortium members have been using iLogic with their 3D models. Also discuss of ideas for iLogic, about sharing code, and answering questions</li>
                                                <li>Revit and Inventor by POWER Engineers - A look at moving/incorporating models from Revit to Inventor and Inventor to Revit</li>
                                            </ul>
                                            <h4>Technology Committee Topics</h4>
                                            <ul>
                                                <li>Orientation: A 20 minute intro to the Technology Committee</li>
                                                <li>The BIM platform Part A by Autodesk</li>
                                                <li>The BIM platform Part B by Bentley </li>
                                                <li>Scan to BIM – Round Table</li>
                                                <li>Managing content throughout the BIM process – Round Table</li>
                                                <li>Distributing content to field operations through the BIM process</li>
                                                <li>BIM and GIS – Substation and Transmission come together</li>
                                                <li>The Digital Twin as the holy grail</li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </main>



                        </div>

                    </div >

                </div >
                <Footer />
            </>
        )
    }
}

export default publiceventKC_Original