import React, { Component } from "react";
import { listByGroup } from "./apiForum";
import { Link } from "react-router-dom";
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js'

class ForumPosts extends Component {
    
    constructor() {
        super();
        this.state = {
            forumposts: [],
            page: 1
        };
    }

     loadPosts = (groupId, page) => {
        listByGroup(groupId, page).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                this.setState({ forumposts: data });
            }
        });
    };

    componentDidMount() {     
        this.loadPosts(this.props.groupId , this.state.page);
    }

    loadMore = number => {
        this.setState({ page: this.state.page + number });
        this.loadPosts(this.props.groupId, this.state.page + number);
    };

    loadLess = number => {
        this.setState({ page: this.state.page - number });
        this.loadPosts(this.props.groupId, this.state.page - number);
    };

    convertFromJSONToHTML = (text) => {
        try{
        return { __html: stateToHTML(convertFromRaw(text))}
        } catch(exp) {
        console.log(exp)
        return { __html: 'Error' }
        }
        }

    renderForumPosts = forumposts => {
        return (
            <div className="blog-post">
                {forumposts.map((forumpost, i) => {                    
                    const forumposterId = forumpost.forumpostedBy
                        ? `/user/${forumpost.forumpostedBy._id}`
                        : "";
                    const forumposterName = forumpost.forumpostedBy
                        ? forumpost.forumpostedBy.name
                        : " Unknown";
                        
                    return (
                        
                        <div className="card" style={{width: "100%"}} key={i}>
                            
                            <div className="card-body" >                                
                                <h5 className="card-title">{forumpost.title}</h5>
                                
                                {/* <p className="card-text">                                 
                                    {forumpost.body}
                                </p>
                                <br /> */}
                                <p className="font-italic mark">
                                    Posted by{" "}
                                    <Link to={`${forumposterId}`}>
                                        {forumposterName}{" "}
                                    </Link>
                                    on {new Date(forumpost.created).toDateString()}
                                </p>
                                <Link
                                    to={`/forumpost/${forumpost._id}`}
                                    className="btn btn-raised btn-primary btn-sm"
                                >
                                    Read more
                                </Link>
                            </div>
                        </div>
                   
                   );
                })}
            </div>
        );
    };

    render() {
        const { forumposts, page } = this.state;
        return (
            <div className="container">
                <h4 className="mt-5 mb-5">
                    {!forumposts.length ? "No more posts!" : forumposts.length + " posts"}
                </h4>

                {this.renderForumPosts(forumposts)}
   
                {page > 1 ? (
                    <button
                        className="btn btn-raised btn-warning mr-5 mt-5 mb-5"
                        onClick={() => this.loadLess(1)}
                    >
                        Previous ({page - 1})
                    </button>
                ) : (
                    ""
                )}

                {forumposts.length > 1 ? (
                    <button
                        className="btn btn-raised btn-success mt-5 mb-5"
                        onClick={() => this.loadMore(1)}
                    >
                        Next ({page + 1})
                    </button>
                ) : (
                    ""
                )}
                
                 
            </div>
        );
    }
}

export default ForumPosts;