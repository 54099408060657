
// with pagination
export const getFilesForGroup = groupId => {

    return fetch(`${process.env.REACT_APP_API_URL}/files/${groupId}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};



// Aggregates the groupId and the file categories available
export const listFilesBycategoryForGroup = (groupId, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/file/listfilesbycategory/${groupId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


// with pagination
export const getFiles = page => {
    return fetch(`${process.env.REACT_APP_API_URL}/files`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createDownloadLink = (token, fileInfo) => {

    return fetch(`${process.env.REACT_APP_API_URL}/file/downloadfile`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(fileInfo)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createNewFile = (token, userId, fileInfo) => {
    // /file/create/:userId
    return fetch(`${process.env.REACT_APP_API_URL}/file/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: fileInfo
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

